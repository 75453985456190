import { Button } from '@material-ui/core';
import React, { useEffect } from 'react';
import { useCustomerOpt } from '../../../core/store/useCustomer';
import useLinker from '../../../core/store/useLinker';
import { userRouter } from './user.router';

export const userHomeMyOrgsLabel = 'My Organizations';

export default function UserHomeScreen() {
  const linker = useLinker();
  const context = useCustomerOpt();

  useEffect(() => {
    if (context) {
      linker.navRoute(userRouter.route('org', { uri: context.customerConcept }));
    }
  }, [context, linker]);

  return context ? null : (
    <div>
      <ul>
        <li>
          <Button {...linker.routeButton(userRouter.route('orgs'))}>{userHomeMyOrgsLabel}</Button>
        </li>
      </ul>
    </div>
  );
}
