import { Dataset, DatasetKey } from '../model/analyticsProductModel';
import { key__timePeriod } from './timePeriod.model';

const createDataset = (inputs: Dataset): Dataset => inputs;

// =============================================================================

export const key__dataset: Record<DatasetKey, Dataset> = {
  ocmDefault: createDataset({
    productKey: 'ocm',
    key: 'ocmDefault',
    label: 'OCM Default',
    defaultTimePeriod: key__timePeriod.ocmPP1ToPP2,
    query: `\
WHERE paymentModel.isOCM = TRUE
WHERE ocmEpisodeSummary.isReconciliationEligible = TRUE
WHERE ocmEpisodeSummary.isComplete = TRUE
WHERE ocmEpisodeSummary.isAttributedToCustomer = TRUE`,
  }),
  ocmUnfiltered: createDataset({
    productKey: 'ocm',
    key: 'ocmUnfiltered',
    label: 'OCM Unfiltered',
    defaultTimePeriod: key__timePeriod.ocmPP1ToPP2,
    query: `\
WHERE paymentModel.isOCM = TRUE`,
  }),
  ocmBene: createDataset({
    productKey: 'ocm',
    key: 'ocmBene',
    label: 'OCM Patient',
    defaultTimePeriod: key__timePeriod.ocmBaselineToPP3,
    query: `WHERE ocmPatientSummary.ocmEpisodeCount != 0`,
  }),
  bpciaDefault: createDataset({
    productKey: 'bpcia',
    key: 'bpciaDefault',
    label: 'BPCI-A Completed',
    defaultTimePeriod: key__timePeriod.bpciaBaseline,
    query: `\
WHERE bpciaEpisodeSummary.isRetained = TRUE
WHERE bpciaEpisodeSummary.has90DaysElapsed = TRUE`,
  }),
  bpciaActive: createDataset({
    productKey: 'bpcia',
    key: 'bpciaActive',
    label: 'BPCI-A Active',
    defaultTimePeriod: key__timePeriod.bpciaPP1,
    query: `\
WHERE bpciaEpisodeSummary.isRetained = TRUE
WHERE bpciaEpisodeSummary.has90DaysElapsed = FALSE`,
  }),
  bpciaCompletedOrActive: createDataset({
    productKey: 'bpcia',
    key: 'bpciaCompletedOrActive',
    label: 'BPCI-A Completed & Active',
    defaultTimePeriod: key__timePeriod.bpciaBaselineToPP1,
    query: `\
WHERE bpciaEpisodeSummary.isRetained = TRUE`,
  }),
  bpciaEnrolledUnfiltered: createDataset({
    productKey: 'bpcia',
    key: 'bpciaEnrolledUnfiltered',
    label: 'BPCI-A Enrolled Unfiltered',
    defaultTimePeriod: key__timePeriod.bpciaBaseline,
    query: `\
WHERE bpciaEpisodeSummary.isEnrolledBundle = TRUE`,
  }),
  bpciaUnfiltered: createDataset({
    productKey: 'bpcia',
    key: 'bpciaUnfiltered',
    label: 'BPCI-A Unfiltered',
    defaultTimePeriod: key__timePeriod.bpciaBaseline, // TODO fix this
    query: ``,
  }),
};

// =============================================================================

const parseDatasetOpt = (s: string): Dataset | undefined =>
  s in key__dataset ? key__dataset[s as DatasetKey] : undefined;

export const parseDataset = (s: string): Dataset => {
  const dataset = parseDatasetOpt(s);
  if (!dataset) throw Error(`unknown dataset: ${s}`);
  return dataset;
};
