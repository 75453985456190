import React from 'react';
import { Helmet } from 'react-helmet';
import { MiniButton, RaisedButton } from '../../../components/workflow/buttons';
import { classes as wfClasses, StatusSection } from '../../../components/workflow/common';
import WorkflowFrame from '../../../components/workflow/WorkflowFrame';
import WorkflowTextField from '../../../components/workflow/WorkflowTextField';
import { Dictionary, WithMaybeError } from '@tuple-health/common';
import theme from '../../../theme';
import {
  PasswordResetInitiateProps as Props,
  PromptFields,
  PromptProps,
  WithSupportPath,
} from './props';

type State = Dictionary<string>;

export default class PasswordResetInitiate extends React.PureComponent<Props, State> {
  state = { ...this.props } as State;

  render() {
    return (
      <WorkflowFrame>
        <Helmet title="Account Recovery" />
        {Props.match({
          prompt: this.renderPrompt,
          pending: this.renderPrompt,
          complete: this.renderComplete,
        })(this.props)}
      </WorkflowFrame>
    );
  }

  renderPrompt = ({
    error,
    initiate,
    supportPath,
  }: PromptFields & Partial<PromptProps> & WithMaybeError) => {
    const submit =
      initiate &&
      (() => initiate({ email: this.state.email || '', phone: this.state.phone || '' }));

    const disabled = !submit;

    const tfprops = {
      state: this.state,
      setState: this.setState.bind(this),
      error,
      submit,
    };

    return (
      <>
        {Explanation}
        <WorkflowTextField {...tfprops} field="email" label="Email Address" />
        <WorkflowTextField {...tfprops} field="phone" label="Phone Number" />
        <RaisedButton style={{ marginTop: 16 }} disabled={disabled} onClick={submit}>
          Recover Account
        </RaisedButton>
        <StatusSection status={disabled ? 'pending' : { error, skipKeys: ['email', 'phone'] }} />
        <MiniButton style={{ marginTop: theme.spacing(3) }} path={supportPath}>
          Need additional help?
        </MiniButton>
      </>
    );
  };

  renderComplete = ({ supportPath }: WithSupportPath) => (
    <>
      {Complete}
      <MiniButton path={supportPath}>Need additional help?</MiniButton>
    </>
  );
}

const Explanation = (
  <>
    <h3 className={wfClasses.smallSpacer}>Account Recovery</h3>
    <p className={wfClasses.spacer}>
      Please provide the contact information for your account. We'll send you an email with a
      special link and a text message with a 6-digit verification code.
    </p>
  </>
);

const Complete = (
  <>
    <h3 className={wfClasses.smallSpacer}>Account Recovery</h3>
    <p className={wfClasses.spacer}>
      If the email and phone number match our records, you'll receive an email with the account
      recovery link, as well as a text message with a 6-digit verification code.
    </p>
  </>
);
