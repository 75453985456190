import { ofType, unionize } from 'unionize';
import { datavizFormat } from '../../../components/dataviz/format/datavizFormat';
import { Dataviz } from '../../../components/dataviz/model/dataviz.model';
import { HasTitle } from '../title/title.model';

// =============================================================================
// content
// =============================================================================

// =====================================
// bound
// =====================================

export const SlideContent = unionize(
  {
    richText: ofType<string>(),
    imageUrl: ofType<string>(), // or do we support image id/uri?
    dataviz: ofType<Dataviz>(),
  },
  { value: 'field' },
);
export type SlideContent = typeof SlideContent._Union;

// =====================================
// unbound
// =====================================

export const UnboundSlideContent = unionize(
  {
    richText: ofType<string>(), // unbound rich text
    imageSource: ofType<string>(), // image url or name within article
    datavizMarkup: ofType<string>(), // unbound dataviz yaml
  },
  { value: 'field' },
);
export type UnboundSlideContent = typeof UnboundSlideContent._Union;

// =====================================
// adapt
// =====================================

const unboundSlideContentWithoutVariables__slideContent = UnboundSlideContent.match<SlideContent>({
  richText: text => SlideContent.richText(text),
  imageSource: source => SlideContent.imageUrl(source),
  datavizMarkup: markup => SlideContent.dataviz(datavizFormat.parse(markup)),
});

const slideContent__unboundSlideContent = SlideContent.match<UnboundSlideContent>({
  richText: text => UnboundSlideContent.richText(text),
  imageUrl: url => UnboundSlideContent.imageSource(url),
  dataviz: dataviz => UnboundSlideContent.datavizMarkup(datavizFormat.write(dataviz)),
});

// =============================================================================
// slide
// =============================================================================

export type SlideView = 'hidden';

export interface SlideHead extends HasTitle {
  name?: string;
  view?: SlideView;
}

export interface SlideMixin extends SlideHead {
  // these are not applicable to text slides
  intro?: string;
  footnote?: string;
  caption?: string;
}

export interface Slide extends SlideMixin {
  content: SlideContent; // TODO array
}

export interface UnboundSlide extends SlideMixin {
  content: UnboundSlideContent; // TODO array
}

// =====================================
// adapt
// =====================================

export const unboundSlideWithoutVariables__slide = (unbound: UnboundSlide): Slide => ({
  ...unbound,
  content: unboundSlideContentWithoutVariables__slideContent(unbound.content),
});

export const slide__unboundSlide = (bound: Slide): UnboundSlide => ({
  ...bound,
  content: slideContent__unboundSlideContent(bound.content),
});
