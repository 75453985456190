import { merge as observableMerge } from 'rxjs';
import {
  distinctUntilChanged,
  distinctUntilKeyChanged,
  filter,
  mergeMap,
  skip,
  skipUntil,
  skipWhile,
  tap,
} from 'rxjs/operators';
import { pathTo } from '../../app/locs';
import { isPublic } from '../../app/locScreens';
import { Authn } from '../../contracts/Authn';
import { GatewayStatusContract } from '../../contracts/GatewayStatus';
import { makeAgent, NOOP } from '../../core/agent';
import { Router } from '../../core/appbase/router.service';
import { makeService } from '../../core/service';
import { logoutSlug } from '../../platform/apps/user/user.router';
import { watchVersion } from './watchVersion';

const agent = makeAgent({
  authn: Authn,
  router: Router,
  status: GatewayStatusContract,
})($ =>
  observableMerge(
    // listen for navigation changes
    $.pipe(
      skipWhile(({ router }) => !router.location),
      distinctUntilKeyChanged('router'),
      tap(({ authn, router }) => {
        const location = router.location!;
        if (Authn.is.none(authn)) {
          if (!isPublic(location.screen)) {
            // redirect all pages to login when not authenticated
            // router.redirectTo(loginScreen, {}, { from: location.path });
            router.redirectTo(pathTo('login', {}, { from: location.path }));
          }
        } else if (Authn.is.session(authn)) {
          if (isPublic(location.screen)) {
            // redirect login screen to home when already logged in
            navigateToCustomerHome({ router });
          }
        }
      }),
    ),

    // listen for session changes, skipping initialization
    $.pipe(
      skipWhile(({ router }) => !router.location),
      distinctUntilChanged((x, y) => Authn.is.session(x.authn) === Authn.is.session(y.authn)),
      skip(1),
      tap(({ authn, router }) => {
        if (Authn.is.session(authn)) {
          // upon session start, redirect to home if we're at the login page
          if (isPublic(router.location!.screen)) {
            navigateToCustomerHome({ router });
          }
        } else {
          // upon session expiration, redirect to login page
          router.redirectTo(pathTo('login', {}, { from: router.location!.path }));
        }
      }),
    ),

    // Log out if any fetch returns a 401 while we're logged in
    $.pipe(
      distinctUntilChanged((x, y) => x.status.last === y.status.last),
      filter(({ status }) => status.last.tag === 'response' && status.last.code === 401),
      tap(({ authn }) => {
        // TODO
        // - this causes the client to know we logged out, which is good
        // - it has the side effect of attempting a logout request, which will always fail
        if (Authn.is.session(authn)) authn.initiateLogout();
      }),
    ),

    // Watch for page navigation and reload if necessary
    $.pipe(
      distinctUntilKeyChanged('router'),
      skipUntil(watchVersion()),
      tap(() => window.location.reload()),
    ),
  ).pipe(mergeMap(NOOP.observable)),
);

function navigateToCustomerHome({ router }: { router: Router }) {
  const { query } = router.location!; // verified before calling this function

  // console.log('navigateToCustomerHome, query = ', query);

  if (hasFrom(query) && !query.from.includes(logoutSlug)) {
    // console.log('navigateToCustomerHome. using from: ', query.from);
    router.redirectTo(query.from);
  } else {
    const path = pathTo('platform', {});
    // console.log('navigateToCustomerHome. router.redirect to: ', path);
    // user home page [if the user belongs to no customer orgs]
    // TODO ugly to update in two ways, but needed to get test login.spec tests to
    router.redirectTo(path);
  }
}

function hasFrom(query?: {}): query is { from: string } {
  return !!query && 'from' in query;
}

export const service = makeService({
  agent,
});
