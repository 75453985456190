import { Menu, Popover } from '@material-ui/core';
import React, { ReactNode, useCallback, useState } from 'react';
import { tuple } from '@tuple-health/common';

export type MenuClickEvent = React.MouseEvent<HTMLElement, MouseEvent>;

const menuZIndex = 999999999; // needs to be above everything else

// =============================================================================
// ContextMenu
// TODO perhaps rename to PointerMenu?
// =============================================================================

export interface ContextMenuPosition {
  top: number;
  left: number;
}

interface ContextMenuHookProps {
  position: ContextMenuPosition;
  onClose: () => void;
}

interface ContextMenuProps extends ContextMenuHookProps {
  children: ReactNode;
}

export function ContextMenu({ position, onClose, children }: ContextMenuProps) {
  return (
    <Popover
      open
      anchorReference="anchorPosition"
      anchorPosition={position}
      onClose={onClose}
      onClick={onClose}
      style={{ zIndex: menuZIndex }}
    >
      {children}
    </Popover>
  );
}

export function useContextMenu(): [ContextMenuHookProps | undefined, (e: MenuClickEvent) => void] {
  const [position, setPosition] = useState<ContextMenuPosition>();

  const onOpen = useCallback((e: MenuClickEvent) => setPosition(handleContextMenuEvent(e)), []);

  const onClose = useCallback(() => setPosition(undefined), []);

  return tuple(position && { position, onClose }, onOpen);
}

export function handleContextMenuEvent(event: MenuClickEvent): ContextMenuPosition {
  event.stopPropagation();
  event.preventDefault();
  const { clientY: top, clientX: left } = event;
  return { top, left };
}

// =============================================================================
// AnchorMenu
// =============================================================================

interface AnchorMenuProps {
  anchor: HTMLElement;
  onClose: () => void;
  children: ReactNode;
  stayOpenOnClick?: boolean;
}

export function AnchorMenu({ anchor, onClose, stayOpenOnClick, children }: AnchorMenuProps) {
  return (
    <Menu
      open
      anchorEl={anchor}
      onClose={onClose}
      onClick={stayOpenOnClick ? undefined : onClose}
      style={{ zIndex: menuZIndex }}
    >
      {children}
    </Menu>
  );
}

// prefer useAnchorToggle
export function useAnchorMenu(): [
  HTMLElement | undefined,
  (e: MenuClickEvent) => void,
  () => void,
] {
  const { anchor, open, close } = useAnchorToggle();
  return [anchor, open, close];
}

export function useAnchorToggle(): AnchorToggle {
  const [anchor, setAnchor] = useState<HTMLElement>();

  const open = useCallback((e: MenuClickEvent) => {
    e.preventDefault();
    e.stopPropagation();
    setAnchor(e.currentTarget);
  }, []);

  const close = useCallback(() => {
    setAnchor(undefined);
  }, []);

  return { anchor, open, close };
}

export interface AnchorToggle {
  anchor: HTMLElement | undefined;
  open: (e: MenuClickEvent) => void;
  close: () => void;
}
