import { OnboardSummary } from '@tuple-health/eng/dist/th/ds/common/product/customer/onboard/OnboardSummary';
import { ofType } from 'unionize';
import { makeProps } from '../../core/props';
import { WithError, WithMaybeError } from '@tuple-health/common';

// -- otp -- //
export interface WithPhoneOtp {
  phoneOtp: string;
}

export interface OtpPromptProps {
  summary: OnboardSummary;
  phoneOtp?: string;
}

interface OtpSendCallbacks {
  sendOtp(): void;
  supportWrongNumberPath: string;
}

interface OtpSubmitCallbacks {
  onResend(params: WithPhoneOtp): void;
  onSubmit(params: WithPhoneOtp): void;
  supportWrongNumberPath: string;
  resent: boolean;
  supportNoCodePath: string;
}

// -- password -- //
interface WithPassword {
  password: string;
}

export interface SubmitPasswordParams extends WithPassword {
  password2: string;
}

export interface PasswordPromptProps extends OtpPromptProps, Partial<SubmitPasswordParams> {}

interface PasswordPromptCallbacks {
  onSubmit(params: SubmitPasswordParams): void;
}

// -- props union -- //
export const RegistrationProps = makeProps(
  {
    initializing: {},
    error: ofType<WithError>(),
    otpSendPrompt: ofType<OtpPromptProps & WithMaybeError & OtpSendCallbacks>(),
    otpSendPending: ofType<OtpPromptProps>(),
    otpSubmitPrompt: ofType<OtpPromptProps & WithMaybeError & OtpSubmitCallbacks>(),
    otpSubmitPending: ofType<OtpPromptProps & { resent: boolean }>(),
    passwordPrompt: ofType<PasswordPromptProps & WithMaybeError & PasswordPromptCallbacks>(),
    passwordPending: ofType<PasswordPromptProps>(),
    accountCreated: {},
  },
  'status',
);

export type RegistrationProps = typeof RegistrationProps._Union;
