import { RefObject, useLayoutEffect, useRef, useState } from 'react';
import { Size } from '@tuple-health/common';

function useSize<T>(ref: RefObject<HTMLElement>, project: (size: Size) => T): T;
function useSize(ref: RefObject<HTMLElement>): Size;
function useSize(ref: RefObject<HTMLElement>, project = (size: Size) => size) {
  const [state, setState] = useState(() => project(zeroSize));

  const lastSizeRef = useRef(zeroSize);

  const beforeLayout = ref.current;
  useLayoutEffect(() => {
    const { current } = ref;

    if (beforeLayout !== current) handleResize();

    if (!current) {
      return;
    }

    const { ResizeObserver } = window as any;

    if (typeof ResizeObserver === 'function') {
      let alive = true;

      // Why the requestAnimationFrame? See:
      // https://github.com/WICG/ResizeObserver/issues/38
      // https://github.com/hshoff/vx/pull/335
      const resizeObserver = new ResizeObserver(() =>
        requestAnimationFrame(() => alive && handleResize()),
      );

      resizeObserver.observe(current);

      return () => {
        alive = false;
        resizeObserver.disconnect(current);
      };
    } else {
      window.addEventListener('resize', handleResize);

      return () => window.removeEventListener('resize', handleResize);
    }

    function handleResize() {
      let size = zeroSize;

      if (current) {
        size = {
          width: current.offsetWidth,
          height: current.offsetHeight,
        };
      }

      const lastSize = lastSizeRef.current;
      if (lastSize.width === size.width && lastSize.height === size.height) size = lastSize;
      else lastSizeRef.current = size;

      setState(project(size));
    }
  });

  return state;
}

export default useSize;

export const useWidth = (ref: RefObject<HTMLElement>) => useSize(ref, dims => dims.width);
export const useHeight = (ref: RefObject<HTMLElement>) => useSize(ref, dims => dims.height);

const zeroSize: Size = { width: 0, height: 0 };
