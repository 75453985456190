import * as _ from '@tuple-health/eng/dist/dryscript/ds';
import { Embed, embedFormat, isEmbedStartLine } from '../../embed/embed.format';
import { UnboundRichLink } from '../../richLink/richLink.model';
import { writeUnboundRichTextMarkup } from '../../richText/richText.write';
import { ArticleVar, ArticleVarType, parseArticleVarType } from '../../variable/articleVar.model';

export const skipBlankLines = (remainingLines: string[]) => {
  while (remainingLines.length) {
    if (_.strs.isBlank(remainingLines[0])) {
      remainingLines.shift();
    } else {
      break;
    }
  }
};

export const nextEmbed = (remainingLines: string[]): string => {
  const embedOpt = nextEmbedTextOpt(remainingLines);
  if (embedOpt === undefined) throw Error('did not find embed');
  return embedOpt;
};

const nextEmbedTextOpt = (remainingLines: string[]): string | undefined => {
  skipBlankLines(remainingLines);

  const firstLine = remainingLines[0].trim();

  if (firstLine.startsWith('```{')) {
    const lines: string[] = [remainingLines.shift()!];
    while (remainingLines.length) {
      const line = remainingLines.shift()!;
      lines.push(line);
      if (line.trim() === '```') break;
    }

    return lines.join('\n');
  }

  if (firstLine.startsWith('`')) {
    return remainingLines.shift();
  }

  return undefined;
};

export const nextEmbedOpt = (remainingLines: string[]): Embed | undefined => {
  const text = nextEmbedTextOpt(remainingLines);
  return text === undefined ? undefined : embedFormat.parse(text);
};

// =============================================================================
// text
// =============================================================================

// =====================================
// write text
// =====================================

export const normalizeText = (text: string): string => text.trim();

// =====================================
// parse text
// =====================================

export const parseOptionalText = (remainingLines: string[]): string | undefined => {
  skipBlankLines(remainingLines);

  const textLineUIs = [];
  while (remainingLines.length && !shouldEndText(remainingLines[0])) {
    textLineUIs.push(remainingLines.shift());
  }
  return textLineUIs.length ? textLineUIs.join('\n').trim() : undefined;
};

// =====================================
// parse text with links
// =====================================

export const parseOptionalTextWithLinks = (
  remainingLines: string[],
  links: UnboundRichLink[],
): string | undefined => {
  const textWithUuids = parseOptionalText(remainingLines);
  return textWithUuids === undefined ? undefined : writeUnboundRichTextMarkup(textWithUuids, links);
};

export const parseRequiredTextWithLinks = (
  remainingLines: string[],
  links: UnboundRichLink[],
): string => {
  const textWithLinks = parseOptionalTextWithLinks(remainingLines, links);
  return textWithLinks || '';
};

// =============================================================================
// delimiters
// =============================================================================

// TODO reuse code from content/embed
export const codeStop = '```';
export const isCodeStop = (line: string) => line.startsWith(codeStop);

// =============================================================================
// code block reusable
// =============================================================================

// =====================================
// var
// =====================================

export const writeVar = ({ name, type }: ArticleVar): string => {
  let s = name;
  if (type) s += `:${writeType(type)}`;
  return s;
};

const writeType = (type: ArticleVarType): string => {
  if (typeof type === 'string') return type;
  return `table<${type.map(writeType).join(', ')}>`;
};

export const parseVar = (text: string): ArticleVar => {
  if (_.strs.isBlank(text)) throw Error('variable name cannot be blank');
  const parts = text.split(':');
  if (parts.length > 2) throw Error('var names should contain at most one :');
  return {
    name: parts[0],
    type: parts.length > 1 ? parseArticleVarType(parts[1]) : undefined,
  };
};

// =============================================================================
// helpers
// =============================================================================

export const skipSlideStop = (remainingLines: string[]) => {
  skipBlankLines(remainingLines);
  if (remainingLines.length && isSlideStop(remainingLines[0])) remainingLines.shift();
};

// =============================================================================
// delimiters
// =============================================================================

// =====================================
// code/yaml chunks
// =====================================

export const fullRowStart = '`row ';
export const emptyRow = '`row`';
const isRowStart = (line: string) => line.startsWith(fullRowStart) || line === emptyRow;

export const rowStop = '`rowStop`';
const isRowStop = (line: string) => line.trim() === rowStop;

// =====================================

const slideConfStart = '`slide ';
export const isSlideConfStart = (line: string) => line.startsWith(slideConfStart);

// =====================================

// page title is required
const pageTitlePrefix = '# ';
export const isPageTitle = (line: string) => line.startsWith(pageTitlePrefix);

// chapter title is required
const chapterTitlePrefix = '## ';
export const isChapterTitle = (line: string) => line.startsWith(chapterTitlePrefix);

// slide title is optional
export const slideTitlePrefix = '###';
export const isSlideTitle = (line: string) =>
  line === slideTitlePrefix || line.startsWith(slideTitlePrefix + ' ');

const promptPrefix = '### [';
export const isPrompt = (line: string) =>
  line.startsWith(promptPrefix) &&
  !line.startsWith('### [subtitle') &&
  !line.startsWith('### [shortTitle');

const slideStopPrefix = '---';
export const isSlideStop = (line: string) => line.startsWith(slideStopPrefix);

const defineTextStart = `${codeStop}{define `;
const isDefineStart = (line: string) => line.startsWith(defineTextStart);

const defineQueryStart = `${codeStop}{query `;
const isQueryStart = (line: string) => line.startsWith(defineQueryStart);

const defineJsStart = `${codeStop}{js `;
const isJsStart = (line: string) => line.startsWith(defineJsStart);

// =====================================

export const shouldEndSegment = (line: string): boolean =>
  isChapterTitle(line) || // ##
  isSlideTitle(line) || // ###
  isPrompt(line) || // ###
  isSlideStop(line) || // --- ||
  isRowStart(line) ||
  isRowStop(line) ||
  isDefineStart(line) ||
  isQueryStart(line) ||
  isJsStart(line);

const shouldEndText = (line: string): boolean => shouldEndSegment(line) || isEmbedStartLine(line);
