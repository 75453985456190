import { TextField } from '@material-ui/core';
import { GenericMsg } from '@tuple-health/eng/dist/dryscript/lib/common/ui/content/msg/GenericMsg';
import React from 'react';

export interface WorkflowTextFieldProps {
  field: string;
  state: Record<string, any>;
  setState: (state: Record<string, any>) => void;
  label: string;
  error: GenericMsg | undefined;
  submit?: () => void;
  type?: string;
  multiline?: number;
}

export default React.memo(
  ({
    field,
    state,
    setState,
    label,
    error,
    submit,
    type = 'text',
    multiline = 0,
  }: WorkflowTextFieldProps) => {
    const disabled = !submit;

    return (
      <TextField
        {...{ type, label, disabled }}
        variant="outlined"
        margin="dense"
        onKeyPress={
          submit &&
          (ev => {
            if (ev.key === 'Enter' && (!multiline || ev.ctrlKey || ev.altKey || ev.metaKey)) {
              ev.preventDefault();
              submit();
            }
          })
        }
        onChange={e => setState({ [field]: e.target.value })}
        id={`textfield--${field}`}
        value={state[field] || ''}
        error={error && error.key__text.keys.has(field)}
        helperText={(error && error.key__text.call(field)) || undefined}
        multiline={!!multiline}
        rows={multiline}
      />
    );
  },
);
