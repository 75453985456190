import { pipe } from '@tuple-health/common';

type ExpectEq<A> = (actual: A, expected: A) => void;

/** An isomorphism between (a subset of) A and (a subset of) B */
export interface Adapter<A, B> {
  parse: (a: A) => B;
  write: (b: B) => A;
  then<C>(next: Adapter<B, C>): Adapter<A, C>;
  verify: (expectEq: ExpectEq<A>, item: A) => B;
  verify2: (a: A, b?: B) => B;
  verifier: (expectEq: ExpectEq<A>) => (item: A) => void;
}

export function toAdapter<A, B>(parse: (a: A) => B, write: (b: B) => A): Adapter<A, B> {
  const then = <C>(next: Adapter<B, C>) =>
    toAdapter(pipe(parse, next.parse), pipe(next.write, write));

  const verify = (expectEq: ExpectEq<A>, item: A): B => {
    const parsed = parse(item);
    const written = write(parsed);
    expectEq(written, item);
    return parsed;
  };

  const verify2 = (a: A, b?: B): B => {
    // console.log('a', a);
    const parsed = parse(a);
    // console.log('parsed', parsed);
    const written = write(parsed);
    // console.log('written', written);
    expect(a).toEqual(written);
    if (b !== undefined) expect(b).toEqual(parsed);
    return parsed;
  };

  return {
    parse,
    write,
    then,
    verify,
    verify2,
    verifier: expectEq => item => verify(expectEq, item),
  };
}
