import * as toUri from '@tuple-health/eng/dist/dryscript/lib/common/prelude/record/toUri';
import { ofType, unionize } from 'unionize';
import { Route } from '@tuple-health/common/dist/router';
import { Slide } from '../slide/slide.model';
import { Cohort } from '../../../components/cohort/model/cohort.model';

export interface PreviewableUri {
  ns: 'patient' | 'clinician';
  key: string;
}

export const parseConceptResource = (curie: string) => {
  const previewableUri = parsePreviewableUri(curie);
  if (!previewableUri) throw Error(`could not parse previewable uri: ${curie}`);
  return Resource.conceptUri(previewableUri);
};

const parsePreviewableUri = (curie: string): PreviewableUri | undefined => {
  const { ns, key } = toUri.parse(curie);
  switch (ns) {
    case 'patient':
    case 'clinician':
      return { ns, key };
  }
};

// =============================================================================

export const Resource = unionize(
  {
    // new tab
    externalUrl: ofType<string>(),
    // same tab
    route: ofType<Route<any>>(), // means we provide the router when parsing resources
    // contextual content
    conceptUri: ofType<PreviewableUri>(),
    richText: ofType<string>(),
    slide: ofType<Slide>(),
    cohort: ofType<Cohort>(),
  },
  { value: 'field' },
);
export type Resource = typeof Resource._Union;
