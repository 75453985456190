import Button, { ButtonProps } from '@material-ui/core/Button';
import React from 'react';
import { style } from 'typestyle';
import { classNames } from '../../core/styles';
import theme from '../../theme';

const classes = {
  miniButton: style({
    padding: '0 !important',
    background: 'transparent !important',
    minWidth: '0 !important',
    fontWeight: 'normal !important' as 'normal',
    textTransform: 'none !important' as 'none',
    minHeight: 'auto',
    color: '#6498e8',
    marginLeft: theme.spacing(1),
  }),
};

export function RaisedButton({ path, ...props }: ButtonProps & { path?: string }) {
  return <Button href={path} variant="contained" color="primary" {...props} />;
}

export function PlainButton({ path, ...props }: ButtonProps & { path?: string }) {
  return <Button href={path} color="primary" disableFocusRipple disableRipple {...props} />;
}

export function MiniButton({ path, ...props }: ButtonProps & { path?: string }) {
  return (
    <Button
      href={path}
      color="secondary"
      disableFocusRipple
      disableRipple
      {...props}
      className={classNames(classes.miniButton, props.className)}
    />
  );
}
