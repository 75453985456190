import { ofType, unionize } from 'unionize';
import { makeContract } from '../core/contract';

export const GatewayStatus = unionize({
  initial: {},
  notOnline: {},
  serverNotFound: {},
  response: ofType<{ code: number }>(),
});
export type GatewayStatus = typeof GatewayStatus._Union;

export const GatewayStatusContract = makeContract<{
  last: GatewayStatus;
  update(status: GatewayStatus): void;
}>('gatewayStatus');
