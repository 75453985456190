import { Drawer, LinearProgress } from '@material-ui/core';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import * as _ from '@tuple-health/eng/dist/dryscript/ds';
import { GatewaySession } from '@tuple-health/eng/dist/th/ds/common/agent/gatewayApi/service/session/GatewaySession';
import { Customer } from '@tuple-health/eng/dist/th/ds/common/domain/customer/customer/Customer';
import React, {
  createContext,
  lazy,
  memo,
  ReactElement,
  Suspense,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { Helmet } from 'react-helmet';
import { profileHasChatRole } from '../../components/messaging/chatUtils';
import { ChatCreator } from '../../components/messaging/chatAgent';
import {
  userDrawerClosedWidth,
  userDrawerOpenWidth,
  userDrawerTransitionStyle,
} from '../../components/messaging/userDrawer/UserDrawerView';
import ScrollContainerContext from '../../components/util/ScrollContainerContext';
import { isIE } from '../../core/browser';
import { useHeight, useWidth } from '../../core/hooks/useSize';
import { CustomerCreator } from '../../core/store/useCustomer';
import { ProductProvider } from '../../core/store/useProduct';
import useUserDrawer from '../../core/store/useUserDrawer';
import theme from '../../theme';
import { Route, RouteContext } from '@tuple-health/common/dist/router';
import CustomerMenu from './CustomerMenu';
import Logo from './Logo';
import NotFound from './NotFound';
import css from './Platform.css';
import { ScreenComponent } from './screen.model';
import SideMenu from './SideMenu';
import TabMenu from './TabMenu';
import TopMenu from './TopMenu';
import TermsOfUseDialog from './TermsOfUseDialog';
import WelcomeDialog from './WelcomeDialog';
import { ErrorBoundary } from 'react-error-boundary';
import UncaughtError from '../../components/UncaughtError';
import { Concept } from '@tuple-health/common/dist/data';

// Import these lazily because they bring in a boatload of dependencies:
const SearchBox = lazy(() => import('./SearchBox')); // 10K
const CrumbBar = lazy(() => import('./CrumbBar')); // 20K
const UserDrawer = lazy(() => import('../../components/messaging/userDrawer/UserDrawer')); // 278K
const appBrandTitle = 'Framework Health';

interface Props {
  session: GatewaySession;
  navMenu: RouteContext<any>;
  screenRoute: Route<any> | undefined;
  customerRecord: _.Record<Customer> | undefined;
  customerRoute: Route<any> | undefined;
  productConcept: Concept | undefined;
  productRoute: Route<any> | undefined;
  screenKey: string;
  Screen: ScreenComponent | undefined;
}

export default function Platform({
  session,
  navMenu,
  screenRoute,
  customerRecord,
  customerRoute,
  productConcept,
  productRoute,
  screenKey,
  Screen,
}: Props) {
  const drawer = useUserDrawer();

  const topBarRef = useRef<HTMLDivElement>(null);
  const topBarHeight = useHeight(topBarRef);

  const locationBarRef = useRef<HTMLDivElement>(null);
  const locationBarHeight = useHeight(locationBarRef);

  const sideBarRef = useRef<HTMLDivElement>(null);
  const sideBarWidth = useWidth(sideBarRef);

  const topOffset = topBarHeight + locationBarHeight;

  const [userDrawerLoaded, setUserDrawerLoaded] = useState(false);
  const userDrawerWidth = useMemo(
    () => (userDrawerLoaded ? (drawer.isOpen ? userDrawerOpenWidth : userDrawerClosedWidth) : 0),
    [drawer.isOpen, userDrawerLoaded],
  );

  // Force unmounting and remounting the tab menu when the fallback unmounts to
  // fix disappearing tab indicator. See:
  // https://github.com/mui-org/material-ui/issues/14077#issuecomment-484026845
  const [tabMenuKey, setTabMenuKey] = useState(0);
  const Fallback = useCallback(() => {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    useEffect(() => () => setTabMenuKey(key => key + 1), []);
    return <LinearProgress />;
  }, []);

  return (
    <TopOffsetContext.Provider value={topOffset}>
      <LeftOffsetContext.Provider value={sideBarWidth}>
        <RightOffsetContext.Provider value={userDrawerWidth}>
          <Helmet title="Tuple Health" />
          {renderCustomer(
            renderProduct(
              renderChat(
                <>
                  {renderTopBar()}
                  {renderSideBar()}
                  {renderLocationBar()}
                  {renderMain()}
                  {renderUserDrawer()}
                </>,
              ),
            ),
          )}
          <TermsOfUseDialog />
          <WelcomeDialog />
        </RightOffsetContext.Provider>
      </LeftOffsetContext.Provider>
    </TopOffsetContext.Provider>
  );

  function renderChat(children: ReactElement) {
    if (!profileHasChatRole(session)) return children;
    return <ChatCreator>{children}</ChatCreator>;
  }

  function renderCustomer(children: ReactElement) {
    if (!customerRecord) return children;
    return (
      <CustomerCreator customerRecord={customerRecord} uris={customerRoute && customerRoute.uris}>
        {children}
      </CustomerCreator>
    );
  }

  function renderProduct(children: ReactElement) {
    if (!productConcept) return children;
    return (
      <ProductProvider
        productKey={productConcept.uri.id}
        uris={productRoute && productRoute.uris}
        params={productRoute && productRoute.params}
      >
        {children}
      </ProductProvider>
    );
  }

  function renderTopBar() {
    return (
      <AppBar
        className={css.appBar}
        position="fixed"
        style={{
          zIndex: theme.zIndex.drawer - 1,
        }}
      >
        <div ref={topBarRef}>
          <Toolbar className={css.toolbar}>
            <Logo />

            <Suspense fallback={null}>
              <SearchBox />
            </Suspense>
            <span className={css.appBrandTitle}>{appBrandTitle}&trade;</span>
            <div className={css.filler} />

            <TopMenu />
            <div
              className={css.customerMenu}
              style={userDrawerTransitionStyle({
                marginRight: userDrawerWidth,
              })}
            >
              <CustomerMenu />
            </div>
          </Toolbar>
        </div>
      </AppBar>
    );
  }

  function renderSideBar() {
    if (!navMenu.sideMenu) return null;
    const menu = navMenu.sideMenu;
    return (
      <Drawer variant="permanent" classes={{ paper: css.sidebarPaper }}>
        <div ref={sideBarRef} style={{ marginTop: topBarHeight + theme.spacing(2) }}>
          {menu.label && <div className={css.sidebarLabel}>{menu.label}</div>}
          <SideMenu menu={menu} />
        </div>
      </Drawer>
    );
  }

  function renderLocationBar() {
    return (
      screenRoute &&
      screenRoute.crumbPaths.length > 1 && (
        <div
          ref={locationBarRef}
          className={css.locationBar}
          style={{
            top: topBarHeight,
            left: sideBarWidth,
            ...userDrawerTransitionStyle({ right: userDrawerWidth }),
          }}
        >
          <Suspense fallback={null}>
            <CrumbBar route={screenRoute} />
          </Suspense>
          <div key={tabMenuKey}>
            {navMenu.tabMenu ? (
              <TabMenu menu={navMenu.tabMenu} />
            ) : (
              // If the tabs aren't there, add some padding between
              // the bottom of the crumb bar and the drop shadow
              <div style={{ height: theme.spacing(1) }} />
            )}
          </div>
        </div>
      )
    );
  }

  function renderMain() {
    return (
      <div
        className={css.content}
        style={{
          top: topOffset,
          left: sideBarWidth,
          ...userDrawerTransitionStyle({ right: userDrawerWidth }),
        }}
      >
        <Suspense fallback={<Fallback />}>
          <MainScreen key={screenKey} Screen={Screen} />
        </Suspense>
      </div>
    );
  }

  function renderUserDrawer() {
    return (
      <Suspense fallback={null}>
        <UserDrawer ref={elem => setUserDrawerLoaded(Boolean(elem))} />
      </Suspense>
    );
  }
}

export const TopOffsetContext = createContext(0);
export const RightOffsetContext = createContext(0);
export const LeftOffsetContext = createContext(0);

// isolated component so only rerenders when the screen component changes
const MainScreen = memo(function MainScreen({ Screen }: { Screen?: ScreenComponent }) {
  // IE 11 doesn't support the <main> element
  const Main = isIE ? 'div' : 'main';

  const mainId = 'main';

  return (
    <ScrollContainerContext.Provider value={{ containerId: mainId }}>
      <Main id={mainId} className={css.main} style={{ right: 0 }}>
        <ErrorBoundary FallbackComponent={UncaughtError}>
          {Screen ? <Screen /> : <NotFound />}
        </ErrorBoundary>
      </Main>
    </ScrollContainerContext.Provider>
  );
});
