import { Button, LinearProgress } from '@material-ui/core';
import React, { lazy, useEffect } from 'react';
import useRequiredContext from '../../../core/hooks/useRequiredContext';
import useApi from '../../../core/store/useApi';
import useInitialConvo from '../../../core/store/useInitialConvo';
import useLinker from '../../../core/store/useLinker';
import useUser from '../../../core/store/useUser';
import useUserDrawer from '../../../core/store/useUserDrawer';
import ChangePassword from '../../../screens/changePassword/ChangePassword';
import { ChangePasswordStore } from '../../../screens/changePassword/changePassword.store';
import { routerKinds } from '../../platform/v1PlatformConcepts';
import { App } from '../../platform/app.model';
import NotFound from '../../platform/NotFound';
import { adaptScreen, defineScreen } from '../../platform/screen.model';
import { adaptPlatformCustomer, adaptPlatformSession } from '../../platform/session.adapt';
import UserRouteContext from '../../platform/UserRouteContext';
import { UserLoc, userRouter } from './user.router';
import UserHomeScreen from './UserHomeScreen';

const InvitedUsersScreen = lazy(() =>
  import(
    /* webpackChunkName: "manage_invitations" */ '../../../screens/admin/invitations/InvitedUsersScreen'
  ),
);

const signout = defineScreen(() => {
  const { logout } = useUser();
  logout();
  return null;
});

const changePassword = defineScreen(() => (
  <ChangePassword store={new ChangePasswordStore(useApi())} />
));

const org = defineScreen(() => <NotFound />);

const orgs = defineScreen(() => {
  const linker = useLinker();
  const { session } = useUser();
  return (
    <div>
      <ul>
        {[...session.customerRecords].map((customerRecord, i) => (
          <li key={i}>
            <Button
              {...linker.routeButton(
                userRouter.route('org', {
                  uri: adaptPlatformCustomer(customerRecord).customerConcept,
                }),
              )}
            >
              {customerRecord.data.name}
            </Button>
          </li>
        ))}
      </ul>
    </div>
  );
});

const conversations = defineScreen(() => <div>Conversations</div>);

const conversation = defineScreen(() => {
  const linker = useLinker();
  const drawer = useUserDrawer();
  const api = useApi();
  const { setInitialConvo } = useInitialConvo();
  const { session, canChat } = useUser();
  const userRoute = useRequiredContext(UserRouteContext);

  const conversationId = userRoute.ns__uri[routerKinds.conversation.ns].id;

  useEffect(() => {
    let active = true;
    void api.userLookupConversation({ conversationId }).then(threadDto => {
      if (!active) return;
      const customerRecord = session.id__customerRecord.call(threadDto.customerId);
      if (customerRecord) {
        linker.navCustomerId(customerRecord.id);

        if (canChat) {
          drawer.open();
          setInitialConvo({ id: conversationId, customerId: threadDto.customerId });
        }
      } else {
        // eslint-disable-next-line no-alert
        alert(`Hmm, something went wrong`);
      }
    });
    return () => {
      active = false;
    };
  }, [canChat, conversationId, drawer, linker, session.id__customerRecord, setInitialConvo, api]);

  return <LinearProgress />;
});

const userApp: App<UserLoc> = {
  router: userRouter,
  lookupSearchingConcepts: (session, _runRequest, handle) => {
    handle(adaptPlatformSession(session).customers.map(c => c.customerConcept));
  },
  screens: {
    home: UserHomeScreen,
    signout,
    changePassword,
    conversations,
    conversation,
    invitations: adaptScreen(InvitedUsersScreen),
    orgs,
    org,
  },
};

export default userApp;
