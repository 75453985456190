import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Menu,
  MenuItem,
} from '@material-ui/core';
import React, { useState, useCallback } from 'react';
import { useCustomerOpt } from '../../core/store/useCustomer';
import useLinker from '../../core/store/useLinker';
import { useProductOpt } from '../../core/store/useProduct';
import useUser from '../../core/store/useUser';
import theme from '../../theme';

interface State {
  anchorEl?: HTMLElement;
  switchingCustomer?: boolean;
  switchingProduct?: boolean;
}

export default function CustomerMenu() {
  const linker = useLinker();
  const { userCustomerRecords } = useUser();
  const customerContext = useCustomerOpt();
  const productContext = useProductOpt();

  const [state, setState] = useState<State>({});

  const { anchorEl } = state;
  const open = Boolean(anchorEl);

  const canSwitchCustomer = userCustomerRecords.length > 1;
  const canSwitchProduct = customerContext && [...customerContext.customer.productKeys].length > 1;
  const hasMenu = canSwitchCustomer || canSwitchProduct;

  const buttonFontColor = theme.palette.primary.main;

  const handleMenu = useCallback(
    (event: any) => {
      setState({ ...state, anchorEl: event.currentTarget });
    },
    [state],
  );

  const closeMenu = useCallback(() => {
    setState({ ...state, anchorEl: undefined });
  }, [state]);

  const closeCustomer = useCallback(() => {
    setState({ ...state, switchingCustomer: undefined });
  }, [state]);

  const openCustomer = useCallback(() => {
    setState({ ...state, anchorEl: undefined, switchingCustomer: true });
  }, [state]);

  const closeProduct = useCallback(() => {
    setState({ ...state, switchingProduct: undefined });
  }, [state]);

  const openProduct = useCallback(() => {
    setState({ ...state, anchorEl: undefined, switchingProduct: true });
  }, [state]);

  // TODO ideally not a button when hasMenu=false
  // however that changes styling, so instead just making cursor: default for now
  return (
    <div>
      <Button
        style={{
          color: buttonFontColor,
          fontSize: 10,
          textAlign: 'center',
          cursor: hasMenu ? 'pointer' : 'default',
        }}
        aria-owns={open ? 'customer-menu' : undefined}
        aria-haspopup="true"
        onClick={hasMenu ? handleMenu : undefined}
        color="inherit"
      >
        <div>
          <div>{customerContext && customerContext.customer.name}</div>
          <div>{productContext && productContext.productConcept.label}</div>
          {/* {hasMenu && <SpringIcon style={{ marginLeft: 5 }} />} */}
        </div>
      </Button>
      <Menu
        id="customer-menu"
        anchorEl={anchorEl}
        // anchorOrigin={{
        //   vertical: 'bottom',
        //   horizontal: 'right',
        // }}
        // transformOrigin={{
        //   vertical: 'bottom',
        //   horizontal: 'right',
        // }}
        open={open}
        onClose={closeMenu}
      >
        {canSwitchCustomer && (
          <MenuItem onClick={openCustomer}>
            Switch Organization ({customerContext!.customer.name})
          </MenuItem>
        )}
        {canSwitchProduct && (
          <MenuItem onClick={openProduct}>
            Switch Application ({productContext!.productConcept.label})
          </MenuItem>
        )}
      </Menu>
      {renderCustomer()}
      {renderProduct()}
    </div>
  );

  function renderCustomer() {
    if (!state.switchingCustomer) return undefined;
    const items: Item[] = userCustomerRecords.map(customerRecord => ({
      label: customerRecord.data.name,
      onClick: () => {
        closeCustomer();
        linker.navCustomerId(customerRecord.id);
      },
    }));
    return renderDialogMenu(items, 'Switch Organization', closeCustomer);
  }

  function renderProduct() {
    if (!state.switchingProduct) return undefined;
    if (!customerContext) return undefined;
    const items: Item[] = customerContext.customerProductConcepts.map(product => ({
      label: product.labelOrId,
      onClick: () => {
        closeProduct();
        linker.navProductKey(product.uri.id);
      },
    }));
    return renderDialogMenu(items, 'Switch Application', closeProduct);
  }
}

interface Item {
  label: string;
  onClick(): void;
}

const renderDialogMenu = (items: Item[], title: string, close: () => void) => (
  <Dialog maxWidth="md" open scroll="paper" onClose={close}>
    <DialogTitle>{title}</DialogTitle>
    <DialogContent>
      {items.map((item, i) => (
        <MenuItem key={i} onClick={item.onClick}>
          {item.label}
        </MenuItem>
      ))}
    </DialogContent>
    <DialogActions style={{ justifyContent: 'space-between' }}>
      <Button onClick={close}>Cancel</Button>
    </DialogActions>
  </Dialog>
);
