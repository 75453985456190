import { useEffect, useRef } from 'react';

/**
 * Based on:
 * @see https://overreacted.io/making-setinterval-declarative-with-react-hooks/
 */
export default function useInterval(callback: () => void, delay: number, runImmediately = true) {
  const savedCallback = useRef(callback);

  // Remember the latest callback.
  useEffect(() => {
    savedCallback.current = callback;
  });

  // Run the callback once at the beginning
  useEffect(() => {
    runImmediately && savedCallback.current();
  }, [runImmediately]);

  // Set up the interval.
  useEffect(() => {
    const id = setInterval(() => savedCallback.current(), delay);
    return () => clearInterval(id);
  }, [delay]);
}
