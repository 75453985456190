import * as _ from '@tuple-health/eng/dist/dryscript/ds';
import { Person } from '@tuple-health/eng/dist/th/ds/common/domain/person/person/Person';
import { ConvoEditUserEvent } from '@tuple-health/eng/dist/th/ds/common/product/user/thread/event/ConvoEditUserEvent';
import { lookupAndAdaptPerson, PersonModel } from '../person/person.model';

interface AddRemoveUserModel {
  user: PersonModel;
}

export interface AddRemoveUserEntry extends AddRemoveUserModel {
  id: string;
  uri: string;
  creator: PersonModel;
  created: Date;
  type: 'addUser' | 'removeUser';
}

export const parseAddRemoveUserRecord = (
  record: _.Record<ConvoEditUserEvent>,
  id__person: _.Dict<string, Person>,
  type: 'addUser' | 'removeUser',
  author?: Person,
): AddRemoveUserEntry => ({
  id: record.id,
  uri: record.uri.curie,
  user: lookupAndAdaptPerson(id__person, record.data.userId, author),
  creator: lookupAndAdaptPerson(id__person, record.creatorId!, author),
  created: new Date(record.created!),
  type,
});
