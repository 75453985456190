import React from 'react';
import { classNames } from '../../core/styles';
import CenteredFrame from '../CenteredFrame';
import css from './WorkflowFrame.css';

interface WorkflowFrameProps {
  classes?: {
    root?: string;
    paper?: string;
  };
  hideBranding?: boolean;
  children?: React.ReactNode;
}

export default function WorkflowFrame(props: WorkflowFrameProps) {
  const { classes = {}, hideBranding = false } = props;
  return (
    <CenteredFrame
      classes={{
        root: classes.root,
        paper: classNames(css.paper, classes.paper),
      }}
    >
      {!hideBranding && (
        <h1 className={css.logo}>
          <span className={css.logoTuple}>Framework</span>
          <span className={css.logoHealth}>Health&trade;</span>
          <span className={css.logoSubhead}>from Tuple Health</span>
        </h1>
      )}
      {props.children}
    </CenteredFrame>
  );
}
