import { createContext, useContext, useMemo, FC, createElement, memo } from 'react';
import { makeApi, Api, ApiKeys, addFetchKeys, getFetchKeys } from '../../services/gateway/api';
import { useCustomerOpt } from './useCustomer';
import { useGatewayOpt } from './useGateway';
import { useProductOpt } from './useProduct';
import { useUserOpt } from './useUser';

const Context = createContext<Api | undefined>(undefined);

export const ApiProvider: FC<{ value: Api }> = memo(function ApiProvider({ value, children }) {
  Object.entries(value).forEach(([name, f]) => {
    if (!getFetchKeys(f)) {
      addFetchKeys(f, name);
    }
  });
  return createElement(Context.Provider, { value }, children);
});

export default function useApi(overrides: ApiKeys = {}): Api {
  const fromContext = useContext(Context);

  const gateway = useGatewayOpt();

  const userContext = useUserOpt();
  const session = overrides.session || (userContext && userContext.session);

  const customerContext = useCustomerOpt();
  const customerId = overrides.customerId || (customerContext && customerContext.customerId);

  const productContext = useProductOpt();
  const productKey = overrides.productKey || (productContext && productContext.productKey);

  const fromHook = useMemo(() => {
    if (!gateway) return undefined;
    return makeApi(gateway, { session, customerId, productKey });
  }, [customerId, gateway, productKey, session]);

  const api = fromContext || fromHook;
  if (!api) throw Error('API not available');

  return api;
}
