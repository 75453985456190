import { HttpStatus, HttpResponse, HttpHeaders, HttpRequest } from '@tuple-health/common/dist/http';

export async function fetchHttpConnect(request: HttpRequest): Promise<HttpResponse> {
  let response: Response;
  let text: string;
  try {
    response = await fetch(request.url, {
      method: request.method,
      body: request.body,
      // timeout: 30000,
    });

    text = await response.text();
    if (response.status === 500 && isProxyError(text))
      // Lump these in with fetch errors
      throw new Error(text);
  } catch (e) {
    throw e;
    // if (!navigator.onLine && isOfflineError(e.message)) {
    //   status.update(GatewayStatus.notOnline());
    //   callback(request, makeFailReply(offlineFail));
    // } else {
    //   status.update(GatewayStatus.serverNotFound());
    //   callback(request, makeFailReply(connectionFail));
    // }
  }

  let headers: HttpHeaders | undefined = undefined;
  response.headers.forEach((key: string, val: string) => {
    if (!headers) headers = {};
    headers[key] = val;
  });
  // status.update(GatewayStatus.response({ code: response.status }));

  return HttpResponse.create(response.status as HttpStatus, {
    body: text,
    ...(!headers ? {} : { headers }),
  });
}

function isProxyError(message: string) {
  return message.startsWith('Proxy error: Could not proxy request');
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
function isTimeoutError(message: string) {
  return message.startsWith('ETIMEOUT');
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
function isNetworkError(message: string) {
  return message.startsWith('NetworkError');
}
