import { Profile } from '@tuple-health/eng/dist/th/ds/common/domain/person/profile/Profile';
import { GatewaySession } from '@tuple-health/eng/dist/th/ds/common/agent/gatewayApi/service/session/GatewaySession';

// TODO, make chat not dependent on customer context
// (probably currently only used to highlight the current customer Chips)
export function profileHasChatRole(session: GatewaySession) {
  // TODO session.customerRecords is undefined in some integration tests for some reason
  return session.customerRecords && session.customerRecords.notEmpty;
}

export function hasTupleEmail(profile: Profile): boolean {
  return profile__emails(profile).some(handle => /@tuplehealth.com$/.test(handle));
}

// =====================================
// =====================================

// function hasCanaryChatEmail(profile: Profile): boolean {
//   return profile__emails(profile).some(isCanaryChatEmail);
// }

// function isCanaryChatEmail(email: string) {
//   return canaryChatEmails.includes(email) || email.includes('@tnonc.com');
// }

// const canaryChatEmails = ['kenny.cole@cox.net'];

function profile__emails(profile: Profile): string[] {
  return [...profile.emailHandleArray];
}
