import * as _ from '@tuple-health/eng/dist/dryscript/ds';
import { Array, Static } from 'runtypes';
import { VizElement, vizElementsEqual } from './VizElement';

export const VizSelection = Array(VizElement);
export type VizSelection = Static<typeof VizSelection>;

const empty: VizSelection = [];

export const vizSelectionLib = {
  empty,

  has(self: VizSelection, element: VizElement): boolean {
    return self.findIndex(other => vizElementsEqual(element, other)) >= 0 ? true : false;
  },

  // ===========================================================================
  // edits
  // ===========================================================================

  add(self: VizSelection, element: VizElement): VizSelection {
    return vizSelectionLib.has(self, element) ? self : [...self, element];
  },

  remove(self: VizSelection, element: VizElement): VizSelection {
    return self.filter(other => !vizElementsEqual(other, element));
  },

  toggle(self: VizSelection, element: VizElement): VizSelection {
    return vizSelectionLib.has(self, element)
      ? vizSelectionLib.remove(self, element)
      : vizSelectionLib.add(self, element);
  },

  selectedUris(self: VizSelection) {
    return _.toSet.call(self.flatMap(e => ('concept' in e ? [e.concept] : [])));
  },

  equals(self: VizSelection, other: VizSelection): boolean {
    if (self === other) return true;
    if (self.length !== other.length) return false;
    const result = self.every((elem, index) => vizElementsEqual(elem, other[index]));
    return result;
  },
};
