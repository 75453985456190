import { Divider, Drawer, Fab, List, ListItem, MenuItem, Tooltip } from '@material-ui/core';
import React, { CSSProperties, ReactElement, ReactNode } from 'react';
import { Transition } from 'react-transition-group';
import { TransitionStatus } from 'react-transition-group/Transition';
import { classNames } from '../../../core/styles';
import { userMenuTestId } from '../../../platform/platform/UserMenu';
import {
  AddIcon,
  ArticleIcon,
  PrivateConvoIcon,
  ProfileIcon,
  PublicConvoIcon,
  SlideLeftIcon,
  SlideRightIcon,
} from '../../icons/icons';
import { AnchorMenu, useAnchorMenu } from '../../Menus';
import css from './UserDrawerView.css';
import UserNavItem from './UserNavItem';
import { root as navItemClass } from './UserNavItem.css';

const userDrawerNavWidth = 44; // was 40 but needed 45 to fit the unread badge
export const userDrawerContentWidth = 350;
export const userDrawerClosedWidth = userDrawerNavWidth;
export const userDrawerOpenWidth = userDrawerNavWidth + userDrawerContentWidth;
const transitionDuration = 100;
export const userDrawerTransitionStyle = <K extends keyof CSSProperties>(
  style: Pick<CSSProperties, K>,
) => ({
  ...style,
  transitionProperty: Object.keys(style).map(toKebabCase).join(', '),
  transitionDuration: `${transitionDuration}ms`,
  transitionTimingFunction: 'ease-out',
});

interface Props {
  userMenu: ReactElement;
  top?: ReactNode;
  bottom?: ReactNode;
  create?: CreateProps;
  toggle: () => void;
  children?: ReactNode; // panel
  open: boolean;
  canOpen: boolean;
}

export default function UserDrawerView({ children, open, canOpen, ...props }: Props) {
  return (
    <Drawer classes={{ paper: css.paper }} variant="permanent" anchor="right">
      <Transition in={open} timeout={transitionDuration}>
        {state => (
          <div
            className={css.expander}
            style={userDrawerTransitionStyle({ width: getWidth(state) })}
          >
            <div className={classNames(css.content, { [css.contentOpen]: state === 'entered' })}>
              {children}
            </div>
          </div>
        )}
      </Transition>
      <Transition in={open} timeout={transitionDuration}>
        {state => <Nav open={state !== 'exited'} canOpen={canOpen} {...props} />}
      </Transition>
    </Drawer>
  );

  function getWidth(status: TransitionStatus) {
    switch (status) {
      case 'entering':
      case 'entered':
        return userDrawerContentWidth;
      default:
        return 0;
    }
  }
}

function Nav({ userMenu, top, bottom, create, toggle, open, canOpen }: Props) {
  return (
    <div
      className={css.nav}
      style={{ width: userDrawerNavWidth, borderLeft: `${open ? 1 : 0}px solid #e5e5e5` }}
    >
      <div className={css.navHeader}>
        <UserButton menuContent={userMenu} />
      </div>
      <List>
        {top}
        {create && (
          <>
            <Divider className={css.divider} />
            <ListItem className={navItemClass} style={{ paddingTop: 10 }}>
              <CreateButton {...create} />
            </ListItem>
          </>
        )}
      </List>
      <List style={{ marginTop: 'auto' }}>
        {bottom}
        {bottom && <Divider />}
        {canOpen && (
          <UserNavItem title={open ? 'Hide Sidebar' : 'Open Sidebar'} onClick={toggle}>
            {open ? <SlideRightIcon /> : <SlideLeftIcon />}
          </UserNavItem>
        )}
      </List>
    </div>
  );
}

interface CreateProps {
  onCreateConvo?: (isPublic: boolean) => void;
  onCreateArticle?: () => void;
}

function UserButton({ menuContent }: { menuContent: ReactNode }) {
  const [menuAnchor, openMenu, closeMenu] = useAnchorMenu();

  return (
    <>
      <Tooltip title="My Account" placement="left">
        <ListItem button className={navItemClass} onClick={openMenu} data-testid={userMenuTestId}>
          <ProfileIcon />
        </ListItem>
      </Tooltip>
      {menuAnchor && (
        <AnchorMenu anchor={menuAnchor} onClose={closeMenu}>
          {menuContent}
        </AnchorMenu>
      )}
    </>
  );
}
function CreateButton({ onCreateConvo, onCreateArticle }: CreateProps) {
  const [menuAnchor, openMenu, closeMenu] = useAnchorMenu();

  return (
    <>
      <Tooltip title="Create" placement="left">
        <Fab
          color="primary"
          aria-label="add"
          size="small"
          onClick={openMenu}
          style={{ height: 24, width: 24, minHeight: 24, minWidth: 24 }}
        >
          <AddIcon style={{ height: 20, width: 20 }} />
        </Fab>
      </Tooltip>
      {menuAnchor && (
        <AnchorMenu anchor={menuAnchor} onClose={closeMenu}>
          {onCreateConvo && [
            <MenuItem key="private" onClick={() => onCreateConvo(false)}>
              <PrivateConvoIcon className={css.menuIcon} /> New direct message
            </MenuItem>,
            <MenuItem key="public" onClick={() => onCreateConvo(true)}>
              <PublicConvoIcon className={css.menuIcon} /> New team discussion
            </MenuItem>,
          ]}
          {onCreateArticle && (
            <MenuItem onClick={() => {}}>
              <ArticleIcon className={css.menuIcon} /> New document
            </MenuItem>
          )}
        </AnchorMenu>
      )}
    </>
  );
}

function toKebabCase(s: string) {
  return s
    .split(/(?=[A-Z])/)
    .join('-')
    .toLowerCase();
}
