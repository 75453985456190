import * as _ from '@tuple-health/eng/dist/dryscript/ds';
import * as uuidLib from '@tuple-health/eng/dist/dryscript/lib/common/format/uuid/uuidLib';
import { pick } from '@tuple-health/common';
import { Router } from '@tuple-health/common/dist/router';
import { Resource } from '../resource/resource.model';
import { toResourceEmbedFormat } from '../resource/resourceEmbed.format';

interface RichLinkMixin {
  anchorMarkdown: string;
  uuid: string;
  productKey?: string;
}

export interface RichLink extends RichLinkMixin {
  resource: Resource;
}

export interface ProductRichLink extends RichLink {
  productKey: string;
}

export interface UnboundRichLink extends RichLinkMixin {
  resourceMarkup: string;
}

// =============================================================================
// constructors
// =============================================================================

interface Options {
  anchorLabel?: string;
  anchorMarkdown?: string;
  productKey?: string;
}

const toRichLinkMixin = ({ anchorMarkdown, anchorLabel, productKey }: Options): RichLinkMixin => {
  if (anchorLabel && anchorMarkdown) throw Error('cannot specify anchorLabel and anchorMarkdown');
  if (!anchorLabel && !anchorMarkdown) throw Error('must specify anchorLabel or anchorMarkdown');
  if (!anchorMarkdown) anchorMarkdown = label__markdown(anchorLabel!);
  return {
    anchorMarkdown: anchorMarkdown.trim(),
    productKey,
    uuid: uuidLib.call(),
  };
};

// not pretty, but need to turn concept labels into anchor text, and markdown is allowed in anchor text
// so strip anchor text terminators and unintended markdown styling characters
// (ideally would escape, but we don't handle that for anchor terminators, but markdown might for their styling characters)
// https://github.com/adam-p/markdown-here/wiki/Markdown-Cheatsheet
function label__markdown(text: string) {
  for (const char of charsToStrip) {
    text = _.strs.replace(text, char, '');
  }
  return text;
}
const charsToStrip = ['[', ']', '*', '#'];

// =====================================

export const toRichLink = ({
  resource,
  ...options
}: Options & { resource: Resource }): RichLink => ({
  ...toRichLinkMixin(options),
  resource,
});

export const toProductRichLink = (options: Options & { resource: Resource }): ProductRichLink =>
  toRichLink(options) as ProductRichLink;

export const toUnboundRichLink = ({
  resourceMarkup,
  ...options
}: Options & { resourceMarkup: string }): UnboundRichLink => ({
  ...toRichLinkMixin(options),
  resourceMarkup,
});

// =====================================
// adapt
// =====================================

export const unboundRichLinkWithoutVariables__richLink = (
  router: Router<any>,
  unboundLink: UnboundRichLink,
): RichLink => ({
  ...pick(unboundLink, 'uuid', 'anchorMarkdown', 'productKey'),
  resource: toResourceEmbedFormat(router).parse(unboundLink.resourceMarkup),
});

export const richLink__unboundRichLink = (
  router: Router<any>,
  link: RichLink,
): UnboundRichLink => ({
  ...pick(link, 'uuid', 'anchorMarkdown', 'productKey'),
  resourceMarkup: toResourceEmbedFormat(router).write(link.resource),
});
