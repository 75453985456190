import * as _ from '@tuple-health/eng/dist/dryscript/ds';
import { HttpReply } from '@tuple-health/eng/dist/dryscript/lib/common/format/http/HttpReply';
import { HttpRequest } from '@tuple-health/eng/dist/dryscript/lib/common/format/http/HttpRequest';
import * as toHttpReply from '@tuple-health/eng/dist/dryscript/lib/common/format/http/toHttpReply';
import * as toMedia from '@tuple-health/eng/dist/dryscript/lib/common/format/media/toMedia';
import * as toUrl from '@tuple-health/eng/dist/dryscript/lib/common/format/url/toUrl';
import * as toGatewayTransport from '@tuple-health/eng/dist/th/ds/common/agent/gatewayApi/transport/toGatewayTransport';
import { consoleSerializer as serializer } from '@tuple-health/eng/dist/th/ds/common/consoleSerializer';
import { Gateway } from '../../contracts/Gateway';
import { GatewayStatus, GatewayStatusContract } from '../../contracts/GatewayStatus';
import { makeProvider } from '../../core/provider';
import { makeSelector } from '../../core/selector';
import { makeGatewayService } from './gateway';

const remoteGatewayProvider = makeProvider(
  Gateway,
  makeSelector({ status: GatewayStatusContract })(({ status }) => {
    const respond = (_httpRequest: HttpRequest): _.Reply<HttpReply> => {
      throw new Error('Console cannot use sync http');
    };

    const respondAsync = async (
      request: HttpRequest,
      callback: (request: HttpRequest, reply: _.Reply<HttpReply>) => void,
    ) => {
      let response: Response;
      let text: string;
      try {
        response = await xfetch(request.url, {
          method: request.method,
          body: request.body,
          timeout: 30000,
        });

        text = await response.text();
        if (response.status === 500 && isProxyError(text))
          // Lump these in with fetch errors
          throw new Error(text);
      } catch (e) {
        if (!navigator.onLine && isOfflineError(e.message)) {
          status.update(GatewayStatus.notOnline());
          callback(request, makeFailReply(offlineFail));
        } else {
          status.update(GatewayStatus.serverNotFound());
          callback(request, makeFailReply(connectionFail));
        }

        return;
      }

      const head = _.toFluidDict.of<string, string>();
      response.headers.forEach(head.put_.bind(head));

      const body = _.bins.str__utf8(text);
      const httpReply = toHttpReply.call(response.status, { head: head.freeze, body });

      status.update(GatewayStatus.response({ code: response.status }));

      callback(request, _.reply.ok(httpReply));
    };

    const httpClient = _.toMap.create<HttpRequest, HttpReply>({ respond, respondAsync });

    const urlLoc = toUrl.parseLoc(window.location.protocol + '//' + window.location.host);
    const urlEndpoint = urlLoc.withMsg(toUrl.msg.parse('api'));

    return toGatewayTransport.call({
      httpClient,
      urlEndpoint,
      serializer,
    }).service;
  }),
);

export const remoteGatewayService = makeGatewayService(remoteGatewayProvider);

// ------------------------------
//  fetch + timeout
// ------------------------------
function xfetch(input: RequestInfo, init: RequestInit & { timeout?: number } = {}) {
  const { timeout } = init;
  const fetchPromise = fetch(input, init);

  if (!timeout) return fetchPromise;

  const url = typeof input === 'string' ? input : input.url;
  const errorMsg = `ETIMEOUT: timed out after ${timeout}ms while requesting ${url}`;
  const error = new Error(errorMsg); // capture stack trace where called

  const timeoutPromise = new Promise<Response>((_, reject) =>
    setTimeout(() => reject(error), timeout),
  );

  return Promise.race([fetchPromise, timeoutPromise]);
}

// ------------------------------
//  Error Helpers
// ------------------------------
function isOfflineError(message: string) {
  return isProxyError(message) || isTimeoutError(message) || isNetworkError(message);
}

function isProxyError(message: string) {
  return message.startsWith('Proxy error: Could not proxy request');
}

function isTimeoutError(message: string) {
  return message.startsWith('ETIMEOUT');
}

function isNetworkError(message: string) {
  return message.startsWith('NetworkError');
}

export const connectionFail = _.toFail.call('2y6ca1ysSxm8WFhy8i5En1', 'Failed to contact server');
export const offlineFail = _.toFail.call('2y6ca1ysSxm8WFhy8i5En2', 'No connection to the internet');

function makeFailReply(fail: _.Fail) {
  const code = 599;
  const gatewayReply = _.reply.call(code, { error: fail });
  const json = serializer.write(gatewayReply);
  const media = toMedia.call({ str: json });
  const contentReply = _.reply.call(code, { body: media });
  const httpReply = toHttpReply.write(contentReply);
  return _.reply.call(code).withBody(httpReply);
}
