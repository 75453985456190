import { CubeCatalog } from '@tuple-health/eng/dist/dryscript/lib/common/cube/catalog/CubeCatalog';
import { schema as houseSchema } from '@tuple-health/eng/dist/th/ds/common/product/customer/house/cube/houseCube';
import { Route, Router, RouterLoc } from '@tuple-health/common/dist/router';
import { CatalogKey, RelnKey } from '../../../services/query/queryBuilder';

// =============================================================================
// time period
// =============================================================================

export type TimePeriodKey =
  | 'ocmAllTime'
  | 'ocmBaseline'
  | 'ocmPP1'
  | 'ocmBaselineToPP1'
  | 'ocmPP2'
  | 'ocmBaselineToPP2'
  | 'ocmPP1ToPP2'
  | 'ocmPP3'
  | 'ocmBaselineToPP3'
  | 'ocmPP1ToPP3'
  | 'ocmPP2ToPP3'
  | 'ocmPP4'
  | 'ocmBaselineToPP4'
  | 'ocmPP1ToPP4'
  | 'ocmPP2ToPP4'
  | 'ocmPP3ToPP4'
  | 'ocmPP5'
  | 'ocmBaselineToPP5'
  | 'ocmPP1ToPP5'
  | 'ocmPP2ToPP5'
  | 'ocmPP3ToPP5'
  | 'ocmPP4ToPP5'
  | 'ocmPP6'
  | 'ocmBaselineToPP6'
  | 'ocmPP1ToPP6'
  | 'ocmPP2ToPP6'
  | 'ocmPP3ToPP6'
  | 'ocmPP4ToPP6'
  | 'ocmPP5ToPP6'
  | 'ocmPP7'
  | 'ocmBaselineToPP7'
  | 'ocmPP1ToPP7'
  | 'ocmPP2ToPP7'
  | 'ocmPP3ToPP7'
  | 'ocmPP4ToPP7'
  | 'ocmPP5ToPP7'
  | 'ocmPP6ToPP7'
  | 'ocmPP8'
  | 'ocmBaselineToPP8'
  | 'ocmPP1ToPP8'
  | 'ocmPP2ToPP8'
  | 'ocmPP3ToPP8'
  | 'ocmPP4ToPP8'
  | 'ocmPP5ToPP8'
  | 'ocmPP6ToPP8'
  | 'ocmPP7ToPP8'
  | 'ocmPP9'
  | 'ocmBaselineToPP9'
  | 'ocmPP1ToPP9'
  | 'ocmPP2ToPP9'
  | 'ocmPP3ToPP9'
  | 'ocmPP4ToPP9'
  | 'ocmPP5ToPP9'
  | 'ocmPP6ToPP9'
  | 'ocmPP7ToPP9'
  | 'ocmPP8ToPP9'
  | 'ocmPP10'
  | 'ocmBaselineToPP10'
  | 'ocmPP1ToPP10'
  | 'ocmPP2ToPP10'
  | 'ocmPP3ToPP10'
  | 'ocmPP4ToPP10'
  | 'ocmPP5ToPP10'
  | 'ocmPP6ToPP10'
  | 'ocmPP7ToPP10'
  | 'ocmPP8ToPP10'
  | 'ocmPP9ToPP10'
  | 'ocmPP11'
  | 'ocmBaselineToPP11'
  | 'ocmPP1ToPP11'
  | 'ocmPP2ToPP11'
  | 'ocmPP3ToPP11'
  | 'ocmPP4ToPP11'
  | 'ocmPP5ToPP11'
  | 'ocmPP6ToPP11'
  | 'ocmPP7ToPP11'
  | 'ocmPP8ToPP11'
  | 'ocmPP9ToPP11'
  | 'ocmPP10ToPP11'
  | 'bpciaBaseline'
  | 'bpciaPP1'
  | 'bpciaBaselineToPP1'
  | 'bpciaPP2'
  | 'bpciaBaselineToPP2'
  | 'bpciaPP1ToPP2';

export const timePeriodNames: TimePeriodKey[] = [
  'ocmAllTime',
  'ocmBaseline',
  'ocmPP1',
  'ocmBaselineToPP1',
  'ocmPP2',
  'ocmBaselineToPP2',
  'ocmPP1ToPP2',
  'ocmPP3',
  'ocmBaselineToPP3',
  'ocmPP1ToPP3',
  'ocmPP2ToPP3',
  'ocmPP4',
  'ocmBaselineToPP4',
  'ocmPP1ToPP4',
  'ocmPP2ToPP4',
  'ocmPP3ToPP4',
  'ocmPP5',
  'ocmBaselineToPP5',
  'ocmPP1ToPP5',
  'ocmPP2ToPP5',
  'ocmPP3ToPP5',
  'ocmPP4ToPP5',
  'ocmPP6',
  'ocmBaselineToPP6',
  'ocmPP1ToPP6',
  'ocmPP2ToPP6',
  'ocmPP3ToPP6',
  'ocmPP4ToPP6',
  'ocmPP5ToPP6',
  'ocmPP7',
  'ocmBaselineToPP7',
  'ocmPP1ToPP7',
  'ocmPP2ToPP7',
  'ocmPP3ToPP7',
  'ocmPP4ToPP7',
  'ocmPP5ToPP7',
  'ocmPP6ToPP7',
  'bpciaBaseline',
  'bpciaPP1',
  'bpciaBaselineToPP1',
  'bpciaPP2',
  'bpciaBaselineToPP2',
  'bpciaPP1ToPP2',
];

export interface TimePeriod {
  productKey: string; // TODO get rid of this
  key: TimePeriodKey;
  label: string;
  minPP?: number;
  maxPP?: number;
}

// =============================================================================
// dataset
// =============================================================================

export type DatasetKey =
  | 'ocmDefault'
  | 'ocmUnfiltered'
  | 'ocmBene'
  | 'bpciaDefault'
  | 'bpciaActive'
  | 'bpciaCompletedOrActive'
  | 'bpciaEnrolledUnfiltered'
  | 'bpciaUnfiltered';

export interface Dataset {
  productKey: string; // TODO get rid of this
  key: DatasetKey;
  label: string;
  defaultTimePeriod: TimePeriod;
  query: string;
}

// =============================================================================
// product
// =============================================================================

interface AnalyticsProductInputs<Loc extends RouterLoc> {
  key: string;

  // router
  router: Router<Loc>;
  globalsLoc: Loc;
  articleDetailLoc: Loc;
  clinicianHomeLoc: Loc;
  patientHomeLoc: Loc;
  patientTimelineLoc: Loc;
  queryId__episodeExplorerRoute: (query: string) => Route<any>;

  //
  defaultCatalogName: string;
  defaultDataset: Dataset;
  datasets: Dataset[];
  timePeriods: TimePeriod[];
  timeTree: string;
  initialArticleMarkup: string;
  defaultFilterLevelKeys: string[];

  // dataviz config
  catalogKey: CatalogKey;
  patientRelnKey: RelnKey; // 3rd party ids -> patient id
  episodeRelnKey: RelnKey; // episode ids -> patient id
  showOcmPeriods: boolean;
}

export interface AnalyticsProduct<Loc extends RouterLoc> extends AnalyticsProductInputs<Loc> {
  defaultCatalogCount: string;
  defaultCatalog: CubeCatalog;
}

export const toAnalyticsProduct = <Loc extends RouterLoc>(
  inputs: AnalyticsProductInputs<Loc>,
): AnalyticsProduct<Loc> => ({
  ...inputs,
  defaultCatalogCount: `CALC ${inputs.defaultCatalogName}.count`,
  defaultCatalog: houseSchema.name__catalog.call(inputs.defaultCatalogName),
});
