type Env = 'production' | 'development' | 'test';

export const env: Env = (() => {
  const env = process.env.NODE_ENV;
  switch (env) {
    case 'production':
    case 'development':
    case 'test':
      return env;
    default:
      throw Error(`Unrecognized env: ${env}`);
  }
})();

export const isDemo = process.env.REACT_APP_DEMO === 'true';
