import React, { createContext, ReactNode, useContext, useMemo, useState } from 'react';

interface Agent {
  isOpen: boolean;
  setIsOpen(isOpen: boolean): void;
  toggle(): void;
  open(): void;
  close(): void;
}

const Context = createContext<Agent | undefined>(undefined);

const UserDrawerProvider = Context.Provider;

interface Props {
  children?: ReactNode;
}

export function UserDrawerCreator({ children }: Props) {
  const [isOpen, setIsOpen] = useState(false);

  const agent: Agent = useMemo(
    () => ({
      isOpen,
      setIsOpen,
      toggle: () => {
        setIsOpen(!isOpen);
      },
      open: () => {
        setIsOpen(true);
      },
      close: () => {
        setIsOpen(false);
      },
    }),
    [isOpen],
  );

  return <UserDrawerProvider value={agent}>{children}</UserDrawerProvider>;
}

export default function useUserDrawer() {
  const context = useContext(Context);
  if (!context) throw Error('user drawer context not provided');
  return context;
}
