import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core';
import { WithChildren } from '@tuple-health/common';
import React, { useCallback, useState } from 'react';
import useLegalAgreement from '../../components/legal/useLegalAgreement';
import theme from '../../theme';
import css from './TermsOfUseDialog.css';

export default function TermsOfUseDialog() {
  const agreement = useLegalAgreement('termsOfUse');

  const [open, setOpen] = useState(!agreement.wasAccepted);
  const closeDialog = useCallback(() => setOpen(false), []);

  const acceptLegal = useCallback(() => {
    void agreement.accept();
    closeDialog();
  }, [closeDialog, agreement]);

  return (
    <Dialog
      style={{ zIndex: theme.zIndex.modal + 1 }}
      maxWidth="md"
      scroll="paper"
      open={open}
      onClose={closeDialog}
    >
      <DialogTitle>Terms of Use</DialogTitle>
      <DialogContent className={css.content}>
        <ol>
          <li>
            <h2>Acceptance</h2>
            During the Term as mutually agreed from time to time, Tuple Health may provide Customer
            with access to certain Services under the Agreement through the Tuple Health Services
            Portal (the <Term>Portal</Term>). Customer’s use of the Portal is governed by these
            Terms of Use (<Term>Terms</Term>) and the Agreement. In addition, access to the Portal
            may be conditioned upon “click-through” or other online agreements which may be
            presented at the time a User accesses the Portal (<Term>Online Agreements</Term>). The
            terms set forth herein are in addition to those set forth in the Agreement. In the event
            of a conflict between these Terms and the Agreement, the Agreement governs with respect
            to the Services (including the Services provided via the Portal) and these Terms govern
            with respect to the Portal (but excluding any Services provided via the Portal).{' '}
            <strong>
              By using the Portal Customer and its Users agree to be bound by these Terms. If you do
              not agree to these Terms, do not use the Portal.
            </strong>
          </li>
          <li>
            <h2>Customer/user obligations</h2>
            Customer shall designate and authorize the Customer personnel that may access the Portal
            on Customer’s behalf (<Term>Users</Term>). For purposes of these Terms, Services
            provided to Users shall be deemed to be Services provided to Customer. The following
            apply with respect to all Users:
            <ol>
              <li>
                Customer shall be directly responsible and liable for the performance, breach or
                other wrongful conduct of any User;
              </li>
              <li>
                Customer shall, and shall use commercially reasonable efforts to cause its Users to,
                protect the confidentiality of the passwords and other security mechanisms deployed
                by Tuple Health and Customer to prevent the unauthorized access to and/or use of the
                Portal and all data, content, information, software and materials contained therein
                (the foregoing, excluding any Customer data provided to Tuple Health under the
                Agreement, collectively, <Term>Portal Content</Term>); and
              </li>
              <li>
                any notice provided by Tuple Health to Customer under these Terms and the Agreement
                will, where applicable, constitute notice to Users, and Users will send notices
                required by these Terms and this Agreement to Tuple Health only through Customer.
              </li>
            </ol>
          </li>
          <li>
            <h2>Licenses</h2>
            Tuple Health grants Customer, during any mutually agreed-upon period during the Term, a
            nonexclusive, non-transferable, royalty-free, license and right for its Users to access
            and use the Portal, including the mutually-agreed upon Services enabled through the
            Portal and Portal Content, solely for Customer’s internal business operations in the
            United States, subject to the terms of these Terms and the Agreement. Customer
            acknowledges that the Portal and Portal Content provided under this Agreement may
            include embedded third-party software, including open-source software. To the extent any
            additional terms and conditions apply to Customer’s or User’s use of such embedded
            software, such terms and conditions will be set forth in the Online Agreements and
            Customer hereby agrees to such terms and conditions.
          </li>
          <li>
            <h2>Restrictions</h2>
            Except to the extent expressly stated otherwise in these Terms, Customer shall not nor
            attempt to, nor permit, procure, enable or request any other person or entity to:
            <ol>
              <li>
                reproduce, modify, create derivative works based on, reverse engineer, decompile,
                reverse compile, reverse assemble, translate or disassemble, all or any portion of
                the Portal or the Portal Content, or otherwise attempt to derive the source code of
                any Portal Content;
              </li>
              <li>
                use or access the Portal or the Portal Content to (i) act as a service bureau on
                behalf of, or to otherwise provide processing services or support to, any other
                person or entity, or (ii) create, market or distribute any product or service that
                is competitive with the Services, the Portal or the Portal Content;
              </li>
              <li>
                provide, transfer, sell, lease, license, sublicense, distribute, disclose, divulge
                or make available the Portal or the Portal Content to, or permit use of or access to
                the Portal or the Portal Content by, any person or entity other than Customer;
              </li>
              <li>
                enter into any agreement with or make any representation to any other person or
                entity that conflicts with, results in any breach of, or constitutes a default
                under, these Terms;
              </li>
              <li>
                remove, alter or obscure any intellectual property notice or other restrictive
                notice or legend contained or included in or on the Portal or the Portal Content; or
              </li>
              <li>
                contest, challenge or otherwise make any claim or take any action adverse to Tuple
                Health’s ownership of, or interest in, the Portal or the Portal Content.
              </li>
            </ol>
          </li>
          <li>
            <h2>Systems</h2>
            <ol>
              <li>
                <h3>Access</h3>
                Tuple Health may limit or terminate Customer’s or User’s use of the Portal and
                Portal Content at any time if Tuple Health has reason to believe there are
                technical, operational or security risks associated with that access or use.
              </li>
              <li>
                <h3>Obligations</h3>
                Customer shall, and shall use commercially reasonable efforts to cause its Users to,
                (i) comply with Tuple Health’s security policies, standards, procedures and other
                requirements communicated to Customer in connection with Customer’s access to and
                use of the Portal, (ii) use reasonable efforts to prevent any unauthorized access to
                or use of the Portal or Portal Content, (iii) cooperate with any investigation
                undertaken by or on behalf of Tuple Health that relates to the security of the
                Portal, including by providing Tuple Health with any relevant information or
                material in Customer’s possession or under its control, and (iv) not download or
                install any software onto Tuple Health’s servers or tamper with, compromise or
                circumvent any security or audit measures employed by Tuple Health.
              </li>
              <li>
                <h3>Viruses</h3>
                Customer shall ensure that no virus, malware, spyware, malicious code, trojan horse,
                worm, back door, trap door, time bomb, software lock, drop dead device or other
                program, routine, instruction, device, code, contaminant, logic, effect or other
                undisclosed feature designed or intended to delete, disable, deactivate, interfere
                with, disrupt, erase, deny access to, enable any person or entity to access without
                authorization, produce modifications of, or otherwise adversely affect the
                functionality or interfere with the use of, any software, data or information
                technology system ( <Term>Viruses</Term>) are transmitted from its IT systems or
                software to or through the Portal, including through the use of Virus protection,
                detection and eradication technology consistent with leading industry standards and
                practices in connection with accessing and using the Portal. If Customer identifies
                a Virus that may affect the Portal, Customer promptly shall notify Tuple Health and
                cease all attempts to access or use the Portal until the Virus has been eliminated.
                If a Virus is introduced to Customer’s IT systems through Customer’s use of the
                Portal, Customer shall cooperate at Customer’s expense to eradicate and limit the
                adverse effects of the Virus and mitigate any resulting losses of operational
                efficiency or data.
              </li>
            </ol>
          </li>
          <li>
            <h2>Disclaimer of warranties and limitations on liability</h2>
            <p>
              THE PORTAL AND PORTAL CONTENT ARE PROVIDED TO CUSTOMER "AS IS" AND WITH ALL FAULTS AND
              DEFECTS WITHOUT WARRANTY OF ANY KIND. TO THE MAXIMUM EXTENT PERMITTED UNDER APPLICABLE
              LAW, TUPLE HEALTH, ON ITS OWN BEHALF AND ON BEHALF OF ITS AFFILIATES AND ITS AND THEIR
              RESPECTIVE LICENSORS AND SERVICE PROVIDERS, EXPRESSLY DISCLAIMS ALL WARRANTIES,
              WHETHER EXPRESS, IMPLIED, STATUTORY, OR OTHERWISE, WITH RESPECT TO THE PORTAL AND
              PORTAL CONTENT, INCLUDING ALL IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A
              PARTICULAR PURPOSE, AND TITLE, AND WARRANTIES THAT MAY ARISE OUT OF COURSE OF DEALING,
              COURSE OF PERFORMANCE, USAGE, OR TRADE PRACTICE. WITHOUT LIMITATION TO THE FOREGOING,
              TUPLE HEALTH PROVIDES NO WARRANTY OR UNDERTAKING, AND MAKES NO REPRESENTATION OF ANY
              KIND THAT THE PORTAL AND PORTAL CONTENT WILL MEET CUSTOMER’S REQUIREMENTS, ACHIEVE ANY
              INTENDED RESULTS, BE COMPATIBLE, OR WORK WITH ANY OTHER SOFTWARE, APPLICATIONS,
              SYSTEMS, OR SERVICES, OPERATE WITHOUT INTERRUPTION, MEET ANY PERFORMANCE OR
              RELIABILITY STANDARDS OR BE ERROR FREE, OR THAT ANY ERRORS OR DEFECTS CAN OR WILL BE
              CORRECTED.
            </p>
            <p>
              UNDER NO CIRCUMSTANCES SHALL TUPLE HEALTH, ITS AFFILIATES AND ITS AND THEIR RESPECTIVE
              LICENSORS AND SERVICE PROVIDERS, BE LIABLE TO CUSTOMER OR ANY THIRD PARTY FOR ANY TYPE
              OF DAMAGES OR CLAIMS RELATED TO THE PORTAL (INCLUDING, FOR THE AVOIDANCE OF DOUBT, FOR
              ANY CONSEQUENTIAL, INCIDENTAL, INDIRECT, EXEMPLARY, SPECIAL, OR PUNITIVE DAMAGES,
              WHETHER ARISING OUT OF OR IN CONNECTION WITH THESE TERMS, BREACH OF CONTRACT, TORT
              (INCLUDING NEGLIGENCE), OR OTHERWISE, REGARDLESS OF WHETHER SUCH DAMAGES WERE
              FORESEEABLE AND WHETHER OR NOT CUSTOMER WAS ADVISED OF THE POSSIBILITY OF SUCH
              DAMAGES).
            </p>
          </li>
          <li>
            <h2>Entire agreement</h2> These Terms, the Agreement and any Online Agreements entered
            into by Users in accessing the Portal constitute the entire agreement of the Parties
            with respect to the Portal, the Portal Content and the Services. Any terms used but not
            defined in these Terms have the meanings set forth in the Agreement
          </li>
        </ol>
      </DialogContent>
      <DialogActions className={css.actions}>
        <Button color="primary" variant="contained" onClick={acceptLegal}>
          Agree
        </Button>
      </DialogActions>
    </Dialog>
  );
}

function Term({ children }: WithChildren) {
  return (
    <>
      “<span className={css.term}>{children}</span>”
    </>
  );
}
