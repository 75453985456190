import './include/browserIncludes';
import './include/background.global.css';

import React from 'react';
import * as ReactDOM from 'react-dom';
import { connect, Provider } from 'react-redux';
import { createBrowserHistory } from 'rudy-history';
import { configureApp } from './app/app.logic';
import { configureStore } from './core/appbase/configureStore';
import { makeResolver } from './core/resolver';
import { ServiceState } from './core/service';
import * as gateway from './services/gateway/gateway.remote';
import * as persistence from './services/persist/persistence.cookie';
import registerServiceWorker from './pushHandler/actor/registerServiceWorker';

const app = configureApp({
  mixins: {
    gateway: gateway.remoteGatewayService,
    persistence: persistence.service,
  },
});

const { store, service } = configureStore({
  createHistory: createBrowserHistory,
  app,
});

const ConnectedApp = connect((state: ServiceState<typeof service>) =>
  makeResolver(service.providers, state)(app.contract),
)(app.render);

function render() {
  ReactDOM.render(
    <Provider store={store}>
      <ConnectedApp />
    </Provider>,
    document.getElementById('root'),
  );
}

render();

store.dispatch({ type: 'APP_INIT' });

registerServiceWorker();
