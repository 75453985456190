import { cleanObject, constant, pipe, WithError, WithMaybeError } from '@tuple-health/common';
import { gatewayCookies } from '@tuple-health/common/dist/gateway';
import { Reply } from '@tuple-health/eng/dist/dryscript/ds';
import * as toGenericMsg from '@tuple-health/eng/dist/dryscript/lib/common/ui/content/msg/toGenericMsg';
import * as identityErrors from '@tuple-health/eng/dist/dryscript/lib/server/auth/identity/identityErrors';
import { GatewaySession } from '@tuple-health/eng/dist/th/ds/common/agent/gatewayApi/service/session/GatewaySession';
import { distinctUntilKeyChanged, mergeMap } from 'rxjs/operators';
import { ofType } from 'unionize';
import { hashedPathTo } from '../../app/locs';
import { Authn } from '../../contracts/Authn';
import { Dispatch } from '../../contracts/Dispatch';
import { Gateway } from '../../contracts/Gateway';
import { Persistence } from '../../contracts/Persistence';
import { makeActions } from '../../core/action';
import { makeAgent, NOOP } from '../../core/agent';
import { makeReducer } from '../../core/reducer';
import { makeSelector } from '../../core/selector';
import { makeStates } from '../../core/state';
import { makeApi } from '../../services/gateway/api';
import { FIELD_HANDLE, FIELD_PHONE_RECORD } from '../support/support.screen';
import {
  LoginPromptProps,
  LoginProps,
  OtpPromptProps,
  WithCredentials,
  WithOtp,
  WithOtpSummary,
} from './login.props';

// ============================
//  state
// ============================
type BaseProperties = WithCredentials &
  OtpPromptProps & {
    resent: boolean;
  };

const State = makeStates({
  loginPrompt: ofType<LoginPromptProps & WithMaybeError>(),
  loginPending: ofType<WithCredentials>(),
  otpPrompt: ofType<BaseProperties & WithMaybeError>(),
  otpResendPending: ofType<BaseProperties>(),
  otpPending: ofType<BaseProperties & WithOtp>(),
});
type State = typeof State._Union;

// ============================
//  action
// ============================
const Action = makeActions({
  loginscreen_submitLogin: ofType<WithCredentials>(),
  loginscreen_receiveOtpRequired: ofType<WithOtpSummary>(),
  loginscreen_receiveLoginFailure: ofType<WithCredentials & WithError>(),
  loginscreen_submitOtp: ofType<WithOtp>(),
  loginscreen_resendOtp: ofType<Partial<WithOtp>>(),
  loginscreen_cancelOtp: {},
  loginscreen_receiveOtpFailure: ofType<WithError>(),
});
type Action = typeof Action._Union;

// ============================
//  reducer
// ============================
export const reducer = makeReducer<State, Action>(State.loginPrompt({}), s => a =>
  State.match(s, {
    loginPrompt: () =>
      Action.match(a, {
        loginscreen_submitLogin: State.loginPending,
        default: constant(s),
      }),
    loginPending: state =>
      Action.match(a, {
        loginscreen_receiveOtpRequired: action =>
          State.otpPrompt({ ...state, ...action, resent: false }),
        loginscreen_receiveLoginFailure: action => State.loginPrompt(action),
        default: constant(s),
      }),
    otpPrompt: state =>
      Action.match(a, {
        loginscreen_submitOtp: action =>
          State.otpPending({ ...cleanObject(state, 'error'), ...action }),
        loginscreen_cancelOtp: () => State.loginPrompt({ remember: state.remember }),
        loginscreen_resendOtp: action =>
          State.otpResendPending({ ...cleanObject(state, 'error'), ...action, resent: true }),
        default: constant(s),
      }),
    otpPending: state =>
      Action.match(a, {
        loginscreen_receiveOtpFailure: action => State.otpPrompt({ ...state, ...action }),
        default: constant(s),
      }),
    otpResendPending: state =>
      Action.match(a, {
        loginscreen_receiveOtpRequired: action => State.otpPrompt({ ...state, ...action }),
        loginscreen_receiveLoginFailure: action => State.loginPrompt(action),
        default: constant(s),
      }),
  }),
);

// ============================
//  agent
// ============================

export const agent = makeAgent({ authn: Authn, gateway: Gateway, persistence: Persistence })<State>(
  $ =>
    $.pipe(
      distinctUntilKeyChanged('state'),
      mergeMap(({ state, authn, gateway, persistence }) => {
        const api = makeApi(gateway);

        return State.match({
          loginPending: async creds => {
            // user requested not to remember this device, so clear any existing tickets
            if (!creds.remember) persistence.clear(gatewayCookies.clientSideSessionTicket);

            // if a ticket exists, attempt to log in without an OTP
            const ticket = persistence.load(gatewayCookies.clientSideSessionTicket);
            if (ticket) {
              try {
                const session = await api.loginTicket({
                  ...creds,
                  ticket,
                });

                return beginSession(session, authn, persistence);
              } catch (e) {
                const ticketReply: Reply<void> = e.reply;
                // is ticket still valid, or did they provide invalid credentials?
                const ticketErrors = ticketReply.errors
                  ? [...ticketReply.errors].map(e => e.code)
                  : [];
                if (
                  ticketErrors.includes(identityErrors.expiredTicket.code) ||
                  ticketErrors.includes(identityErrors.unknownTicket.code)
                ) {
                  // ticket was invalid, so clear any existing ticket and then proceed to send OTP
                  persistence.clear(gatewayCookies.clientSideSessionTicket);
                } else {
                  // assume failure due to invalid credentials or server error
                  return Action.loginscreen_receiveLoginFailure({
                    ...creds,
                    error: toGenericMsg.fromReply(ticketReply),
                  });
                }
              }
            }

            // send OTP
            return sendOtp(creds);
          },

          otpPending: state =>
            api
              .loginOtp({
                ...cleanObject(state, 'remember', 'otpSummary', 'resent'),
                createTicket: state.remember,
              })
              .then(session => beginSession(session, authn, persistence))
              .catch(e =>
                Action.loginscreen_receiveOtpFailure({
                  error: toGenericMsg.fromReply(e.reply),
                }),
              ),

          otpResendPending: sendOtp,

          default: NOOP.observable,
        })(state);

        function sendOtp(creds: WithCredentials) {
          // send an OTP
          return api
            .sendOtp(creds)
            .then(otpSummary => Action.loginscreen_receiveOtpRequired({ otpSummary }))
            .catch(e => {
              const { reply } = e;
              if (!reply) throw e;
              return Action.loginscreen_receiveLoginFailure({
                ...creds,
                error: toGenericMsg.fromReply(reply),
              });
            });
        }
      }),
    ),
);

function beginSession(session: GatewaySession, authn: Authn, persistence: Persistence) {
  if (Authn.is.none(authn)) {
    if (session.ticket) persistence.save(gatewayCookies.clientSideSessionTicket, session.ticket);
    authn.beginSession(session);
  }

  return NOOP.promise();
}

// ============================
//  selector
// ============================
export const selector = makeSelector({
  authn: Authn,
  dispatch: Dispatch,
  persistence: Persistence,
})<State, LoginProps>(({ state: stateUnion, authn, dispatch, persistence }) =>
  Authn.match({
    none: () =>
      State.match({
        loginPrompt: state =>
          LoginProps.loginPrompt({
            remember: !!persistence.load(gatewayCookies.clientSideSessionTicket),
            ...state, // will override remember if an explicit value is set
            onSubmit: pipe(Action.loginscreen_submitLogin, dispatch),
            supportNeedAccountPath: hashedPathTo('support_create_account', {}),
            forgotPasswordPath: hashedPathTo('password_reset_init', {}),
          }),
        loginPending: LoginProps.loginPending,
        otpPrompt: state =>
          LoginProps.otpPrompt({
            ...cleanObject(state, 'handle', 'password'),
            onSubmit: pipe(Action.loginscreen_submitOtp, dispatch),
            onResend: pipe(Action.loginscreen_resendOtp, dispatch),
            onCancel: () => dispatch(Action.loginscreen_cancelOtp()),
            supportNoCodePath: hashedPathTo(
              'support_login_no_code',
              {},
              {
                [FIELD_HANDLE]: state.handle,
                [FIELD_PHONE_RECORD]: state.otpSummary.sentPhoneSuffix,
              },
            ),
            supportWrongNumberPath: hashedPathTo(
              'support_login_wrong_number',
              {},
              {
                [FIELD_HANDLE]: state.handle,
                [FIELD_PHONE_RECORD]: state.otpSummary.sentPhoneSuffix,
              },
            ),
          }),
        otpResendPending: LoginProps.otpPending,
        otpPending: LoginProps.otpPending,
      })(stateUnion),
    session: LoginProps.loggedIn,
    initializing: () => {
      throw new Error('impossible');
    },
  })(authn),
);
