import { createElement, lazy } from 'react';
import { makeScreen, Screen, ScreenParameters } from '../core/appbase/screen';
import { entries, tuple, validateShape } from '@tuple-health/common';

import PlatformOldScreen from '../platform/platform/PlatformOldScreen';
import { loginScreen } from '../screens/login';
import { passwordResetConfirmScreen } from '../screens/passwordReset/confirm';
import { passwordResetInitiateScreen } from '../screens/passwordReset/initiate';
import registrationScreen from '../screens/registration/index';
import { supportScreens } from '../screens/support/support.routes';
import {
  agentFailScreen,
  reducerFailScreen,
  selectorFailScreen,
  viewFailScreen,
} from './errorScreens';
import { key__loc, Loc, LocKey } from './locs';
import orgRedirectScreen from './orgRedirectScreen';

const TestScreen = lazy(() => import(/* webpackChunkName: "test" */ '../test/TestScreen'));

const NotFoundScreen = lazy(() =>
  import(/* webpackChunkName: "not_found" */ '../components/NotFoundScreen'),
);

// TODO replace Record with LocDict?
const locKey__screen = validateShape<Record<LocKey, Screen>>()({
  // ===================================
  // testing
  // ===================================
  view_fail: viewFailScreen,
  selector_fail: selectorFailScreen,
  reducer_fail: reducerFailScreen,
  agent_fail: agentFailScreen,
  test: componentScreen(TestScreen),
  // ===================================
  // login not required
  // ===================================
  login: loginScreen,
  welcome: registrationScreen,
  support: supportScreens.general,
  support_create_account: supportScreens.createAccount,
  support_welcome_wrong_number: supportScreens.welcomeWrongNumber,
  support_welcome_no_code: supportScreens.welcomeNoCode,
  support_welcome_expired: supportScreens.welcomeInviteExpired,
  support_login_wrong_number: supportScreens.loginWrongNumber,
  support_login_no_code: supportScreens.loginNoCode,
  password_reset_init: passwordResetInitiateScreen,
  password_reset_confirm: passwordResetConfirmScreen,
  // ===================================
  // user
  // ===================================
  platform: componentScreen(PlatformOldScreen),
  not_found: componentScreen(NotFoundScreen),
  org: orgRedirectScreen,
});

function componentScreen<C extends React.FC<any>, P>(Component: C, staticProps?: P) {
  type Props = React.ComponentProps<C>;
  type Params = Omit<Props, keyof P>;
  const paramsContract = ScreenParameters<Params>();
  const screen: Screen<Params, Params, {}> = makeScreen({
    parameters: paramsContract,
    selector: (_, resolve) => resolve(paramsContract),
    view: (params: Params) => createElement(Component, { ...params, ...staticProps } as any),
  });
  return screen;
}

// =============================================================================
// =============================================================================

// TODO ideally every  screen maps to loc,
// but a screen seems to show up during init that doesn't
const screen__locKey = (() => {
  const map = new Map<Screen, LocKey>(
    entries(locKey__screen).map(([key, screen]) => tuple(screen, key)),
  );
  return (screen: Screen) => map.get(screen);
})();

const screen__loc = (screen: Screen): Loc | undefined => {
  const locKey = screen__locKey(screen);
  return locKey && key__loc[locKey];
};

export const isPublic = (screen: Screen) => {
  const loc = screen__loc(screen);
  return !!loc && loc.isPublic;
};

// =============================================================================
// backwards compat
// =============================================================================

export const route__screen: Record<string, Screen> = {};

entries(locKey__screen).forEach(([key, screen]) => {
  const loc = key__loc[key];
  route__screen[loc.route] = screen;
});
