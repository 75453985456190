import { LinearProgress } from '@material-ui/core';
import { GenericMsg } from '@tuple-health/eng/dist/dryscript/lib/common/ui/content/msg/GenericMsg';
import React from 'react';
import { style } from 'typestyle';
import theme from '../../theme';
import { MiniButton } from './buttons';

export const classes = {
  row: style({
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  }),
  errorSection: style({
    marginTop: theme.spacing(2),
    color: 'red',
    textAlign: 'center',
  }),
  flexSpacer: style({ flexGrow: 1 }),
  horizontalSpacer: style({
    display: 'inline-block',
    width: theme.spacing(2),
  }),
  phoneContainer: style({
    marginBottom: theme.spacing(2),
    $nest: {
      '&>span': {
        fontWeight: 'bold',
      },
    },
  }),
  smallSpacer: style({ marginBottom: theme.spacing(1) + 'px !important' }),
  spacer: style({ marginBottom: theme.spacing(2) }),
};

// ============================
//  spacers
// ============================

export const HorizontalSpacer = <div className={classes.horizontalSpacer} />;

// ============================
//  PhoneNumber
// ============================
interface PhoneNumberProps {
  phoneNumber: string;
  supportPath?: string;
}
export const PhoneNumber = React.memo(({ phoneNumber, supportPath }: PhoneNumberProps) => (
  <div className={classes.phoneContainer}>
    <span>
      ({phoneNumber.substring(0, 3)}) {phoneNumber.substring(3, 6)}-{phoneNumber.substring(6)}
    </span>
    <MiniButton path={supportPath}>Not your number?</MiniButton>
  </div>
));

// ============================
//  ErrorSection
// ============================
interface ErrorSectionProps {
  error?: GenericMsg;
  skipKeys?: string[];
}
export const ErrorSection = React.memo(({ error, skipKeys = [] }: ErrorSectionProps) => {
  let errorText: string | undefined;
  if (error) {
    errorText = error.text;
    if (!errorText) {
      const first = [...error.key__text].filter(([key]) => !skipKeys.includes(key))[0];
      if (first) {
        errorText = first.join(': ');
      }
    }
  }

  return <div className={classes.errorSection}>{errorText || <>&nbsp;</>}</div>;
});

export function StatusSection({ status }: { status: 'pending' | ErrorSectionProps }) {
  return (
    <>
      {status === 'pending' && (
        <div style={{ position: 'relative', height: 0, top: theme.spacing(3) }}>
          <LinearProgress style={{ height: theme.spacing(1) }} />
        </div>
      )}
      <ErrorSection {...(status === 'pending' ? undefined : status)} />
    </>
  );
}
