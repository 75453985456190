import deepEqual from 'deep-equal';
import { Boolean, Record, Static, String, Union } from 'runtypes';
import { ItemPoint } from './ItemPoint';

export const VizElement = Union(
  Record({ concept: String }), // e.g. hover over legend item or axis item
  Record({ item: ItemPoint }), // e.g. hover over mark
  Record({ attr: String }), // e.g. hover over legend title or axis title
  Record({ cohort: Boolean }), // e.g. hover over chart title
);
export type VizElement = Static<typeof VizElement>;

export function vizElementsEqual(left: VizElement, right: VizElement): boolean {
  return deepEqual(left, right);

  // the following code wasn't working...
  // const tag = getTag(left)
  // if (tag !== getTag(right)) return false

  //
  // return (left as any)[tag] === (right as any)[tag]
}

// function getTag(elem: VizElement): string {
//   const keys = Object.keys(elem)
//   if (keys.length !== 1)
//     throw new Error(`VizElement had more than one tag: ${JSON.stringify(keys)}`)
//   return keys[0]
// }
