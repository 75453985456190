import { toAdapter, Adapter } from '../../../components/util/adaptLib';
import {
  ProductRichLink,
  RichLink,
  richLink__unboundRichLink,
  toUnboundRichLink,
  UnboundRichLink,
  unboundRichLinkWithoutVariables__richLink,
} from '../richLink/richLink.model';
import { Router } from '@tuple-health/common/dist/router';
import getAnalyticsProduct from '../../../components/product/analytics/getAnalyticsProduct';

// =============================================================================
// bound
// =============================================================================

const writeRichLink = (router: Router<any>, link: RichLink): string =>
  writeUnboundRichLink(richLink__unboundRichLink(router, link));

const parseRichLink = (router: Router<any>, text: string): RichLink => {
  const unbound = parseUnboundRichLink(text);
  return unboundRichLinkWithoutVariables__richLink(router, unbound);
};

export function toRichLinkFormat(router: Router<any>): Adapter<string, RichLink> {
  const parse = (text: string) => parseRichLink(router, text);
  const write = (link: RichLink) => writeRichLink(router, link);
  return toAdapter<string, RichLink>(parse, write);
}

// =============================================================================
// unbound
// =============================================================================

const writeUnboundRichLink = (link: UnboundRichLink): string => {
  const productKey = link.productKey ? `@${link.productKey}, ` : '';
  return `[${link.anchorMarkdown}](${productKey}${link.resourceMarkup})`;
};

const parseUnboundRichLink = (text: string): UnboundRichLink => {
  const index = text.indexOf('](');
  if (!text.startsWith('[') || !text.endsWith(')') || index < 0)
    throw Error(`could not parse rich link: ${text}`);

  const target = text.substring(index + 2, text.length - 1);

  return toUnboundRichLink({
    anchorMarkdown: text.substring(1, index),
    ...parseTarget(target),
  });
};

const parseTarget = (text: string) => {
  if (text.startsWith('@')) {
    const index = text.indexOf(',');
    if (index < 0) throw Error(`unable to parse link target: ${text}`);

    return {
      resourceMarkup: text.substring(index + 1).trim(),
      productKey: text.substring(1, index),
    };
  } else {
    return { resourceMarkup: text, productKey: undefined };
  }
};

export const unboundRichLinkFormat = toAdapter(parseUnboundRichLink, writeUnboundRichLink);

// =============================================================================
// product bound
// =============================================================================

function writeProductRichLink(link: ProductRichLink): string {
  const { router } = getAnalyticsProduct(link.productKey);
  return writeRichLink(router, link);
}

const parseProductRichLink = (text: string): ProductRichLink => {
  const unboundLink = parseUnboundRichLink(text);
  if (!unboundLink.productKey) throw Error('product rich link must specify productKey');
  const { router } = getAnalyticsProduct(unboundLink.productKey);
  return parseRichLink(router, text) as ProductRichLink;
};

export const productRichLinkFormat = toAdapter<string, ProductRichLink>(
  parseProductRichLink,
  writeProductRichLink,
);
