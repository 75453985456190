import { SingleValueRec, unionize, Unionized } from 'unionize';
import { constant } from '@tuple-health/common';

export interface Action<T extends PropertyKey = string, P = unknown> {
  type: T;
  payload: P;
}

export function makeActions<Record extends SingleValueRec>(
  record: Record,
): Unionized<Record, { [T in keyof Record]: Action<T, Record[T]> }, 'type'> {
  const union = unionize(record, { tag: 'type', value: 'payload' });

  // monkey-patch a default handler when not provided
  const { match } = union;

  union.match = (...args: any[]) => {
    if (args.length < 1 || args.length > 2)
      throw Error(`unexpected number of arguments: ${args.length}`);

    const matcher = args[args.length - 1];
    matcher.default = matcher.default || constant(undefined);

    return (match as any)(...args);
  };

  return union;
}
