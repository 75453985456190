import { makeContract } from '../core/contract';

export type PersistenceListener = (value?: string) => void;

export interface Persistence {
  load(key: string): string | undefined;
  save(key: string, token: string): void;
  clear(key: string): void;
}

export const Persistence = makeContract<Persistence>('persistence');
