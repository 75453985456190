import { $concept, Concept, Kind } from '@tuple-health/common/dist/data';
import { AnalyticsProduct } from '../../components/product/model/analyticsProductModel';
import { RunRequest } from '../../services/query/useRunRequest';

export const lookupConceptsFromV1Query = async (
  runRequest: RunRequest,
  analytics: AnalyticsProduct<any>,
  dottedRelnPath: string,
  kind: Kind,
): Promise<Concept[]> => {
  const dataResult = await runRequest({
    deepQuery: analytics.defaultCatalogCount + `\n` + analytics.defaultDataset.query,
    shallowQuery: analytics.defaultCatalogCount + `\nGROUP ${dottedRelnPath}`,
  });

  const concepts: Concept[] = [...dataResult.table.items].map(item => {
    const dsUri = item.conceptUri(dottedRelnPath.replace('.', '_'));
    const uri = kind.uri(dsUri.key); // this is used to change ns from npi to clinician
    return $concept.create({
      uri,
      label: dataResult.concepts.uri__label.hasKey(dsUri)
        ? dataResult.concepts.uri__label.call(dsUri)
        : undefined,
    });
  });

  return concepts;
};

// =============================================================================
// router kinds
// - this is deprecated, left these for backwards compatibility
// - instead of centralizing kinds and schemas, they are distributed to the appropriate model files
// =============================================================================

export const routerKinds = {
  // user
  userId: Kind.create('user', 'User Id'),
  conversation: Kind.create('conversation', 'Conversation'),
  // customer & product
  customerSlug: Kind.create('customerSlug', 'Customer Slug'),
  // common
  article: Kind.create('article', 'Data Story'),
  patient: Kind.create('patient', 'Patient'),
  clinician: Kind.create('clinician', 'Clinician'),
  // bpcia
  bpciaBundle: Kind.create('bpciaBundle', 'Bundle'),
  hospital: Kind.create('hospital', 'Facility'),
  // hip
  provider: Kind.create('provider', 'Provider'),
  goal: Kind.create('goal', 'Goal'),
  outcome: Kind.create('outcome', 'Outcome'),
  behavior: Kind.create('behavior', 'Behavior'),
  strategy: Kind.create('strategy', 'Strategy'),
  message: Kind.create('message', 'Message'),
};
