import * as _ from '@tuple-health/eng/dist/dryscript/ds';
import { GatewaySession } from '@tuple-health/eng/dist/th/ds/common/agent/gatewayApi/service/session/GatewaySession';
import { Customer } from '@tuple-health/eng/dist/th/ds/common/domain/customer/customer/Customer';
import React, { createContext, ReactNode, useContext, useMemo } from 'react';
import { profileHasChatRole } from '../../components/messaging/chatUtils';

interface Agent {
  session: GatewaySession;
  token: string;
  userId: string;
  isMe: (userId: string) => boolean;
  logout: () => void;
  canChat: boolean;
  isTupleStaff: boolean;
  userCustomerRecords: _.Record<Customer>[];
  hasMultipleCustomers: boolean;
}

const UserContext = createContext<Agent | undefined>(undefined);

export function UserProvider({
  session,
  logout,
  children,
}: {
  session: GatewaySession;
  logout: () => void;
  children?: ReactNode;
}) {
  const agent: Agent = useMemo(
    () => ({
      session,
      token: session.token,
      userId: session.userId,
      isMe: otherUserId => otherUserId === session.userId,
      logout,
      canChat: profileHasChatRole(session),
      isTupleStaff: !!session.user.userIsEmployee,
      userCustomerRecords: [...session.customerRecords],
      hasMultipleCustomers: session.customerRecords.count > 1,
    }),
    [logout, session],
  );

  return <UserContext.Provider value={agent}>{children}</UserContext.Provider>;
}

export function useUserOpt() {
  return useContext(UserContext);
}

export default function useUser() {
  const context = useUserOpt();
  if (!context) throw Error('user context not provided');
  return context;
}
