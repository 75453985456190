import { Card } from '@material-ui/core';
import ArrowIcon from '@material-ui/icons/ArrowForward';
import { GenericMsg } from '@tuple-health/eng/dist/dryscript/lib/common/ui/content/msg/GenericMsg';
import { OnboardSummary } from '@tuple-health/eng/dist/th/ds/common/product/customer/onboard/OnboardSummary';
import { max } from 'd3';
import React, { ReactElement } from 'react';
import { Helmet } from 'react-helmet';
import { MiniButton, RaisedButton } from '../../components/workflow/buttons';
import {
  classes as wfClasses,
  ErrorSection,
  PhoneNumber,
  StatusSection,
} from '../../components/workflow/common';
import WorkflowTextField from '../../components/workflow/WorkflowTextField';
import { Dictionary, FindByTag } from '@tuple-health/common';
import Logo from '../../platform/platform/Logo';
import css from './Registration.css';
import {
  RegistrationProps as Props,
  SubmitPasswordParams,
  WithPhoneOtp,
} from './registration.props';

type State = Dictionary<string>;

const pageNums = [1, 2] as const;
type PageNum = typeof pageNums[number];

export default class Registration extends React.PureComponent<Props, State> {
  state = { ...this.props } as State;

  render() {
    const { props } = this;

    switch (props.status) {
      case 'initializing':
      case 'accountCreated':
        return null;
      case 'error':
        return <ErrorSection error={props.error} />;
    }

    const pageNum = this.props.status.startsWith('otp') ? 1 : 2;

    return (
      <div className={css.root}>
        <Helmet title="Welcome" />
        <div className={css.centered}>
          {header}
          <div className={css.sideBySide}>
            <Card className={css.card}>
              <header>
                {this.renderTitle(pageNum)}
                <span>
                  <span className={css.currentPageNum}>{pageNum}</span>
                  <span className={css.maxPageNum}>/{max(pageNums)}</span>
                </span>
              </header>
              {this.renderContent(props)}
            </Card>
            {this.renderAside(pageNum)}
          </div>
        </div>
      </div>
    );
  }

  private renderContent(
    props: FindByTag<
      Props,
      Exclude<Props['status'], 'initializing' | 'error' | 'accountCreated'>,
      'status'
    >,
  ) {
    switch (props.status) {
      case 'otpSendPrompt':
        return this.renderOtpSendPrompt(
          props.summary,
          props.supportWrongNumberPath,
          props.sendOtp,
          props.error,
        );
      case 'otpSendPending':
        return this.renderOtpSendPrompt(props.summary);
      case 'otpSubmitPrompt':
        return this.renderOtpSubmitPrompt(
          props.summary,
          props.resent,
          props.supportWrongNumberPath,
          props.supportNoCodePath,
          props.onSubmit,
          props.onResend,
          props.error,
        );
      case 'otpSubmitPending':
        return this.renderOtpSubmitPrompt(props.summary, props.resent);
      case 'passwordPrompt':
        return this.renderPasswordPrompt(props.summary, props.onSubmit, props.error);
      case 'passwordPending':
        return this.renderPasswordPrompt(props.summary);
    }
  }

  private renderOtpSendPrompt(
    summary: OnboardSummary,
    supportWrongNumberPath?: string,
    sendCode?: () => void,
    error?: GenericMsg,
  ) {
    const disabled = !sendCode;

    return (
      <>
        {otpExplanation}
        <PhoneNumber phoneNumber={summary.invitePhone!} supportPath={supportWrongNumberPath} />
        <div className={css.buttons}>
          <RaisedButton style={{ flexGrow: 1 }} disabled={disabled} onClick={sendCode}>
            Send Code
          </RaisedButton>
        </div>
        <StatusSection status={disabled ? 'pending' : { error }} />
      </>
    );
  }

  private renderOtpSubmitPrompt(
    summary: OnboardSummary,
    resent: boolean,
    supportWrongNumberPath?: string,
    supportNoCodePath?: string,
    onSubmit?: (credentials: WithPhoneOtp) => void,
    onResend?: (credentials: WithPhoneOtp) => void,
    error?: GenericMsg,
  ) {
    const submit =
      onSubmit &&
      (() => {
        onSubmit({ phoneOtp: this.state.phoneOtp || '' });
      });

    const resend =
      onResend &&
      (() => {
        onResend({ phoneOtp: this.state.phoneOtp || '' });
      });

    const disabled = !submit;

    const tfprops = {
      state: this.state,
      setState: this.setState.bind(this),
      error,
      submit,
    };

    return (
      <>
        {otpExplanation}
        <PhoneNumber phoneNumber={summary.invitePhone!} supportPath={supportWrongNumberPath} />
        <WorkflowTextField {...tfprops} field="phoneOtp" label="Enter code" />
        <div className={css.buttons}>
          {resent ? (
            <MiniButton disabled={!supportNoCodePath} path={supportNoCodePath}>
              Didn't receive a code?
            </MiniButton>
          ) : (
            <span className={css.resend}>
              <span className={css.resendLabel}>Didn't get it?</span>
              <MiniButton style={{ fontSize: 'inherit' }} disabled={disabled} onClick={resend}>
                Resend code
              </MiniButton>
            </span>
          )}
          <RaisedButton className={css.verify} disabled={disabled} onClick={submit}>
            Verify
          </RaisedButton>
        </div>
        <StatusSection status={disabled ? 'pending' : { error, skipKeys: ['phoneOtp'] }} />
      </>
    );
  }

  private renderPasswordPrompt(
    summary: OnboardSummary,
    onSubmit?: (credentials: SubmitPasswordParams) => void,
    error?: GenericMsg,
  ) {
    const submit =
      onSubmit &&
      (() => {
        onSubmit({
          password: this.state.password || '',
          password2: this.state.password2 || '',
        });
      });

    const disabled = !submit;

    const tfprops = {
      state: this.state,
      setState: this.setState.bind(this),
      error,
      submit,
    };

    return (
      <>
        <h4>
          It's nice to meet you, {summary.name.split(' ')[0]}.<br />
          Set your password to finish activating your account.
        </h4>
        {passwordExplanation}
        <WorkflowTextField {...tfprops} field="password" label="Create Password" type="password" />
        <WorkflowTextField
          {...tfprops}
          field="password2"
          label="Confirm Password"
          type="password"
        />
        <div className={css.buttons}>
          <RaisedButton style={{ flexGrow: 1 }} disabled={disabled} onClick={submit}>
            See the data
            <ArrowIcon className={css.arrowIcon} />
          </RaisedButton>
        </div>
        <StatusSection
          status={disabled ? 'pending' : { error, skipKeys: ['password', 'password2'] }}
        />
      </>
    );
  }

  private renderAside(pageNum: PageNum): ReactElement {
    switch (pageNum) {
      case 1:
        return (
          <aside>
            <h4>About Tuple</h4>
            <p>
              Tuple is physician-led and dedicated to a future where the miracle of medicine is
              again filled with purpose and is a joy to deliver.
            </p>
            <h4>We master the complexities of healthcare so you don’t have to</h4>
            <p>
              We combine a deep understanding of oncology practice and payment models to draw
              clinically meaningful insights from claims data, and to identify opportunities to
              better manage clinical and financial risk.
            </p>
          </aside>
        );
      case 2:
        return (
          <aside>
            <h4>Your time is valuable, and we respect that deeply</h4>
            <p>
              We know that asking you to log into yet another piece of technology isn’t anyone’s
              idea of fun. But we believe a little time with Tuple can make a big difference for
              you, your patients, and your organization.
            </p>
          </aside>
        );
    }
  }

  private renderTitle(pageNum: PageNum): ReactElement {
    switch (pageNum) {
      case 1:
        return <h2>You have been invited to Tuple Health. Activate your account today.</h2>;
      case 2:
        return <h2>Set your password</h2>;
    }
  }
}

const header = (
  <header className={css.header}>
    <Logo className={css.logo} />
    <div>
      <div className={css.companyName}>Tuple Health</div>
      <h1>Medicine is a miracle. Now let’s make healthcare less of a headache.</h1>
    </div>
  </header>
);

const otpExplanation = (
  <>
    <h3>Verify your phone number</h3>
    <p className={wfClasses.spacer}>
      Your organization sent us your phone number to provide an extra layer of security. We'll send
      you a 6-digit code to verify your identity and set up 2-factor authentication. Standard
      messaging rates apply.
    </p>
  </>
);

const passwordExplanation = (
  <p className={wfClasses.spacer}>
    Must be at least 8 characters long and include an uppercase letter, a lowercase letter, a
    number, and a symbol.
  </p>
);
