import { Checkbox, FormControlLabel } from '@material-ui/core';
import { GenericMsg } from '@tuple-health/eng/dist/dryscript/lib/common/ui/content/msg/GenericMsg';
import React from 'react';
import { Helmet } from 'react-helmet';
import { MiniButton, PlainButton, RaisedButton } from '../../components/workflow/buttons';
import {
  classes as wfClasses,
  HorizontalSpacer,
  PhoneNumber,
  StatusSection,
} from '../../components/workflow/common';
import WorkflowFrame from '../../components/workflow/WorkflowFrame';
import WorkflowTextField from '../../components/workflow/WorkflowTextField';
import BrowserCompatibilityBanner from './BrowserCompatibilityBanner';
import css from './login.css';
import { LoginProps as Props, WithCredentials, WithOtp } from './login.props';

type State = Partial<WithCredentials & WithOtp>;

export default class Login extends React.PureComponent<Props, State> {
  state = { ...this.props } as State;

  render() {
    return (
      <>
        <Helmet title="Login" />

        <WorkflowFrame>
          <BrowserCompatibilityBanner />
          {Props.match(this.props, {
            loginPrompt: props =>
              this.renderLoginPrompt(
                props.supportNeedAccountPath,
                props.forgotPasswordPath,
                props.onSubmit,
                props.error,
              ),
            loginPending: () => this.renderLoginPrompt(),
            otpPrompt: props =>
              this.renderOtpPrompt(
                props.resent,
                props.otpSummary.sentPhoneSuffix,
                props.supportWrongNumberPath,
                props.supportNoCodePath,
                props.onSubmit,
                props.onResend,
                props.onCancel,
                props.error,
              ),
            otpPending: props =>
              this.renderOtpPrompt(props.resent, props.otpSummary.sentPhoneSuffix),
            loggedIn: () => <div />,
          })}
        </WorkflowFrame>
      </>
    );
  }

  renderLoginPrompt(
    createAccountPath?: string,
    resetPasswordPath?: string,
    onSubmit?: (credentials: WithCredentials) => void,
    error?: GenericMsg,
  ) {
    const submit =
      onSubmit &&
      (() => {
        onSubmit({
          handle: this.state.handle || '',
          password: this.state.password || '',
          remember: !!this.state.remember,
        });
      });

    const disabled = !submit;

    const tfprops = {
      state: this.state,
      setState: this.setState.bind(this),
      error,
      submit,
    };

    return (
      <>
        <WorkflowTextField {...tfprops} field="handle" label="Email or mobile number" />
        <WorkflowTextField {...tfprops} field="password" label="Password" type="password" />
        <div className={css.buttonGroup}>
          {this.renderRememberCheckbox(disabled)}
          <RaisedButton disabled={disabled} onClick={submit}>
            Next
          </RaisedButton>
        </div>
        <StatusSection
          status={disabled ? 'pending' : { error, skipKeys: ['handle', 'password'] }}
        />
        <div className={css.links}>
          <MiniButton path={resetPasswordPath}>Forgot password?</MiniButton>
          <MiniButton path={createAccountPath}>Need an account?</MiniButton>
        </div>
      </>
    );
  }

  renderOtpPrompt(
    resent: boolean,
    phoneSuffix: string,
    supportWrongNumberPath?: string,
    supportNoCodePath?: string,
    onSubmit?: (credentials: WithOtp) => void,
    onResend?: (credentials: WithOtp) => void,
    onCancel?: () => void,
    error?: GenericMsg,
  ) {
    const submit =
      onSubmit &&
      (() => {
        onSubmit({
          otp: this.state.otp || '',
          remember: !!this.state.remember,
        });
      });

    const resend =
      onResend &&
      (() => {
        onResend({
          otp: this.state.otp || '',
          remember: !!this.state.remember,
        });
      });

    const disabled = !submit;

    const tfprops = {
      state: this.state,
      setState: this.setState.bind(this),
      error,
      submit,
    };

    return (
      <>
        {OtpExplanation}
        <PhoneNumber phoneNumber={'******' + phoneSuffix} supportPath={supportWrongNumberPath} />
        <WorkflowTextField {...tfprops} field="otp" label="Code" />
        <div style={{ display: 'flex', paddingTop: 16 }}>
          <PlainButton style={{ marginRight: 'auto' }} disabled={disabled} onClick={onCancel}>
            Back
          </PlainButton>
          {resent ? (
            <MiniButton disabled={!supportNoCodePath} path={supportNoCodePath}>
              didn't receive a code?
            </MiniButton>
          ) : (
            <PlainButton disabled={disabled} onClick={resend}>
              Resend
            </PlainButton>
          )}
          {HorizontalSpacer}
          <RaisedButton disabled={disabled} onClick={submit}>
            Verify
          </RaisedButton>
        </div>
        <StatusSection status={disabled ? 'pending' : { error, skipKeys: ['otp'] }} />
      </>
    );
  }

  renderRememberCheckbox(disabled: boolean) {
    return (
      <FormControlLabel
        control={
          <Checkbox
            disabled={disabled}
            checked={!!this.state.remember}
            onChange={e => this.clickedRememberThisDevice(e)}
          />
        }
        label="Remember this device"
      />
    );
  }

  clickedRememberThisDevice(event: React.ChangeEvent<any>) {
    const { checked } = event.target;
    this.setState({ remember: checked });
  }
}

const OtpExplanation = (
  <>
    <h3 className={wfClasses.smallSpacer}>Verify your identity</h3>
    <p className={wfClasses.spacer}>
      This looks like a new device or one that you haven't used in a while. We've sent you a text
      message with a 6-digit verification code.
    </p>
  </>
);
