import { createRouter, vocabulary } from '@tuple-health/common/dist/router';
import { createElement } from 'react';
import {
  ClinicianIcon,
  DataStoryIcon,
  DrugIcon,
  EdIcon,
  EpisodeIcon,
  FacilityIcon,
  HomeHealthIcon,
  HomeIcon,
  HospiceIcon,
  HospitalIcon,
  MethodologyIcon,
  PacIcon,
  PatientsIcon,
  RadiationIcon,
  ReconciliationIcon,
  SnfIcon,
  TherapyIcon,
} from '../../../components/icons/icons';
import { routerKinds } from '../../platform/v1PlatformConcepts';

// =============================================================================
// locs
// =============================================================================

// note: page names are stored in databases and links, so must preserve backwards compat
const locs = vocabulary({
  customer_home: undefined,
  globals: undefined,
  // ===========================================================================
  // folders
  // ===========================================================================
  folders: undefined,
  // ===========================================================================
  // articles
  // ===========================================================================
  article_list: undefined,
  article_detail: undefined,
  // ===========================================================================
  // patients
  // ===========================================================================
  patients: undefined,
  episodes: undefined,
  patient_timeline: undefined,
  // ===========================================================================
  // clinicians
  // ===========================================================================
  ocm_clinician_list: undefined,
  ocm_clinician_overview: undefined,
  // ===========================================================================
  // episode
  // ===========================================================================
  ocm_episode_summary: undefined,
  pp4_update: undefined,
  // ===========================================================================
  // methodology
  // ===========================================================================
  methodology_overview: undefined,
  methodology_costs: undefined,
  methodology_pbp: undefined,
  methodology_prediction_model: undefined,
  methodology_metastatic: undefined,
  methodology_updates: undefined,
  methodology_two_sided: undefined,
  // ===========================================================================
  // reconciliation
  // ===========================================================================
  recon_financials: undefined,
  recon_cancers: undefined,
  // recon_markets: undefined,
  recon_clinicians: undefined,
  // recon_trueups: undefined,
  patient_explorer: undefined,
  // ===========================================================================
  // snf
  // ===========================================================================
  snf_overview: undefined,
  snf_utilization: undefined,
  snf_costs: undefined,
  snf_outliers: undefined,
  snf_insights: undefined,
  snf_performance: undefined,
  // ===========================================================================
  // hh
  // ===========================================================================
  hh_overview: undefined,
  hh_utilization: undefined,
  hh_costs: undefined,
  // hh_outliers: undefined,
  hh_insights: undefined,
  hh_performance: undefined,
  // ===========================================================================
  // eol
  // ===========================================================================
  eol_overview: undefined,
  eol_utilization: undefined,
  eol_costs: undefined,
  eol_outliers: undefined,
  eol_insights: undefined,
  eol_performance: undefined,
  // ===========================================================================
  // v2 hosp
  // ===========================================================================
  v2_hosp_impact: undefined,
  v2_hosp_utilization: undefined,
  v2_hosp_cancers: undefined,
  v2_hosp_outliers: undefined,
  v2_hosp_readmissions: undefined,
  v2_hosp_stays: undefined,
  // ===========================================================================
  // v2 ed
  // ===========================================================================
  v2_ed_impact: undefined,
  v2_ed_utilization: undefined,
  v2_ed_observation: undefined,
  v2_ed_metric: undefined,
  v2_ed_cancers: undefined,
  v2_ed_outliers: undefined,
  v2_ed_visits: undefined,
  // ===========================================================================
  // v2 pac
  // ===========================================================================
  v2_pac_impact: undefined,
  v2_pac_utilization: undefined,
  v2_pac_cancers: undefined,
  v2_pac_outliers: undefined,
  v2_pac_stays: undefined,
  // ===========================================================================
  // v2 eol
  // ===========================================================================
  v2_eol_metric: undefined,
  v2_eol_performance: undefined,
  v2_eol_hospice: undefined,
  v2_eol_eol: undefined,
  v2_eol_outliers: undefined,
  v2_eol_quality: undefined,
  // ===========================================================================
  // v2 drug
  // ===========================================================================
  v2_drug_impact: undefined,
  v2_drug_classes: undefined,
  v2_drug_agents: undefined,
  v2_drug_substitutions: undefined,
  v2_drug_orals: undefined,
  v2_drug_outliers: undefined,
  v2_drug_clinicians: undefined,
  // v2_drug_cohorts: undefined,
  // ===========================================================================
  // v2 radiation
  // ===========================================================================
  v2_radiation_impact: undefined,
  v2_radiation_utilization: undefined,
  v2_radiation_protocol: undefined,
  v2_radiation_costs: undefined,
  v2_radiation_outliers: undefined,
  // ===========================================================================
  // v2 home health
  // ===========================================================================
  v2_hh_impact: undefined,
  v2_hh_utilization: undefined,
  v2_hh_diagnosis: undefined,
  v2_hh_provider: undefined,
});

export type OcmLoc = typeof locs.Term;

// =============================================================================
// builder
// =============================================================================

const _ = createRouter<OcmLoc>('ocm', 'OCM Insights', locs);

// =============================================================================
// folders
// =============================================================================

const folders = _.page({
  loc: 'folders',
  icon: createElement(DataStoryIcon),
  slug: 'folders',
  label: 'Data Stories',
  visibility: 'tupleStaff',
});

// =============================================================================
// articles
// =============================================================================

const articles = _.page({
  loc: 'article_list',
  icon: createElement(DataStoryIcon),
  slug: 'data-stories',
  label: 'Data Stories',
  childConcept: _.concept(
    routerKinds.article,
    _.page({
      loc: 'article_detail',
      label: 'Data Story',
    }),
  ),
});

// =============================================================================
// patients
// =============================================================================

const patients = _.page({
  loc: 'patients',
  icon: createElement(PatientsIcon),
  slug: 'patients',
  label: 'OCM Patients',
  visibility: 'tupleStaff',
  childConcept: _.concept(
    routerKinds.patient,
    _.page({
      loc: 'patient_timeline',
      label: 'Patient',
    }),
  ),
});

const episodes = _.page({
  loc: 'episodes',
  icon: createElement(PatientsIcon),
  slug: 'episodes',
  label: 'OCM Episodes',
});

// =============================================================================
// clinicians
// =============================================================================

const clinicians = _.page({
  loc: 'ocm_clinician_list',
  icon: createElement(ClinicianIcon),
  slug: 'clinicians',
  label: 'Clinicians',
  childConcept: _.concept(
    routerKinds.clinician, // kind
    _.page({
      loc: 'ocm_clinician_overview',
      label: 'Clinician',
    }),
  ),
});

// =============================================================================
// recon
// =============================================================================

const recon = _.tabMenu({
  slug: 'reconciliation',
  icon: createElement(ReconciliationIcon),
  label: 'Reconciliation Summary',
  items: [
    _.page({
      loc: 'recon_financials',
      label: 'Financial Results',
    }),
    _.page({
      loc: 'recon_cancers',
      slug: 'cancers',
      label: 'Cancers',
    }),
    // _.page({
    //   loc: 'recon_markets',
    //   slug: 'markets',
    //   label: 'Markets',
    // }),
    _.page({
      loc: 'recon_clinicians',
      slug: 'clinicians',
      label: 'Clinicians',
    }),
    // _.page({
    //   loc: 'recon_trueups',
    //   slug: 'true-ups',
    //   label: 'True-ups',
    // }),
    _.page({
      loc: 'patient_explorer',
      slug: 'patients',
      label: 'Patient Explorer',
    }),
  ],
});

// =============================================================================
// snf
// =============================================================================

const snf = _.tabMenu({
  slug: 'skilled-nursing-facility',
  label: 'SNF Stays',
  icon: createElement(SnfIcon),
  items: [
    _.page({
      loc: 'snf_overview',
      label: 'Overview',
      visibility: 'tupleStaff',
    }),
    _.page({
      slug: 'costs',
      loc: 'snf_costs',
      label: 'Costs',
      visibility: 'tupleStaff',
    }),
    _.page({
      slug: 'utilization',
      loc: 'snf_utilization',
      label: 'Utilization',
      visibility: 'tupleStaff',
    }),
    _.page({
      slug: 'outliers',
      loc: 'snf_outliers',
      label: 'Outliers',
      visibility: 'tupleStaff',
    }),
    _.page({
      slug: 'insights',
      loc: 'snf_insights',
      label: 'Key Insights',
      visibility: 'tupleStaff',
    }),
    _.page({
      slug: 'performance',
      loc: 'snf_performance',
      label: 'OCM Performance',
      visibility: 'tupleStaff',
    }),
  ],
});

// =============================================================================
// hh
// =============================================================================

const hh = _.tabMenu({
  slug: 'home-health',
  label: 'Home Health',
  icon: createElement(HomeHealthIcon),
  items: [
    _.page({
      loc: 'hh_overview',
      label: 'Overview',
    }),
    _.page({
      slug: 'costs',
      loc: 'hh_costs',
      label: 'Costs',
    }),
    _.page({
      slug: 'utilization',
      loc: 'hh_utilization',
      label: 'Utilization',
    }),
    // _.page({
    //   slug: 'outliers',
    //   loc: 'hh_outliers',
    //   label: 'Outliers',
    // }),
    _.page({
      slug: 'insights',
      loc: 'hh_insights',
      label: 'Key Insights',
    }),
    _.page({
      slug: 'performance',
      loc: 'hh_performance',
      label: 'OCM Performance',
    }),
  ],
});

// =============================================================================
// eol
// =============================================================================

const eol = _.tabMenu({
  slug: 'end-of-life',
  label: 'Hospice & End of Life',
  icon: createElement(HospiceIcon),
  items: [
    _.page({
      loc: 'eol_overview',
      label: 'Overview',
    }),
    _.page({
      slug: 'costs',
      loc: 'eol_costs',
      label: 'Costs',
    }),
    _.page({
      slug: 'utilization',
      loc: 'eol_utilization',
      label: 'Utilization',
    }),
    _.page({
      slug: 'outliers',
      loc: 'eol_outliers',
      label: 'Outliers',
    }),
    _.page({
      slug: 'insights',
      loc: 'eol_insights',
      label: 'Key Insights',
    }),
    _.page({
      slug: 'performance',
      loc: 'eol_performance',
      label: 'OCM Performance',
    }),
  ],
});

// =============================================================================
// methodology
// =============================================================================

const methodology = _.tabMenu({
  slug: 'methodology',
  label: 'OCM Methodology',
  icon: createElement(MethodologyIcon),
  items: [
    _.page({
      loc: 'methodology_overview',
      label: 'Target Prices',
    }),
    _.page({
      slug: 'costs',
      loc: 'methodology_costs',
      label: 'Episode Costs',
    }),
    _.page({
      slug: 'pbp',
      loc: 'methodology_pbp',
      label: 'PBP Calculations',
    }),
    _.page({
      slug: 'prediction-model',
      loc: 'methodology_prediction_model',
      label: 'Prediction Model',
    }),
    _.page({
      slug: 'metastatic',
      loc: 'methodology_metastatic',
      label: 'Metastatic Adjustments',
    }),
    _.page({
      slug: 'two-sided-risk',
      loc: 'methodology_two_sided',
      label: '2-Sided Risk',
    }),
    _.page({
      slug: 'updates',
      loc: 'methodology_updates',
      label: 'Methodology Updates',
    }),
  ],
});

// =============================================================================
// v2_hosp
// =============================================================================

const v2_hosp = _.tabMenu({
  slug: 'hospitalizations',
  label: 'Hospitalizations',
  icon: createElement(HospitalIcon),
  items: [
    _.page({
      loc: 'v2_hosp_impact',
      label: 'Impact on Performance',
    }),
    _.page({
      slug: 'utilization',
      loc: 'v2_hosp_utilization',
      label: 'Use & Rates',
    }),
    _.page({
      slug: 'cancers',
      loc: 'v2_hosp_cancers',
      label: 'By Cancer',
    }),
    _.page({
      slug: 'outliers',
      loc: 'v2_hosp_outliers',
      label: 'Outliers',
    }),
    _.page({
      slug: 'readmissions',
      loc: 'v2_hosp_readmissions',
      label: '30-Day Readmissions',
    }),
    _.page({
      slug: 'stays',
      loc: 'v2_hosp_stays',
      label: 'Course of Stay',
    }),
  ],
});

// =============================================================================
// v2_ed
// =============================================================================

const v2_ed = _.tabMenu({
  slug: 'ed',
  label: 'ED & Obs',
  icon: createElement(EdIcon),
  items: [
    _.page({
      loc: 'v2_ed_impact',
      label: 'Impact on Performance',
      childItems: [
        // TODO this was removed, delete if ends up not being used
        _.page({
          slug: 'observation',
          loc: 'v2_ed_observation',
          label: 'Observation Stays',
        }),
      ],
    }),
    _.page({
      slug: 'metric',
      loc: 'v2_ed_metric',
      label: 'OCM Metric',
    }),
    _.page({
      slug: 'utilization',
      loc: 'v2_ed_utilization',
      label: 'Use & Rates',
    }),
    _.page({
      slug: 'cancers',
      loc: 'v2_ed_cancers',
      label: 'By Cancer',
    }),
    _.page({
      slug: 'outliers',
      loc: 'v2_ed_outliers',
      label: 'Outliers',
    }),
    _.page({
      slug: 'stays',
      loc: 'v2_ed_visits',
      label: 'Course of Visit',
    }),
  ],
});

// =============================================================================
// v2_pac
// =============================================================================

const v2_pac = _.tabMenu({
  slug: 'pac',
  label: 'PAC Stays',
  icon: createElement(PacIcon),
  items: [
    _.page({
      loc: 'v2_pac_impact',
      label: 'Impact on Performance',
      childItems: [],
    }),
    _.page({
      slug: 'utilization',
      loc: 'v2_pac_utilization',
      label: 'Use & Rates',
    }),
    _.page({
      slug: 'cancers',
      loc: 'v2_pac_cancers',
      label: 'By Cancer',
    }),
    _.page({
      slug: 'outliers',
      loc: 'v2_pac_outliers',
      label: 'Outliers',
    }),
    _.page({
      slug: 'stays',
      loc: 'v2_pac_stays',
      label: 'Course of Stay',
    }),
  ],
});

// =============================================================================
// v2_hh
// =============================================================================

const v2_hh = _.tabMenu({
  slug: 'hh',
  label: 'Home Health',
  icon: createElement(HomeHealthIcon),
  items: [
    _.page({
      loc: 'v2_hh_impact',
      label: 'Impact on Performance',
      visibility: 'tupleStaff',
      childItems: [],
    }),
    _.page({
      slug: 'utilization',
      loc: 'v2_hh_utilization',
      label: 'Use & Rates',
      visibility: 'tupleStaff',
    }),
    _.page({
      slug: 'cancers',
      loc: 'v2_hh_diagnosis',
      label: 'Diagnosis',
      visibility: 'tupleStaff',
    }),
    _.page({
      slug: 'outliers',
      loc: 'v2_hh_provider',
      label: 'Providers',
      visibility: 'tupleStaff',
    }),
  ],
});

// =============================================================================
// v2_eol
// =============================================================================

const v2_eol = _.tabMenu({
  slug: 'hospice-and-end-of-life',
  label: 'Hospice & End of Life',
  icon: createElement(HospiceIcon),
  items: [
    _.page({
      loc: 'v2_eol_metric',
      label: 'OCM Metric',
      // visibility: 'tupleStaff',
      childItems: [],
    }),
    _.page({
      slug: 'performance',
      loc: 'v2_eol_performance',
      label: 'Association with Performance',
      // visibility: 'tupleStaff',
    }),
    _.page({
      slug: 'hospice',
      loc: 'v2_eol_hospice',
      label: 'Hospice',
      // visibility: 'tupleStaff',
    }),
    _.page({
      slug: 'end-of-life',
      loc: 'v2_eol_eol',
      label: 'End of Life',
      // visibility: 'tupleStaff',
    }),
    _.page({
      slug: 'outliers',
      loc: 'v2_eol_outliers',
      label: 'Outliers',
      // visibility: 'tupleStaff',
    }),
    _.page({
      slug: 'quality',
      loc: 'v2_eol_quality',
      label: 'Quality of End of Life',
      // visibility: 'tupleStaff',
    }),
  ],
});

// ===========================================================================
// v2 drug
// ===========================================================================

const v2_drug = _.tabMenu({
  slug: 'medications',
  label: 'Medications',
  icon: createElement(DrugIcon),
  items: [
    _.page({
      loc: 'v2_drug_impact',
      label: 'Impact on Performance',
    }),
    _.page({
      slug: 'classes',
      loc: 'v2_drug_classes',
      label: 'By Drug Class',
    }),
    _.page({
      slug: 'agents',
      loc: 'v2_drug_agents',
      label: 'Individual Agents',
    }),
    _.page({
      slug: 'substitutions',
      loc: 'v2_drug_substitutions',
      label: 'Therapeutic Substitutions',
    }),
    _.page({
      slug: 'orals-and-infusions',
      loc: 'v2_drug_orals',
      label: 'Part B vs. Part D',
    }),
    _.page({
      slug: 'outliers',
      loc: 'v2_drug_outliers',
      label: 'Outliers',
    }),
    _.page({
      slug: 'clinicians',
      loc: 'v2_drug_clinicians',
      label: 'Clinicians',
    }),
    // _.page({
    //   slug: 'cohorts',
    //   loc: 'v2_drug_cohorts',
    //   label: 'Cohort Selector',
    // }),
  ],
});

// =============================================================================
// v2_radiation
// =============================================================================

const v2_radiation = _.tabMenu({
  slug: 'radiation',
  label: 'Radiation Therapy',
  icon: createElement(RadiationIcon),
  items: [
    _.page({
      loc: 'v2_radiation_impact',
      label: 'Impact on Performance',
    }),
    _.page({
      slug: 'utilization',
      loc: 'v2_radiation_utilization',
      label: 'Utilization',
    }),
    _.page({
      slug: 'protocols',
      loc: 'v2_radiation_protocol',
      label: 'By Protocol',
    }),
    _.page({
      slug: 'costs',
      loc: 'v2_radiation_costs',
      label: 'Costs',
    }),
    _.page({
      slug: 'outliers',
      loc: 'v2_radiation_outliers',
      label: 'Outliers',
    }),
  ],
});

// =============================================================================
// home
// =============================================================================

const home = _.sideMenu({
  items: [
    _.page({
      loc: 'customer_home',
      icon: createElement(HomeIcon),
      label: 'Home',
      childItems: [
        articles,
        _.page({
          loc: 'globals',
          slug: 'globals',
          label: 'Global Variables',
          visibility: 'tupleStaff',
        }),
        _.page({
          loc: 'pp4_update',
          slug: 'pp4-update',
          label: 'PP4 Update',
          visibility: 'tupleStaff',
        }),
        snf,
        v2_hh,
        eol,
      ],
    }),
    _.page({
      loc: 'ocm_episode_summary',
      icon: createElement(EpisodeIcon),
      slug: '2-sided-risk-report',
      label: '2-Sided Risk Report',
    }),
    recon,
    // patients,
    // clinicians,
    _.folder({
      label: 'Facility Utilization',
      icon: createElement(FacilityIcon),
      items: [v2_hosp, v2_ed, v2_pac],
    }),
    hh,
    v2_eol,
    _.folder({
      label: 'Treatments',
      icon: createElement(TherapyIcon),
      items: [v2_drug, v2_radiation],
    }),
    methodology,
  ],
});

// =============================================================================
// router
// =============================================================================

// top menu
_.add(home);
_.add(patients);
_.add(episodes);
_.add(clinicians);
_.add(folders);

export const ocmRouter = _.build();
