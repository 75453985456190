import * as _ from '@tuple-health/eng/dist/dryscript/ds';
import { unboundRichLinkFormat } from '../richLink/richLink.format';
import { RichLink, richLink__unboundRichLink, UnboundRichLink } from '../richLink/richLink.model';
import { Router } from '@tuple-health/common/dist/router';
import { removePhraseLinks } from './removePhraseLinks';

// =============================================================================
// bound
// =============================================================================

export const writeRichTextMarkup = (
  router: Router<any>,
  markup: string,
  links: RichLink[],
): string => {
  const unboundLinks = links.map(link => richLink__unboundRichLink(router, link));
  return writeUnboundRichTextMarkup(markup, unboundLinks);
};

// =============================================================================
// unbound
// =============================================================================

export const writeUnboundRichTextMarkup = (markup: string, links: UnboundRichLink[]): string => {
  markup = removePhraseLinks(markup);
  links.forEach(link => {
    const linkMarkup = unboundRichLinkFormat.write(link);
    markup = _.strs.replace(markup, link.uuid, linkMarkup);
  });
  return markup;
};
