import querySerializer from 'query-string';
import { actionToPath, RoutesMap } from 'redux-first-router';
import { values, mapValues } from '@tuple-health/common';

// =============================================================================
// loc interface
// =============================================================================

export interface Loc<Params extends object = object> {
  __Params?: Params;
  iaLabel: string; // context free human readable label
  navLabel?: string; // shorter label used in the context of navigation menus
  route: string;
  isPublic?: boolean;
  isArticle?: boolean;
}

// =============================================================================
// loc construction
// =============================================================================

// type ProtoLoc<Params = unknown> = Omit<Loc<Params>, 'key'>;

const toLoc = <Params extends Record<string, string>>(loc: Loc<Params>): Loc<Params> => loc;

const deriveLocs = <M extends Record<string, Loc>>(
  map: M,
): { [K in keyof M]: K extends string ? (M[K] extends Loc<infer P> ? Loc<P> : never) : never } =>
  mapValues(map, (value, key) => Object.assign(value, { key })) as any;

// =============================================================================
// locs
// =============================================================================

type NoParams = Record<never, string>;

type UserParams = Record<never, string>;

// loc keys are serialized in the database and in the markup,
// so don't change them unless we enable backwards compatibility
export const key__loc = deriveLocs({
  // ===========================================================================
  // testing
  // ===========================================================================
  view_fail: toLoc<NoParams>({
    iaLabel: 'View Fail',
    route: '/view-fail-screen',
  }),
  selector_fail: toLoc<NoParams>({
    iaLabel: 'Selector Fail',

    route: '/selector-fail-screen',
  }),
  reducer_fail: toLoc<NoParams>({
    iaLabel: 'Reducer Fail',

    route: '/reducer-fail-screen',
  }),
  agent_fail: toLoc<NoParams>({
    iaLabel: 'Agent Fail',

    route: '/agent-fail-screen',
  }),
  test: toLoc<NoParams>({
    iaLabel: 'Test',

    route: '/test',
  }),
  // ===========================================================================
  // login not required
  // ===========================================================================
  login: toLoc<NoParams>({
    iaLabel: 'Login',

    isPublic: true,
    route: '/',
  }),
  welcome: toLoc<NoParams>({
    iaLabel: 'Welcome',

    isPublic: true,
    route: '/welcome',
  }),
  support: toLoc<NoParams>({
    iaLabel: 'Support Generic',
    navLabel: 'Support',

    isPublic: true,
    route: '/support',
  }),
  support_create_account: toLoc<NoParams>({
    iaLabel: 'Support Create Account',
    navLabel: 'Support',

    isPublic: true,
    route: '/support/create-account',
  }),
  support_welcome_wrong_number: toLoc<NoParams>({
    iaLabel: 'Support Welcome Wrong Number',
    navLabel: 'Support',

    isPublic: true,
    route: '/support/welcome/wrong-number',
  }),
  support_welcome_no_code: toLoc<NoParams>({
    iaLabel: 'Support Welcome No Code',
    navLabel: 'Support',

    isPublic: true,
    route: '/support/welcome/no-code',
  }),
  support_welcome_expired: toLoc<NoParams>({
    iaLabel: 'Support Welcome Expired',
    navLabel: 'Support',

    isPublic: true,
    route: '/support/welcome/expired',
  }),
  support_login_wrong_number: toLoc<NoParams>({
    iaLabel: 'Support Login Wrong Number',
    navLabel: 'Support',

    isPublic: true,
    route: '/support/login/wrong-number',
  }),
  support_login_no_code: toLoc<NoParams>({
    iaLabel: 'Support Login No Code',
    navLabel: 'Support',

    isPublic: true,
    route: '/support/login/no-code',
  }),
  password_reset_init: toLoc<NoParams>({
    iaLabel: 'Reset Password',
    navLabel: 'Reset Password',

    isPublic: true,
    route: '/account/recovery',
  }),
  password_reset_confirm: toLoc<NoParams>({
    iaLabel: 'Reset Password Confirmation',
    navLabel: 'Reset Password',

    isPublic: true,
    route: '/password-reset',
  }),
  // ===========================================================================
  // require login
  // ===========================================================================
  platform: toLoc<UserParams>({
    iaLabel: 'Platform',
    route: `/menu/:path*`,
  }),
  not_found: toLoc<UserParams>({
    iaLabel: 'Not Found',
    route: '@@redux-first-router/NOT_FOUND',
  }),
  org: toLoc<UserParams>({
    iaLabel: 'Organization',
    route: `/org/:path*`,
  }),
});

// =============================================================================
// loc derivations
// =============================================================================

const locs = values(key__loc);

type LocTable = typeof key__loc;

export type LocKey = keyof LocTable;

type ParamsFor<K extends LocKey> = LocTable[K] extends Loc<infer P> ? P : never;

// =============================================================================
// router
// =============================================================================

const routesMap: RoutesMap = {};
for (const loc of locs) routesMap[loc.route] = { path: loc.route };

export const pathTo = <Key extends LocKey>(
  key: Key,
  params: ParamsFor<Key>,
  query?: {},
): string => {
  const loc = key__loc[key];
  return actionToPath({ type: loc.route, payload: params, query }, routesMap, querySerializer);
};

export const hashedPathTo = <Key extends LocKey>(
  key: Key,
  params: ParamsFor<Key>,
  query?: {},
): string => '#' + pathTo(key, params, query);
