import React, { createContext, ReactNode, useCallback, useContext, useMemo, useState } from 'react';
import { RouterVisibility } from '@tuple-health/common/dist/router';
import useCookie from './useCookie';

export interface VisibilityAgent {
  visibility: RouterVisibility;
  toggleVisibility: () => void;
  toggleVisibilityLabel: string;
  isVisible: (item: { visibility: RouterVisibility }) => boolean;
}

// =============================================================================
// context
// =============================================================================

const Context = createContext<VisibilityAgent | undefined>(undefined);

export const VisibilityProvider = Context.Provider;

export default function useVisibility() {
  const context = useContext(Context);
  if (!context) throw Error('froster context not provided');
  return context;
}

// =============================================================================
// actor
// =============================================================================

interface Props {
  children?: ReactNode;
}

const cookieKey = 'session-userMode';

export function VisibilityCreator({ children }: Props) {
  const cookie = useCookie();

  const [visibility, setVisibility] = useState<RouterVisibility>(() => {
    const saved = cookie.load(cookieKey);
    if (saved && (saved === 'user' || saved === 'tupleStaff')) return saved;
    return 'user';
  });

  const setAndSave = useCallback(
    (visibility: RouterVisibility) => {
      setVisibility(visibility);
      cookie.save(cookieKey, visibility);
    },
    [cookie],
  );

  const actor = useMemo<VisibilityAgent>(
    () => ({
      visibility,
      toggleVisibility: () => {
        setAndSave(visibility === 'user' ? 'tupleStaff' : 'user');
      },
      isVisible: item => {
        switch (item.visibility) {
          case 'user':
            return true;
          case 'tupleStaff':
            return visibility === 'tupleStaff';
        }
      },
      toggleVisibilityLabel: visibility === 'user' ? switchToInternalMode : switchToUserMode,
    }),
    [setAndSave, visibility],
  );

  return <VisibilityProvider value={actor}>{children}</VisibilityProvider>;
}

export const switchToInternalMode = 'Switch to Internal View';
const switchToUserMode = 'Switch to User View';
