import { Concept, Uri } from '@tuple-health/common/dist/data';
import { createUriPath, UriPath } from '@tuple-health/common/dist/router';
import * as _ from '@tuple-health/eng/dist/dryscript/ds';
import { Customer } from '@tuple-health/eng/dist/th/ds/common/domain/customer/customer/Customer';
import React, { createContext, ReactNode, useContext, useMemo } from 'react';
import { GatewayConceptSearchProvider } from '../../platform/apps/vcm/db/common/useConceptSearch';
import { GatewayDecodeConceptsProvider } from '../../platform/apps/vcm/db/common/useDecodeConcepts';
import { adaptPlatformCustomer } from '../../platform/platform/session.adapt';

interface CustomerAgent {
  customerRecord: _.Record<Customer>;
  customer: Customer;
  customerId: string;
  customerKey: string;
  customerSlug: string;
  customerName: string;
  customerConcept: Concept;
  customerRoute: UriPath;
  customerProductConcepts: Concept[];
}

const CustomerContext = createContext<CustomerAgent | undefined>(undefined);

interface Props {
  customerRecord: _.Record<Customer>;
  uris?: Uri[];
  children?: ReactNode;
}

const noUris: Uri[] = [];

export function CustomerCreator({ customerRecord, uris = noUris, children }: Props) {
  const agent: CustomerAgent = useMemo(() => {
    const platformCustomer = adaptPlatformCustomer(customerRecord);
    return {
      customerRecord,
      customer: customerRecord.data,
      customerId: customerRecord.id,
      customerKey: customerRecord.key!,
      customerSlug: customerRecord.slug!,
      customerName: customerRecord.data.name,
      customerConcept: platformCustomer.customerConcept,
      customerProductConcepts: platformCustomer.productConcepts,
      customerRoute: createUriPath(uris),
    };
  }, [uris, customerRecord]);

  return (
    <CustomerContext.Provider value={agent}>
      <GatewayDecodeConceptsProvider>
        <GatewayConceptSearchProvider>{children}</GatewayConceptSearchProvider>
      </GatewayDecodeConceptsProvider>
    </CustomerContext.Provider>
  );
}

export function useCustomerOpt() {
  return useContext(CustomerContext);
}

export default function useCustomer() {
  const context = useCustomerOpt();
  if (!context) throw Error('customer context not provided');
  return context;
}
