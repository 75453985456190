import { useMemo } from 'react';
import useMethods from 'use-methods';

export type PushHandlerMachine = ReturnType<typeof usePushHandlerMachine>;

export interface PushHandlerMachineArgs {
  hasPushSupport?: boolean;
  isPushEnabled?: boolean;
}

export function usePushHandlerMachine(args: PushHandlerMachineArgs) {
  const [state, callbacks] = useMethods(methods, args);
  return useMemo(() => ({ ...state, ...callbacks }), [callbacks, state]);
}

function methods(state: PushHandlerMachineArgs) {
  return {
    updatePushSupport(hasPushSupport?: boolean) {
      state.hasPushSupport = hasPushSupport;
    },
    updatePushStatus(isPushEnabled?: boolean) {
      state.isPushEnabled = isPushEnabled;
    },
  };
}
