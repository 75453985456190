import { Array, Boolean, Partial, Record, Static, String, Union } from 'runtypes';
import { pick } from '@tuple-health/common';
import { toAdapter } from '../util/adaptLib';
import { RefineItem, RefineMenu } from './refineMenu.model';

// =============================================================================
// menu
// =============================================================================

// hard coded items or the tree level for looking them up
const JsonRefineSource = Union(Array(RefineItem), String);
type JsonRefineSource = Static<typeof JsonRefineSource>;

export const JsonRefineMenu = Record({
  label: String,
  items: JsonRefineSource,
}).And(
  Partial({
    filter: Boolean, // default is to filter
    conceptType: String,
    itemsFilter: String,
  }),
);
export type JsonRefineMenu = Static<typeof JsonRefineMenu>;

// =============================================================================
// format
// =============================================================================

export const jsonRefineMenuAdapter = toAdapter<JsonRefineMenu, RefineMenu>(
  json => {
    const { items } = json;
    if (typeof items === 'string') {
      return RefineMenu.taxMenu({
        ...pick(json, 'label', 'conceptType'),
        treeLevel: items,
        doFilter: typeof json.filter === 'undefined' ? true : json.filter,
        ...(json.itemsFilter ? { itemsFilter: json.itemsFilter } : {}),
      });
    } else {
      if (json.conceptType) throw Error('concept type not applicable to hand written refine menus');
      return RefineMenu.itemMenu({
        label: json.label,
        items,
      });
    }
  },
  model =>
    RefineMenu.match<JsonRefineMenu>(model, {
      itemMenu: menu => menu,
      taxMenu: menu => ({
        ...pick(menu, 'label', 'conceptType'),
        items: menu.treeLevel,
        ...(menu.doFilter ? {} : { filter: false }),
        ...(menu.itemsFilter ? { itemsFilter: menu.itemsFilter } : {}),
      }),
    }),
);
