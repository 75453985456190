import React from 'react';
import { ofType } from 'unionize';
import { Action } from '../core/action';
import { Agent } from '../core/agent';
import { placeholder } from '../core/appbase/screen';
import { Contract, makeContract } from '../core/contract';
import { makeProvider } from '../core/provider';
import { Reducer } from '../core/reducer';
import { Selector } from '../core/selector';
import { makeService, Service } from '../core/service';

interface BaseWidgetDefinition<State> {
  tag: string;
  agent?: Agent<State>;
  reducer?: Reducer<State, Action<never, never>>;
}

interface WidgetDefinitionWithProps<State, Props> extends BaseWidgetDefinition<State> {
  selector: Selector<State, Props>;
  view?: React.ComponentType<Props>;
}

interface WidgetDefinitionWithoutProps<State> extends BaseWidgetDefinition<State> {
  view?: React.ComponentType<{}>;
}

interface WidgetDefinition<State, Props> extends BaseWidgetDefinition<State> {
  selector?: Selector<State, Props>;
  view?: React.ComponentType<Props>;
}

interface Widget<State, Props> {
  contract: Contract<Props>;
  service: Service<State>;
  render: (props: Props & { children?: React.ReactNode }) => React.ReactElement<Props>;

  _State: State;
  _Props: Props;
}

export function makeWidget<State, Props>(
  args: WidgetDefinitionWithProps<State, Props>,
): Widget<State, Props>;
export function makeWidget<State>(args: WidgetDefinitionWithoutProps<State>): Widget<State, {}>;
export function makeWidget<State, Props>(args: any): Widget<State, Props> {
  const { tag, agent, reducer, selector, view = placeholder } = args as WidgetDefinition<
    State,
    Props
  >;

  // TODO: contract and service should be unique per-instance
  const contract = makeContract<Props>(tag);

  let currentProps: Props;
  const provider = makeProvider<State, Props>(contract, (context, resolve) => {
    const nextProps = selector ? selector(context, resolve) : currentProps;
    if (!currentProps || currentProps !== nextProps) currentProps = nextProps;

    return currentProps;
  });

  const service = makeService({
    reducer,
    agent,
    providers: [provider],
  });

  const render: Widget<State, Props>['render'] = props => React.createElement(view, props);

  return { contract, service, render, _State: ofType<State>(), _Props: ofType<Props>() };
}
