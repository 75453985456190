import * as _ from '@tuple-health/eng/dist/dryscript/ds';
import * as toLru from '@tuple-health/eng/dist/dryscript/lib/common/data/lru/toLru';
import * as toGenericMsg from '@tuple-health/eng/dist/dryscript/lib/common/ui/content/msg/toGenericMsg';
import { GatewayApi } from '@tuple-health/eng/dist/th/ds/common/agent/gatewayApi/api/GatewayApi';
import { GatewaySession } from '@tuple-health/eng/dist/th/ds/common/agent/gatewayApi/service/session/GatewaySession';
import { DataResult } from '@tuple-health/eng/dist/th/ds/common/tech/analytics/data/DataResult';
import { makeApi } from '../gateway/api';
import {
  CubeRequest,
  cubeRequest__dataRequest,
  CustomerCubeRequest,
  makeCustomerCubeRequest,
} from './query.types';
import { sleep } from '@tuple-health/common';

interface RequestArgs {
  gateway: GatewayApi;
  session: GatewaySession;
  customerId: string;
  request: CubeRequest;
}

export function submitRequest(args: RequestArgs): Promise<DataResult> {
  const cache = (args.session as any).queryCache as QueryCache;
  if (!cache) throw Error('could not get query cache');
  return cache.request(args);
}

interface QueryCache {
  clear(): void;

  request(args: RequestArgs): Promise<DataResult>;
}

export function makeQueryCache(cacheSize = 1000): QueryCache {
  // cache [internal]
  const cache = toLru.call(cacheSize).of<string, Promise<DataResult>>();

  // clear()
  const clear = cache.empty_;

  // request()
  function sendRequest({
    gateway,
    session,
    customerId,
    request,
  }: RequestArgs): Promise<DataResult> {
    // console.log('query cache - request: ', query.query);
    const req = makeCustomerCubeRequest({ ...request, customerId });

    let promise = cache.callOpt(req.fingerprint);
    if (!promise) {
      // console.log('query cache - MISS: ', query.query);
      promise = dispatch(gateway, session.token, req).catch(e => {
        cache.delete_(req.fingerprint);
        // console.error(
        //   `query failed:\n\ndataset:\n\n${query.dataset.trim()}\n\nquery:\n\n${query.query.trim()}`,
        // );
        throw e;
      });
      cache.put_(req.fingerprint, promise);
    } else {
      // console.log('query cache - HIT: ', query.query);
    }

    return promise;
  }

  return { request: sendRequest, clear };
}

async function dispatch(
  gateway: GatewayApi,
  token: string,
  request: CustomerCubeRequest,
): Promise<DataResult> {
  // console.log('query cache - dispatch: ', request.query);
  // console.log(request.query.query);

  // console.log('QUERY START');
  // const start = new Date().getTime();
  let reply = await attempt(gateway, token, request);
  // const stop = new Date().getTime();
  // console.log('QUERY STATUS first attempt', reply.status, ', elapsed ms: ', stop - start);

  let pauseMs = 5 * 1000; // start with 5s pause
  const maxPauseMs = 30 * 1000; // increase pause until it gets to 30s
  const maxWaitMs = 5 * 60 * 1000; // retry for 5 minutes
  let totalWaitMs = 0;
  let errorCount = 0;
  const maxErrorCount = 2;
  while (reply.status >= 500 && totalWaitMs < maxWaitMs && errorCount < maxErrorCount) {
    // note that this check is done before doing any retries
    // don't keep retrying for server errors other than timeout
    if (reply.status !== 504 && reply.status !== 599) {
      errorCount++;
    }

    // console.log('QUERY RETRY', tryCount);
    await sleep(pauseMs); // give server a break
    totalWaitMs += pauseMs;

    pauseMs = Math.min(maxPauseMs, pauseMs + 5 * 1000); // backoff
    reply = await attempt(gateway, token, request);
  }

  if (reply.notOk) {
    throw Error(
      toGenericMsg.fromReply(reply).text || 'unknown query error: no message from server',
    );
  }

  const containerBody = _.opt(reply.body);
  if (containerBody.count !== 1)
    throw new Error(`too many replies: expected 1 but got ${containerBody.count}`);

  const bodyReply = _.opt(reply.body).singleItem;
  if (bodyReply.notOk)
    throw new Error(toGenericMsg.fromReply(bodyReply).text || 'unknown nested error');

  const result = bodyReply.body!;
  // console.log('REPLY');
  // console.log(dsResultFormat.write(result));

  return result;
}

function attempt(
  gateway: GatewayApi,
  token: string,
  request: CustomerCubeRequest,
): Promise<_.Reply<_.Array<_.Reply<DataResult>>>> {
  const { customerId, comparison } = request;

  return (
    makeApi(gateway, { token, customerId })
      .requestData({
        requests: _.toArray.of(cubeRequest__dataRequest(request)),
        compare: comparison,
      })
      // Convert back to a non-throwing reply
      .then(_.toReply.ok)
      .catch(e => e.reply)
  );
}
