import * as _ from '@tuple-health/eng/dist/dryscript/ds';
import { toRexNative } from '@tuple-health/eng/dist/dryscript/lib/common/prelude/rex/toRexNative';
import { textHasVars } from '../../../components/article/scope/bindVars';
import { pick } from '@tuple-health/common';
import { embedBlockOpenPrefix } from '../embed/embed.format';
import { RichText, UnboundRichText } from './richText.model';
import {
  unboundRichLinkWithoutVariables__richLink,
  RichLink,
  UnboundRichLink,
} from '../richLink/richLink.model';
import { unboundRichLinkFormat } from '../richLink/richLink.format';
import { Router } from '@tuple-health/common/dist/router';

export function removeRichLinks(before: string): string {
  const { links, textWithUuids } = parseUnboundRichText(before);
  let after = textWithUuids;
  links.forEach(link => {
    after = _.strs.replace(after, link.uuid, link.anchorMarkdown);
  });
  return after;
}

// =============================================================================
// bound
// =============================================================================

export const parseRichText = (router: Router<any>, text: string): RichText => {
  if (textHasVars(text)) {
    throw Error(`parseRichText cannot parse text containing variables:\n${text}`);
  }

  const unboundText = parseUnboundRichText(text);

  const links = unboundText.links.map(link =>
    unboundRichLinkWithoutVariables__richLink(router, link),
  );

  const uuid__link: Record<string, RichLink> = {};
  links.forEach(link => {
    uuid__link[link.uuid] = link;
  });

  return {
    ...pick(unboundText, 'textWithUuids'),
    uuid__link,
    links,
  };
};

// =============================================================================
// unbound
// =============================================================================

export const parseUnboundRichText = (text: string): UnboundRichText => {
  // console.log('parseUnboundRichText');
  // console.log(text);

  const links: UnboundRichLink[] = [];
  const uuid__link: Record<string, UnboundRichLink> = {};

  while (true) {
    const open = findLinkOpen(text);
    if (!open) break;
    // console.log('open', open);
    // text = text.substring(open.stop);
    const textAfterOpen = text.substring(open.stop);

    const close = findLinkClose(textAfterOpen, open.stop);
    if (!close) throw Error(`could not parse rich text, found unterminated link:\n${text}`);
    // console.log('close', close);

    const link = unboundRichLinkFormat.parse(text.substring(open.start, close.stop));

    links.push(link);
    uuid__link[link.uuid] = link;

    text = text.substring(0, open.start) + link.uuid + text.substring(close.stop);
  }

  return {
    textWithUuids: text,
    uuid__link,
    links,
  };
};

// =============================================================================

const findLinkOpen = (text: string) => {
  // console.log('findLinkOpen', text);

  const linkOpenMatch = toRexNative.match(text, linkOpenRex);
  if (!linkOpenMatch) return undefined;

  const match = text.match(linkOpenRex);
  if (!match) return undefined;

  // const [matchText, anchorText] = match;
  const [matchText] = match;
  const start = match.index!;
  const stop = start + matchText.length;

  return { start, stop };
};

const linkOpenRex = /\[([^\]]+)\]\(/;

// =============================================================================

const findLinkClose = (text: string, offset: number) => {
  // console.log('findLinkClose', text);

  const index = findLinkCloseParen(text);

  return typeof index === 'undefined'
    ? undefined
    : {
        start: index + offset,
        stop: index + offset + ')'.length,
        // resourceMarkup: text.substring(0, index),
      };
};

const findLinkCloseParen = (text: string) => {
  // multi line resource (nested links are supported)
  if (text.startsWith(embedBlockOpenPrefix)) {
    const lines = text.split('\n');
    const parenIndex = findLinkCloseParenNested(lines, 0, 0, 0); // first line increases depth to 1
    return parenIndex === undefined ? undefined : parenIndex;
  }
  // single line resource (nested links not supported)
  else if (text.startsWith('`')) {
    const tickIndex = text.indexOf('`)');
    return tickIndex < 0 ? undefined : tickIndex + 1;
  }
  // single line web link
  else {
    const parenIndex = text.indexOf(')');
    return parenIndex < 0 ? undefined : parenIndex;
  }
};

const findLinkCloseParenNested = (
  lines: string[],
  lineIndex: number,
  charIndex: number,
  depth: number,
): number | undefined => {
  if (lineIndex >= lines.length) return undefined;
  const line = lines[lineIndex];

  if (lineIndex > 0) charIndex++; // count newline

  lineIndex++;

  if (lineClosesEmbed(line)) {
    // console.log('decrease depth', line);
    depth--;
    if (depth === 0) return charIndex + line.indexOf(')');
  }

  if (line.includes(embedBlockOpenPrefix)) {
    // console.log('increase depth', line);
    depth++;
  }

  charIndex += line.length;
  return findLinkCloseParenNested(lines, lineIndex, charIndex, depth);
};

// const embedBlockAndLinkClose = embedBlockClose + ')';

const lineClosesEmbed = (text: string) => {
  text = text.trim();
  return text.startsWith('```') && !text.startsWith('```{');
};
