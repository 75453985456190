import { combineReducers, ReducersMapObject } from 'redux';
import { map, tap } from 'rxjs/operators';
import { Action } from './action';
import { Agent, AgentMap, combineAgents } from './agent';
import { addWitness, keys } from '@tuple-health/common';
import { combineProviders, mergeProviders, Provider, Providers, ProvidersMap } from './provider';
import { Reducer } from './reducer';
import { Resolver } from './resolver';

// ------------------------------
//  services
// ------------------------------
// TODO: expose all injection dependencies through typing?

interface ProtoService<S> {
  reducer?: Reducer<S, Action<never, never>>;
  agent?: Agent<S>;
  providers: Providers<S>;
}

export interface Service<S> extends ProtoService<S> {
  _S: S;
}

export type ServiceState<S extends Service<any>> = S['_S'];

export type ServiceMap<M> = { [K in keyof M]: Service<M[K]> };

const addStateWitness = <S>(proto: ProtoService<S>) => addWitness(proto)<'_S', S>();

export function makeService<S>(definition: {
  reducer?: Reducer<S, Action<never, never>>;
  agent?: Agent<S>;
  providers?: Provider<S, any>[];
}): Service<S> {
  const { reducer, agent, providers = [] } = definition;

  return addStateWitness({
    reducer,
    agent,
    providers: mergeProviders(...providers),
  });
}

export function combineServices<M>(services: ServiceMap<M>): Service<M> {
  const reducers: ReducersMapObject<M, Action<never, never>> = {} as any;
  const agents: AgentMap<M> = {} as any;
  const providers: ProvidersMap<M> = {} as any;

  // TODO: warn on tag / provider collisions
  // TODO: support duplicate + optional injection dependencies
  for (const serviceKey of keys(services)) {
    const service = services[serviceKey];
    if (!service) throw new Error('missing service: ' + serviceKey);

    // combine reducers
    if (service.reducer) reducers[serviceKey] = service.reducer;

    // combine agents
    if (service.agent)
      // TODO: stabilize action sourcing API
      agents[serviceKey] = $ =>
        service.agent!($).pipe(
          tap(stateFrame => {
            // compensating for missing type errors in agents
            if (!stateFrame) {
              throw Error(`missing stateFrame, did you return undefined in an agent?`);
            }
            if (!(stateFrame as any).__source) (stateFrame as any).__source = serviceKey;
          }),
        );

    // collect providers
    providers[serviceKey] = service.providers;
  }

  return addStateWitness({
    // TODO: remove depedency on combineReducers(), because it's opinionated
    //  see https://github.com/reactjs/redux/issues/2427
    reducer: keys(reducers).length ? combineReducers(reducers) : undefined,
    agent: combineAgents(agents),
    providers: combineProviders(providers),
  });
}

// TODO: name this more appropriately
export interface ComposedState<S, T> {
  external: S;
  internal: T;
}

export function composeServices<S, T>(
  external: Service<S>,
  internal: Service<T>,
): Service<ComposedState<S, T>> {
  type M = ComposedState<S, T>;
  const services = { external, internal };

  const reducers: ReducersMapObject<M, Action<never, never>> = {} as any;
  const agents: AgentMap<M> = {} as any;
  const providers: Providers<M> = {} as any;

  for (const serviceKey of keys(services)) {
    const service = services[serviceKey];

    // combine reducers
    if (service.reducer) reducers[serviceKey] = service.reducer as any;

    // combine agents
    if (service.agent) agents[serviceKey] = service.agent as any;
  }

  // combine agent and inject internal providers
  const combinedAgent = combineAgents(agents);
  const agent: typeof combinedAgent = $ =>
    combinedAgent(
      $.pipe(
        map(({ resolve, state, ...rest }) => ({
          resolve: combineResolve(state, resolve),
          state,
          ...rest,
        })),
      ),
    );

  // collect providers
  for (const providerKey of keys(external.providers)) {
    const provider = external.providers[providerKey];
    providers[providerKey] = ({ state }, externalResolve) =>
      provider({ state: state.external }, combineResolve(state, externalResolve));
  }

  return addStateWitness({
    reducer: keys(reducers).length ? combineReducers(reducers) : undefined,
    agent,
    providers,
  });

  // helpers
  function combineResolve(state: ComposedState<S, T>, externalResolve: Resolver): Resolver {
    return function combinedResolve(contract) {
      let result = externalResolve(contract);
      if (!result) {
        const selector = internal.providers[contract._injectiontag];
        result = selector && selector({ state: state.internal }, combinedResolve);
      }
      return result;
    };
  }
}
