import { AnalyticsProduct, TimePeriod, TimePeriodKey } from '../model/analyticsProductModel';

// =============================================================================
// time period
// =============================================================================

const createTimePeriod = (inputs: TimePeriod): TimePeriod => inputs;

export const createTimePeriodQuery = (
  analytics: AnalyticsProduct<any>,
  timePeriod: TimePeriod,
): string => {
  let query = analytics.defaultCatalogCount;

  const filter = createTimePeriodFilter(analytics, timePeriod);
  if (filter) query += '\nWHERE ' + filter;

  return query;
};
const createTimePeriodFilter = (
  analytics: AnalyticsProduct<any>,
  timePeriod: TimePeriod,
): string | undefined => {
  const { minPP, maxPP } = timePeriod;

  const hasMin = typeof minPP === 'number';
  const hasMax = typeof maxPP === 'number';

  let filter: string | undefined;

  if (hasMin || hasMax) {
    filter = '';
    if (hasMin) filter += `${minPP} <= `;
    filter += analytics.timeTree;
    if (hasMax) filter += ` <= ${maxPP}`;
  }

  return filter;
};

export const key__timePeriod: Record<TimePeriodKey, TimePeriod> = {
  ocmAllTime: createTimePeriod({
    productKey: 'ocm',
    key: 'ocmAllTime',
    label: 'OCM No Time Filter',
  }),
  ocmBaseline: createTimePeriod({
    productKey: 'ocm',
    key: 'ocmBaseline',
    label: 'OCM Baseline Period',
    maxPP: 0,
  }),
  ocmPP1: createTimePeriod({
    productKey: 'ocm',
    key: 'ocmPP1',
    label: 'OCM Performance Period 1',
    minPP: 1,
    maxPP: 1,
  }),
  ocmBaselineToPP1: createTimePeriod({
    productKey: 'ocm',
    key: 'ocmBaselineToPP1',
    label: 'OCM Baseline to Performance Period 1',
    maxPP: 1,
  }),
  ocmPP2: createTimePeriod({
    productKey: 'ocm',
    key: 'ocmPP2',
    label: 'OCM Performance Period 2',
    minPP: 2,
    maxPP: 2,
  }),
  ocmBaselineToPP2: createTimePeriod({
    productKey: 'ocm',
    key: 'ocmBaselineToPP2',
    label: 'OCM Baseline to Performance Period 2',
    maxPP: 2,
  }),
  ocmPP1ToPP2: createTimePeriod({
    productKey: 'ocm',
    key: 'ocmPP1ToPP2',
    label: 'OCM Performance Periods 1-2',
    minPP: 1,
    maxPP: 2,
  }),
  ocmPP3: createTimePeriod({
    productKey: 'ocm',
    key: 'ocmPP3',
    label: 'OCM Performance Period 3',
    minPP: 3,
    maxPP: 3,
  }),
  ocmBaselineToPP3: createTimePeriod({
    productKey: 'ocm',
    key: 'ocmBaselineToPP3',
    label: 'OCM Baseline to Performance Period 3',
    maxPP: 3,
  }),
  ocmPP1ToPP3: createTimePeriod({
    productKey: 'ocm',
    key: 'ocmPP1ToPP3',
    label: 'OCM Performance Periods 1-3',
    minPP: 1,
    maxPP: 3,
  }),
  ocmPP2ToPP3: createTimePeriod({
    productKey: 'ocm',
    key: 'ocmPP2ToPP3',
    label: 'OCM Performance Periods 2-3',
    minPP: 2,
    maxPP: 3,
  }),
  ocmPP4: createTimePeriod({
    productKey: 'ocm',
    key: 'ocmPP4',
    label: 'OCM Performance Period 4',
    minPP: 4,
    maxPP: 4,
  }),
  ocmBaselineToPP4: createTimePeriod({
    productKey: 'ocm',
    key: 'ocmBaselineToPP4',
    label: 'OCM Baseline to Performance Period 4',
    maxPP: 4,
  }),
  ocmPP1ToPP4: createTimePeriod({
    productKey: 'ocm',
    key: 'ocmPP1ToPP4',
    label: 'OCM Performance Periods 1-4',
    minPP: 1,
    maxPP: 4,
  }),
  ocmPP2ToPP4: createTimePeriod({
    productKey: 'ocm',
    key: 'ocmPP2ToPP4',
    label: 'OCM Performance Periods 2-4',
    minPP: 2,
    maxPP: 4,
  }),
  ocmPP3ToPP4: createTimePeriod({
    productKey: 'ocm',
    key: 'ocmPP3ToPP4',
    label: 'OCM Performance Periods 3-4',
    minPP: 3,
    maxPP: 4,
  }),

  ocmPP5: createTimePeriod({
    productKey: 'ocm',
    key: 'ocmPP5',
    label: 'OCM Performance Period 5',
    minPP: 5,
    maxPP: 5,
  }),
  ocmBaselineToPP5: createTimePeriod({
    productKey: 'ocm',
    key: 'ocmBaselineToPP5',
    label: 'OCM Baseline to Performance Period 5',
    maxPP: 5,
  }),
  ocmPP1ToPP5: createTimePeriod({
    productKey: 'ocm',
    key: 'ocmPP1ToPP5',
    label: 'OCM Performance Periods 1-5',
    minPP: 1,
    maxPP: 5,
  }),
  ocmPP2ToPP5: createTimePeriod({
    productKey: 'ocm',
    key: 'ocmPP2ToPP5',
    label: 'OCM Performance Periods 2-5',
    minPP: 2,
    maxPP: 5,
  }),
  ocmPP3ToPP5: createTimePeriod({
    productKey: 'ocm',
    key: 'ocmPP3ToPP5',
    label: 'OCM Performance Periods 3-5',
    minPP: 3,
    maxPP: 5,
  }),
  ocmPP4ToPP5: createTimePeriod({
    productKey: 'ocm',
    key: 'ocmPP4ToPP5',
    label: 'OCM Performance Periods 4-5',
    minPP: 4,
    maxPP: 5,
  }),

  ocmPP6: createTimePeriod({
    productKey: 'ocm',
    key: 'ocmPP6',
    label: 'OCM Performance Period 6',
    minPP: 6,
    maxPP: 6,
  }),
  ocmBaselineToPP6: createTimePeriod({
    productKey: 'ocm',
    key: 'ocmBaselineToPP6',
    label: 'OCM Baseline to Performance Period 6',
    maxPP: 6,
  }),
  ocmPP1ToPP6: createTimePeriod({
    productKey: 'ocm',
    key: 'ocmPP1ToPP6',
    label: 'OCM Performance Periods 1-6',
    minPP: 1,
    maxPP: 6,
  }),
  ocmPP2ToPP6: createTimePeriod({
    productKey: 'ocm',
    key: 'ocmPP2ToPP6',
    label: 'OCM Performance Periods 2-6',
    minPP: 2,
    maxPP: 6,
  }),
  ocmPP3ToPP6: createTimePeriod({
    productKey: 'ocm',
    key: 'ocmPP3ToPP6',
    label: 'OCM Performance Periods 3-6',
    minPP: 3,
    maxPP: 6,
  }),
  ocmPP4ToPP6: createTimePeriod({
    productKey: 'ocm',
    key: 'ocmPP4ToPP6',
    label: 'OCM Performance Periods 4-6',
    minPP: 4,
    maxPP: 6,
  }),
  ocmPP5ToPP6: createTimePeriod({
    productKey: 'ocm',
    key: 'ocmPP5ToPP6',
    label: 'OCM Performance Periods 5-6',
    minPP: 5,
    maxPP: 6,
  }),

  ocmPP7: createTimePeriod({
    productKey: 'ocm',
    key: 'ocmPP7',
    label: 'OCM Performance Period 7',
    minPP: 7,
    maxPP: 7,
  }),
  ocmBaselineToPP7: createTimePeriod({
    productKey: 'ocm',
    key: 'ocmBaselineToPP7',
    label: 'OCM Baseline to Performance Period 7',
    maxPP: 7,
  }),
  ocmPP1ToPP7: createTimePeriod({
    productKey: 'ocm',
    key: 'ocmPP1ToPP7',
    label: 'OCM Performance Periods 1-7',
    minPP: 1,
    maxPP: 7,
  }),
  ocmPP2ToPP7: createTimePeriod({
    productKey: 'ocm',
    key: 'ocmPP2ToPP7',
    label: 'OCM Performance Periods 2-7',
    minPP: 2,
    maxPP: 7,
  }),
  ocmPP3ToPP7: createTimePeriod({
    productKey: 'ocm',
    key: 'ocmPP3ToPP7',
    label: 'OCM Performance Periods 3-7',
    minPP: 3,
    maxPP: 7,
  }),
  ocmPP4ToPP7: createTimePeriod({
    productKey: 'ocm',
    key: 'ocmPP4ToPP7',
    label: 'OCM Performance Periods 4-7',
    minPP: 4,
    maxPP: 7,
  }),
  ocmPP5ToPP7: createTimePeriod({
    productKey: 'ocm',
    key: 'ocmPP5ToPP7',
    label: 'OCM Performance Periods 5-7',
    minPP: 5,
    maxPP: 7,
  }),
  ocmPP6ToPP7: createTimePeriod({
    productKey: 'ocm',
    key: 'ocmPP6ToPP7',
    label: 'OCM Performance Periods 6-7',
    minPP: 6,
    maxPP: 7,
  }),

  ocmPP8: createTimePeriod({
    productKey: 'ocm',
    key: 'ocmPP8',
    label: 'OCM Performance Period 8',
    minPP: 8,
    maxPP: 8,
  }),
  ocmBaselineToPP8: createTimePeriod({
    productKey: 'ocm',
    key: 'ocmBaselineToPP8',
    label: 'OCM Baseline to Performance Period 8',
    maxPP: 8,
  }),
  ocmPP1ToPP8: createTimePeriod({
    productKey: 'ocm',
    key: 'ocmPP1ToPP8',
    label: 'OCM Performance Periods 1-8',
    minPP: 1,
    maxPP: 8,
  }),
  ocmPP2ToPP8: createTimePeriod({
    productKey: 'ocm',
    key: 'ocmPP2ToPP8',
    label: 'OCM Performance Periods 2-8',
    minPP: 2,
    maxPP: 8,
  }),
  ocmPP3ToPP8: createTimePeriod({
    productKey: 'ocm',
    key: 'ocmPP3ToPP8',
    label: 'OCM Performance Periods 3-8',
    minPP: 3,
    maxPP: 8,
  }),
  ocmPP4ToPP8: createTimePeriod({
    productKey: 'ocm',
    key: 'ocmPP4ToPP8',
    label: 'OCM Performance Periods 4-8',
    minPP: 4,
    maxPP: 8,
  }),
  ocmPP5ToPP8: createTimePeriod({
    productKey: 'ocm',
    key: 'ocmPP5ToPP8',
    label: 'OCM Performance Periods 5-8',
    minPP: 5,
    maxPP: 8,
  }),
  ocmPP6ToPP8: createTimePeriod({
    productKey: 'ocm',
    key: 'ocmPP6ToPP8',
    label: 'OCM Performance Periods 6-8',
    minPP: 6,
    maxPP: 8,
  }),
  ocmPP7ToPP8: createTimePeriod({
    productKey: 'ocm',
    key: 'ocmPP7ToPP8',
    label: 'OCM Performance Periods 7-8',
    minPP: 7,
    maxPP: 8,
  }),

  ocmPP9: createTimePeriod({
    productKey: 'ocm',
    key: 'ocmPP9',
    label: 'OCM Performance Period 9',
    minPP: 9,
    maxPP: 9,
  }),
  ocmBaselineToPP9: createTimePeriod({
    productKey: 'ocm',
    key: 'ocmBaselineToPP9',
    label: 'OCM Baseline to Performance Period 9',
    maxPP: 9,
  }),
  ocmPP1ToPP9: createTimePeriod({
    productKey: 'ocm',
    key: 'ocmPP1ToPP9',
    label: 'OCM Performance Periods 1-9',
    minPP: 1,
    maxPP: 9,
  }),
  ocmPP2ToPP9: createTimePeriod({
    productKey: 'ocm',
    key: 'ocmPP2ToPP9',
    label: 'OCM Performance Periods 2-9',
    minPP: 2,
    maxPP: 9,
  }),
  ocmPP3ToPP9: createTimePeriod({
    productKey: 'ocm',
    key: 'ocmPP3ToPP9',
    label: 'OCM Performance Periods 3-9',
    minPP: 3,
    maxPP: 9,
  }),
  ocmPP4ToPP9: createTimePeriod({
    productKey: 'ocm',
    key: 'ocmPP4ToPP9',
    label: 'OCM Performance Periods 4-9',
    minPP: 4,
    maxPP: 9,
  }),
  ocmPP5ToPP9: createTimePeriod({
    productKey: 'ocm',
    key: 'ocmPP5ToPP9',
    label: 'OCM Performance Periods 5-9',
    minPP: 5,
    maxPP: 9,
  }),
  ocmPP6ToPP9: createTimePeriod({
    productKey: 'ocm',
    key: 'ocmPP6ToPP9',
    label: 'OCM Performance Periods 6-9',
    minPP: 6,
    maxPP: 9,
  }),
  ocmPP7ToPP9: createTimePeriod({
    productKey: 'ocm',
    key: 'ocmPP7ToPP9',
    label: 'OCM Performance Periods 7-9',
    minPP: 7,
    maxPP: 9,
  }),
  ocmPP8ToPP9: createTimePeriod({
    productKey: 'ocm',
    key: 'ocmPP8ToPP9',
    label: 'OCM Performance Periods 8-9',
    minPP: 8,
    maxPP: 9,
  }),

  ocmPP10: createTimePeriod({
    productKey: 'ocm',
    key: 'ocmPP10',
    label: 'OCM Performance Period 10',
    minPP: 10,
    maxPP: 10,
  }),
  ocmBaselineToPP10: createTimePeriod({
    productKey: 'ocm',
    key: 'ocmBaselineToPP10',
    label: 'OCM Baseline to Performance Period 10',
    maxPP: 10,
  }),
  ocmPP1ToPP10: createTimePeriod({
    productKey: 'ocm',
    key: 'ocmPP1ToPP10',
    label: 'OCM Performance Periods 1-10',
    minPP: 1,
    maxPP: 10,
  }),
  ocmPP2ToPP10: createTimePeriod({
    productKey: 'ocm',
    key: 'ocmPP2ToPP10',
    label: 'OCM Performance Periods 2-10',
    minPP: 2,
    maxPP: 10,
  }),
  ocmPP3ToPP10: createTimePeriod({
    productKey: 'ocm',
    key: 'ocmPP3ToPP10',
    label: 'OCM Performance Periods 3-10',
    minPP: 3,
    maxPP: 10,
  }),
  ocmPP4ToPP10: createTimePeriod({
    productKey: 'ocm',
    key: 'ocmPP4ToPP10',
    label: 'OCM Performance Periods 4-10',
    minPP: 4,
    maxPP: 10,
  }),
  ocmPP5ToPP10: createTimePeriod({
    productKey: 'ocm',
    key: 'ocmPP5ToPP10',
    label: 'OCM Performance Periods 5-10',
    minPP: 5,
    maxPP: 10,
  }),
  ocmPP6ToPP10: createTimePeriod({
    productKey: 'ocm',
    key: 'ocmPP6ToPP10',
    label: 'OCM Performance Periods 6-10',
    minPP: 6,
    maxPP: 10,
  }),
  ocmPP7ToPP10: createTimePeriod({
    productKey: 'ocm',
    key: 'ocmPP7ToPP10',
    label: 'OCM Performance Periods 7-10',
    minPP: 7,
    maxPP: 10,
  }),
  ocmPP8ToPP10: createTimePeriod({
    productKey: 'ocm',
    key: 'ocmPP8ToPP10',
    label: 'OCM Performance Periods 8-10',
    minPP: 8,
    maxPP: 10,
  }),
  ocmPP9ToPP10: createTimePeriod({
    productKey: 'ocm',
    key: 'ocmPP9ToPP10',
    label: 'OCM Performance Periods 9-10',
    minPP: 9,
    maxPP: 10,
  }),

  ocmPP11: createTimePeriod({
    productKey: 'ocm',
    key: 'ocmPP11',
    label: 'OCM Performance Period 11',
    minPP: 11,
    maxPP: 11,
  }),
  ocmBaselineToPP11: createTimePeriod({
    productKey: 'ocm',
    key: 'ocmBaselineToPP11',
    label: 'OCM Baseline to Performance Period 11',
    maxPP: 11,
  }),
  ocmPP1ToPP11: createTimePeriod({
    productKey: 'ocm',
    key: 'ocmPP1ToPP11',
    label: 'OCM Performance Periods 1-11',
    minPP: 1,
    maxPP: 11,
  }),
  ocmPP2ToPP11: createTimePeriod({
    productKey: 'ocm',
    key: 'ocmPP2ToPP11',
    label: 'OCM Performance Periods 2-11',
    minPP: 2,
    maxPP: 11,
  }),
  ocmPP3ToPP11: createTimePeriod({
    productKey: 'ocm',
    key: 'ocmPP3ToPP11',
    label: 'OCM Performance Periods 3-11',
    minPP: 3,
    maxPP: 11,
  }),
  ocmPP4ToPP11: createTimePeriod({
    productKey: 'ocm',
    key: 'ocmPP4ToPP11',
    label: 'OCM Performance Periods 4-11',
    minPP: 4,
    maxPP: 11,
  }),
  ocmPP5ToPP11: createTimePeriod({
    productKey: 'ocm',
    key: 'ocmPP5ToPP11',
    label: 'OCM Performance Periods 5-11',
    minPP: 5,
    maxPP: 11,
  }),
  ocmPP6ToPP11: createTimePeriod({
    productKey: 'ocm',
    key: 'ocmPP6ToPP11',
    label: 'OCM Performance Periods 6-11',
    minPP: 6,
    maxPP: 11,
  }),
  ocmPP7ToPP11: createTimePeriod({
    productKey: 'ocm',
    key: 'ocmPP7ToPP11',
    label: 'OCM Performance Periods 7-11',
    minPP: 7,
    maxPP: 11,
  }),
  ocmPP8ToPP11: createTimePeriod({
    productKey: 'ocm',
    key: 'ocmPP8ToPP11',
    label: 'OCM Performance Periods 8-11',
    minPP: 8,
    maxPP: 11,
  }),
  ocmPP9ToPP11: createTimePeriod({
    productKey: 'ocm',
    key: 'ocmPP9ToPP11',
    label: 'OCM Performance Periods 9-11',
    minPP: 9,
    maxPP: 11,
  }),
  ocmPP10ToPP11: createTimePeriod({
    productKey: 'ocm',
    key: 'ocmPP10ToPP11',
    label: 'OCM Performance Periods 10-11',
    minPP: 10,
    maxPP: 11,
  }),

  bpciaBaseline: createTimePeriod({
    productKey: 'bpcia',
    key: 'bpciaBaseline',
    label: 'BPCI-A Baseline Period',
    maxPP: 0,
  }),
  bpciaPP1: createTimePeriod({
    productKey: 'bpcia',
    key: 'bpciaPP1',
    label: 'BPCI-A Performance Period 1',
    minPP: 1,
    maxPP: 1,
  }),
  bpciaBaselineToPP1: createTimePeriod({
    productKey: 'bpcia',
    key: 'bpciaBaselineToPP1',
    label: 'BPCI-A Baseline to Performance Period 1',
    maxPP: 1,
  }),
  bpciaPP2: createTimePeriod({
    productKey: 'bpcia',
    key: 'bpciaPP2',
    label: 'BPCI-A Performance Period 2',
    minPP: 2,
    maxPP: 2,
  }),
  bpciaBaselineToPP2: createTimePeriod({
    productKey: 'bpcia',
    key: 'bpciaBaselineToPP2',
    label: 'BPCI-A Baseline to Performance Period 2',
    maxPP: 2,
  }),
  bpciaPP1ToPP2: createTimePeriod({
    productKey: 'bpcia',
    key: 'bpciaPP1ToPP2',
    label: 'BPCI-A Performance Periods 1-2',
    minPP: 1,
    maxPP: 2,
  }),
};

const parseTimePeriodNameOpt = (s: string): TimePeriodKey | undefined => {
  const clean = s.trim();
  return clean in key__timePeriod ? (clean as TimePeriodKey) : undefined;
};

const parseTimePeriodName = (s: string): TimePeriodKey => {
  const name = parseTimePeriodNameOpt(s);
  if (name) return name;
  throw Error(`unknown time period name: ${s}`);
};

export const parseTimePeriodOpt = (s: string): TimePeriod | undefined => {
  const name = parseTimePeriodNameOpt(s);
  return name ? key__timePeriod[name] : undefined;
};

export const parseTimePeriod = (s: string): TimePeriod => key__timePeriod[parseTimePeriodName(s)];

export const toTimePeriod = (s: TimePeriodKey) => parseTimePeriod(s);
