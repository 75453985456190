import querySerializer from 'query-string';
import * as redux from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension/developmentOnly';
import { connectRoutes, GenericCreateHistory, LocationState, RoutesMap } from 'redux-first-router';
import { createEpicMiddleware, Epic } from 'redux-observable';
import { History } from 'rudy-history';
import {
  EMPTY as emptyObservable,
  merge as observableMerge,
  Observable,
  queueScheduler,
  Subject,
} from 'rxjs';
import { catchError, map, mergeMap, observeOn, tap, withLatestFrom } from 'rxjs/operators';
import analytics from '../../analytics';
import { Authn } from '../../contracts/Authn';
import { onAuthnInit, onSessionBegin } from '../../contracts/Authn.extensions';
import { Action } from '../action';
import { AgentContext, makeAgent, NOOP } from '../agent';
import { constant } from '@tuple-health/common';
import { makeResolver } from '../resolver';
import { combineServices, makeService, Service } from '../service';

interface StoreConfig<S> {
  app: {
    service: Service<S>;
    routes: RoutesMap;
  };
  createHistory: GenericCreateHistory;
  // TODO: figure out how to expose state shape after combining services
  preloadedState?: any;
  middlewares?: redux.Middleware[];
  handleAsyncError?(error: {}): void;
  debug?: boolean;
}

export function configureStore<S>({
  app,
  createHistory,
  preloadedState,
  middlewares = [],
  handleAsyncError = e => {
    throw e;
  },
  debug = false,
}: StoreConfig<S>) {
  let history: History | undefined;

  analytics.initialize();

  let lastPath: string | undefined;

  const router = connectRoutes(app.routes, {
    initialDispatch: false,
    createHistory: options => (history = createHistory(options)),
    querySerializer,
    scrollTop: true,
    basename: '#/',
    onAfterChange() {
      const { pathname, hash } = window.location;
      let path = `${pathname}${hash.substring(2)}`;
      // strip possible patient ID from patient URLs
      path = path.replace(/(\/patients\/)[a-zA-Z0-9\-_]+(\/.*)?/, '$1<redacted>$2');
      analytics.pageView(
        '', // FIXME: can't know the real 'title' of this page from here
        path,
        lastPath,
      );
      lastPath = path;
    },
  });

  if (!history) throw Error('failed to create history (IMPOSSIBLE)');

  // // TODO: don't use combineReducers within Service.merge()
  // const service = Service.merge(
  //   // TODO: distinguish compound and simple services, use merge/combine appropriately
  //   app.service,
  //   // TODO: accept MapObject<Service> as an argument?
  //   Service.combine({
  //     location: Service.create({ reducer: router.reducer }),
  //   }),
  // )

  const service = combineServices({
    app: app.service,
    location: makeService({
      reducer: router.reducer,
      agent: makeAgent({ authn: Authn })<LocationState>($ =>
        observableMerge(
          onAuthnInit($).pipe(
            tap(() => router.initialDispatch!()),
            mergeMap(NOOP.observable),
          ),
          onSessionBegin($).pipe(
            tap(({ authn }) => {
              analytics.setUserId(Authn.as.session(authn).session.user.anyEmail);
            }),
            mergeMap(NOOP.observable),
          ),
        ),
      ),
    }),
  });

  const { reducer, providers, agent } = service;
  // TODO: fix typings so this is unnecessary
  if (!reducer || !agent)
    throw new Error('app is missing reducer or agent (this should be impossible)');

  const subjectDispatch = new Subject<Action>();
  const dispatchMonkey = (action: Action) => {
    subjectDispatch.next(action);
    return action;
  };
  const asyncDispatchMonkey = (action: Action) => {
    setImmediate(() => subjectDispatch.next(action));
    return action;
  };

  providers.dispatch = constant(dispatchMonkey);

  const epic: Epic<Action, any, { state$: Observable<any> }> = (action$, state$) => {
    // FIXME: figure out the right way to "freeze" action + state
    let i = 0;
    const $ = new Subject<any>();
    action$
      .pipe(
        tap(action => ((action as any).__id = ++i)),
        withLatestFrom(state$),
        map(([action, state]) => ({
          action,
          state,
          resolve: makeResolver(providers, state as any),
        })),
        tap(debug ? createLogger() : () => {}),
      )
      .subscribe($);

    return observableMerge(agent($), subjectDispatch).pipe(
      catchError(error => (handleAsyncError(error), emptyObservable)),
      observeOn(queueScheduler),
    );
  };

  const epicMiddleware = createEpicMiddleware();

  const enhancer = composeWithDevTools(
    createStore =>
      (router.enhancer as redux.StoreEnhancer)((...args) => {
        const store = createStore(...args);
        store.dispatch = asyncDispatchMonkey as any;
        return store;
      }),
    redux.applyMiddleware(
      store => {
        const clone = { ...store };
        clone.dispatch = asyncDispatchMonkey as any;
        return router.middleware(clone);
      },
      epicMiddleware,
      // Middleware to handle errors that occur while dispatching asynchronously
      () => dispatch => action => {
        try {
          dispatch(action);
        } catch (error) {
          handleAsyncError(error);
        }
        return action;
      },
      ...middlewares,
    ),
  );

  const store: redux.Store<any, any> = preloadedState
    ? redux.createStore(reducer, preloadedState, enhancer)
    : redux.createStore(reducer, enhancer);

  epicMiddleware.run(epic as any);

  return {
    store,
    service,
    history,
  };
}

function createLogger() {
  return ({ action }: AgentContext) => {
    // eslint-disable-next-line no-console
    console.log(`$${(action as any).__id} ${action.type} by <${(action as any).__source}>`);
  };
}
