import React from 'react';
import Page from '../../components/Page';

interface ScreenProps {
  // this has new identity everytime some navigates or changes params
  // changing customer or product does change route
  // currently changing visibility does not
  // route: Route<RouterLoc>;
  // ===================================
  // don't add props here without reviewing memo
  // ===================================
  // registerNavApprover(approver: (canINavigate: () => boolean) => void): void;
  // registerUnloadNotifier(unload: (canINavigate: () => boolean) => void): void;
}

export type ScreenComponent = React.ComponentType<ScreenProps>;

// memo currently prevents stores from being recreated, will have better solution
// when platform store is broken into hooks
export const defineScreen = (Component: ScreenComponent): ScreenComponent => React.memo(Component);

export const dummyScreen = (loc: string) => defineScreen(() => <Page>screen: {loc}</Page>);

export function adaptScreen<C extends React.FC<any>>(Component: C) {
  return defineScreen(() => React.createElement(Component));
}
