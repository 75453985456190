import { serviceWorkerRegistrationPromise } from './registerServiceWorker';
import { Api } from '../../services/gateway/api';
import { env } from '../../core/env';

/**
 * @returns whether registration was successful (false meaning push
 * notifications not supported in this browser)
 */
export async function registerPushSubscription(api: Api): Promise<boolean> {
  const subscription = await getPushSubscription();

  if (!subscription) return false;

  await api.registerWebSubscription({ subscription: JSON.stringify(subscription) });

  return true;
}

export async function isSubscribedToPushNotifications(): Promise<boolean> {
  // TODO, this should ask the API to make sure it's current
  const pm = await getPushManager();
  return (await getCurrentSubscription(pm)) !== undefined;
}

export async function canSubscibeToPushNotifications(): Promise<boolean> {
  const pm = await getPushManager();
  return pm !== undefined;
}

async function getPushSubscription(): Promise<PushSubscriptionJSON | undefined> {
  const pm = await getPushManager();

  if (!pm) {
    return;
  }

  let subscription = await getCurrentSubscription(pm);

  if (!subscription) {
    subscription = await pm.subscribe({
      userVisibleOnly: true,
      applicationServerKey: urlB64ToUint8Array(
        env === 'development'
          ? 'BL--BhUijPh_fCaffk102IQlhK4oP-UNPQHGw9Za1j2g3_hGuoIwkrs0KOymwAVw4zNy7Kqzh6ieLf3rDes-RsQ'
          : 'BKoXXVPJXPTWXnVz4AMY1JdnnYwUmBff6Noy-wBtqsSDVvw474Yc1zQd-2feBn5tP2oRB5b0PguK_LqaDIz9I0o',
      ), //TODO, centralize, copied on the server
    });
  }

  return subscription;
}

// =============================================================================
// helpers
// =============================================================================

async function getCurrentSubscription(
  pm: PushManager | undefined,
): Promise<PushSubscriptionJSON | undefined> {
  if (!pm) {
    return;
  }

  const currentSubscription = await pm.getSubscription();
  return currentSubscription ? currentSubscription : undefined;
}

async function getPushManager(): Promise<PushManager | undefined> {
  const reg = await serviceWorkerRegistrationPromise;
  return registration__pushManager(reg);
}

function registration__pushManager(
  reg: ServiceWorkerRegistration | undefined,
): PushManager | undefined {
  if (!reg) {
    return;
  }

  const pm = reg.pushManager;
  if (!pm) {
    return;
  }

  return pm;
}

function urlB64ToUint8Array(base64String: string) {
  const padding = '='.repeat((4 - (base64String.length % 4)) % 4);
  const base64 = (base64String + padding).replace(/-/g, '+').replace(/_/g, '/');

  const rawData = window.atob(base64);
  const outputArray = new Uint8Array(rawData.length);

  for (let i = 0; i < rawData.length; ++i) {
    outputArray[i] = rawData.charCodeAt(i);
  }
  return outputArray;
}
