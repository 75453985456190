import { Reply } from '@tuple-health/eng/dist/dryscript/ds';
import { GatewayApi } from '@tuple-health/eng/dist/th/ds/common/agent/gatewayApi/api/GatewayApi';
import { GatewaySession } from '@tuple-health/eng/dist/th/ds/common/agent/gatewayApi/service/session/GatewaySession';
import { isTruthy } from '@tuple-health/common';
import apiAdapters from './apiAdapters';

export interface ApiKeys {
  token?: string;
  session?: GatewaySession;
  customerId?: string;
  productKey?: string;
}

export function makeApi(gateway: GatewayApi, keys: ApiKeys = {}): Api {
  const { session, token = session && session.token, customerId, productKey } = keys;

  const result: any = {};
  for (const p of Object.getOwnPropertyNames(Object.getPrototypeOf(gateway))) {
    // Skip delegate getters
    if (p[0] === '$') continue;

    let method = (gateway as any)[`${p}RespondAsync`];
    if (typeof method === 'function') {
      method = method.bind(gateway);
      const request = (...args: any[]) => {
        const { stack } = new Error();
        return new Promise((resolve, reject) => {
          args[args.length - 1].callback = (reply: Reply<any>) => {
            if (reply.isOk) resolve(reply.body!);
            else reject(new ReplyError(reply, stack));
          };

          method(...args);
        });
      };

      const adapter = (apiAdapters as any)[p];

      result[p] = (params: any = undefined) => {
        if (adapter) params = adapter.input(params, keys);

        if (params === undefined) params = {};

        let promise;
        switch (method.length) {
          case 1:
            promise = request(params);
            break;
          case 2:
            if (!token) throw Error(`API method ${p} requires a token`);
            promise = request(token, params);
            break;
          case 3:
            if (!token) throw Error(`API method ${p} requires a token`);
            if (!customerId) throw Error(`API method ${p} requires a customerId`);
            promise = request(token, customerId, params);
            break;
          case 4:
            if (!token) throw Error(`API method ${p} requires a token`);
            if (!customerId) throw Error(`API method ${p} requires a customerId`);
            if (!productKey) throw Error(`API method ${p} requires a productKey`);
            promise = request(token, customerId, productKey, params);
            break;
          default:
            throw Error(
              `API method '${p}' requires an unsupported number of inputs: ${method.length}`,
            );
        }

        if (adapter) promise = promise.then(data => adapter.output(data, keys));

        return promise;
      };

      addFetchKeys(result[p], p, ...[token, customerId, productKey].filter(isTruthy));
    }
  }
  return result;
}

const fetchKeysProp = '__fetch_keys';

export function addFetchKeys<F extends Function>(f: F, name: string, ...keys: string[]) {
  Object.defineProperty(f, 'name', { value: name, writable: false });
  Object.defineProperty(f, fetchKeysProp, { value: [name, ...keys], writable: false });
}

export function getFetchKeys<F extends Function>(f: F): string[] | undefined {
  return (f as any)[fetchKeysProp];
}

export class ReplyError extends Error {
  constructor(public reply: Reply<unknown>, stack?: string) {
    super(
      `[status ${reply.status}] ${[...(reply.errors || [])].map(error => error.msg).join(', ')}`,
    );

    if (this.stack && stack) {
      const [topFrame] = this.stack.split('\n');
      const frames = stack.split('\n');
      frames.splice(0, 3, topFrame);
      this.stack = frames.join('\n');
    }
  }
}

export type RawApi = { [K in MethodName]: RawMethod<K> };
type MethodName = {
  [K in keyof GatewayApi]: RawMethod<K> extends never ? never : K;
}[keyof GatewayApi];
type RawMethod<K extends keyof GatewayApi> = GatewayApi[K] extends (
  token: string,
  customerId: string,
  productKey: string,
  params: infer A,
) => infer B
  ? ExtractMethod<NonNullable<A>, B>
  : GatewayApi[K] extends (token: string, customerId: string, params: infer A) => infer B
  ? ExtractMethod<NonNullable<A>, B>
  : GatewayApi[K] extends (token: string, params: infer A) => infer B
  ? ExtractMethod<NonNullable<A>, B>
  : GatewayApi[K] extends (params: infer A) => infer B
  ? ExtractMethod<NonNullable<A>, B>
  : never;

type ExtractMethod<A, B> = A extends { callback(arg0: Reply<any>): void }
  ? never
  : B extends Reply<any>
  ? never
  : keyof A extends never
  ? () => Promise<B>
  : {} extends A
  ? (params?: A) => Promise<B>
  : (params: A) => Promise<B>;

export type Api = {
  [K in keyof RawApi]: K extends keyof Adapters
    ? (...params: Parameters<Adapters[K]['input']>) => Promise<ReturnType<Adapters[K]['output']>>
    : RawMethod<K>;
};
type Adapters = typeof apiAdapters;
