import { makeScreen } from '../../../core/appbase/screen';
import * as logic from './logic';
import PasswordResetConfirm from './PasswordResetConfirm';

export const passwordResetConfirmScreen = makeScreen({
  agent: logic.agent,
  reducer: logic.reducer,
  selector: logic.selector,
  view: PasswordResetConfirm,
});
