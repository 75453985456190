import { Dictionary, keys, StringKeys } from '@tuple-health/common';
import { Contract } from './contract';
import { Selector } from './selector';

export interface Provider<S, C> {
  contract: Contract<C>;
  selector: Selector<S, C>;
}

export type Providers<S> = Dictionary<Selector<S, {}>>;

export type ProvidersMap<S> = { [K in StringKeys<S>]: Providers<S[K]> };

export function makeProvider<S, C>(
  contract: Contract<C>,
  selector: Selector<S, C>,
): Provider<S, C> {
  // TODO: remove this debug check
  if (!contract._injectiontag) throw new Error(`invalid contract!`);
  return { contract, selector };
}

export function mergeProviders<S>(...providers: Provider<S, any>[]): Providers<S> {
  const dictionary: Providers<S> = {};
  for (const provider of providers) {
    const { contract, selector } = provider;
    dictionary[contract._injectiontag] = selector;
  }
  return dictionary;
}

export function combineProviders<M>(map: ProvidersMap<M>): Providers<M> {
  const combined: Providers<M> = {};
  for (const key of keys(map)) {
    const resolutionMap = map[key];
    for (const providerKey of keys(resolutionMap)) {
      const selector = resolutionMap[providerKey];
      combined[providerKey] = ({ state }, resolve) =>
        selector({ state: state && state[key] }, resolve);
    }
  }
  return combined;
}
