import { memoize } from 'lodash';
import { map } from 'rxjs/operators';
import { makeScreen } from '../core/appbase/screen';

export const viewFailError = memoize(() => Error('view fail'));

export const viewFailScreen = makeScreen({
  view: () => {
    throw viewFailError();
  },
});

// ===========================================================================

const reducerFailError = memoize(() => Error('reducer fail'));

export const reducerFailScreen = makeScreen({
  reducer: (): {} => {
    throw reducerFailError();
  },
});

// ===========================================================================

const selectorFailError = memoize(() => Error('selector fail'));

export const selectorFailScreen = makeScreen({
  selector: (): {} => {
    throw selectorFailError();
  },
});

// ===========================================================================

const agentFailError = memoize(() => Error('agent fail'));

export const agentFailScreen = makeScreen({
  agent: $ =>
    $.pipe(
      map(() => {
        throw agentFailError();
      }),
    ),
});
