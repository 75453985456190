import { SingleValueRec, unionize, Unionized } from 'unionize';

interface State<T extends PropertyKey = string, V = {}> {
  tag: T;
  value: V;
}

export function makeStates<Record extends SingleValueRec>(
  record: Record,
): Unionized<Record, { [T in keyof Record]: State<T, Record[T]> }, 'tag'> {
  return unionize(record, { tag: 'tag', value: 'value' });
}
