import { ofType } from 'unionize';
import { makeProps } from '../../../core/props';
import { WithMaybeError } from '@tuple-health/common';

export interface PromptFields {
  email: string;
  phone: string;
}

export interface WithSupportPath {
  supportPath: string;
}

export interface PromptProps extends WithSupportPath {
  initiate(fields: PromptFields): void;
}

// -- props union -- //
export const PasswordResetInitiateProps = makeProps(
  {
    prompt: ofType<PromptFields & WithMaybeError & PromptProps>(),
    pending: ofType<PromptFields>(),
    complete: ofType<WithSupportPath>(),
  },
  'status',
);

export type PasswordResetInitiateProps = typeof PasswordResetInitiateProps._Union;
