import React, { lazy } from 'react';
import useProduct from '../../core/store/useProduct';
import { routerKinds } from '../../platform/platform/v1PlatformConcepts';
import { defineScreen } from '../../platform/platform/screen.model';

const ArticleComponent = lazy(
  /* webpackChunkName: "article_detail" */ () => import('./ArticleComponent'),
);

export const genericArticleScreen = (locationName: string) =>
  defineScreen(() => <ArticleComponent locationName={locationName} />);

export const customArticleScreen = defineScreen(() => {
  const { productRoute } = useProduct();
  const articleId = productRoute.ns__uri[routerKinds.article.ns].id;
  return <ArticleComponent articleId={articleId} />;
});
