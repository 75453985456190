import { Observable } from 'rxjs';
import { distinctUntilChanged, filter, first, skipWhile } from 'rxjs/operators';
import { Authn } from './Authn';

// -----------------------------------------------------
//  Triggers
// -----------------------------------------------------
interface WithAuthn {
  authn: Authn;
}

function onAuthnChanged<T extends WithAuthn>(x: T, y: T) {
  return x.authn.tag === y.authn.tag;
}

export function onAuthnInit<T extends WithAuthn>($: Observable<T>) {
  return $.pipe(
    skipWhile(({ authn }) => Authn.is.initializing(authn)),
    first(),
  );
}

export function onSessionBegin<T extends WithAuthn>($: Observable<T>) {
  return $.pipe(
    distinctUntilChanged(onAuthnChanged),
    filter(({ authn }) => Authn.is.session(authn)),
  );
}
