import { merge as observableMerge } from 'rxjs';
import { isPublic } from '../../app/locScreens';
import { Authn } from '../../contracts/Authn';
import { makeWidget } from '../../contracts/Widget';
import { makeAgent } from '../../core/agent';
import { Router } from '../../core/appbase/router.service';
import { pick } from '@tuple-health/common';
import { makeSelector } from '../../core/selector';
import Frame, { FrameProps } from './Frame';

// =============================================================================
// frame store <-> redux bridge
// =============================================================================

const selector = makeSelector({
  authn: Authn,
  router: Router,
})<FrameState, FrameProps>(({ authn, router }) => {
  // not logged in
  if (!Authn.is.session(authn)) return {};

  const props: FrameProps = {
    expiryWarning: pick(authn, 'expiry', 'continueSession'),
  };

  // at this point you are logged in, but the screen is public?
  // - why not check this before checking session?
  const { location } = router;
  if (!location || isPublic(location.screen)) return props;

  // frame store was here

  return props;
});

// =============================================================================
// deprecated
// =============================================================================

export interface FrameState {}

const reducer = (state = {}) => state;

const agent = makeAgent({
  authn: Authn,
  router: Router,
})<FrameState>(_$ => observableMerge());

export default makeWidget<FrameState, FrameProps>({
  tag: 'frame',
  agent,
  reducer,
  selector,
  view: Frame,
});
