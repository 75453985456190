import { memoize } from '@tuple-health/common';
import { bindInjector, ContractDictionary, InjectedContext, Resolver } from './resolver';

// ------------------------------
//  selectors
// ------------------------------
export type Selector<S, T, I extends {} = {}> = (context: Context<S, I>, resolve: Resolver) => T;

type Context<S = {}, I extends {} = {}> = InjectedContext<I> & { state: S };

export function makeSelector(): <S, T>(c: Selector<S, T>) => Selector<S, T>;
export function makeSelector<I extends ContractDictionary>(
  i: I,
): <S, T>(c: Selector<S, T, I>) => Selector<S, T>;
export function makeSelector(i: ContractDictionary = {}) {
  const inject = bindInjector(i);
  const keys = Object.keys(i).concat('state');

  return <S, T>(call: Selector<S, T, {}>): Selector<S, T> => {
    const f = memoize(call, ([context]) => keys.map(k => context[k]));
    return ({ state }, resolve) => f(inject({ state }, resolve), resolve);
  };
}
