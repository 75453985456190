import * as cubeQueryFormat from '@tuple-health/eng/dist/dryscript/lib/common/cube/format/cubeQueryFormat';
import * as houseCube from '@tuple-health/eng/dist/th/ds/common/product/customer/house/cube/houseCube';
import * as houseCubeDatasets from '@tuple-health/eng/dist/th/ds/common/product/customer/house/cube/houseCubeDatasets';
import unionize, { ofType } from 'unionize';
import getAnalyticsProduct from '../analytics/getAnalyticsProduct';
import { TimePeriod, TimePeriodKey, timePeriodNames } from '../model/analyticsProductModel';
import { createTimePeriodQuery, parseTimePeriod } from './timePeriod.model';

// =============================================================================
// datasets
// =============================================================================

export const OldDataset = unionize(
  {
    timePeriod: ofType<TimePeriod>(),
    query: ofType<string>(),
  },
  { tag: 'tag', value: 'field' },
);
export type OldDataset = typeof OldDataset._Union;

const sentence__clean = (sentence: string): string =>
  cubeQueryFormat.writeClean(houseCube.schema, sentence);

const sentence__key = (sentence: string): string => {
  const lines = sentence__clean(sentence).split('\n');
  lines.sort();
  return lines.join('\n');
};

export const writeOldDatasetQuery = OldDataset.match({
  timePeriod: timePeriod =>
    createTimePeriodQuery(getAnalyticsProduct(timePeriod.productKey), timePeriod),
  query: query => sentence__clean(query),
});

// =====================================
// write/parse
// =====================================

export function parseOldDataset(s: string): OldDataset {
  const timePeriodNameOrSentence = s.trim();

  if (timePeriodNameOrSentence.includes(' ')) {
    return OldDataset.query(sentence__clean(timePeriodNameOrSentence));
  }

  const timePeriod = parseTimePeriod(s);
  return OldDataset.timePeriod(timePeriod);
}

// export function writeDataset(sentence: string): string {
//   const normSentence = sentence__key(sentence)
//   return sentence__clean(normSentence)
// }

export const writeOldDataset = OldDataset.match({
  timePeriod: timePeriod => timePeriod.key,
  query: sentence__clean,
});

const datasetName__cleanSentence: Record<TimePeriodKey, string> = {} as any;
const sentenceKey__oldDatasetName: Record<string, TimePeriodKey> = {};
timePeriodNames.forEach(timePeriodName => {
  if (!(timePeriodName in houseCubeDatasets)) return;
  const cleanSentence = sentence__clean((houseCubeDatasets as any)[timePeriodName]);
  datasetName__cleanSentence[timePeriodName] = cleanSentence;

  const sentenceKey = sentence__key(cleanSentence);
  sentenceKey__oldDatasetName[sentenceKey] = timePeriodName;
});

// =============================================================================
// =============================================================================

export const writeOldDatasetsJson = (oldDatasets: OldDataset[] | undefined) => {
  if (!oldDatasets) return {};
  const strings = oldDatasets.map(writeOldDataset); // write dataset names and clean queries
  if (strings.length === 1) return { dataset: strings[0] };
  return { datasets: strings };
};

export const parseOldDatasetsJson = (
  oldDataset: string | undefined,
  oldDatasets: string[] | undefined,
): OldDataset[] | undefined => {
  if (oldDataset && oldDatasets) {
    throw Error('cannot specify dataset and datasets');
  }
  if (oldDataset) oldDatasets = [oldDataset];
  if (!oldDatasets) return undefined;
  return oldDatasets.map(parseOldDataset); // parse dataset names and clear queries
};
