import { checkedAccess, tuple } from '@tuple-health/common';
import * as _ from '@tuple-health/eng/dist/dryscript/ds';
import { record2 } from '@tuple-health/eng/dist/dryscript/lib/common/concept/concept/toConcept';
import { parse as parseQuery } from '@tuple-health/eng/dist/dryscript/lib/common/cube/format/cubeQueryFormat';
import { toCubeEntry } from '@tuple-health/eng/dist/dryscript/lib/common/cube/item/toCubeEntry';
import { CubeQuery } from '@tuple-health/eng/dist/dryscript/lib/common/cube/query/CubeQuery';
import { call as makePageChoice } from '@tuple-health/eng/dist/dryscript/lib/common/data/pager/toPageChoice';
import * as toUri from '@tuple-health/eng/dist/dryscript/lib/common/prelude/record/toUri';
import { Uri } from '@tuple-health/eng/dist/dryscript/lib/common/prelude/record/Uri';
import { CalPoint } from '@tuple-health/eng/dist/dryscript/lib/common/time/cal/CalPoint';
import * as timeLib from '@tuple-health/eng/dist/dryscript/lib/common/time/timeLib';
import { schema } from '@tuple-health/eng/dist/th/ds/common/product/customer/house/cube/houseCube';
import { writeCohort } from '@tuple-health/eng/dist/th/ds/common/product/customer/house/cube/houseCubeWrite';
import * as toConceptReport from '@tuple-health/eng/dist/th/ds/common/tech/analytics/data/concept/toConceptReport';
import { generic } from '@tuple-health/eng/dist/th/ds/common/tech/analytics/data/concept/toConceptSummary';
import { cubeData__tableData } from '@tuple-health/eng/dist/th/ds/common/tech/analytics/data/cubeDataLib';
import { DataResult } from '@tuple-health/eng/dist/th/ds/common/tech/analytics/data/DataResult';
import { toDataResult } from '@tuple-health/eng/dist/th/ds/common/tech/analytics/data/toDataResult';
import { parse as parseDate } from 'date-fns';
import { keyBy } from 'lodash';
import * as RT from 'runtypes';

export function fromCalPoint(point: CalPoint): Date {
  return parseDate(point.mdy, 'M/d/yyyy', 0);
}

export function fromCalPointKey(key: string): Date {
  return fromCalPoint(timeLib.calPoint.parseYmd(key));
}

export type DsUnion<U extends { tag: string }> = {
  [K in U['tag']]: { tag: K; field: K extends keyof U ? U[K] : undefined };
}[U['tag']] &
  { [K in Exclude<keyof U, U['tag']>]: U[K] };

export function fromDsEnum<U extends { tag: string }>(u: U): DsUnion<U> {
  return u as any;
}

export function fakeDataResult(
  query: CubeQuery | string,
  rows: Record<string, string | number>[],
): DataResult {
  const cubeQuery = typeof query === 'string' ? parseQuery(schema, query) : query;
  const clauseLookup = keyBy(
    [...cubeQuery.clauses].filter(clause => !clause.isWhere).map(fromDsEnum),
    clause => {
      switch (clause.tag) {
        case 'calc': {
          const calc = clause.field;
          return calc.dottedKey;
        }
        case 'group': {
          const level = clause.field;
          return level.depth === 1 ? level.tree.key : level.key;
        }
        default:
          return '???';
      }
    },
  );

  const entries = rows.map(row => {
    const entries = Object.entries(row);
    return toCubeEntry({
      numbers: _.toDict.call(
        entries.flatMap(([key, value]) => {
          const clause = checkedAccess('key', clauseLookup, key);
          if (clause.tag !== 'calc') return [];
          return [[clause.field.dottedKey, RT.Number.check(value)]];
        }),
      ),
      concepts: _.toDict.call(
        entries.flatMap(([key, value]) => {
          const clause = checkedAccess('key', clauseLookup, key);
          if (clause.tag !== 'group') return [];
          const level = clause.field;
          const label = RT.String.check(value);
          return [...level.tree.attrs.before(level.depth)].map(attr => {
            let uri: Uri;
            try {
              uri = toUri.parse(label);
            } catch (e) {
              uri = toUri.call('fake', label.split(' ').join('_'));
            }
            return tuple(attr.keyPath.dotted, uri);
          });
        }),
      ),
    });
  });

  const concepts = toConceptReport.call(
    _.toSet.call(
      entries
        .flatMap(entry => [...entry.concepts.vals])
        .map(uri => generic(record2(uri, { primaryLabel: uri.key.replace('_', ' ') }))),
    ),
  );

  const conceptUri__label = concepts.uri__label;

  return toDataResult({
    cohortLabel: writeCohort(conceptUri__label.call, cubeQuery),
    concepts,
    data: cubeData__tableData({
      autoCount: false,
      cubeQuery,
      cubeEntries: _.array.call(entries),
    }),
    pageChoice: makePageChoice({ pageNum: 1, pageSize: 1, hasNext: true }),
  });
}
