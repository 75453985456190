import React from 'react';
import { Action, makeActions } from '../action';
import { Agent } from '../agent';
import { cleanObject, Dictionary, property, StringKeys } from '@tuple-health/common';
import { Contract, makeContract } from '../contract';

import { makeProvider, Provider } from '../provider';
import { Reducer } from '../reducer';
import { makeSelector, Selector } from '../selector';
import {
  combineServices,
  ComposedState,
  composeServices,
  makeService,
  ServiceMap,
} from '../service';

export interface Screen<
  Params extends Dictionary<any> = Dictionary<any>,
  Props = any,
  State = any
> {
  _Props?: Props;
  parameters?: ScreenParameters<Params>;
  reducer?: Reducer<State, Action<never, never>>;
  agent?: Agent<State>;
  providers?: Provider<State, any>[];
  selector?: Selector<State, Props>;
  view?: React.ComponentType<Props>;
}

interface ProtoScreen<Params extends Dictionary<any> = Dictionary<any>, Props = any, State = any> {
  parameters?: ScreenParameters<Params>;
  reducer?: Reducer<State, Action<never, never>>;
  agent?: Agent<State>;
  providers?: Provider<State, any>[];
  selector?: Selector<State, Props>;
  view?: React.ComponentType<Props>;
}

type MixedState<State, Mixin> = ComposedState<{}, { core: State; mixins: Mixin }>;

export function makeScreen<State, Props, Params extends Dictionary<any>>(
  screen?: ProtoScreen<Params, Props, State>,
): Screen<Params, Props, State>;
export function makeScreen<State, Props, Params extends Dictionary<any>, Mixin>(
  screen: ProtoScreen<Params, Props, State>,
  mixins: ServiceMap<Mixin>,
): Screen<Params, Props, MixedState<State, Mixin>>;
export function makeScreen<State, Props, Params extends Dictionary<any>, Mixin>(
  screen: ProtoScreen<Params, Props, State> = {},
  mixins?: ServiceMap<Mixin>,
): Screen<Params, Props, State> | Screen<Params, Props, MixedState<State, Mixin>> {
  if (!mixins) return cleanObject(screen, 'providers');

  const externalContract = makeContract<Props>('screenprops-external');
  const externalProviders: Provider<{}, Props>[] = [];
  const internalProviders = (screen.providers || []).slice();
  if (screen.selector) {
    const internalContract = makeContract<Props>('screenprops-internal');
    internalProviders.push(makeProvider<State, Props>(internalContract, screen.selector));
    externalProviders.push(
      makeProvider<{}, Props>(
        externalContract,
        // makeSelector({ props: internalContract })<{}, Props>(property('props')),
        makeSelector({ props: internalContract })<{}, Props>(property('props')),
      ),
    );
  }

  const service = composeServices(
    makeService({
      providers: externalProviders,
    }),
    combineServices({
      core: makeService({
        reducer: screen.reducer,
        agent: screen.agent,
        providers: internalProviders,
      }),
      mixins: combineServices(mixins),
    }),
  );

  return {
    parameters: screen.parameters,
    reducer: service.reducer,
    agent: service.agent,
    selector: service.providers[externalContract._injectiontag] as Selector<
      MixedState<State, Mixin>,
      Props
    >,
    view: screen.view,
  };
}

export const ScreenAction = makeActions({
  screeninit: {},
});
export type ScreenAction = typeof ScreenAction._Union;

const parametersContract = makeContract<any>('screen-parameters');
type ScreenParameters<T> = Contract<T>;
export const ScreenParameters = <T>(): Contract<T> => parametersContract;

export const placeholder = (props: any) =>
  React.createElement('div', {}, `Placeholder view: ${JSON.stringify(props)}`);

export interface ScreenParameterMap {
  [key: string]: {
    state: any;
    props: any;
    parameters: {};
  };
}

export type ScreenRoutes<S extends ScreenParameterMap> = {
  [K in keyof S]: Screen<S[K]['parameters'], S[K]['props'], S[K]['state']>;
};

type ScreenStateUnion<S extends ScreenParameterMap> = {
  [K in StringKeys<S>]: {
    path: string;
    pathId: K;
    parameters: S[K]['parameters'];
    screen: S[K]['state'];
    query: {};
    error?: Error;
  };
}[StringKeys<S>];

export type ScreenState<S extends ScreenParameterMap> = ScreenStateUnion<S> | null;
