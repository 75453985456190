export default function registerServiceWorker() {
  if ('serviceWorker' in navigator) {
    const sw = navigator.serviceWorker;

    window.addEventListener('load', () => {
      sw.register('/sw.js').then(resolveRegistration).catch(rejectRegistration);
    });
  } else {
    resolveRegistration(undefined);
  }
}

export type MessageHandler = (event: MessageEvent) => void;

export function registerServiceWorkerListener(handler: (event: MessageEvent) => void): boolean {
  if ('serviceWorker' in navigator) {
    navigator.serviceWorker.addEventListener('message', handler);
    return true;
  }
  return false;
}

export function deregisterServiceWorkerListener(handler: (event: MessageEvent) => void): boolean {
  if ('serviceWorker' in navigator) {
    navigator.serviceWorker.removeEventListener('message', handler);
    return true;
  }
  return false;
}

let resolveRegistration: (reg: ServiceWorkerRegistration | undefined) => void;
let rejectRegistration: (reg: ServiceWorkerRegistration | undefined) => void;

export const serviceWorkerRegistrationPromise = new Promise<ServiceWorkerRegistration | undefined>(
  (resolve, reject) => {
    resolveRegistration = resolve;
    rejectRegistration = reject;
  },
);
