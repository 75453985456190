import * as _ from '@tuple-health/eng/dist/dryscript/ds';
import { Uri } from '@tuple-health/eng/dist/dryscript/lib/common/prelude/record/Uri';
import { ConceptSummary } from '@tuple-health/eng/dist/th/ds/common/tech/analytics/data/concept/ConceptSummary';
import { DataResult } from '@tuple-health/eng/dist/th/ds/common/tech/analytics/data/DataResult';
import { ItemData } from '@tuple-health/eng/dist/th/ds/common/tech/analytics/data/table/ItemData';
import { TableData } from '@tuple-health/eng/dist/th/ds/common/tech/analytics/data/table/TableData';
import * as toTableData from '@tuple-health/eng/dist/th/ds/common/tech/analytics/data/table/toTableData';
import * as toData from '@tuple-health/eng/dist/th/ds/common/tech/analytics/data/toData';
import { toDataResult } from '@tuple-health/eng/dist/th/ds/common/tech/analytics/data/toDataResult';
import { Literal, Partial, Record, Static, Union } from 'runtypes';
import { pick } from '@tuple-health/common';

export const TableSortDirection = Union(Literal('asc'), Literal('desc'));
export type TableSortDirection = Static<typeof TableSortDirection>;

export const TableSortType = Union(
  Literal('count'),
  Literal('calc'),
  Literal('label'),
  Literal('concept'),
);
export type TableSortType = Static<typeof TableSortType>;

export const TableSort = Record({
  type: TableSortType,
}).And(
  Partial({
    direction: TableSortDirection,
  }),
);
export type TableSort = Static<typeof TableSort>;

// =============================================================================

export const sortResult = (result: DataResult, sort?: TableSort): DataResult => {
  const type = sort ? sort.type : 'concept';
  const direction = sort && sort.direction ? sort.direction : sortType__defaultDirection(type);
  return toDataResult({
    ...pick(result, 'cohortLabel', 'cohort', 'concepts'),
    data: toData.table(sortTable(result, type, direction)),
  });
};

const sortType__defaultDirection = (type: TableSortType): TableSortDirection => {
  switch (type) {
    case 'count':
      return 'desc';
    case 'calc':
      return 'desc';
    case 'label':
      return 'asc';
    case 'concept':
      return 'asc';
  }
};

const sortTable = (
  result: DataResult,
  type: TableSortType,
  direction: TableSortDirection,
): TableData =>
  toTableData.call({
    attrs: result.table.attrs,
    items: sortItems(result, type, direction),
  });

const sortItems = (
  result: DataResult,
  type: TableSortType,
  direction: TableSortDirection,
): _.List<ItemData> => {
  const ascItems = _.list.call(sortItemsAsc(result, type));
  switch (direction) {
    case 'asc':
      return ascItems;
    case 'desc':
      return _.list.call(ascItems.reverse);
  }
};

const sortItemsAsc = (result: DataResult, type: TableSortType): Iterable<ItemData> => {
  const { table } = result;
  const items = [...table.items];

  // Memoize each item's conceptUri function to speed up sorting
  items.forEach(item => {
    let { conceptUri } = item;
    conceptUri = conceptUri.bind(item);
    const map = new Map<string, Uri>();
    item.conceptUri = key => {
      let value = map.get(key);
      if (!value) map.set(key, (value = conceptUri(key)));
      return value;
    };
  });

  switch (type) {
    case 'count':
      return items.sort(compareByCount(table));
    case 'calc':
      return items.sort(compareByCalc(table));
    case 'label':
      return items.sort(compareItemsByLabel(result));
    case 'concept':
      return items.sort(compareItemsByKey(result));
  }
  return items;
};

const compareByCount = (table: TableData) => {
  // TODO review, this is preserving old behavior
  const column = table.countAttr || table.numeratorCountAttr;

  return (left: ItemData, right: ItemData) => {
    if (!column) return 0;
    return _.ints.compare(left.numbers(column.key), right.numbers(column.key));
  };
};

const compareByCalc = (table: TableData) => (left: ItemData, right: ItemData) => {
  for (const attr of table.attrs) {
    if (attr.type.isNumber) {
      const order = _.reals.compare(left.number(attr.key), right.number(attr.key));
      if (order !== 0) return order;
    } else if (attr.type.isBox) {
      const order = _.reals.compare(left.box(attr.key).mean, right.box(attr.key).mean);
      if (order !== 0) return order;
    }
  }
  return 0;
};

const compareItemsByLabel = (result: DataResult) => (left: ItemData, right: ItemData) =>
  compareConceptsBy(result, compareConceptsByLabel)(left, right);

const compareItemsByKey = (result: DataResult) => (left: ItemData, right: ItemData) =>
  compareConceptsBy(result, compareConceptsByKey)(left, right);

type Order = number;

const compareConceptsByLabel = (left: ConceptSummary, right: ConceptSummary): Order =>
  // console.log(
  //   `comparing: ${left.label}, ${right.label}, order: ${_.strs.compareLower(
  //     left.label,
  //     right.label,
  //   )}`,
  // )
  _.strs.compareLower(left.label, right.label);

const compareConceptsByKey = (left: ConceptSummary, right: ConceptSummary): Order =>
  _.strs.compareLower(left.concept.conceptSortBy, right.concept.conceptSortBy);

const compareConceptsBy = (
  result: DataResult,
  compareConcept: (left: ConceptSummary, right: ConceptSummary) => Order,
) => (left: ItemData, right: ItemData) => {
  const { table } = result;
  const { concepts } = result;
  for (const attr of table.attrs) {
    if (attr.type.isConcept) {
      const leftUri = left.conceptUri(attr.key);
      const rightUri = right.conceptUri(attr.key);

      const leftConcept = concepts.uri__summary.call(leftUri);
      const rightConcept = concepts.uri__summary.call(rightUri);

      const order = compareConcept(leftConcept, rightConcept);
      if (order !== 0) return order;
    }
  }
  return 0;
};
