import { makeScreen } from '../../core/appbase/screen';
import * as logic from './login.logic';
import Login from './Login';

export { LoginProps } from './login.props';

export const loginScreen = makeScreen({
  agent: logic.agent,
  reducer: logic.reducer,
  selector: logic.selector,
  view: Login,
});
