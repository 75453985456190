import * as _ from '@tuple-health/eng/dist/dryscript/ds';
import { Pager } from '@tuple-health/eng/dist/dryscript/lib/common/data/pager/Pager';
import { Set } from 'immutable';
import { parseThreadSummaryRecord } from '../../components/messaging/convoList/ConvoSummary.model';
import { adaptPerson } from '../../components/messaging/person/person.model';
import { ApiKeys, RawApi } from './api';
import { ThreadPagerDto } from '@tuple-health/eng/dist/th/ds/common/dto/thread/ThreadPagerDto';

const noInput = (x?: undefined) => x;

export default adaptApi({
  listCollections: {
    input: (ns: string) => ({ ns }),
    output: pager =>
      [...pager.items].map(record => ({
        name: record.data.name,
        id: record.id,
      })),
  },
  listCustomerConversationMembers: {
    input: noInput,
    output: ppl => [...ppl].map(adaptPerson),
  },
  listPublicConversations: {
    input: noInput,
    output: toConvoSummaries,
  },
  listStars: {
    input: (ns?: string) => ({
      count: 100000,
      namespaces: ns === undefined ? undefined : _.toSet.of(ns),
    }),
    output: (pager: Pager<string>) => Set(pager.items),
  },
  userListConversations: {
    input: ({ customerIds, isPublic }: { customerIds: string[]; isPublic: boolean }) => ({
      customerIds: _.toSet.call(customerIds),
      isPublic,
    }),
    output: toConvoSummaries,
  },
});

function toConvoSummaries(pager: ThreadPagerDto, { session }: ApiKeys) {
  return [...pager.threadRecords.items].map(threadSummaryRecord =>
    parseThreadSummaryRecord(
      threadSummaryRecord,
      session!.userId,
      session!.id__customerRecord,
      pager.id__person,
    ),
  );
}

function adaptApi<
  O extends {
    [K in keyof RawApi]?: {
      input: (input: never, ids: ApiKeys) => Parameters<RawApi[K]>[0];
      output: (
        output: ReturnType<RawApi[K]> extends Promise<infer R> ? R : never,
        ids: ApiKeys,
      ) => unknown;
    };
  }
>(o: O) {
  return o;
}
