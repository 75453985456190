import { createContext, useContext } from 'react';
import { GatewayApi } from '@tuple-health/eng/dist/th/ds/common/agent/gatewayApi/api/GatewayApi';

const Context = createContext<GatewayApi | undefined>(undefined);

export const GatewayProvider = Context.Provider;

export function useGatewayOpt() {
  return useContext(Context);
}

export default function useGateway() {
  const context = useGatewayOpt();
  if (!context) throw Error('gateway context not provided');
  return context;
}
