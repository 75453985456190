import React from 'react';
import css from './NotFound.css';

export default function NotFound() {
  return (
    <div className={css.root}>
      <h1>Sorry, there doesn't appear to be anything here!</h1>
    </div>
  );
}
