import { Concept, Uri } from '@tuple-health/common/dist/data';
import { createUriPath, RouterParams, UriPath } from '@tuple-health/common/dist/router';
import React, { createContext, ReactNode, useContext, useMemo } from 'react';
import {
  CohortProvider,
  productKey__defaultCohort,
} from '../../components/cohort/model/cohort.model';
import getAnalyticsProduct from '../../components/product/analytics/getAnalyticsProduct';
import { adaptPlatformProduct } from '../../platform/platform/session.adapt';

interface Agent {
  productKey: string;
  productConcept: Concept;
  productRoute: UriPath;
  productParams: RouterParams;
}

const Context = createContext<Agent | undefined>(undefined);

interface Props {
  productKey: string;
  uris?: Uri[];
  params?: RouterParams;
  children?: ReactNode;
}

const noUris: Uri[] = [];
const noParams: Record<string, string> = {};

export function ProductProvider({ productKey, uris = noUris, params = noParams, children }: Props) {
  if (!productKey) throw Error('product key is required');

  const agent: Agent = useMemo(
    () => ({
      productKey,
      productConcept: adaptPlatformProduct(productKey),
      productRoute: createUriPath(uris),
      productParams: params,
    }),
    [uris, params, productKey],
  );

  const cohort = useMemo(() => productKey__defaultCohort(productKey), [productKey]);

  return (
    <Context.Provider value={agent}>
      <CohortProvider value={cohort}>{children}</CohortProvider>
    </Context.Provider>
  );
}

export function useProductOpt() {
  return useContext(Context);
}

export default function useProduct() {
  const context = useProductOpt();
  if (!context) throw Error('product context not provided');
  return context;
}

export function useAnalyticsProduct() {
  const { productKey } = useProduct();
  return getAnalyticsProduct(productKey);
}
