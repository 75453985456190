import { Divider, MenuItem } from '@material-ui/core';
import React, { forwardRef } from 'react';
import useLinker from '../../core/store/useLinker';
import useUser from '../../core/store/useUser';
import useVisibility from '../../core/store/useVisibility';
import { userRouter } from '../apps/user/user.router';
import { useChatOpt } from '../../components/messaging/chatAgent';
import useUserDrawer from '../../core/store/useUserDrawer';
import useSidebarRoute from '../../components/messaging/userDrawer/useSidebarRoute';

export const userMenuTestId = 'userMenu-test';
export const logoutLabel = 'Sign Out';

// Don't ask me why this needs to use forwardRef, but we get warnings otherwise
export default forwardRef(function UserMenu() {
  const canChat = !!useChatOpt();
  const visibility = useVisibility();
  const linker = useLinker();
  const { isTupleStaff } = useUser();
  const sidebar = useUserDrawer();
  const sidebarRoute = useSidebarRoute();
  return (
    <>
      <MenuItem {...linker.routeButton(userRouter.route('changePassword'))}>
        Change Password
      </MenuItem>
      <MenuItem {...linker.routeButton(userRouter.route('signout'))}>{logoutLabel}</MenuItem>
      {isTupleStaff && (
        <>
          <Divider />
          <MenuItem {...linker.routeButton(userRouter.route('invitations'))}>
            Manage Invitations
          </MenuItem>
        </>
      )}
      {isTupleStaff && (
        <>
          <Divider />
          <MenuItem
            onClick={() => {
              visibility.toggleVisibility();
            }}
          >
            {visibility.toggleVisibilityLabel}
          </MenuItem>
        </>
      )}
      {canChat && (
        <>
          <Divider />
          <MenuItem
            onClick={() => {
              sidebarRoute.goToUserSettings();
              sidebar.open();
            }}
          >
            Settings
          </MenuItem>
        </>
      )}
    </>
  );
});
