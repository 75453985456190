import { AnalyticsProduct } from '../model/analyticsProductModel';
import { bpciaAnalyticsProduct } from './analyticsProductBpci';
import { ocmAnalyticsProduct } from './analyticsProductOcm';

// TODO use product.key
const lookup: Record<string, AnalyticsProduct<any>> = {
  ocm: ocmAnalyticsProduct,
  bpcia: bpciaAnalyticsProduct,
};

export function getAnalyticsProductOpt(key: string): AnalyticsProduct<any> | undefined {
  return lookup[key];
}

export default function getAnalyticsProduct(key: string) {
  const analyticsProduct = getAnalyticsProductOpt(key);
  if (!analyticsProduct) throw Error(`unexpected analytics product key: ${key}`);
  return analyticsProduct;
}
