import * as _ from '@tuple-health/eng/dist/dryscript/ds';
import { ItemData } from '@tuple-health/eng/dist/th/ds/common/tech/analytics/data/table/ItemData';
import { Dictionary, Static, String } from 'runtypes';
import { keys } from '@tuple-health/common';

// attrKey__uriCurie
export const ItemPoint = Dictionary(String, 'string');
export type ItemPoint = Static<typeof ItemPoint>;

export const item__point = (item: ItemData): ItemPoint => {
  const point: ItemPoint = {};
  item.props.each(([attrKey, val]) => {
    if (typeof val === 'string') {
      point[attrKey] = val;
    }
  });
  return point;
};

export const itemPoint__key = (point: ItemPoint): string =>
  _.strs
    .sortN(keys(point))
    .pipe(attrKey => `${attrKey}=${point[attrKey]}`)
    .join(',');

export const item__key = (item: ItemData) => itemPoint__key(item__point(item));
