import React from 'react';
import { Helmet } from 'react-helmet';
import { style } from 'typestyle';
import { ofType } from 'unionize';
import { RaisedButton } from '../../components/workflow/buttons';
import { classes as wfClasses, StatusSection } from '../../components/workflow/common';
import WorkflowFrame from '../../components/workflow/WorkflowFrame';
import WorkflowTextField, {
  WorkflowTextFieldProps,
} from '../../components/workflow/WorkflowTextField';
import { Dictionary, property, WithMaybeError } from '@tuple-health/common';

import { makeProps } from '../../core/props';

import theme from '../../theme';

export interface FormField {
  name: string;
  value?: string;
  multiline?: boolean;
}

interface BaseProps {
  intro?: string;
  fields: FormField[];
}

interface PromptProps extends BaseProps, WithMaybeError {
  onSubmit: (values: Dictionary<string>) => void;
}

type CombinedProps = Partial<PromptProps> & BaseProps;

export const SupportProps = makeProps(
  {
    prompt: ofType<PromptProps>(),
    pending: ofType<BaseProps>(),
    submitted: {},
  },
  'status',
);
export type SupportProps = typeof SupportProps._Union;

export default class Support extends React.PureComponent<SupportProps, Dictionary<string>> {
  state = { ...this.props } as Dictionary<string>;

  render() {
    return SupportProps.match({
      prompt: this.renderForm,
      pending: this.renderForm,
      submitted: this.renderSubmitted,
    })(this.props);
  }

  renderForm = ({ intro, onSubmit, error, fields }: CombinedProps) => {
    const submit = onSubmit && (() => onSubmit(this.state));
    const disabled = !submit;

    const tfprops = {
      state: this.state,
      setState: (s => this.setState(s)) as WorkflowTextFieldProps['setState'],
      error,
      submit,
    };

    return (
      <WorkflowFrame classes={classes.frame} hideBranding>
        <Helmet title="Contact Support" />
        <h1>Contact Support</h1>
        {intro && <p className={wfClasses.spacer}>{intro}</p>}
        {fields.map(({ name, multiline }, i) => (
          <WorkflowTextField
            {...tfprops}
            field={name}
            label={name}
            key={i}
            multiline={multiline ? 3 : undefined}
          />
        ))}
        <RaisedButton disabled={disabled} onClick={submit}>
          Submit
        </RaisedButton>
        <StatusSection
          status={disabled ? 'pending' : { error, skipKeys: fields.map(property('name')) }}
        />
      </WorkflowFrame>
    );
  };

  renderSubmitted = () => (
    <WorkflowFrame classes={classes.frame}>
      <Helmet title="Contact Support" />
      <p className={classes.paragraph}>Thank you for contacting us!</p>
      <p>
        Our support team has been notified and will reach out to you shortly. We're sorry for any
        inconvenience this may have caused.
      </p>
    </WorkflowFrame>
  );
}

const classes = {
  frame: {
    paper: style({
      minHeight: 0,
      $nest: {
        '&>h1': {
          marginTop: 0,
          marginBottom: theme.spacing(1),
        },
      },
    }),
  },

  paragraph: style({
    marginTop: `${theme.spacing(3)}px !important`,
    fontWeight: 'bold',
  }),
};
