import { Typography } from '@material-ui/core';
import Icon from '@material-ui/icons/Warning';
import React from 'react';
import { isIE } from '../../core/browser';
import css from './BrowserCompatibilityBanner.css';

export default function BrowserCompatibilityBanner() {
  return isIE ? (
    <div className={css.root}>
      <Icon className={css.icon} />{' '}
      <Typography className={css.typographyTitle} variant="subtitle1">
        Please switch browsers
      </Typography>
      <Typography className={css.typographyBody} variant="subtitle1">
        Internet Explorer does not support modern web technologies, and we'll be discontinuing
        support for it soon.Please switch to <Chrome /> or <Firefox />
        .If you are unable to switch, <LetUsKnow />.
      </Typography>
    </div>
  ) : null;
}

const Link = ({ children, ...props }: JSX.IntrinsicElements['a']) => (
  <a className={css.link} {...props}>
    {children}
  </a>
);

const Chrome = () => <Link href="https://www.google.com/chrome/">Chrome</Link>;

const Firefox = () => <Link href="https://www.mozilla.org/firefox/">Firefox</Link>;

const LetUsKnow = () => <Link href="mailto:support@tuplehealth.com">let us know</Link>;
