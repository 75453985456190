import { makeScreen } from '../../../core/appbase/screen';
import * as logic from './logic';
import PasswordResetInitiate from './PasswordResetInitiate';

export const passwordResetInitiateScreen = makeScreen({
  agent: logic.agent,
  reducer: logic.reducer,
  selector: logic.selector,
  view: PasswordResetInitiate,
});
