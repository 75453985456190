import { safeDump, safeLoad } from 'js-yaml';
import { toAdapter } from './adaptLib';

type Json = any;
type Yaml = string;

// =============================================================================

// skipInvalid is used to skip undefined
export const yamlAdapter = toAdapter<Yaml, Json>(
  (yaml: Yaml): Json => {
    try {
      return safeLoad(yaml.trim());
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(`failed YAML:\n${yaml}`);
      throw e;
    }
  },
  (json: Json): Yaml =>
    json === undefined ? '' : safeDump(json, { skipInvalid: true, lineWidth: 99999999999 }).trim(),
);

export const { parse } = yamlAdapter;
export const { write } = yamlAdapter;

// =============================================================================

export const indent = (s: string, amount = '  '): string =>
  s
    .trim()
    .split('\n')
    .map(s => amount + s)
    .join('\n');

export const rootItem = (s: string): string => {
  let delim = '- ';
  const newLines: string[] = [];
  s.trim()
    .split('\n')
    .forEach(line => {
      newLines.push(delim + line);
      delim = '  ';
    });
  return newLines.join('\n');
};
