import { App, makeApp } from '../core/appbase/app';
import { ScreenParameterMap } from '../core/appbase/screen';
import { ServiceMap } from '../core/service';
import { service as authn } from '../services/authn';
import { service as workflow } from '../services/workflow/workflow.service';
import { route__screen } from './locScreens';

interface ConfigureAppParams<M> {
  mixins: ServiceMap<M>;
}

export function configureApp<M>(c: ConfigureAppParams<M>): App<{}, M>;
export function configureApp<M, S extends ScreenParameterMap>(c: ConfigureAppParams<M>): App<S, M>;
export function configureApp<M>({ mixins }: ConfigureAppParams<M>) {
  return makeApp(route__screen, Object.assign({ authn, workflow }, mixins));
}
