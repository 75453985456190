import { $concept } from '@tuple-health/common/dist/data';
import { routerKinds } from '../../platform/v1PlatformConcepts';

class BpciaDb {
  greenville = $concept.create({
    uri: routerKinds.hospital.uri('420078'),
    label: 'Greenville Memorial',
  });
  richland = $concept.create({
    uri: routerKinds.hospital.uri('420018'),
    label: 'Palmetto Richland',
  });
  baptist = $concept.create({ uri: routerKinds.hospital.uri('420086'), label: 'Palmetto Baptist' });
  bpciaFacilities = [this.greenville, this.richland, this.baptist];

  copd = $concept.create({ uri: routerKinds.bpciaBundle.uri('copd'), label: 'COPD' });
  pneumonia = $concept.create({
    uri: routerKinds.bpciaBundle.uri('pneumonia'),
    label: 'Pneumonia',
  });
  sepsis = $concept.create({ uri: routerKinds.bpciaBundle.uri('sepsis'), label: 'Sepsis' });
  pci_inpatient = $concept.create({
    uri: routerKinds.bpciaBundle.uri('percCoronaryIp'),
    label: 'Inpatient PCI',
  });
  pci_outpatient = $concept.create({
    uri: routerKinds.bpciaBundle.uri('percCoronaryOp'),
    label: 'Outpatient PCI',
  });
  bpciaBundles = [this.copd, this.pneumonia, this.sepsis, this.pci_inpatient, this.pci_outpatient];

  bpciaPairs = [
    [this.greenville, this.sepsis],
    [this.greenville, this.pci_inpatient],
    [this.greenville, this.pci_outpatient],
    [this.richland, this.pneumonia],
    [this.richland, this.pci_inpatient],
    [this.richland, this.pci_outpatient],
    [this.baptist, this.copd],
  ];

  allConcepts = [...this.bpciaBundles, ...this.bpciaFacilities];
}

export const bpciaDb = new BpciaDb();
