import * as _ from '@tuple-health/eng/dist/dryscript/ds';

export const removePhraseLinks = (text: string): string => {
  text = ' ' + text; // regex looks at character before the link

  const matches = [..._.rex.find(text, rex)].reverse();

  matches.forEach(match => {
    const start = _.rex.start(match) + 1; // +1 for $
    const stop = _.rex.stop(match);
    const varName = _.rex.group(match, 3);
    const anchorText = _.rex.group(match, 2) || varName;
    const richLinkMarkup = `[${anchorText}](\`\`\`{textOrEmbed}\n\${${varName}}\n\`\`\`)`;

    text = text.substring(0, start) + richLinkMarkup + text.substring(stop);
  });

  return text.substring(1);
};

// group 1: optional text
// group 2: required phrase
const rex = _.rex.call('[^`$](\\[([^\\]]*)\\])?\\{([^\\}]*)\\}');
