import * as Redux from 'redux';
import { Action } from './action';
import { constant, memoize } from '@tuple-health/common';

// TODO: allow injectables into Reducers (require subsetting APIs to exclude functions)
export type Reducer<S, A extends Action> = Redux.Reducer<S, A>;

type Transformer<S, A extends Action> = (state: S) => (action: A) => S;

// turn a curried reducer into a standard reducer, and replace undefined return values with the previous state
export const makeReducer = <S, A extends Action>(
  initialState: S,
  f: Transformer<S, A> = constant,
): Reducer<S, A> =>
  memoize((state: S = initialState, action: A) => {
    const newState = f(state)(action);
    return newState === undefined ? state : newState;
  });
