import { CubeFactPredicate } from '@tuple-health/eng/dist/dryscript/lib/common/cube/boolExp/CubeFactPredicate';
import { CubeCatalog } from '@tuple-health/eng/dist/dryscript/lib/common/cube/catalog/CubeCatalog';
import { createContext, useContext } from 'react';
import { getAnalyticsProductOpt } from '../../product/analytics/getAnalyticsProduct';
import { Dataset } from '../../product/model/analyticsProductModel';

// =============================================================================
// cohort filter
// =============================================================================

interface CohortFilter {
  deepFilters: CubeFactPredicate[];
  shallowFilters: CubeFactPredicate[];
}

interface ExtendArgs {
  deepFilters?: CubeFactPredicate[];
  shallowFilters?: CubeFactPredicate[];
}

const extendCohortFilter = (
  before: CohortFilter,
  { deepFilters, shallowFilters }: ExtendArgs,
): CohortFilter => ({
  ...before,
  deepFilters: deepFilters ? [...before.deepFilters, ...deepFilters] : before.deepFilters,
  shallowFilters: shallowFilters
    ? [...before.shallowFilters, ...shallowFilters]
    : before.shallowFilters,
});

// =============================================================================
// model
// =============================================================================

export interface Cohort extends CohortFilter {
  dataset: Dataset;
  catalog: CubeCatalog;
}

export const extendCohort = (before: Cohort, args: ExtendArgs): Cohort => ({
  ...before,
  ...extendCohortFilter(before, args),
});

// =============================================================================
// context
// =============================================================================

const Context = createContext<Cohort | undefined>(undefined);

export const CohortProvider = Context.Provider;

export const useCohort = () => {
  const cohort = useContext(Context);
  if (!cohort) throw Error('no cohort context available');
  return cohort;
};

// =============================================================================
// default cohort
// =============================================================================

export function productKey__defaultCohort(productKey: string): Cohort | undefined {
  const analytics = getAnalyticsProductOpt(productKey);

  return (
    analytics && {
      catalog: analytics.defaultCatalog,
      dataset: analytics.defaultDataset,
      deepFilters: [],
      shallowFilters: [],
    }
  );
}
