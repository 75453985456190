import { Tab, Tabs } from '@material-ui/core';
import React from 'react';
import { style } from 'typestyle';
import useVisibility from '../../core/store/useVisibility';
import theme from '../../theme';
import {
  RouterLoc,
  RouterMenu,
  RouterMenuItem,
  RouterMenuPage,
} from '@tuple-health/common/dist/router';
import css from './TabMenu.css';
import useLinker from '../../core/store/useLinker';

// =============================================================================
// tab menu
// =============================================================================

export default function TabMenu<Loc extends RouterLoc>({ menu }: { menu: RouterMenu<Loc> }) {
  const visibility = useVisibility();
  return (
    <div className={css.root}>
      {menu.label && (
        <h2 style={{ marginTop: 10, marginBottom: theme.spacing(1), flexShrink: 0 }}>
          {menu.label}
        </h2>
      )}
      <Tabs
        classes={classes.tabs}
        value={menu.items.findIndex(item => item.active) || 0}
        indicatorColor="primary"
        textColor="primary"
        // scrollable leaves blank white space to left tabs (where the arrow will go if there is overflow-x)
        variant={menu.items.length > 5 ? 'scrollable' : 'fullWidth'}
      >
        {menu.items.filter(visibility.isVisible).map((item, i) => (
          <TabMenuItem key={i} item={item} />
        ))}
      </Tabs>
    </div>
  );
}

// =============================================================================

const TabMenuItem = <Loc extends RouterLoc>({ item }: { item: RouterMenuItem<Loc> }) => {
  switch (item.tag) {
    case 'folder':
      throw Error('tab menu folders not supported');
    case 'page':
      return <TabMenuPage page={item} />;
  }
};

// =============================================================================

const TabMenuPage = <Loc extends RouterLoc>({ page }: { page: RouterMenuPage<Loc> }) => {
  const linker = useLinker();
  return <Tab classes={classes.tab} label={page.label} {...linker.pathButton(page.path)} />;
};

// position: sticky isn't implemented below Edge 16, and seems partly broken even there
// const useSticky = !(isIE || isEdge);

const sidePadding = theme.spacing(3);
const classes = {
  // from sticky app bar
  // root: classNames(
  //   style({
  //     position: useSticky ? (isSafari ? ['-webkit-sticky', 'sticky'] : 'sticky') : 'relative',
  //     width: `100%`,
  //     // marginLeft: sidebarWidth,
  //     boxSizing: 'content-box',
  //   }),
  //   // mediaFilterClasses.screen,
  // ),
  // colorSecondary: style({
  //   backgroundColor: theme.palette.secondary.main,// dark styling
  //   color: theme.palette.secondary.contrastText,// dark styling
  // }),

  title: {
    root: style({
      margin: `${theme.spacing(2)}px ${sidePadding}px`,
    }),

    colorTextSecondary: style({
      // color: theme.palette.secondary.contrastText, // dark styling
    }),
  },

  tabs: {
    flexContainer: style({
      // height: headerHeight,
      minHeight: 0,
    }),
    indicator: style({
      // backgroundColor: theme.palette.secondary.contrastText, // dark styling
    }),
  },

  tab: {
    root: style({
      minWidth: 120,
      textAlign: 'center', // https://github.com/mui-org/material-ui/issues/13257
      $nest: {
        '&:hover': {
          backgroundColor: 'rgba(255, 255, 255, 0.1)',
        },
      },
    }),
    selected: style({
      backgroundColor: 'rgba(255, 255, 255, 0.15)',
    }),
  },
};
