import * as Cookies from 'js-cookie';
import { Persistence } from '../../contracts/Persistence';
import { constant } from '@tuple-health/common';
import { makeProvider } from '../../core/provider';
import { makeService } from '../../core/service';

const load = (key: string): string | undefined => {
  try {
    const value = Cookies.get(key);
    if (typeof value === 'string') return value;

    const value2 = localStorage.getItem(key);
    if (typeof value2 === 'string') {
      save(key, value2);
      return value2;
    }

    return undefined;
  } catch (err) {
    // eslint-disable-next-line no-console
    console.warn('Failed to load state from cookie', err);
    return undefined;
  }
};

const secure = window.location.toString().includes('https://');
const save = (key: string, token: string): void => {
  Cookies.set(key, token, {
    secure,
    expires: 365,
  });
  localStorage.removeItem(key);
};

const clear = (key: string): void => {
  Cookies.remove(key);
  localStorage.removeItem(key);
};

export const cookiePersistence: Persistence = { load, save, clear };

const provider = makeProvider(Persistence, constant(cookiePersistence));

export const service = makeService({
  providers: [provider],
});
