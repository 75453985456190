import * as _ from '@tuple-health/eng/dist/dryscript/ds';

export type ArticleCellVarType =
  | 'number'
  | 'string'
  | 'concept'
  | 'concept.label'
  | 'concept.key'
  | 'concept.uri'
  | 'concept.ns'
  | 'conceptPath'
  | 'conceptPath.keyPath';

export type ArticleVarType =
  | ArticleCellVarType
  | 'number[]'
  | 'string[]'
  | 'concept[]'
  | 'concept[].keyPath'
  | 'conceptPath[]'
  | 'conceptPath[].keyPaths'
  | ArticleCellVarType[]; // table

export interface ArticleVar {
  name: string;
  type?: ArticleVarType;
}

const parseArticleCellVarType = (text: string): ArticleCellVarType => {
  if (_.strs.isBlank(text)) throw Error('variable type cannot be blank');
  switch (text) {
    case 'number':
    case 'string':
    case 'concept':
    case 'concept.label':
    case 'concept.key':
    case 'concept.uri':
    case 'concept.ns':
    case 'conceptPath':
    case 'conceptPath.keyPath':
      return text;
  }
  throw Error(`unexpected variable type: ${text}`);
};

export const parseArticleVarType = (text: string): ArticleVarType => {
  if (_.strs.isBlank(text)) throw Error('variable type cannot be blank');
  if (text.startsWith('table<')) {
    return text
      .replace('table<', '')
      .replace('>', '')
      .split(',')
      .map(t => t.trim())
      .map(parseArticleCellVarType);
  }
  switch (text) {
    case 'number[]':
    case 'string[]':
    case 'concept[]':
    case 'concept[].keyPath':
    case 'conceptPath[]':
    case 'conceptPath[].keyPaths':
      return text;
  }
  return parseArticleCellVarType(text);
};
