import * as _ from '@tuple-health/eng/dist/dryscript/ds';
import { Person } from '@tuple-health/eng/dist/th/ds/common/domain/person/person/Person';
import { Msg } from '@tuple-health/eng/dist/th/ds/common/product/user/thread/Msg';
import * as toMsg from '@tuple-health/eng/dist/th/ds/common/product/user/thread/toMsg';
import { ProductRichLink } from '../../../packages/content/richLink/richLink.model';
import { toAdapter } from '../../util/adaptLib';
import { lookupAndAdaptPerson, PersonModel } from '../person/person.model';
import { productRichLinkFormat } from '../../../packages/content/richLink/richLink.format';
import { buildMessage } from './convoEvent.model';

export interface Message {
  text: string;
  attachments: ProductRichLink[];
}

export const emptyMessage: Message = { text: '', attachments: [] };

export const pushAttachment = (message: Message, attachment: ProductRichLink): Message => ({
  ...message,
  attachments: [...message.attachments, attachment],
});

// ============================

export interface MessageEntry extends Message {
  id?: string; // messages witout ids are currently being created
  uri?: string;
  creator: PersonModel;
  created: Date;
  modified?: Date;
  type: 'message';
}

export const parseMessageRecord = (
  record: _.Record<Msg>,
  id__person: _.Dict<string, Person>,
  author?: Person,
): MessageEntry =>
  parseMessageEntry({
    msg: record.data,
    id: record.id,
    creator: lookupAndAdaptPerson(id__person, record.creatorId!, author),
    created: new Date(record.created!),
    modified: record.modified ? new Date(record.modified) : undefined,
  });

export const parseMessageEntry = ({
  msg,
  id,
  created,
  creator,
  modified,
}: {
  msg: Msg;
  id: string | undefined;
  creator: PersonModel;
  created: Date;
  modified?: Date;
}): MessageEntry =>
  buildMessage({
    ...messageAdapter.parse(msg),
    id,
    creator,
    created,
    modified,
  });

export const messageAdapter = toAdapter<Msg, Message>(
  msg => ({
    text: msg.text,
    attachments: msg.attachments ? [...msg.attachments].map(productRichLinkFormat.parse) : [],
  }),
  message =>
    toMsg.call({
      text: message.text,
      attachments: message.attachments.length
        ? _.toArray.call(message.attachments.map(productRichLinkFormat.write))
        : undefined,
    }),
);
