import { GatewaySession } from '@tuple-health/eng/dist/th/ds/common/agent/gatewayApi/service/session/GatewaySession';
import { IObservableValue } from 'mobx';
import { ofType, unionize } from 'unionize';
import { combine } from '@tuple-health/common';
import { makeContract } from '../core/contract';

// service definition
const AuthnUnion = unionize({
  initializing: {},

  none: ofType<{
    beginSession(session: GatewaySession): void;
  }>(),

  session: ofType<{
    initiateLogout(): void;
    session: GatewaySession;
    expiry: IObservableValue<number>;
    continueSession(): void;
  }>(),
});

export type Authn = typeof AuthnUnion._Union;
export const Authn = combine(AuthnUnion, makeContract<Authn>('authn'));
