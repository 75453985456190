import React from 'react';
import { Helmet } from 'react-helmet';
import ThemeProvider from '../../ThemeProvider';
import ExpiryWarning, { ExpiryWarningProps } from './expiry/ExpiryWarning';

export interface FrameProps {
  children?: React.ReactNode;

  // eventually move this onto frame store
  expiryWarning?: ExpiryWarningProps;
}

export default React.memo(({ expiryWarning, ...props }: FrameProps) => (
  <ThemeProvider>
    <Helmet defaultTitle="Tuple Health" titleTemplate="%s - Tuple Health" />
    {expiryWarning && <ExpiryWarning {...expiryWarning} />}
    {props.children}
  </ThemeProvider>
));
