import { Adapter, toAdapter } from '../../../components/util/adaptLib';

// =============================================================================
// embed
// =============================================================================

// TODO review, arguably embed lines should be args only, not body only?
export interface Embed {
  tag: string;
  args?: string;
  body: string;
}

export const writeEmbed = (embed: Embed) =>
  embed.body.includes('\n') || embed.args ? writeEmbedBlock(embed) : writeEmbedLine(embed);

const parseEmbed = (text: string): Embed => {
  const embed = parseEmbedOpt(text);
  if (!embed) throw Error(`unable to parse embed: ${text}`);
  return embed;
};

export const parseEmbedOpt = (text: string) => parseEmbedLine(text) || parseEmbedBlock(text);

export const embedFormat = toAdapter<string, Embed>(parseEmbed, writeEmbed);

// =============================================================================
// embed line
// =============================================================================

export const writeEmbedLine = ({ tag, body }: Embed) =>
  `\`${tag}${body.trim().length ? ' ' + body.trim() : ''}\``;

const parseEmbedLine = (text: string): Embed | undefined => {
  text = text.trim();
  if (text.includes('\n')) return undefined;
  if (!text.startsWith('`') || !text.endsWith('`')) return undefined;
  const tagAndBody = text.substring(1, text.length - 1);
  const index = tagAndBody.indexOf(' ');
  if (index < 0) return { tag: tagAndBody, body: '' }; // TODO undefined body?
  const tag = tagAndBody.substring(0, index);
  const body = tagAndBody.substring(index + 1);
  return { tag, body };
};

// const embedLineRex = new RegExp('^`([^ ]+)( (.*))`$');

// =============================================================================
// embed block
// =============================================================================

const writeEmbedBlock = ({ tag, args, body }: Embed) =>
  `\`\`\`{${tag}${args ? ' ' + args : ''}}\n${body}\n\`\`\``;

// this must preserve space before line (i.e. YAML indentations)
const parseEmbedBlock = (text: string): Embed | undefined => {
  const lines = text.split('\n');
  if (lines.length < 3) return undefined;
  const first = lines[0].trim();
  const last = lines[lines.length - 1].trim();

  if (!first.startsWith(embedBlockOpenPrefix) || !first.endsWith(embedBlockOpenSuffix))
    return undefined;
  const tagAndArgs = first.substring(
    embedBlockOpenPrefix.length,
    first.length - embedBlockOpenSuffix.length,
  );

  const [tag, args] = parseTagAndArgs(tagAndArgs);

  if (last !== embedBlockClose) return undefined;

  const bodyLines = lines.slice(1, lines.length - 1);
  const body = bodyLines.join('\n');

  return { tag, args, body };
};

const parseTagAndArgs = (tagAndArgs: string): [string, string | undefined] => {
  const index = tagAndArgs.indexOf(' ');
  if (index < 0) return [tagAndArgs, undefined];
  const tag = tagAndArgs.substring(0, index);
  const args = tagAndArgs.substring(index + 1).trim();
  return args.trim().length ? [tag, args] : [tag, undefined];
};

export const embedBlockOpenPrefix = '```{';
const embedBlockOpenSuffix = '}';
const embedBlockClose = '```';

export const isEmbedStartLine = (line: string) => line.trim().startsWith('`');

// =============================================================================
// embed format generator
// =============================================================================

const toEmbedFormat = <Val>(
  tag: string,
  bodyFormat: Adapter<string, Val>,
  forceMultiLine?: boolean,
): Adapter<string, Val> => {
  const writeEmbed = (val: Val): string => {
    const body = bodyFormat.write(val);
    const embed = { tag, body };
    return forceMultiLine ? writeEmbedBlock(embed) : embedFormat.write(embed);
  };

  const parseEmbed = (text: string): Val => {
    const embed = parseEmbedOpt(text);
    if (!embed) throw Error(`could not parse ${tag}: ${text}`);
    if (embed.tag !== tag) throw Error(`could not parse due to tag mismatch ${tag}: ${text}`);
    return bodyFormat.parse(embed.body);
  };

  return toAdapter<string, Val>(parseEmbed, writeEmbed);
};

// =============================================================================
// passthru format generator
// =============================================================================

export const toPassthruEmbedFormat = (
  tag: string,
  forceMultiLine?: boolean,
): Adapter<string, string> => toEmbedFormat(tag, passthruBodyFormat, forceMultiLine);

const passthruBodyFormat = toAdapter<string, string>(
  s => s,
  s => s,
);
