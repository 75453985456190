import { useMemo } from 'react';
import useApi from '../../core/store/useApi';
import useUser from '../../core/store/useUser';

interface LegalAgreement {
  wasAccepted: boolean;
  accept: () => Promise<void>;
}

export default function useLegalAgreement(legalType: string): LegalAgreement {
  const {
    session: { legalType__acceptedMs },
  } = useUser();
  const { acceptLegal } = useApi();

  const agent: LegalAgreement = useMemo(
    () => ({
      wasAccepted: legalType__acceptedMs.hasKey(legalType),
      accept: () => acceptLegal({ legalType }),
    }),
    [acceptLegal, legalType, legalType__acceptedMs],
  );

  return agent;
}
