import React, { useMemo, createContext, useContext, ReactNode } from 'react';
import useMethods from 'use-methods';

type Loc = 'chat' | 'userSettings';

interface State {
  activeTab: Loc;
}

export type SidebarRouteAgent = ReturnType<typeof useSidebarRouteAgent>;

function useSidebarRouteAgent() {
  const [state, callbacks] = useMethods(methods, { activeTab: 'chat' });

  return useMemo(() => ({ ...state, ...callbacks }), [state, callbacks]);
}

const methods = (state: State) => ({
  goToUserSettings() {
    state.activeTab = 'userSettings';
  },
  goToChat() {
    state.activeTab = 'chat';
  },
});

const Context = createContext<SidebarRouteAgent | undefined>(undefined);

export const SidebarRouteProvider = Context.Provider;

export default function useSidebarRoute() {
  const agent = useContext(Context);
  if (!agent) throw Error('No sidebar route context found');
  return agent;
}

export function SidebarRouteCreator({ children }: { children?: ReactNode }) {
  const agent = useSidebarRouteAgent();
  return <SidebarRouteProvider value={agent}>{children}</SidebarRouteProvider>;
}
