import { createMuiTheme } from '@material-ui/core';
import { keyframes } from 'typestyle';
import { isDemo } from './core/env';

const primary = {
  main: '#333E4C',
};

const secondary = {
  main: isDemo ? '#4695EC' : '#1989AC',
  contrastText: '#fff',
};

// Make progress components fade in after 1 second
// https://github.com/mui-org/material-ui/issues/9306
const progressStyles = {
  root: {
    animationName: keyframes({
      '0%': { opacity: 0 },
      '50%': { opacity: 0 },
      '100%': { opacity: 1 },
    }),
    animationDuration: '1s',
  },
};

const paperStyle = {
  boxShadow: '0 10px 40px rgba(0, 0, 0, 0.1)',
  borderRadius: 2,
};

export default createMuiTheme({
  palette: {
    primary,
    secondary,
  },
  overrides: {
    MuiLinearProgress: progressStyles,
    MuiCircularProgress: progressStyles,
    MuiCard: { root: paperStyle },
    MuiPopover: { paper: paperStyle },
  },
});
