import { $app, createRouter, vocabulary } from '@tuple-health/common/dist/router';

// =============================================================================
// locs
// =============================================================================

const locs = vocabulary({
  home: undefined,
  admin: undefined,
  apps: undefined,
  app: undefined,
});

export type CustomerLoc = typeof locs.Term;

// =============================================================================
// builder
// =============================================================================

const _ = createRouter<CustomerLoc>('customer', 'Customer', locs);

// =============================================================================
// home
// =============================================================================

_.add(
  _.page({
    loc: 'home',
    label: 'Organization',
    childItems: [
      _.page({
        loc: 'admin',
        slug: 'admin',
        label: 'Administration',
      }),
      _.page({
        loc: 'apps',
        slug: 'apps',
        label: 'Applications',
        childConcept: _.concept(
          $app,
          _.page({
            loc: 'app',
            label: 'Application',
            overflow: true,
          }),
        ),
      }),
    ],
  }),
);

// =============================================================================
// router
// =============================================================================

export const customerRouter = _.build();
