import React from 'react';
import { concat as observableConcat, of as observableOf } from 'rxjs';
import { catchError, filter, map, mergeMap, takeUntil } from 'rxjs/operators';
import { ofType } from 'unionize';
import { Authn } from '../../contracts/Authn';
import { Dispatch } from '../../contracts/Dispatch';
import Frame, { FrameState } from '../../services/frame/frame.service';
import { FrameProps } from '../../services/frame/Frame';
import { Action as GenericAction, makeActions } from '../action';
import { AgentAction, makeAgent, NOOP } from '../agent';
import { combine, constant } from '@tuple-health/common';
import { Contract, makeContract } from '../contract';

import { makeProvider, Provider } from '../provider';
import { makeReducer } from '../reducer';
import { Resolver } from '../resolver';
import { ComposedState, composeServices, makeService, Service } from '../service';
import { Router } from './router.service';
import {
  ScreenAction,
  ScreenParameterMap,
  ScreenParameters,
  ScreenRoutes,
  ScreenState,
} from './screen';
import ScreenView from './ScreenView';

// ============================
// typings
// ============================
export interface GenericScreenProps {
  pathId: string;
  screen: any;
  frame: FrameProps;
  error?: Error;
  screens: ScreenRoutes<any>;
  onDidCatch: (error: Error) => void;
  resolve: Resolver;
}

const contract = makeContract<GenericScreenProps>('screen-props');

type NavAction = GenericAction & { meta: any };

const Action = makeActions({
  screen__error: ofType<Error>(),
});
type Action = typeof Action._Union;

// ============================
// widget
// ============================
export function makeScreenWidget<S extends ScreenParameterMap>(
  screens: ScreenRoutes<S>,
  router: Provider<ScreenState<S>, Router>,
): {
  service: Service<ComposedState<ScreenState<S>, FrameState>>;
  contract: Contract<GenericScreenProps>;
  render: (props: GenericScreenProps) => React.ReactElement<any>;
} {
  // --- helpers] -- //
  const isNavAction = (action: GenericAction): action is NavAction => action.type in screens;

  // --- reducer -- //
  const reducer = makeReducer<ScreenState<S>, GenericAction<never, never>>(
    null,
    state => (genericAction): any => {
      if (isNavAction(genericAction)) {
        const base = {
          path: genericAction.meta.location.current.pathname,
          pathId: genericAction.type,

          parameters: genericAction.payload || {},
          query: genericAction.meta.location.current.query || {},
        };

        const screen = screens[genericAction.type];
        try {
          return {
            ...base,
            screen: (screen.reducer || constant({}))(undefined, genericAction),
          };
        } catch (error) {
          return {
            ...base,
            screen: undefined,
            error,
          };
        }
      }

      if (!state || state.error) return state;

      {
        const action = genericAction as AgentAction;
        if (AgentAction.is.agent__unhandledError(action)) {
          const { error } = action.payload;
          return combine(state, {
            error,
          });
        }
      }

      {
        const action = genericAction as Action;
        if (Action.is.screen__error(action)) {
          return combine(state, {
            error: action.payload,
          });
        }
      }

      const screen = screens[state.pathId];
      if (!screen.reducer) return state;

      try {
        const newScreenState = screen.reducer(state.screen, genericAction);
        if (newScreenState === state.screen) return state;

        return {
          path: state.path,
          pathId: state.pathId,

          parameters: state.parameters,
          query: state.query,
          screen: newScreenState,
        };
      } catch (error) {
        return combine(state, {
          error,
        });
      }
    },
  );

  // --- agent -- //

  const isNavFrame = <F extends { action: GenericAction }>(frame: F) => isNavAction(frame.action);
  const agent = makeAgent({
    router: Router,
    authn: Authn,
  })<ScreenState<S>>($ =>
    $.pipe(
      filter(isNavFrame),
      mergeMap(frame => {
        const { state } = frame;
        if (!state) return NOOP.observable();

        const screen = screens[state.pathId];
        if (!screen.agent) return NOOP.observable();

        return screen
          .agent(
            observableConcat([{ ...frame, action: ScreenAction.screeninit() }], $).pipe(
              map(frame => ({ ...frame, state: frame.state!.screen })),
              takeUntil($.pipe(filter(isNavFrame))),
            ),
          )
          .pipe(catchError(e => observableOf(Action.screen__error(e))));
      }),
    ),
  );

  // -- parameters selector -- //
  // TODO: only provide parameters internally

  const parametersProvider = makeProvider<ScreenState<S>, any>(ScreenParameters(), ({ state }) =>
    state ? state.parameters : undefined,
  );

  // -- screen props provider -- //
  let lastProps: GenericScreenProps;
  const propsSelector = ({ state }: { state: ScreenState<S> }, resolve: Resolver) => {
    const frame = resolve(Frame.contract);
    const onDidCatch = (error: Error) => resolve(Dispatch)(Action.screen__error(error));

    if (!state) {
      return (
        lastProps ||
        (lastProps = {
          pathId: '@@init',
          screen: undefined,
          frame,
          onDidCatch,
          screens,
          resolve,
        })
      );
    }

    const baseProps = {
      pathId: state.pathId,
      frame,
      screen: undefined,
      onDidCatch,
      screens,
      resolve,
    };

    if (state.error) {
      return (lastProps = {
        ...baseProps,
        error: state.error,
      });
    }

    let newProps: GenericScreenProps;

    try {
      const screen = screens[state.pathId];
      const nextScreenProps = screen.selector
        ? screen.selector({ state: state.screen }, resolve)
        : undefined;

      newProps = {
        ...baseProps,
        screen: nextScreenProps,
      };
    } catch (error) {
      // update state
      onDidCatch(error);

      return (lastProps = {
        ...baseProps,
        error,
      });
    }

    if (
      !lastProps ||
      // TODO: shallow compare
      lastProps.pathId !== newProps.pathId ||
      lastProps.screen !== newProps.screen ||
      lastProps.frame !== newProps.frame
    ) {
      lastProps = newProps;
    }

    return lastProps;
  };
  const propsProvider = makeProvider(contract, propsSelector);

  // -- screen view -- //
  const render = (props: GenericScreenProps) => <ScreenView {...props} />;

  // -- screen service -- //
  const service = composeServices(
    makeService({
      reducer,
      agent,
      providers: [parametersProvider, propsProvider, router],
    }),
    Frame.service,
  );

  // -- screen widget -- //
  return {
    service,
    contract,
    render,
  };
}
