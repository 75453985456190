import { Boolean, Partial, Record, Static, String } from 'runtypes';
import unionize, { ofType } from 'unionize';

export const RefineItem = Record({
  label: String, // optional means it needs to be decoded
  query: String, // optional is equivalent to ''
}).And(
  Partial({
    default: Boolean,
  }),
);
export type RefineItem = Static<typeof RefineItem>;

interface ItemRefineMenu {
  label: string;
  items: RefineItem[];
}

export interface TaxRefineMenu {
  label: string;
  treeLevel: string; // could be fancier query with contextual filters in future
  itemsFilter?: string;
  doFilter: boolean; // defaults to true, can want to turn off when selecting concept
  conceptType?: string;
}

export const RefineMenu = unionize(
  {
    itemMenu: ofType<ItemRefineMenu>(),
    taxMenu: ofType<TaxRefineMenu>(),
  },
  { value: 'field' },
);
export type RefineMenu = typeof RefineMenu._Union;

export function menu__conceptType(menu: RefineMenu): string | undefined {
  if (!RefineMenu.is.taxMenu(menu)) return undefined;
  const taxMenu = RefineMenu.as.taxMenu(menu);
  return taxMenu.conceptType;
}
