import { createRouter, vocabulary } from '@tuple-health/common/dist/router';
import { createElement } from 'react';
import { HomeIcon, MailIcon } from '../../../components/icons/icons';
import { routerKinds } from '../../platform/v1PlatformConcepts';

// =============================================================================
// locs
// =============================================================================

const locs = vocabulary({
  me: undefined,
  me_conversations: undefined,
  providers: undefined,
  provider: undefined,
  provider_report: undefined,
  patients: undefined,
  patient: undefined,
  patient_timeline: undefined,
  patient_goals: undefined,
  ed: undefined,
  ed_utilization: undefined,
  ed_costs: undefined,
  ed_outliers: undefined,
  ed_insights: undefined,
  ed_performance: undefined,
  hospitalizations: undefined,
});

export type HipLoc = typeof locs.Term;

// =============================================================================
// builder
// =============================================================================

const _ = createRouter<HipLoc>('hip', 'Health Improvement', locs);

// =============================================================================
// home
// =============================================================================

_.add(
  _.sideMenu({
    items: [
      _.page({
        loc: 'me',
        icon: createElement(HomeIcon),
        label: 'Home',
      }),
      _.page({
        loc: 'me_conversations',
        icon: createElement(MailIcon),
        slug: 'conversations',
        label: 'Conversations',
      }),
    ],
  }),
);

// =============================================================================
// team
// =============================================================================

const provider = _.concept(
  routerKinds.provider,
  _.sideMenu({
    items: [
      _.page({
        loc: 'provider',
        icon: createElement(HomeIcon),
        label: 'Overview',
      }),
      _.page({
        loc: 'provider_report',
        icon: createElement(MailIcon),
        slug: 'report',
        label: 'Report',
      }),
    ],
  }),
);

_.add(
  _.page({
    loc: 'providers',
    slug: 'team',
    label: 'Team',
    childConcept: provider,
  }),
);

// =============================================================================
// patients
// =============================================================================

const patient = _.concept(
  routerKinds.patient,
  _.sideMenu({
    items: [
      _.page({
        loc: 'patient',
        icon: createElement(HomeIcon),
        label: 'Overview',
      }),
      _.page({
        loc: 'patient_timeline',
        icon: createElement(MailIcon),
        slug: 'timeline',
        label: 'Timeline',
      }),
      _.page({
        loc: 'patient_goals',
        icon: createElement(MailIcon),
        slug: 'goals',
        label: 'Goals',
      }),
    ],
  }),
);

_.add(
  _.page({
    loc: 'patients',
    slug: 'patients',
    label: 'Patients',
    childConcept: patient,
  }),
);

// =============================================================================
// reports
// =============================================================================

const edMenu = _.tabMenu({
  slug: 'ed',
  label: 'ED Visits',
  items: [
    _.page({
      loc: 'ed',
      label: 'Overview',
    }),
    _.page({
      loc: 'ed_utilization',
      slug: 'utilization',
      label: 'Utilization',
    }),
    _.page({
      loc: 'ed_costs',
      slug: 'costs',
      label: 'Costs',
    }),
    _.page({
      loc: 'ed_outliers',
      slug: 'outliers',
      label: 'Outliers',
    }),
    _.page({
      loc: 'ed_insights',
      slug: 'insights',
      label: 'Key Insights',
    }),
    _.page({
      loc: 'ed_performance',
      slug: 'performance',
      label: 'OCM Performance',
    }),
  ],
});

_.add(
  _.folder({
    label: 'Reports',
    items: [
      edMenu,
      _.page({
        loc: 'hospitalizations',
        slug: 'hospitalizations',
        label: 'Hospitalizations',
        visibility: 'tupleStaff',
      }),
    ],
  }),
);

// =============================================================================
// router
// =============================================================================

export const hipRouter = _.build();
