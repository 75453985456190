import { FindByTag } from '@tuple-health/common';
import {
  Boolean,
  Dictionary,
  Literal,
  Number,
  Partial,
  Record,
  Static,
  String,
  Union,
} from 'runtypes';

// =============================================================================
// tables
// =============================================================================

const HeadlineVizConfig = Record({
  type: Literal('headline'),
}).And(
  Partial({
    label: String,
  }),
);
type HeadlineVizConfig = Static<typeof HeadlineVizConfig>;

const ScoreboardVizConfig = Record({
  type: Literal('scoreboard'),
});
type ScoreboardVizConfig = Static<typeof ScoreboardVizConfig>;

const MatrixVizConfig = Record({
  type: Literal('table').Or(Literal('matrix')),
});
type MatrixVizConfig = Static<typeof MatrixVizConfig>;

// =============================================================================
// charts
// =============================================================================

const DonutVizConfig = Record({
  type: Literal('donut'),
});
type DonutVizConfig = Static<typeof DonutVizConfig>;

const CommonBarOptions = Partial({
  vertical: Boolean,
  labelAlign: Union(Literal('start'), Literal('middle'), Literal('end')),
});
export type CommonBarOptions = Static<typeof CommonBarOptions>;

const BarVizConfig = Record({
  type: Literal('bar'),
}).And(CommonBarOptions);
type BarVizConfig = Static<typeof BarVizConfig>;

const StackedBarVizConfig = Record({
  type: Literal('stackedBar'),
}).And(CommonBarOptions);
type StackedBarVizConfig = Static<typeof StackedBarVizConfig>;

const DodgedBarVizConfig = Record({
  type: Literal('dodgedBar'),
}).And(CommonBarOptions);

const SideBySideBarVizConfig = Record({
  type: Literal('sideBySideBar'),
})
  .And(
    Partial({
      position: Union(Literal('stacked'), Literal('dodged')),
    }),
  )
  .And(CommonBarOptions);
type SideBySideBarVizConfig = Static<typeof SideBySideBarVizConfig>;

// groupedBar (or encode via param)

// coloredBar (or encode via param)

const BubbleVizConfig = Record({
  type: Literal('scatter').Or(Literal('bubble')),
}).And(
  Partial({
    identityLine: Boolean,
  }),
);

const BoxVizConfig = Record({
  type: Literal('box'),
});

const BeeswarmVizConfig = Record({
  type: Literal('beeswarm'),
}).And(
  Partial({
    diameter: Number,
  }),
);

// performance vs historical timeline
const TimeSeriesVizConfig = Record({
  type: Literal('timeSeries').Or(Literal('historicalVsPerformanceTimeline')),
}).And(
  Partial({
    areaFocus: Boolean,
  }),
);

// =============================================================================
// arbitrary
// =============================================================================

const VegaVizConfig = Record({
  type: Literal('vega'),
  spec: Record({}),
});
type VegaVizConfig = Static<typeof VegaVizConfig>;

// =============================================================================
// =============================================================================

const ConceptLabelType = Union(Literal('key'), Literal('label'));
export type ConceptLabelType = Static<typeof ConceptLabelType>;

export const VizConfig = Union(
  // tables
  HeadlineVizConfig,
  ScoreboardVizConfig,
  MatrixVizConfig,
  // charts
  DonutVizConfig,
  BarVizConfig,
  StackedBarVizConfig,
  DodgedBarVizConfig,
  SideBySideBarVizConfig,
  BubbleVizConfig,
  BoxVizConfig,
  BeeswarmVizConfig,
  TimeSeriesVizConfig,
  // arbitrary
  VegaVizConfig,
).And(
  Partial({
    hideLegend: Boolean,
    countLabel: Boolean.Or(String),
    conceptLabeling: Dictionary(
      Partial({
        guide: ConceptLabelType,
        tooltip: ConceptLabelType,
      }),
    ),
    config: Record({}),
  }),
);
type VizConfigUnion = Static<typeof VizConfig>;

export type VizType = VizConfigUnion['type'];

type VizConfigLookup = { [K in VizType]: FindByTag<VizConfigUnion, K, 'type'> };

export type VizConfig<K extends VizType = VizType> = VizConfigLookup[K];

type NonChartType =
  // tables
  'headline' | 'scoreboard' | 'table' | 'matrix';

export type ChartType = Exclude<VizType, NonChartType>;
