import { Concept } from '@tuple-health/common/dist/data';
import { gatewayCookies } from '@tuple-health/common/dist/gateway';
import { $app, Route, RouteContext, Router, RouterPath } from '@tuple-health/common/dist/router';
import { createSearcher, Searcher } from '@tuple-health/common/dist/searcher';
import * as _ from '@tuple-health/eng/dist/dryscript/ds';
import { GatewayApi } from '@tuple-health/eng/dist/th/ds/common/agent/gatewayApi/api/GatewayApi';
import { GatewaySession } from '@tuple-health/eng/dist/th/ds/common/agent/gatewayApi/service/session/GatewaySession';
import { Customer } from '@tuple-health/eng/dist/th/ds/common/domain/customer/customer/Customer';
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { SidebarRouteCreator } from '../../components/messaging/userDrawer/useSidebarRoute';
import { Authn } from '../../contracts/Authn';
import { Gateway } from '../../contracts/Gateway';
import { Persistence } from '../../contracts/Persistence';
import { ResolverContext } from '../../core/resolver';
import { CookieProvider } from '../../core/store/useCookie';
import { GatewayProvider } from '../../core/store/useGateway';
import { InitialConvoCreator } from '../../core/store/useInitialConvo';
import { Linker, LinkerProvider } from '../../core/store/useLinker';
import { NavMenuProvider } from '../../core/store/useNavMenu';
import { SearchAgent, SearchProvider } from '../../core/store/useSearch';
import useUrlFragment, { UrlFragmentCreator } from '../../core/store/useUrlFragment';
import { UserProvider } from '../../core/store/useUser';
import { UserDrawerCreator } from '../../core/store/useUserDrawer';
import { VisibilityCreator } from '../../core/store/useVisibility';
import { PushHandlerCreator } from '../../pushHandler/actor/pushHandler.actor';
import { submitRequest } from '../../services/query/query.cache';
import { SendQueryCreator } from '../../services/query/useRunQuery';
import { RunRequest, SendRequestCreator } from '../../services/query/useRunRequest';
import { UserConfCreator } from '../../user/userConf/actor/userConf.actor';
import { key__productApp } from '../apps/allApps';
import { CustomerLoc, customerRouter } from '../apps/customer/customer.router';
import customerApp from '../apps/customer/customerApp';
import { UserLoc, userRouter } from '../apps/user/user.router';
import userApp from '../apps/user/userApp';
import { routerKinds } from '../platform/v1PlatformConcepts';
import { App } from './app.model';
import Platform from './Platform';
import { adaptPlatformCustomer, adaptPlatformProduct, adaptPlatformSession } from './session.adapt';
import UserRouteContext from './UserRouteContext';

// =============================================================================
// old screen
// =============================================================================

export default function PlatformOldScreen(_props: { path: string }) {
  // resolving from legacy injection
  const resolve = useContext(ResolverContext);
  if (!resolve) throw Error('Unable to find resolver context');

  // Hack: there's no good reason this should be changing, so force
  // it to memoize
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const gateway = useMemo(() => resolve(Gateway), []);

  const cookie = resolve(Persistence);
  const authn = resolve(Authn);
  // no platform when not logged in
  if (!Authn.is.session(authn)) return null;

  const { session } = Authn.as.session(authn);
  const logout = authn.initiateLogout;

  [...session.customerRecords].forEach(r => {
    const keys = r.data.productKeys as any;
    if (r.key! === 'atlantis' || r.key! === 'tuple') keys.items.add('vcm'); // items is a ValueSet
  });

  return (
    <UrlFragmentCreator>
      <PlatformScreen gateway={gateway} cookie={cookie} session={session} logout={logout} />
    </UrlFragmentCreator>
  );
}

// =============================================================================
// old screen
// =============================================================================

interface Props {
  gateway: GatewayApi;
  cookie: Persistence;
  session: GatewaySession;
  logout: () => void;
}

function PlatformScreen({ gateway, cookie, session, logout }: Props) {
  const { fragment, setFragment } = useUrlFragment();

  // ===========================================================================
  // user
  // ===========================================================================

  const userConcepts = useMemo(
    () => adaptPlatformSession(session).customers.map(c => c.customerConcept),
    [session],
  );

  const userSearcher = useMemo<Searcher<any>>(() => createSearcher(userRouter, userConcepts), [
    userConcepts,
  ]);

  const userRoute: Route<UserLoc> | undefined = useMemo(() => {
    if (!fragment || !fragment.startsWith(platformPrefix)) return undefined;
    validatePathQuery(fragment);
    const pathQuery = fragment.substring(platformPrefix.length);

    const userRoute = userRouter.parseUrlText(pathQuery);
    // console.log(
    //   'parsed userRoute',
    //   userRoute,
    //   ', overflowPathQuery = ',
    //   userRoute && userRoute.overflowPathQuery,
    // );

    return userRoute;
  }, [fragment]);

  // ===========================================================================
  // customer
  // ===========================================================================

  const [customerRecord, _setCustomerRecord] = useState<_.Record<Customer> | undefined>(() => {
    if (![...session.customerRecords].length) return;

    const cookieCustomerId = cookie.load(gatewayCookies.clientSideCustomerId);
    const customerRecord = cookieCustomerId
      ? session.id__customerRecord.call(cookieCustomerId)
      : undefined;
    const initialCustomerRecord = customerRecord || session.customerRecords.anItem;
    return initialCustomerRecord;
  });
  const setCustomerRecord = useCallback(
    (newCustomerRecord?: _.Record<Customer>) => {
      if (customerRecord !== newCustomerRecord) _setCustomerRecord(newCustomerRecord);
    },
    [customerRecord],
  );

  // sync state to cookie
  useEffect(() => {
    if (customerRecord) cookie.save(gatewayCookies.clientSideCustomerId, customerRecord.id);
  }, [cookie, customerRecord]);

  const runRequest: RunRequest = useCallback(
    request => {
      if (!customerRecord) throw Error('no customer');
      return submitRequest({
        gateway,
        session,
        customerId: customerRecord.id,
        request,
      });
    },
    [customerRecord, gateway, session],
  );

  const customerConcepts = useMemo<Concept[] | undefined>(() => {
    if (!customerRecord) return undefined;
    return [...customerRecord.data.productKeys].map(adaptPlatformProduct);
  }, [customerRecord]);

  const customerSearcher = useMemo<Searcher<any> | undefined>(
    () => createSearcher(customerRouter, customerConcepts || []),
    [customerConcepts],
  );

  const customerRoute: Route<CustomerLoc> | undefined = useMemo(() => {
    if (!userRoute) {
      return undefined;
    }
    if (userRoute.loc !== 'org') return undefined;
    const customerRoute = customerRouter.parseUrlText(userRoute.overflowPathQuery!, userRoute.path);
    return customerRoute;
  }, [userRoute]);

  // ===========================================================================
  // product
  // ===========================================================================

  const initialProductKey = useMemo(() => {
    if (!customerRecord) return undefined;
    const productKeys = [...customerRecord.data.productKeys];
    if (!productKeys.length) return undefined;

    // default to the current hash
    // (important not to argue with current hash or will cause a loop)
    const match = fragment.match(/\/apps\/([^/]*)/);
    const urlProductKey = match && match[1];
    if (urlProductKey) return urlProductKey;

    // otherwise fall back to cookie
    const cookieProductKey = cookie.load(gatewayCookies.clientSideProductKey);
    if (cookieProductKey && productKeys.indexOf(cookieProductKey) > -1) return cookieProductKey;

    // else randomly pick
    return productKeys[0];
  }, [cookie, customerRecord, fragment]);

  const [productKey, _setProductKey] = useState<string | undefined>(initialProductKey);
  const setProductKey = useCallback(
    (newProductKey?: string) => {
      if (productKey !== newProductKey) {
        // console.log(`productKey: ${productKey}, newProductKey: ${newProductKey}`);
        _setProductKey(newProductKey);
      }
    },
    [productKey],
  );

  // sync state to cookie
  useEffect(() => {
    if (productKey) cookie.save(gatewayCookies.clientSideProductKey, productKey);
  }, [cookie, productKey]);

  // update state when initial product key recomputes (presumably only happens when customer changes)
  useEffect(() => {
    // console.log(`initialProductKey updated: ${initialProductKey}`);
    setProductKey(initialProductKey);
  }, [initialProductKey, setProductKey]);

  const productApp = useMemo<App<any> | undefined>(() => {
    if (!productKey) return undefined;
    return key__productApp[productKey];
  }, [productKey]);

  const productRouter = useMemo<Router<any> | undefined>(() => {
    if (!productApp) return undefined;
    return productApp.router;
  }, [productApp]);

  // TODO hack, separating searching concepts from routing concepts, because we don't want
  // routing to update, causing a screen reload, when new searching concepts arrive
  // TODO real solution is to fix the router by decoupling decoding from routing
  const [productSearchingConcepts, setProductSearchingConcepts] = useState<Concept[]>([]);
  useEffect(() => {
    setProductSearchingConcepts([]);
    if (!productApp) return;
    let active = true;
    // needed to collect concepts that arrive synchronously before productConcepts gets updated
    const loadedConcepts: Concept[] = [];
    productApp.lookupSearchingConcepts(
      session,
      runRequest,
      concepts => {
        // note that we append as concepts flow in
        // also note that if depended on productConcepts here, it causes a loop
        loadedConcepts.push(...concepts);
        active && setProductSearchingConcepts(loadedConcepts);
      },
      customerRecord,
    );
    return () => {
      active = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customerRecord, productApp]);

  const productSearcher = useMemo<Searcher<any> | undefined>(() => {
    if (!productApp) return undefined;
    const allSearchingConcepts: Concept[] = [];
    if (productApp.routingConcepts) allSearchingConcepts.push(...productApp.routingConcepts);
    if (productSearchingConcepts) allSearchingConcepts.push(...productSearchingConcepts);
    return createSearcher(productApp.router, allSearchingConcepts);
  }, [productApp, productSearchingConcepts]);

  const productRoute = useMemo<Route<any> | undefined>(() => {
    if (!customerRoute) {
      // console.log('no customerRoute');
      return undefined;
    }
    if (customerRoute.loc !== 'app') {
      // console.log('customerRoute not at app, was:', customerRoute.loc);
      return undefined;
    }
    if (!productRouter) {
      // console.log('no productRouter');
      return undefined;
    }

    // TODO review, crucial to always parse the route right which
    // which means we must have productDecode so we can always parseRoute
    // which means we need to decouple parsing from decoding (should be done afterwards asynchronously)
    return productRouter.parseUrlText(customerRoute.overflowPathQuery!, customerRoute.path);
  }, [customerRoute, productRouter]);

  // ===========================================================================
  // screen
  // ===========================================================================

  const { screenApp, screenRoute, screenCrumbSearch } = useMemo(() => {
    if (productRoute)
      return {
        screenApp: productApp!,
        screenRoute: productRoute,
        screenCrumbSearch: productSearcher!,
      };
    if (customerRoute)
      return {
        screenApp: customerApp,
        screenRoute: customerRoute,
        screenCrumbSearch: customerSearcher!,
      };
    if (userRoute)
      return { screenApp: userApp, screenRoute: userRoute, screenCrumbSearch: userSearcher! };
    return { screenApp: undefined, screenRoute: undefined, screenCrumbSearch: undefined };
  }, [
    customerRoute,
    customerSearcher,
    productApp,
    productRoute,
    productSearcher,
    userRoute,
    userSearcher,
  ]);

  const Screen = useMemo(() => {
    if (!screenRoute) return undefined;
    const screenComponent = screenApp!.screens[screenRoute.loc];
    if (!screenComponent) throw Error(`did not have a screen for loc: ${screenRoute.loc}`);
    return { Screen: screenComponent };
  }, [screenApp, screenRoute]);

  const navMenu = useMemo((): RouteContext<any> => {
    if (productRoute) return productRoute;
    if (productRouter) return productRouter.defaultContext;
    if (customerRoute) return customerRoute;
    if (customerRecord) return customerRouter.defaultContext;
    return userRouter.defaultContext;
  }, [customerRecord, customerRoute, productRoute, productRouter]);

  const searcher = useMemo(
    (): SearchAgent => ({
      globalSearch: (text: string) => {
        // no customer pages, so no point to search them
        // user has pages, but also customer names, so not including for now
        // TODO want better ui
        if (!productSearcher) return new Promise(resolve => resolve([]));
        return productSearcher.globalSearch(text);
      },
      crumbSearch: screenCrumbSearch ? screenCrumbSearch.crumbSearch : (undefined as any), // TODO hack where we don't have a userRoute (so 404)
      uri__label: uri => {
        if (!productSearcher) return uri.id;
        return productSearcher.uri__label(uri);
      },
    }),
    [productSearcher, screenCrumbSearch],
  );

  // ===========================================================================
  // linker
  // ===========================================================================

  const writeRoute = useCallback(
    (route: Route<any>, overrides?: PlatformContainerOverrides): string => {
      // already have a container
      if (route.containerPath) return route.text;

      // user routes
      if (route.router === userRouter) return route.text;

      // customer container route
      const routeCustomerRecord =
        overrides && overrides.customerId
          ? session.id__customerRecord.call(overrides.customerId)
          : customerRecord;
      if (!routeCustomerRecord) throw Error('could not find customer recorrd');
      const customerContainerRoute = userRouter.route('org', {
        uri: adaptPlatformCustomer(routeCustomerRecord).customerConcept,
      });

      // customer app route
      if (route.router === customerRouter) {
        return customerContainerRoute.path.text + (route.text.length ? '/' + route.text : '');
      }

      // product route container
      const routeProductConcept =
        overrides && overrides.productKey
          ? adaptPlatformProduct(overrides.productKey)
          : productRouter
          ? productRouter.appConcept
          : undefined;
      if (!routeProductConcept) throw Error('could not find customer recorrd');
      const productContainerRoute = customerRouter.route('app', {
        uri: routeProductConcept,
        containerPath: customerContainerRoute.path, // TODO is containerPath deprecated?
      });

      // product route
      return productContainerRoute.path.text + (route.text.length ? '/' + route.text : '');
    },
    [customerRecord, productRouter, session.id__customerRecord],
  );

  const linker = useMemo((): Linker => {
    const linker: Linker = {
      routeHref: (route: Route<any>, overrides?: PlatformContainerOverrides) => {
        let href = platformPrefix;
        const routeText = writeRoute(route, overrides);
        if (routeText.length) href += '/' + routeText;
        return href;
      },
      navText: setFragment,
      navRoute: (route: Route<any>, overrides?: PlatformContainerOverrides) => {
        // updateFragment(linker.routeHref(route, overrides));
        linker.navText(linker.routeHref(route, overrides));
      },
      navPath: (path: RouterPath<any>, overrides?: PlatformContainerOverrides) => {
        linker.navRoute(path.router.routeFromPath(path), overrides);
      },
      navCustomerId: (customerId: string) => {
        const customerRecord = session.id__customerRecord.call(customerId);
        const { customerConcept } = adaptPlatformCustomer(customerRecord);
        linker.navRoute(userRouter.route('org', { uri: customerConcept }));
      },
      navProductKey: (productKey: string) => {
        // note that this enforces that the customer has the productKey
        if (!customerRecord) return;
        if (![...customerRecord.data.productKeys].find(k => k === productKey)) return;
        const productConcept = adaptPlatformProduct(productKey);
        linker.navRoute(customerRouter.route('app', { uri: productConcept }));
      },
      routeButton: (route: Route<any>, beforeNav?: () => void) => ({
        onClick: (e: React.MouseEvent) => {
          if (beforeNav) beforeNav();
          e.preventDefault();
          linker.navRoute(route);
        },
      }),
      pathButton: (path: RouterPath<any>, beforeNav?: () => void) =>
        linker.routeButton(path.router.routeFromPath(path), beforeNav),
      routeLink: (route: Route<any>) => ({
        href: linker.routeHref(route),
      }),
      pathLink: (path: RouterPath<any>) => linker.routeLink(path.router.routeFromPath(path)),
    };
    globalLinker = linker; // TODO hack for test environment
    return linker;
  }, [customerRecord, session.id__customerRecord, setFragment, writeRoute]);

  // ===========================================================================
  // nav
  // ===========================================================================

  useEffect(() => {
    if (!fragment.startsWith(platformPrefix)) return;
    validatePathQuery(fragment);

    // update customer (must happen before update product)
    if (userRoute && userRoute.loc === 'org') {
      const customerUri = userRoute.ns__uri[routerKinds.customerSlug.ns];
      const newCustomerRecord = session.slug__customerRecord.call(customerUri.id);
      setCustomerRecord(newCustomerRecord);
    }

    // update product
    if (customerRoute && customerRoute.loc === 'app') {
      // console.log(
      //   `route product key updated: ${customerRoute.ns__uri[conceptTypes.appKey.ns].id}`,
      // );
      setProductKey(customerRoute.ns__uri[$app.ns].id);
    }
  }, [
    customerRoute,
    fragment,
    session.slug__customerRecord,
    setCustomerRecord,
    setProductKey,
    userRoute,
  ]);

  // useDebugChanges({
  //   label: 'PlatformOldScreen',
  //   values: {
  //     linker,
  //     writeRoute,
  //     fragment,
  //     userRoute,
  //     userConcepts,
  //     customerRecord,
  //     customerRoute,
  //     customerConcepts,
  //     productRoute,
  //     productRoutingDecode,
  //     productSearchingConcepts,
  //     productRouter,
  //     productKey,
  //     productApp,
  //     Screen: Screen ? Screen.Screen : undefined,
  //   },
  // });
  // console.log('fragment', fragment);
  // console.log('userRoute', userRoute && userRoute.text + ' ' + userRoute.loc);
  // console.log('customerRoute', customerRoute && customerRoute.text + ' ' + customerRoute.loc);
  // console.log('productRoute', productRoute && productRoute.text + ' ' + productRoute.loc);

  return (
    <CookieProvider value={cookie}>
      <GatewayProvider value={gateway}>
        <VisibilityCreator>
          <UserDrawerCreator>
            <InitialConvoCreator>
              <NavMenuProvider value={navMenu}>
                <SearchProvider value={searcher}>
                  <LinkerProvider value={linker}>
                    <UserProvider session={session} logout={logout}>
                      <UserRouteContext.Provider value={userRoute}>
                        <UserConfCreator>
                          <PushHandlerCreator>
                            <SidebarRouteCreator>
                              <SendRequestCreator>
                                <SendQueryCreator>
                                  <Platform
                                    session={session}
                                    navMenu={navMenu}
                                    screenRoute={screenRoute}
                                    customerRecord={customerRecord}
                                    customerRoute={customerRoute}
                                    productConcept={
                                      productRouter ? productRouter.appConcept : undefined
                                    }
                                    productRoute={productRoute}
                                    screenKey={fragment} // TODO in future only use path as screenKey, and let screen handle query changes
                                    Screen={Screen ? Screen.Screen : undefined}
                                  />
                                </SendQueryCreator>
                              </SendRequestCreator>
                            </SidebarRouteCreator>
                          </PushHandlerCreator>
                        </UserConfCreator>
                      </UserRouteContext.Provider>
                    </UserProvider>
                  </LinkerProvider>
                </SearchProvider>
              </NavMenuProvider>
            </InitialConvoCreator>
          </UserDrawerCreator>
        </VisibilityCreator>
      </GatewayProvider>
    </CookieProvider>
  );
}

let globalLinker: Linker | undefined;
export const getGlobalLinker = (): Linker => {
  if (!globalLinker) throw Error('globalLinker not defined');
  return globalLinker;
};

// =============================================================================
// store
// =============================================================================

export interface PlatformContainerOverrides {
  customerId?: string;
  productKey?: string;
}

// =============================================================================
// lib
// =============================================================================

const platformPathName = 'menu';
const platformPrefix = `#/${platformPathName}`;

function validatePathQuery(pathQuery: string) {
  if (!pathQuery.startsWith(platformPrefix))
    throw Error(`pathQuery "${pathQuery}" did not start with platform prefix "${platformPrefix}"`);
}
