import { Uri } from '@tuple-health/common/dist/data';
import { CrumbSearch, GlobalSearch } from '@tuple-health/common/dist/searcher';
import { createContext, useContext } from 'react';

export interface SearchAgent {
  globalSearch: GlobalSearch<any>;
  crumbSearch: CrumbSearch<any>;
  uri__label: (uri: Uri) => string;
}

const Context = createContext<SearchAgent | undefined>(undefined);

export const SearchProvider = Context.Provider;

export default function useSearch() {
  const context = useContext(Context);
  if (!context) throw Error('router context not provided');
  return context;
}
