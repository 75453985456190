import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core';
import BarChartIcon from '@material-ui/icons/BarChart';
import PeopleIcon from '@material-ui/icons/People';
import React, { ReactElement, useCallback, useState } from 'react';
import { ClinicianIcon, HospitalIcon } from '../../components/icons/icons';
import useAutoWelcome, { setHasSeenWelcome } from './useAutoWelcome';
import css from './WelcomeDialog.css';

export default function WelcomeDialog() {
  const [isOpen, setOpen] = useState<boolean | undefined>(undefined);
  const openDialog = useCallback(() => setOpen(true), []);
  const closeDialog = useCallback(() => {
    setHasSeenWelcome(true);
    setOpen(false);
  }, []);

  useAutoWelcome(openDialog);

  return (
    <Dialog classes={{ paper: css.paper }} open={Boolean(isOpen)} onClose={closeDialog}>
      {renderMain()}
      {renderAboutUs()}
    </Dialog>
  );

  function renderMain() {
    return (
      <div>
        <DialogTitle>
          <span className={css.title}>Welcome to Tuple Health</span>
        </DialogTitle>
        <DialogContent className={css.content}>
          <p>Here you'll find insights on:</p>
          <div className={css.categories}>
            {renderCategory(<HospitalIcon />, 'Org-level data')}
            {renderCategory(<ClinicianIcon />, 'Clinician-level data')}
            {renderCategory(<PeopleIcon />, 'Patient cases')}
            {renderCategory(<BarChartIcon />, 'Custom analyses')}
          </div>
          <p>
            And much more. But don’t worry — it won’t take a data science degree to understand it
            all. Your passion for patient care is more than enough.
          </p>
        </DialogContent>
        <DialogActions className={css.actions}>
          <Button variant="contained" color="primary" onClick={closeDialog}>
            Let's go
          </Button>
        </DialogActions>
      </div>
    );
  }

  function renderCategory(icon: ReactElement, label: string) {
    return (
      <div>
        <div className={css.categoryIcon}>{icon}</div>
        <div className={css.categoryLabel}>{label}</div>
      </div>
    );
  }

  function renderAboutUs() {
    return (
      <div className={css.aboutUs}>
        <h2>About us</h2>
        <p>
          Tuple is physician-led and dedicated to a future where the miracle of medicine is again
          filled with purpose and is a joy to deliver.
        </p>
      </div>
    );
  }
}
