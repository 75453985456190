// https://stackoverflow.com/a/31479176/231853

type BrowserName = 'opera' | 'firefox' | 'safari' | 'chrome' | 'ie' | 'edge' | 'blink' | undefined;

const win: any = window;
const winNav: any = window.navigator;

// https://stackoverflow.com/questions/4565112/javascript-how-to-find-out-if-the-user-browser-is-chrome/13348618#13348618
const method2 = {
  isChromium: win.chrome,
  winNav: win.navigator,
  vendorName: winNav.vendor,
  isOpera: typeof win.opr !== 'undefined',
  isIEedge: winNav.userAgent.indexOf('Edge') > -1,
  isIOSChrome: winNav.userAgent.match('CriOS'),
  isAnyChrome: false,
};
if (method2.isIOSChrome) {
  // is Google Chrome on IOS
  method2.isAnyChrome = true;
} else if (
  method2.isChromium !== null &&
  typeof method2.isChromium !== 'undefined' &&
  method2.vendorName === 'Google Inc.' &&
  method2.isOpera === false &&
  method2.isIEedge === false
) {
  // is Google Chrome
  method2.isAnyChrome = true;
} else {
  // not Google Chrome
  method2.isAnyChrome = false;
}

// Opera 8.0+
const isOpera =
  (!!win.opr && !!win.opr.addons) || !!win.opera || navigator.userAgent.indexOf(' OPR/') >= 0;

// Firefox 1.0+
const isFirefox = win.InstallTrigger !== undefined;

// Internet Explorer 6-11
export const isIE = !!win.document.documentMode;

// Edge 20+
const isEdge = !isIE && !!win.StyleMedia;

// Chrome 1+
export const isChrome = method2.isAnyChrome || (!!win.chrome && !!win.chrome.webstore);

// Blink engine detection
const isBlink = (isChrome || isOpera) && !!win.CSS;

// https://stackoverflow.com/a/39621764/231853
const isSafari =
  Object.prototype.toString.call(win.HTMLElement).indexOf('Constructor') > 0 ||
  (!isChrome && !isOpera && win.webkitAudioContext !== undefined);

const browser: BrowserName = isOpera
  ? 'opera'
  : isFirefox
  ? 'firefox'
  : isSafari
  ? 'safari'
  : isChrome
  ? 'chrome'
  : isIE
  ? 'ie'
  : isEdge
  ? 'edge'
  : isBlink
  ? 'blink'
  : undefined;

export default browser;

export const isMac = !!navigator.platform.match(/Mac/i);
