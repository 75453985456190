import { BpciaLoc, bpciaRouter } from '../../../platform/apps/bpcia/bpcia.router';
import { key__dataset } from '../dataset/dataset.model';
import { key__timePeriod } from '../dataset/timePeriod.model';
import { toAnalyticsProduct } from '../model/analyticsProductModel';

// =============================================================================

const defaultBpciaMarkup = `\
\`\`\`{article}
dataset: bpciaDefault
timePeriod: bpciaBaseline
\`\`\`

# Untitled Data Story

## Untitled Chapter

### Untitled Text Slide

Hello!

### Untitled Data Slide

\`\`\`{dataviz}
query: |
  CALC bpciaEpisode.count
\`\`\`
`;

// =============================================================================

// TODO if we add to this, go back and backwards compat old filtered stories but explicitly
// setting theier filter list
const bpciaDefaultLevelKeys: string[] = [
  // 'bpciaEpisodeSummary.has90DaysElapsed',
  // 'bpciaEpisodeSummary.isComplete',
  'bpciaEpisodeSummary.performancePeriod.1',
  'bpciaAnchorAttendingClinician.npi',
  'bpciaAnchorOperatingClinician.npi',
  'bpciaEpisodeSummary.hadEdVisit',
  'bpciaEpisodeSummary.hadIrfAdmission',
  'bpciaEpisodeSummary.hadSnfStay',
  'bpciaEpisodeSummary.hadLtcAdmission',
  'bpciaEpisodeSummary.hadHomeHealth',
  'bpciaEpisodeSummary.hadBpciaReadmission',
];

// =============================================================================
// analytics
// =============================================================================

export const bpciaAnalyticsProduct = toAnalyticsProduct<BpciaLoc>({
  key: 'bpcia',
  // router
  router: bpciaRouter,
  globalsLoc: 'globals',
  articleDetailLoc: 'bpcia_article_detail',
  clinicianHomeLoc: 'bpcia_clinician_overview',
  patientHomeLoc: 'bpcia_patient_timeline',
  patientTimelineLoc: 'bpcia_patient_timeline',
  queryId__episodeExplorerRoute: queryId =>
    bpciaRouter.route('bpcia_episodes', { params: { cohort: queryId } }),
  //
  defaultCatalogName: 'bpciaEpisode',
  defaultDataset: key__dataset.bpciaDefault,
  datasets: [
    key__dataset.bpciaDefault,
    key__dataset.bpciaActive,
    key__dataset.bpciaCompletedOrActive,
    key__dataset.bpciaEnrolledUnfiltered,
    key__dataset.bpciaUnfiltered,
  ],
  timePeriods: [
    key__timePeriod.bpciaBaseline,
    key__timePeriod.bpciaPP1,
    key__timePeriod.bpciaBaselineToPP1,
    key__timePeriod.bpciaPP2,
    key__timePeriod.bpciaBaselineToPP2,
    key__timePeriod.bpciaPP1ToPP2,
  ],
  timeTree: 'bpciaEpisodeSummary.performancePeriod',
  initialArticleMarkup: defaultBpciaMarkup,
  defaultFilterLevelKeys: bpciaDefaultLevelKeys,
  // dataviz config:
  catalogKey: 'catalogBpciaEpisode',
  patientRelnKey: 'bpciaEpisodeSummaryPatientSnapshot',
  episodeRelnKey: 'bpciaEpisodeSummary',
  showOcmPeriods: false,
});
