import React, { lazy } from 'react';
import {
  customArticleScreen,
  genericArticleScreen,
} from '../../../components/article/articleScreens';
import { bpciaAnalyticsProduct } from '../../../components/product/analytics/analyticsProductBpci';
import useLinker from '../../../core/store/useLinker';
import useProduct from '../../../core/store/useProduct';
import { App } from '../../platform/app.model';
import { adaptScreen, defineScreen } from '../../platform/screen.model';
import { lookupConceptsFromV1Query, routerKinds } from '../../platform/v1PlatformConcepts';
import { bpciaDb } from './bpcia.db';
import { BpciaLoc, bpciaRouter } from './bpcia.router';

const bpcia_customer_home = lazy(() =>
  import(/* webpackChunkName: "bpcia_home" */ '../../../screens/homeBpcia/BpciaHomeScreen'),
);

const bundleAndFacility = lazy(() =>
  import(
    /* webpackChunkName: "bpcia_bundleAndFacilityHome" */ '../../../screens/homeBpciaBundleAndFacility/BpciaBundleAndFacilityHomeScreen'
  ),
);

const BpciaClinicianListScreen = lazy(() =>
  import(
    /* webpackChunkName: "bpcia_clinician_list" */ '../../../components/clinicianList/bpciaClinicianList/BpciaClinicianList'
  ),
);

const bpcia_article_list = lazy(
  /* webpackChunkName: "article_list" */ () =>
    import('../../../screens/articleList/articleList.screen'),
);

const BpciaEpisodes = lazy(
  /* webpackChunkName: "bpcia_episodes" */ () =>
    import('../../../screens/patients/bpcia/BpciaEpisodes'),
);

const bpcia_patient_timeline = lazy(() =>
  import(
    /* webpackChunkName: "patient_timeline" */ '../../../components/timeline/view/TimelineScreen'
  ),
);

const facilities = defineScreen(() => {
  const linker = useLinker();
  return (
    <div>
      <h2 style={{ marginTop: 15, marginLeft: 15 }}>Facilities</h2>
      <ul>
        {bpciaDb.bpciaFacilities.map((concept, i) => (
          <li key={i}>
            <a {...linker.routeLink(bpciaRouter.route('facility', { uri: concept }))}>
              {concept.labelOrId}
            </a>
          </li>
        ))}
      </ul>
    </div>
  );
});

const bundles = defineScreen(() => {
  const linker = useLinker();
  return (
    <div>
      <h2 style={{ marginTop: 15, marginLeft: 15 }}>BPCI-A Bundles</h2>
      <ul>
        {bpciaDb.bpciaBundles.map((concept, i) => (
          <li key={i}>
            <a {...linker.routeLink(bpciaRouter.route('bundle', { uri: concept }))}>
              {concept.labelOrId}
            </a>
          </li>
        ))}
      </ul>
    </div>
  );
});

const bundle_facilities = defineScreen(() => {
  const linker = useLinker();
  const { productRoute } = useProduct();
  const bundleUri = productRoute.ns__uri[routerKinds.bpciaBundle.ns];
  return (
    <div>
      <h2 style={{ marginTop: 15, marginLeft: 15 }}>Facilities</h2>
      <ul>
        {bpciaDb.bpciaFacilities.map((facility, i) => (
          <li key={i} style={{ borderRight: '1px solid #ccc' }}>
            <a
              {...linker.routeLink(
                bpciaRouter.route('bundleAndFacility', { uris: [bundleUri, facility] }),
              )}
            >
              {facility.labelOrId}
            </a>
          </li>
        ))}
      </ul>
    </div>
  );
});

const bundle = bundle_facilities;

const facility_bundles = defineScreen(() => {
  const linker = useLinker();
  const { productRoute } = useProduct();
  const facilityUri = productRoute.ns__uri[routerKinds.hospital.ns];
  return (
    <div>
      <h2 style={{ marginTop: 15, marginLeft: 15 }}>BPCI-A Bundles</h2>
      <ul>
        {bpciaDb.bpciaBundles.map((bundle, i) => (
          <li key={i} style={{ borderRight: '1px solid #ccc' }}>
            <a
              {...linker.routeLink(
                bpciaRouter.route('bundleAndFacility', { uris: [bundle, facilityUri] }),
              )}
            >
              {bundle.labelOrId}
            </a>
          </li>
        ))}
      </ul>
    </div>
  );
});

const facility = facility_bundles;

const bpciaApp: App<BpciaLoc> = {
  router: bpciaRouter,
  routingConcepts: bpciaDb.allConcepts,
  lookupSearchingConcepts: async (_session, runRequest, handle, customerRecord) => {
    try {
      // clinicians
      if (customerRecord!.key !== 'atlantis') {
        const clinicians = await lookupConceptsFromV1Query(
          runRequest,
          bpciaAnalyticsProduct,
          'bpciaAnchorAttendingClinician.npi',
          routerKinds.clinician,
        );
        handle(clinicians);
        // console.log('bpcia clinicians: ', clinicians.length);
      }
    } catch (e) {} // TODO causing errors in tests
  },
  screens: {
    bpcia_customer_home,
    globals: genericArticleScreen('globals'),
    // =========================================================================
    // folders
    // =========================================================================
    folders: bpcia_article_list,
    // =========================================================================
    // article
    // =========================================================================
    bpcia_article_list,
    bpcia_article_detail: customArticleScreen,
    // =========================================================================
    // patient
    // =========================================================================
    bpcia_episodes: () => <BpciaEpisodes />,
    bpcia_patient_timeline,
    // =========================================================================
    // clinician
    // =========================================================================
    bpcia_clinician_list: adaptScreen(BpciaClinicianListScreen),
    bpcia_clinician_overview: genericArticleScreen('bpcia_clinician_overview'),
    // =========================================================================
    // program overview
    // =========================================================================
    bpcia_crossbundle_baseline_overview: genericArticleScreen(
      'bpcia_crossbundle_baseline_overview',
    ),
    bpcia_crossbundle_baseline_utilization: genericArticleScreen(
      'bpcia_crossbundle_baseline_utilization',
    ),
    bpcia_crossbundle_pp_overview: genericArticleScreen('bpcia_crossbundle_pp_overview'),
    bpcia_methodology: genericArticleScreen('bpcia_methodology'),
    // =========================================================================
    // facility
    // =========================================================================
    facilities,
    facility,
    facility_patients: () => <BpciaEpisodes />,
    // =========================================================================
    // bundle
    // =========================================================================
    bundles,
    bundle,
    bundle_patients: () => <BpciaEpisodes />,
    bundle_facilities,
    // =========================================================================
    // bundle and facility
    // =========================================================================
    bundleAndFacility,
    bundleAndFacility_patients: () => <BpciaEpisodes />,
    // ===================================
    // bundleAndFacility.episodes
    // ===================================
    bpcia_episodes_metrics: genericArticleScreen('bpcia_episodes_metrics'),
    bpcia_episodes_drgs: genericArticleScreen('bpcia_episodes_drgs'),
    bpcia_episodes_clinicians: genericArticleScreen('bpcia_episodes_clinicians'),
    bpcia_episodes_performance: genericArticleScreen('bpcia_episodes_performance'),
    bpcia_patient_explorer: genericArticleScreen('bpcia_patient_explorer'),
    // ===================================
    // bundleAndFacility.anchor
    // ===================================
    bpcia_anchors_summary: genericArticleScreen('bpcia_anchors_summary'),
    bpcia_anchors_drgs: genericArticleScreen('bpcia_anchors_drgs'),
    bpcia_anchors_outliers: genericArticleScreen('bpcia_anchors_outliers'),
    // ===================================
    // bundleAndFacility.readmits
    // ===================================
    bpcia_readmits_overview: genericArticleScreen('bpcia_readmits_overview'),
    bpcia_readmits_utilization: genericArticleScreen('bpcia_readmits_utilization'),
    bpcia_readmits_costs: genericArticleScreen('bpcia_readmits_costs'),
    bpcia_readmits_outliers: genericArticleScreen('bpcia_readmits_outliers'),
    // bpcia_readmits_insights: genericArticleScreen('bpcia_readmits_insights'),
    // ===================================
    // bundleAndFacility.ed
    // ===================================
    bpcia_ed_overview: genericArticleScreen('bpcia_ed_overview'),
    bpcia_ed_utilization: genericArticleScreen('bpcia_ed_utilization'),
    bpcia_ed_costs: genericArticleScreen('bpcia_ed_costs'),
    bpcia_ed_outliers: genericArticleScreen('bpcia_ed_outliers'),
    // bpcia_ed_insights: genericArticleScreen('bpcia_ed_insights'),
    // ===================================
    // bundleAndFacility.snf
    // ===================================
    bpcia_snf_overview: genericArticleScreen('bpcia_snf_overview'),
    bpcia_snf_utilization: genericArticleScreen('bpcia_snf_utilization'),
    bpcia_snf_costs: genericArticleScreen('bpcia_snf_costs'),
    bpcia_snf_outliers: genericArticleScreen('bpcia_snf_outliers'),
    bpcia_snf_insights: genericArticleScreen('bpcia_snf_insights'),
    // ===================================
    // bundleAndFacility.irf
    // ===================================
    bpcia_irf_overview: genericArticleScreen('bpcia_irf_overview'),
    bpcia_irf_utilization: genericArticleScreen('bpcia_irf_utilization'),
    bpcia_irf_costs: genericArticleScreen('bpcia_irf_costs'),
    bpcia_irf_outliers: genericArticleScreen('bpcia_irf_outliers'),
    bpcia_irf_insights: genericArticleScreen('bpcia_irf_insights'),
    // ===================================
    // bundleAndFacility.ltach
    // ===================================
    bpcia_ltach_overview: genericArticleScreen('bpcia_ltach_overview'),
    bpcia_ltach_utilization: genericArticleScreen('bpcia_ltach_utilization'),
    bpcia_ltach_costs: genericArticleScreen('bpcia_ltach_costs'),
    bpcia_ltach_outliers: genericArticleScreen('bpcia_ltach_outliers'),
    bpcia_ltach_insights: genericArticleScreen('bpcia_ltach_insights'),
    // ===================================
    // bundleAndFacility.imaging
    // ===================================
    bpcia_imaging_overview: genericArticleScreen('bpcia_imaging_overview'),
    bpcia_imaging_utilization: genericArticleScreen('bpcia_imaging_utilization'),
    bpcia_imaging_costs: genericArticleScreen('bpcia_imaging_costs'),
    bpcia_imaging_outliers: genericArticleScreen('bpcia_imaging_outliers'),
    bpcia_imaging_insights: genericArticleScreen('bpcia_imaging_insights'),
    // ===================================
    // bundleAndFacility.labs
    // ===================================
    bpcia_labs_overview: genericArticleScreen('bpcia_labs_overview'),
    bpcia_labs_utilization: genericArticleScreen('bpcia_labs_utilization'),
    bpcia_labs_costs: genericArticleScreen('bpcia_labs_costs'),
    bpcia_labs_outliers: genericArticleScreen('bpcia_labs_outliers'),
    bpcia_labs_insights: genericArticleScreen('bpcia_labs_insights'),
    // ===================================
    // bundleAndFacility.hh
    // ===================================
    bpcia_hh_overview: genericArticleScreen('bpcia_hh_overview'),
    bpcia_hh_utilization: genericArticleScreen('bpcia_hh_utilization'),
    bpcia_hh_costs: genericArticleScreen('bpcia_hh_costs'),
    bpcia_hh_outliers: genericArticleScreen('bpcia_hh_outliers'),
    bpcia_hh_insights: genericArticleScreen('bpcia_hh_insights'),
    // ===================================
    // bundleAndFacility.officeVisits
    // ===================================
    bpcia_officeVisits_overview: genericArticleScreen('bpcia_officeVisits_overview'),
    bpcia_officeVisits_utilization: genericArticleScreen('bpcia_officeVisits_utilization'),
    bpcia_officeVisits_costs: genericArticleScreen('bpcia_officeVisits_costs'),
    bpcia_officeVisits_outliers: genericArticleScreen('bpcia_officeVisits_outliers'),
    bpcia_officeVisits_insights: genericArticleScreen('bpcia_officeVisits_insights'),
    // ===================================
    // bundleAndFacility.outpatientRehab
    // ===================================
    bpcia_outpatientRehab_overview: genericArticleScreen('bpcia_outpatientRehab_overview'),
    bpcia_outpatientRehab_utilization: genericArticleScreen('bpcia_outpatientRehab_utilization'),
    bpcia_outpatientRehab_costs: genericArticleScreen('bpcia_outpatientRehab_costs'),
    bpcia_outpatientRehab_outliers: genericArticleScreen('bpcia_outpatientRehab_outliers'),
    bpcia_outpatientRehab_insights: genericArticleScreen('bpcia_outpatientRehab_insights'),
    // ===================================
    // bundleAndFacility.dme
    // ===================================
    bpcia_dme_overview: genericArticleScreen('bpcia_dme_overview'),
    bpcia_dme_utilization: genericArticleScreen('bpcia_dme_utilization'),
    bpcia_dme_costs: genericArticleScreen('bpcia_dme_costs'),
    bpcia_dme_outliers: genericArticleScreen('bpcia_dme_outliers'),
    bpcia_dme_insights: genericArticleScreen('bpcia_dme_insights'),
    // ===================================
    // bundleAndFacility.hospice
    // ===================================
    bpcia_hospice_overview: genericArticleScreen('bpcia_hospice_overview'),
    bpcia_hospice_utilization: genericArticleScreen('bpcia_hospice_utilization'),
    bpcia_hospice_costs: genericArticleScreen('bpcia_hospice_costs'),
    bpcia_hospice_outliers: genericArticleScreen('bpcia_hospice_outliers'),
    bpcia_hospice_insights: genericArticleScreen('bpcia_hospice_insights'),
    // ===================================
    // bundleAndFacility.otherProcedures
    // ===================================
    bpcia_otherProcedures_overview: genericArticleScreen('bpcia_otherProcedures_overview'),
    // bpcia_otherProcedures_utilization: genericArticleScreen('bpcia_otherProcedures_utilization'),
    // bpcia_otherProcedures_costs: genericArticleScreen('bpcia_otherProcedures_costs'),
    // bpcia_otherProcedures_outliers: genericArticleScreen('bpcia_otherProcedures_outliers'),
    // bpcia_otherProcedures_insights: genericArticleScreen('bpcia_otherProcedures_insights'),
    // ===================================
    // sandbox
    // ===================================
    sandbox: genericArticleScreen('sandbox'),
  },
};

export default bpciaApp;
