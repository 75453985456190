import { consoleSerializer } from '@tuple-health/eng/dist/th/ds/common/consoleSerializer';
import { PushEvent } from '@tuple-health/eng/dist/th/ds/common/product/user/push/PushEvent';
import { useContext, useEffect, useRef } from 'react';
import { PushNoteContext } from './PushNoteContext';
import { DsUnion, fromDsEnum } from '../core/ds';
import { noop } from 'lodash';

interface PushNote {
  event: DsUnion<PushEvent>;
  isClick: boolean;
  badge?: number;
  notifyIsHandled: (isHandled: boolean) => void;
}

export type PushHandler = (note: PushNote) => void;

export default function usePushNotes(handler: PushHandler) {
  const pushNoteStore = useContext(PushNoteContext);

  // keep track of the latest handler
  const handlerRef = useRef(handler);
  useEffect(() => {
    handlerRef.current = handler;
  }, [handler]);

  useEffect(() => {
    const genericHandler = ({ data, ports }: MessageEvent) => {
      const event = fromDsEnum(consoleSerializer.parseCast<PushEvent>(data.data));
      const isClick = data.isClick as boolean;
      const badge = data.badge as number | undefined;
      const notifyIsHandled =
        ports && ports.length > 0
          ? (isHandled: boolean) => {
              ports[0].postMessage(isHandled);
            }
          : noop;

      handlerRef.current({ event, isClick, badge, notifyIsHandled });
    };

    // listen for push notifications
    if (pushNoteStore.registerPushListener(genericHandler)) {
      return () => {
        pushNoteStore.deregisterPushListener(genericHandler);
      };
    }
  }, [pushNoteStore]);
}
