import { Card } from '@material-ui/core';
import React from 'react';
import { classNames } from '../core/styles';
import CenteredContainer from './CenteredContainer';
import css from './centeredFrame.css';

interface CenteredFrameProps {
  classes?: {
    root?: string;
    paper?: string;
  };
}

const CenteredFrame: React.FunctionComponent<CenteredFrameProps> = ({ children, classes = {} }) => (
  <CenteredContainer className={classes.root}>
    <Card className={classNames(css.paper, classes.paper)}>{children}</Card>
    {FrameworkExplanation}
  </CenteredContainer>
);

export default CenteredFrame;

const FrameworkExplanation = (
  <>
    <p className={css.centeredExplanation}>
      Our Framework Health&trade; lines of service cover consulting and technology solutions in
      oncology. This includes, but is not limited to services related to alternative payment models,
      value-based care models, quality improvement, care coordination, care management, utilization
      management, data analysis and decision support.
      <br />
      <br />
      If you have questions about use of this portal, please contact our{' '}
      <a href="mailto:support@tuplehealth.com">support desk</a>.
      <br />
      <br />
      To learn more about our Framework Health&trade; services, please contact{' '}
      <a href="mailto:lisatran@tuplehealth.com">Lisa Tran</a>.
    </p>
  </>
);
