import { GatewayApi } from '@tuple-health/eng/dist/th/ds/common/agent/gatewayApi/api/GatewayApi';
import { ofType } from 'unionize';
import { Dispatch } from '../../contracts/Dispatch';
import { GatewayStatus, GatewayStatusContract } from '../../contracts/GatewayStatus';
import { makeActions } from '../../core/action';
import { constant, identity } from '@tuple-health/common';
import { makeProvider, Provider } from '../../core/provider';
import { makeReducer } from '../../core/reducer';
import { makeSelector } from '../../core/selector';
import { makeService } from '../../core/service';

const Action = makeActions({
  gateway_setStatus: ofType<GatewayStatus>(),
});

const reducer = makeReducer(
  GatewayStatus.initial(),
  constant(
    Action.match({
      gateway_setStatus: identity,
    }),
  ),
);

const statusProvider = makeProvider(
  GatewayStatusContract,
  makeSelector({ dispatch: Dispatch })<GatewayStatus, typeof GatewayStatusContract._injectiontype>(
    ({ state, dispatch }) => ({
      last: state,
      update(newValue) {
        dispatch(Action.gateway_setStatus(newValue));
      },
    }),
  ),
);

export function makeGatewayService(gatewayProvider: Provider<{}, GatewayApi>) {
  return makeService({
    providers: [gatewayProvider, statusProvider],
    reducer,
  });
}
