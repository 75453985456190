import * as RT from 'runtypes';

// =============================================================================
// concept
// =============================================================================

const JsonConcept = RT.Record({
  uri: RT.String,
  label: RT.String,
}).And(
  RT.Partial({
    sort: RT.String,
  }),
);
export type JsonConcept = RT.Static<typeof JsonConcept>;

const JsonConceptReport = RT.Array(JsonConcept);
export type JsonConceptReport = RT.Static<typeof JsonConceptReport>;

// =============================================================================
// box
// =============================================================================

export const JsonBox = RT.Record({
  mean: RT.Number,
  deviation: RT.Number,
  lowerQuartile: RT.Number,
  median: RT.Number,
  upperQuartile: RT.Number,
  min: RT.Number,
  max: RT.Number,
});
export type JsonBox = RT.Static<typeof JsonBox>;

// =============================================================================
// attr
// =============================================================================

const JsonDataType = RT.Union(RT.Literal('number'), RT.Literal('box'), RT.Literal('concept'));
export type JsonDataType = RT.Static<typeof JsonDataType>;

const JsonColumnRole = RT.Union(
  RT.Literal('count'),
  RT.Literal('numeratorCount'),
  RT.Literal('denominatorCount'),
);
export type JsonColumnRole = RT.Static<typeof JsonColumnRole>;

const JsonDataColumn = RT.Record({
  key: RT.String,
  label: RT.String, // singular
  // pluralLabel: String,
  type: JsonDataType,
}).And(
  RT.Partial({
    format: RT.String, // applicable to types: number and box
    isMeta: RT.Boolean,
    role: JsonColumnRole,
    // help: String, // for sidebar
  }),
);
export type JsonDataColumn = RT.Static<typeof JsonDataColumn>;

// =============================================================================
// item
// =============================================================================

// string is the either a concept's uri or a label
// - if uri: we find the label in the concept report and we can fetch contextual info
// - if label: a stub concept is generated and no contextual info will be available
const JsonCell = RT.Union(RT.Number, RT.String, JsonBox);
export type JsonCell = RT.Static<typeof JsonCell>;

// ItemData: attrKey__tableCell
const JsonRow = RT.Dictionary(JsonCell, 'string');
export type JsonRow = RT.Static<typeof JsonRow>;

// =============================================================================
// cohort
// =============================================================================

const JsonCohort = RT.Record({
  label: RT.String,
}).And(
  RT.Partial({
    query: RT.String,
  }),
);
export type JsonCohort = RT.Static<typeof JsonCohort>;

// =============================================================================
// table
// =============================================================================

export const JsonTable = RT.Record({
  cohort: JsonCohort,
  columns: RT.Array(JsonDataColumn),
  rows: RT.Array(JsonRow),
}).And(
  RT.Partial({
    concepts: JsonConceptReport,
  }),
);
export type JsonTable = RT.Static<typeof JsonTable>;

// =============================================================================
// table delta
// =============================================================================

const JsonCohortDelta = RT.Record({
  label: RT.String,
});
export type JsonCohortDelta = RT.Static<typeof JsonCohortDelta>;

const JsonColumnDelta = RT.Record({
  key: RT.String,
}).And(
  RT.Partial({
    label: RT.String,
    format: RT.String,
  }),
);
export type JsonColumnDelta = RT.Static<typeof JsonColumnDelta>;

const JsonConceptDelta = RT.Record({
  uri: RT.String,
}).And(
  RT.Partial({
    label: RT.String,
    sort: RT.String,
  }),
);
export type JsonConceptDelta = RT.Static<typeof JsonConceptDelta>;

export const JsonTableDelta = RT.Partial({
  cohort: JsonCohortDelta, // merge
  columns: RT.Array(JsonColumnDelta), // merge
  concepts: RT.Array(JsonConceptDelta), // merge
  rows: RT.Array(JsonRow), // append
});
export type JsonTableDelta = RT.Static<typeof JsonTableDelta>;
