import * as _ from '@tuple-health/eng/dist/dryscript/ds';
import {
  parse as parseQuery,
  write as writeQuery,
} from '@tuple-health/eng/dist/dryscript/lib/common/cube/format/cubeQueryFormat';
import { CubeQuery } from '@tuple-health/eng/dist/dryscript/lib/common/cube/query/CubeQuery';
import { where } from '@tuple-health/eng/dist/dryscript/lib/common/cube/query/toCubeClause';
import { schema } from '@tuple-health/eng/dist/th/ds/common/product/customer/house/cube/houseCube';
import { DataResult } from '@tuple-health/eng/dist/th/ds/common/tech/analytics/data/DataResult';
import React, { createContext, ReactNode, useCallback, useContext } from 'react';
import { Cohort, useCohort } from '../../components/cohort/model/cohort.model';
import { key__dataset } from '../../components/product/dataset/dataset.model';
import { DatasetKey } from '../../components/product/model/analyticsProductModel';
import useCustomer from '../../core/store/useCustomer';
import useGateway from '../../core/store/useGateway';
import useUser from '../../core/store/useUser';
import { submitRequest } from './query.cache';
import { CubeRequest } from './query.types';

// =============================================================================
// send query
// =============================================================================

export type SendQuery = (args: {
  customerId: string;
  cohort: Cohort;
  query: CubeQuery;
}) => Promise<DataResult>;

const Context = createContext<SendQuery | undefined>(undefined);

export const SendQueryProvider = Context.Provider;

export function SendQueryCreator({ children }: { children?: ReactNode }) {
  const gateway = useGateway();
  const { session } = useUser();

  const sendQuery: SendQuery = useCallback(
    ({ customerId, cohort, query: shallowQuery }) => {
      const baseDeepQueryText = `CALC ${cohort.catalog.name}.count\n${cohort.dataset.query}`;
      const baseDeepQuery = parseQuery(schema, baseDeepQueryText);
      const deepQuery = baseDeepQuery.addClauses(_.toArray.call(cohort.deepFilters.map(where)));

      // string -> query
      if (typeof shallowQuery === 'string') shallowQuery = parseQuery(schema, shallowQuery);

      // add shallow filters if there are any
      if (cohort.shallowFilters.length)
        shallowQuery = shallowQuery.addClauses(_.toArray.call(cohort.shallowFilters.map(where)));

      const request: CubeRequest = {
        deepQuery: writeQuery(deepQuery),
        shallowQuery: writeQuery(shallowQuery),
      };

      return submitRequest({ gateway, session, customerId, request });
    },
    [gateway, session],
  );

  return <SendQueryProvider value={sendQuery}>{children}</SendQueryProvider>;
}

// =============================================================================
// run query
// =============================================================================

export type RunQuery = (query: CubeQuery | string, datasetKey?: DatasetKey) => Promise<DataResult>;

export default function useRunQuery() {
  const sendQuery = useContext(Context);
  if (!sendQuery) throw new Error('send query not provided');

  const { customerId } = useCustomer();

  const cohort = useCohort();

  const runQuery: RunQuery = useCallback(
    (query, datasetKey) => {
      if (typeof query === 'string') query = parseQuery(schema, query);
      return sendQuery({
        customerId,
        cohort: datasetKey
          ? {
              ...cohort,
              dataset: key__dataset[datasetKey],
            }
          : cohort,
        query,
      });
    },
    [cohort, customerId, sendQuery],
  );

  return runQuery;
}
