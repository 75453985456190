import { fromPairs2, toPairs, values } from '@tuple-health/common';
import { $concept, Concept, DecodeConcepts, Uri } from '@tuple-health/common/dist/data';
import React, { createContext, ReactNode, useCallback, useContext, useMemo } from 'react';
import useCustomer from '../../../../../core/store/useCustomer';
import { gateway } from '../../gateway.fetch';

interface DecodeConceptsAgent {
  decodeConcepts: DecodeConcepts;
}

// =============================================================================
// hook
// =============================================================================

const Context = createContext<DecodeConceptsAgent | undefined>(undefined);

export function useDecodeConcepts(): DecodeConceptsAgent {
  const agent = useContext(Context);
  if (!agent) throw Error('decode agent not provided');
  return agent;
}

// =============================================================================
// provider
// =============================================================================

interface Props {
  agent: DecodeConceptsAgent;
  children?: ReactNode;
}

function DecodeConceptsProvider({ agent, children }: Props) {
  return <Context.Provider value={agent}>{children}</Context.Provider>;
}

export function GatewayDecodeConceptsProvider({ children }: { children: ReactNode }) {
  const { customerId } = useCustomer();

  const agent: DecodeConceptsAgent = useMemo(
    () => ({ decodeConcepts: genDecodeConcepts(customerId) }),
    [customerId], // TODO review, currently clear cache when changing customer
  );

  return <DecodeConceptsProvider agent={agent}>{children}</DecodeConceptsProvider>;
}

// =============================================================================
// =============================================================================

function genDecodeConcepts(_customerId: string): DecodeConcepts {
  // const x = useCustomer(); sadfasdf
  // console.log('genDecodeConcepts()');

  // customer specific decode cache
  const cacheCurie__concept: Record<string, Concept> = {};

  let curie__waitingUri: Record<string, Uri> = {};
  let waitingCallbacks: (() => void)[] = [];

  const decodeConcepts: DecodeConcepts = (uris: Uri[]) => {
    const outputCurie__concept: Record<string, Concept> = {};

    const missUris = uris.filter(uri => {
      if (!cacheCurie__concept[uri.curie]) return true;
      outputCurie__concept[uri.curie] = cacheCurie__concept[uri.curie];
      return false;
    });

    if (!missUris) return Promise.resolve(outputCurie__concept);

    return new Promise(resolve => {
      missUris.forEach(missUri => {
        curie__waitingUri[missUri.curie] = missUri;
      });

      waitingCallbacks.push(() => {
        missUris.forEach(missUri => {
          const concept = cacheCurie__concept[missUri.curie];
          if (!concept) throw Error(`missing decode uri: ${missUri.curie}`);
          outputCurie__concept[missUri.curie] = concept;
        });
        resolve(outputCurie__concept);
      });

      if (waitingCallbacks.length === 1) {
        // timeout allows us to batch requests that come from disparate places in a screen
        setTimeout(() => {
          const waitingUris = values(curie__waitingUri);
          curie__waitingUri = {};

          const callbacks = waitingCallbacks;
          waitingCallbacks = [];

          void gateway.decodeConcepts(waitingUris).then(curie__concept => {
            for (const [curie, concept] of toPairs(curie__concept)) {
              cacheCurie__concept[curie] = concept;
            }
            callbacks.forEach(callback => callback());
          });
        }, 50); // enough time to collect requests without visible latency
      }
    });
  };

  return decodeConcepts;
}

// =============================================================================
// dummy1
// =============================================================================

// export function DummyDecodeConceptsProvider({ children }: { children: ReactNode }) {
//   const agent: DecodeConceptsAgent = useMemo(() => ({ decodeConcepts: dummyDecodeConcepts }), []);
//   return <DecodeConceptsProvider agent={agent}>{children}</DecodeConceptsProvider>;
// }

// =============================================================================
// dummy2
// =============================================================================

export function DummyDecodeConceptsProvider({
  concepts,
  children,
}: {
  concepts: Concept[];
  children: ReactNode;
}) {
  const curie__concept = fromPairs2(concepts.map(c => [c.uri.curie, c]));

  const decodeConcepts: DecodeConcepts = useCallback(
    (uris: Uri[]) =>
      new Promise(resolve => {
        setTimeout(() => {
          resolve(
            fromPairs2(
              uris.map(uri => {
                let concept = curie__concept[uri.curie];
                if (!concept) {
                  // console.log('decode fallback for', uri.curie);
                  concept = $concept.create({ uri, label: `decoded3_${uri.id.substring(0, 10)}` });
                } else if (!concept.label) {
                  // console.log('no decode fallback, but no label for', uri.curie);
                }
                return [uri.curie, concept];
              }),
            ),
          );
        }, 1500);
      }),
    [curie__concept],
  );
  const agent: DecodeConceptsAgent = useMemo(() => ({ decodeConcepts }), [decodeConcepts]);
  return <DecodeConceptsProvider agent={agent}>{children}</DecodeConceptsProvider>;
}
