/* eslint-disable @typescript-eslint/require-await */
import * as _ from '@tuple-health/eng/dist/dryscript/ds';
import { GenericMsg } from '@tuple-health/eng/dist/dryscript/lib/common/ui/content/msg/GenericMsg';
import * as toGenericMsg from '@tuple-health/eng/dist/dryscript/lib/common/ui/content/msg/toGenericMsg';
import { mergeMap } from 'rxjs/operators';
import { ofType } from 'unionize';
import { Dispatch } from '../../contracts/Dispatch';
import { Gateway } from '../../contracts/Gateway';
import { makeActions } from '../../core/action';
import { makeAgent, NOOP } from '../../core/agent';
import { Router } from '../../core/appbase/router.service';
import { makeScreen } from '../../core/appbase/screen';
import { Dictionary, pipe, tuple } from '@tuple-health/common';

import { makeReducer } from '../../core/reducer';
import { makeSelector } from '../../core/selector';
import Support, { FormField, SupportProps } from './Support';
import { makeApi } from '../../services/gateway/api';

type FormFieldConfig = FormField & {
  hidden?: boolean;
  optional?: boolean;
};

type FormFieldConfigOptId = FormField & {
  hidden?: boolean;
  optional?: boolean;
};

interface SupportConfig {
  intro?: string;
  fields: FormFieldConfigOptId[];
}

interface State {
  status: keyof typeof SupportProps._Record;
  values: Dictionary<string>;
  error?: GenericMsg;
}

const Action = makeActions({
  support__submit: ofType<Dictionary<string>>(),
  support__update: ofType<Partial<State>>(),
});
type Action = typeof Action._Union;

export const FIELD_HANDLE = 'Handle';
export const FIELD_EMAIL_ADDRESS = 'Email Address';
export const FIELD_NAME = 'Name';
export const FIELD_SUPPORT_TYPE = 'Request Type';
export const FIELD_INVITE_ID = 'Invite ID';
export const FIELD_PHONE_RECORD = 'Record';

export function makeSupportScreen(config: SupportConfig) {
  const hiddenFields: FormFieldConfig[] = config.fields.filter(field => field.hidden);
  const inputFields: FormFieldConfig[] = config.fields.filter(field => !field.hidden);

  const reducer = makeReducer<State, Action>({ values: {}, status: 'prompt' }, state =>
    Action.match({
      support__submit: values => ({
        status: 'pending',
        values,
        error: undefined,
      }),

      support__update: delta => ({ ...state, ...delta }),
    }),
  );

  const agent = makeAgent({ dispatch: Dispatch, router: Router, gateway: Gateway })<State>($ =>
    $.pipe(
      mergeMap(async ({ state, action, router, gateway }) => {
        if (!Action.is.support__submit(action as Action)) return NOOP.promise();

        const filledFields = inputFields.map(field => ({
          ...field,
          value: state.values[field.name],
        }));

        const missingFields = filledFields.filter(field => !(field.optional || field.value));
        if (missingFields.length) {
          return Action.support__update({
            status: 'prompt',
            error: toGenericMsg.call({
              isError: true,
              key__text: _.toDict.of(
                ...missingFields.map(({ name }) => tuple(name, 'This field is required.')),
              ),
            }),
          });
        }

        const fields = [
          ...hiddenFields.map(field =>
            field.name in router.location!.query
              ? {
                  ...field,
                  value: (router.location!.query as Dictionary<string>)[field.name],
                }
              : field,
          ),
          ...filledFields,
        ];

        const type = fields.find(field => field.name === FIELD_SUPPORT_TYPE);

        return makeApi(gateway)
          .support({
            body: fields
              .map(({ name, value, hidden }) => `${name}${hidden ? '*' : ''}: ${value}`)
              .join('\n'),
            email: 'noreply@tuplehealth.com',
            name: 'No Reply',
            subject: `Support Request: ${(type && type.value) || 'Unknown'}`,
          })
          .then(() => Action.support__update({ status: 'submitted' }))
          .catch(e =>
            Action.support__update({ status: 'prompt', error: toGenericMsg.fromReply(e.reply) }),
          );
      }),
    ),
  );

  const selector = makeSelector({ dispatch: Dispatch })<State, SupportProps>(
    ({ state, dispatch }) => {
      switch (state.status) {
        case 'submitted':
          return SupportProps.submitted();
        case 'pending':
          return SupportProps.pending({
            fields: inputFields.map(field => ({ ...field, value: state.values[field.name] })),
            intro: config.intro,
          });
        case 'prompt':
          return SupportProps.prompt({
            fields: inputFields.map(field => ({ ...field, value: state.values[field.name] })),
            intro: config.intro,
            onSubmit: pipe(Action.support__submit, dispatch),
            error: state.error,
          });
      }
    },
  );

  return makeScreen({
    reducer,
    agent,
    selector,
    view: Support,
  });
}
