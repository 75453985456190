import {
  EMPTY as emptyObservable,
  merge as observableMerge,
  Observable,
  of as observableOf,
} from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { ofType } from 'unionize';
import { Action, makeActions } from './action';
import { keys, constant } from '@tuple-health/common';
import { bindInjector, ContractDictionary, InjectedContext, Resolver } from './resolver';

type ActionStream = Observable<Action>;
type AsyncActions = ActionStream | Promise<Action>;

export const NOOP = {
  observable: constant(emptyObservable as AsyncActions),
  promise: constant(
    new Promise<Action>(() => {}),
  ),
};

export const AgentAction = makeActions({
  agent__unhandledError: ofType<{ error: Error; errorString: string }>(),
});
export type AgentAction = typeof AgentAction._Union;

export type Agent<S> = AgentFactory<S>;

type AgentFactory<S, I extends ContractDictionary = {}> = (
  $: AgentContextStream<S, I>,
) => ActionStream;

export type AgentContext<S = {}, I extends ContractDictionary = {}> = InjectedContext<I> & {
  state: S;
  action: Action;
  resolve: Resolver;
};

type AgentContextStream<S = {}, I extends ContractDictionary = {}> = Observable<AgentContext<S, I>>;

export type AgentMap<StateMap> = { [K in keyof StateMap]: Agent<StateMap[K]> };

export const makeAgent = <I extends ContractDictionary>(i: I) => {
  const inject = bindInjector(i);
  return <S>(f: AgentFactory<S, I>): Agent<S> => $ =>
    f($.pipe(map(frame => inject(frame, frame.resolve)))).pipe(
      catchError(e => {
        // eslint-disable-next-line no-console
        console.error('Uncaught agent error:', e); // this avoids error suppression in tests

        return observableOf(
          AgentAction.agent__unhandledError({
            error: e,
            errorString: e + '', // added so error is visible in action body when log=verbose
          }),
        );
      }),
    );
};

const mergeAgents = <S>(...agents: Agent<S>[]): Agent<S> => $ =>
  observableMerge(...agents.map(w => w($)));

export const combineAgents = <M>(agentMap: AgentMap<M>): Agent<M> =>
  mergeAgents(
    ...keys(agentMap).map(key => ($: AgentContextStream<M>) =>
      agentMap[key](
        $.pipe(
          map(({ state, action, resolve }) => ({ state: state && state[key], action, resolve })),
        ),
      ),
    ),
  );
