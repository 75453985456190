import React, {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';

// =============================================================================
// agent
// =============================================================================

interface UrlFragmentAgent {
  fragment: string;
  setFragment: (fragment: string) => void;
}

// =============================================================================
// context
// =============================================================================

const Context = createContext<UrlFragmentAgent | undefined>(undefined);

export default function useUrlFragment() {
  const context = useContext(Context);
  if (!context) throw Error('urlFragment context not provided');
  return context;
}

const UrlFragmentProvider = Context.Provider;

// =============================================================================
// simulator
// =============================================================================

export function UrlFragmentSimulator({
  children,
  initialFragment,
}: {
  children?: ReactNode;
  initialFragment: string;
}) {
  const [fragment, _setFragment] = useState<string>(initialFragment);

  const setFragment = useCallback((fragment: string) => {
    // console.log('fragment change', fragment);
    _setFragment(fragment);
  }, []);

  const agent: UrlFragmentAgent = useMemo(
    () => ({
      fragment,
      setFragment,
    }),
    [fragment, setFragment],
  );

  return <UrlFragmentProvider value={agent}>{children}</UrlFragmentProvider>;
}

export function UrlBarSimulator() {
  const { fragment, setFragment } = useUrlFragment();
  return (
    <input value={fragment} onChange={e => setFragment(e.target.value)} style={{ width: '100%' }} />
  );
}

// =============================================================================
// real
// =============================================================================

export function UrlFragmentCreator({ children }: { children?: ReactNode }) {
  const [fragment, setStateFragment] = useState<string>(getUrlFragment());

  const agent: UrlFragmentAgent = useMemo(
    () => ({
      fragment,
      setFragment,
    }),
    [fragment],
  );

  useEffect(() => {
    const listener = () => {
      const fragment = getUrlFragment();
      // console.log('hashchange event:', fragment);
      setStateFragment(fragment);
    };

    // this is more reliable than hoping the legacy container mechanism forwards the path consistently
    window.addEventListener('hashchange', listener);
    return () => {
      window.removeEventListener('hashchange', listener);
    };
  }, []);

  return <UrlFragmentProvider value={agent}>{children}</UrlFragmentProvider>;
}

// =============================================================================
// real lib
// =============================================================================

// https://stackoverflow.com/questions/3870057/how-can-i-update-window-location-hash-without-jumping-the-document
export const setFragment = (fragment: string) => {
  // console.log('setUrlFragment', fragment);
  // while (s.startsWith('#')) s = s.substring(1);

  // TODO fix
  // history doesn't fire the hashchange event
  // which we are currently listening to
  // if (history.pushState) {
  //   history.pushState(null, null as any, s);
  // } else {
  // fragment = _.strs.strip(fragment.trim(), '#', { left: true });
  fragment = fragment.trim();
  // console.log('setUrlFragment: ', fragment);
  window.location.hash = fragment;
  // }
};

function getUrlFragment() {
  // const fragment = _.strs.strip(window.location.hash.trim(), '#', { left: true });
  const fragment = window.location.hash.trim();
  // console.log('getUrlFragment: ', fragment);
  return fragment;
}
