import { ListItem, Tooltip } from '@material-ui/core';
import React, { ReactNode } from 'react';
import { classNames } from '../../../core/styles';
import css from './UserNavItem.css';

interface UserNavItemProps {
  title: string;
  active?: boolean;
  children: ReactNode;
  onClick: () => void;
}

export default function UserNavItem(item: UserNavItemProps) {
  return (
    <Tooltip title={item.title} placement="left">
      <ListItem
        button
        className={classNames(css.root, item.active ? css.active : undefined)}
        onClick={item.onClick}
      >
        {item.children}
      </ListItem>
    </Tooltip>
  );
}
