import { fromPromise } from 'mobx-utils';
import { entries } from '@tuple-health/common';

// =============================================================================
// scope result
// =============================================================================

export type ScopeResult<Val> =
  | {
      tag: 'val';
      ok: true;
      val: Val;
    }
  | { tag: 'computeError'; ok: false; errorMsg: string }
  | { tag: 'referenceError'; ok: false; errorMsg: string; varName: string };

export const createScopeVal = <Val>(val: Val): ScopeResult<Val> => {
  if (val === null) throw Error('scope val cannot be null');
  return {
    tag: 'val',
    ok: true,
    val,
  };
};

export const createScopeComputeError = <Val>(msg: string): ScopeResult<Val> => ({
  tag: 'computeError',
  ok: false,
  errorMsg: `compute error: ${msg}`,
});

export const createScopeReferenceError = <Val>(varName: string): ScopeResult<Val> => ({
  tag: 'referenceError',
  ok: false,
  errorMsg: `reference error: ${varName}`,
  varName,
});

// =============================================================================
// scope concepts
// =============================================================================

interface ScopeConcept {
  uri: string;
  ns: string;
  key: string;
  label: string;
}

export const toScopeConcept = (uri: string, label: string): ScopeConcept => ({
  uri,
  label,
  ns: uri.split(':')[0],
  key: uri.split(':')[1],
});

// =====================================

interface ScopeConceptPath {
  concepts: ScopeConcept[];
  labels: string[];
  uris: string[];
  keys: string[];
  keyPath: string;
}

export const toScopeConceptPath = (concepts: ScopeConcept[]): ScopeConceptPath => {
  const keys = concepts.map(c => c.key);
  return {
    concepts,
    labels: concepts.map(c => c.label),
    uris: concepts.map(c => c.uri),
    keys,
    keyPath: keys.join('/'),
  };
};

// =============================================================================
// generic scope & result
// =============================================================================

export type GenericScopeResult = ScopeResult<any>;

export type Scope = Map<string, Promise<GenericScopeResult>>;

// =============================================================================
// text scope result
// =============================================================================

export type TextScopeResult = ScopeResult<string>;

export const writeScopeResultBoxed = (result: GenericScopeResult): TextScopeResult => {
  if (!result.ok) return result;
  return { ...result, val: writeVal(result.val) };
};

const writeVal = (val: any): string => {
  switch (typeof val) {
    case 'string':
      return val;
    case 'number':
      return '' + val;
  }
  return '' + val; // TODO review
};

export const writeScopeResultThrows = (result: GenericScopeResult): string => {
  const written = writeScopeResultBoxed(result);
  if (!written.ok) throw Error(written.errorMsg);
  return written.val;
};

// =============================================================================
// unbound text -> bound text promise
// =============================================================================

export const bindVarsObservablePromise = (scope: Scope, text: string) =>
  fromPromise(bindVarsPromise(scope, text));

export const bindVarsPromise = (scope: Scope, text: string): Promise<GenericScopeResult> => {
  const varNames = text__varNames(text);

  const varRecordPromise = lookupVarsPromise(scope, varNames);

  // TODO handle errors better than throwing
  // const writtenVarRecordPromise = varRecordPromise.then(writeScopeVars);

  return varRecordPromise.then(vars => bindVarsBoxed(text, vars));
};

export const lookupVarsPromise = (
  scope: Scope,
  varNames: string[],
): Promise<Record<string, GenericScopeResult>> => {
  // pull from scope
  const varPromises: Promise<GenericScopeResult>[] = varNames.map(varName => {
    if (scope.has(varName)) return scope.get(varName)!;

    // handle forward references by waiting
    // - alternative: process segments in top sort
    // - alternative: 2 pass model (discover, then define)
    return new Promise<GenericScopeResult>(resolve => {
      setTimeout(() => {
        if (scope.has(varName)) resolve(scope.get(varName));
        else resolve(createScopeReferenceError(varName));
      }, 100);
    });
  });

  // merge
  const varArrayPromise = Promise.all(varPromises);

  // positions to names
  const varRecordPromise: Promise<Record<string, GenericScopeResult>> = varArrayPromise.then(
    varValues => {
      const vars: Record<string, GenericScopeResult> = {};
      varNames.forEach((varName, i) => {
        vars[varName] = varValues[i];
      });
      return vars;
    },
  );

  return varRecordPromise;
};

// =============================================================================
// unbound text -> bound text
// =============================================================================

const bindVarsBoxed = (
  inputText: string,
  vars: Record<string, GenericScopeResult>,
): TextScopeResult => {
  let outputText = inputText;

  const varNames = text__varNames(outputText);

  for (const key of varNames) {
    const keyMarkup = '${' + key + '}';

    if (!(key in vars)) {
      return createScopeReferenceError(key);
    }

    const val = vars[key];

    const written = writeScopeResultBoxed(val);
    if (!written.ok) return written;
    const valMarkup = written.val;

    const newText = outputText.replace(keyMarkup, valMarkup);
    outputText = newText;
  }

  if (textHasVars(outputText)) {
    throw Error(
      `variables cannot produce text containing variables:\nvarNames: ${varNames.join(
        ', ',
      )}\ninput text:\n${inputText}\noutput text:\n${outputText}`,
    );
  }

  return createScopeVal(outputText);
};

export const bindVars = (text: string, vars: Record<string, string>): string => {
  const boxedVars: Record<string, GenericScopeResult> = {};
  for (const [key, val] of entries(vars)) {
    boxedVars[key] = createScopeVal(val);
  }

  const boxedVal = bindVarsBoxed(text, boxedVars);

  if (!boxedVal.ok) {
    throw Error(`error during bindVarsBoxed: ${boxedVal.errorMsg}`);
  }

  return boxedVal.val;
};

// =============================================================================
// text -> dependencies
// =============================================================================

const rex = /\$\{([^\n}{]+)\}/;

const text__varNames = (text: string): string[] => {
  const varNames: string[] = [];
  let match: RegExpExecArray | null = null;
  do {
    match = rex.exec(text);
    if (match) {
      // console.log('MATCH', match);
      const [keyMarkup, varName] = match;
      const newText = text.replace(keyMarkup, '');
      if (text === newText) {
        throw Error('infinite loop');
      }
      text = newText;
      varNames.push(varName);
    }
  } while (match);

  return varNames;
};

export const textHasVars = (text: string) => text__varNames(text).length > 0;

// =============================================================================

export const getScopeVar = (scope: Scope, key: string): any => {
  const resultPromise = scope.get(key);
  if (!resultPromise) return 'reference error';
  return fromPromise(resultPromise).case({
    fulfilled: result => (result.ok ? result.val : result.errorMsg),
    pending: () => 'loading...',
    rejected: e => 'unhandled error: ' + e,
  });
};

export const getScopeVars = (scope: Scope): Record<string, any> => {
  const vars: Record<string, any> = {};
  for (const key of scope.keys()) {
    vars[key] = getScopeVar(scope, key);
  }
  return vars;
};
