import React from 'react';
import { style } from 'typestyle';
import { classNames } from '../core/styles';
import theme from '../theme';

interface PageProps {
  withMargin?: boolean;
  extraClassNames?: string;
  children?: React.ReactNode;
}

export default React.memo(({ withMargin = true, extraClassNames, children }: PageProps) => (
  <div className={classNames(withMargin && classes.withMargin, extraClassNames)}>{children}</div>
));

const classes = {
  withMargin: style({
    margin: theme.spacing(2),
  }),
};
