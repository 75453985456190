import { timer as observableTimer } from 'rxjs';
import { filter, first, map, mergeMap } from 'rxjs/operators';
import { identity } from '@tuple-health/common';

const VERSION_CHECK_INTERVAL = 5 * 60 * 1000;
const regexVersion = /th-version" content="([^"]+)"/;

export function watchVersion() {
  return observableTimer(VERSION_CHECK_INTERVAL, VERSION_CHECK_INTERVAL).pipe(
    mergeMap(() => fetch(process.env.PUBLIC_URL!).catch(() => {})),
    mergeMap(req => (req && req.ok ? req.text().catch(() => '') : [])),
    map(content => {
      const match = regexVersion.exec(content);
      return !!match && match[1] !== process.env.CONSOLE_VERSION_ID;
    }),
    filter(identity),
    first(),
  );
}
