import { ofType } from 'unionize';
import { makeProps } from '../../../core/props';
import { WithError, WithMaybeError } from '@tuple-health/common';

export interface ExpiredProps {
  passwordResetPath: string;
  supportPath: string;
}

export interface FormProps {
  complete(fields: FormFields): void;
  supportPath: string;
}

export interface FormFields {
  phoneOtp: string;
  password: string;
  password2: string;
}

// -- props union -- //
export const PasswordResetConfirmProps = makeProps(
  {
    initializing: {},
    error: ofType<WithError>(),
    expired: ofType<ExpiredProps>(),
    prompt: ofType<FormFields & WithMaybeError & FormProps>(),
    pending: ofType<FormFields>(),
  },
  'status',
);

export type PasswordResetConfirmProps = typeof PasswordResetConfirmProps._Union;
