import matomoAnalytics from './matomoAnalytics';
import { env } from '../core/env';

const allAnalytics: Analytics[] = [matomoAnalytics];

export interface Analytics {
  initialize(): void;
  setUserId(userId: string): void;
  pageView(title: string, path: string, lastPath?: string): void;
  event(category: string, action: string, name?: string, value?: number): void;
  error(message: string): void;
}

// We don't exclude the 'test' environment because Chromatic uses
// `env === 'production'` and we want storyshots to work as close to that
// environment as possible.
const tracking = env !== 'development' && window.location.hostname !== 'localhost';

export default Object.keys(allAnalytics[0]).reduce((accum, name) => {
  const typedName = name as keyof Analytics;
  accum[typedName] = (...args: any[]) =>
    // console.error(
    //   `${name}(${args.map(x => (x === undefined ? 'undefined' : JSON.stringify(x))).join(', ')})`,
    // );
    tracking && allAnalytics.forEach(analytics => (analytics[typedName] as any)(...args));
  return accum;
}, {} as Analytics);
