import { IconButton, Snackbar, SnackbarContent } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import InfoIcon from '@material-ui/icons/Info';
import { IObservableValue } from 'mobx';
import { useObserver } from 'mobx-react';
import React, { useMemo, useReducer } from 'react';
import useId from '../../../core/hooks/useId';
import useInterval from '../../../core/hooks/useInterval';
import css from './ExpiryWarning.css';

export interface ExpiryWarningProps {
  expiry: IObservableValue<number>;
  continueSession(): void;
}

export default function ExpiryWarning({ expiry, continueSession }: ExpiryWarningProps) {
  const messageId = useId('expiry-warning-message-');

  const exp = useObserver(() => expiry.get());
  const [now, updateNow] = useReducer(Date.now, Date.now());
  useInterval(updateNow, 30 * 1000);

  const minutesRemaining = useMemo(() => (exp - now) / (60 * 1000), [exp, now]);
  const visible = minutesRemaining <= 5;
  const roundedMinutes = Math.round(minutesRemaining);

  return (
    <Snackbar
      open={visible}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      onClose={continueSession}
    >
      <SnackbarContent
        className={css.root}
        aria-describedby={messageId}
        message={
          <span
            id={messageId}
            className={css.message}
            style={{ visibility: visible ? 'visible' : 'hidden' }}
          >
            <InfoIcon />
            You will be logged out due to inactivity in{' '}
            {minutesRemaining < 1
              ? 'less than a minute'
              : `${roundedMinutes} minute${roundedMinutes === 1 ? '' : 's'}`}
            .
          </span>
        }
        action={
          <IconButton aria-label="Close" color="inherit" onClick={continueSession}>
            <CloseIcon />
          </IconButton>
        }
      />
    </Snackbar>
  );
}
