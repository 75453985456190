import { createContext } from 'react';
import { PlatformContainerOverrides } from '../../platform/platform/PlatformOldScreen';
import { Route, RouterPath } from '@tuple-health/common/dist/router';
import useRequiredContext from '../hooks/useRequiredContext';

export interface Linker {
  routeHref(route: Route<any>, overrides?: PlatformContainerOverrides): string;

  navText(pathQuery: string): void;
  navRoute(route: Route<any>, overrides?: PlatformContainerOverrides): void;
  navPath(path: RouterPath<any>, overrides?: PlatformContainerOverrides): void;
  navCustomerId(customerId: string): void;
  navProductKey(productKey: string): void;

  routeLink(route: Route<any>): { href: string };
  pathLink(path: RouterPath<any>): { href: string };

  routeButton(
    route: Route<any>,
    beforeNav?: () => void,
  ): {
    onClick: (e: React.MouseEvent) => void;
  };
  pathButton(
    path: RouterPath<any>,
    beforeNav?: () => void,
  ): {
    onClick: (e: React.MouseEvent) => void;
  };
}

const Context = createContext<Linker | undefined>(undefined);

export const LinkerProvider = Context.Provider;

export default function useLinker() {
  return useRequiredContext(Context);
}
