import { cleanObject, constant, WithError, WithMaybeError } from '@tuple-health/common';
import { Reply } from '@tuple-health/eng/dist/dryscript/ds';
import * as toDict from '@tuple-health/eng/dist/dryscript/lib/common/prelude/dict/toDict';
import * as toGenericMsg from '@tuple-health/eng/dist/dryscript/lib/common/ui/content/msg/toGenericMsg';
import * as gatewayErrors from '@tuple-health/eng/dist/th/ds/common/agent/gatewayApi/service/gatewayErrors';
import { Record, Static, String } from 'runtypes';
import { merge as observableMerge } from 'rxjs';
import { distinctUntilKeyChanged, mergeMap } from 'rxjs/operators';
import { ofType } from 'unionize';
import { hashedPathTo, pathTo } from '../../../app/locs';
import { Authn } from '../../../contracts/Authn';
import { Dispatch } from '../../../contracts/Dispatch';
import { Gateway } from '../../../contracts/Gateway';
import { makeActions } from '../../../core/action';
import { makeAgent, NOOP } from '../../../core/agent';
import { Router } from '../../../core/appbase/router.service';
import { makeReducer } from '../../../core/reducer';
import { makeSelector } from '../../../core/selector';
import { makeStates } from '../../../core/state';
import { makeApi } from '../../../services/gateway/api';
import { FormFields, PasswordResetConfirmProps } from './props';

const QueryParameters = Record({
  email: String,
  code: String,
});
type QueryParameters = Static<typeof QueryParameters>;

// ============================
//  state
// ============================
const State = makeStates({
  initializing: {},
  expired: {},
  error: ofType<WithError>(),
  prompt: ofType<QueryParameters & FormFields & WithMaybeError>(),
  pending: ofType<QueryParameters & FormFields>(),
});
type State = typeof State._Union;

// ============================
//  action
// ============================
const Action = makeActions({
  passwordResetConfirmScreen_initializeExpired: {},
  passwordResetConfirmScreen_initializeError: ofType<WithError>(),
  passwordResetConfirmScreen_initializeSuccess: ofType<QueryParameters>(),
  passwordResetConfirmScreen_submit: ofType<FormFields>(),
  passwordResetConfirmScreen_receiveError: ofType<Partial<FormFields> & WithError>(),
});
type Action = typeof Action._Union;

// ============================
//  reducer
// ============================
export const reducer = makeReducer<State, Action>(State.initializing(), s => a =>
  State.match(s, {
    initializing: () =>
      Action.match(a, {
        passwordResetConfirmScreen_initializeError: State.error,
        passwordResetConfirmScreen_initializeExpired: State.expired,
        passwordResetConfirmScreen_initializeSuccess: queryParams =>
          State.prompt({ ...queryParams, phoneOtp: '', password: '', password2: '' }),
        default: constant(s),
      }),
    prompt: state =>
      Action.match(a, {
        passwordResetConfirmScreen_submit: action =>
          State.pending({ ...cleanObject(state, 'error'), ...action }),
        passwordResetConfirmScreen_receiveError: action => State.prompt({ ...state, ...action }),
        default: constant(s),
      }),
    pending: state =>
      Action.match(a, {
        passwordResetConfirmScreen_receiveError: action => State.prompt({ ...state, ...action }),
        default: constant(s),
      }),
    default: constant(s),
  }),
);

// ============================
//  agent
// ============================
export const agent = makeAgent({
  authn: Authn,
  gateway: Gateway,
  router: Router,
})<State>($ =>
  observableMerge(
    $.pipe(
      distinctUntilKeyChanged('state'),
      mergeMap(({ state: stateUnion, gateway, router, authn }) => {
        const api = makeApi(gateway);

        return State.match({
          initializing: async () => {
            const { query } = router.location!;
            if (!QueryParameters.guard(query))
              return Action.passwordResetConfirmScreen_initializeError({
                error: toGenericMsg.call({
                  isError: true,
                  text: 'Invalid query parameters.',
                }),
              });

            const { email, code } = query;

            try {
              await api.checkPasswordReset({
                email,
                emailCode: code,
              });
              return Action.passwordResetConfirmScreen_initializeSuccess(query);
            } catch (e) {
              // eslint-disable-next-line prefer-destructuring
              const reply: Reply<void> = e.reply;
              const { errors } = reply;

              if (errors) {
                if (errors.allMatch(e => e.code === gatewayErrors.passwordResetExpired.code)) {
                  return Action.passwordResetConfirmScreen_initializeExpired();
                } else if (
                  errors.allMatch(e => e.code === gatewayErrors.passwordResetCompleted.code)
                ) {
                  router.redirectTo(pathTo('login', {}));
                }
              }

              return Action.passwordResetConfirmScreen_initializeError({
                error: toGenericMsg.fromReply(reply),
              });
            }
          },

          pending: state =>
            api
              .completePasswordReset({
                ...cleanObject(state, 'code', 'password2'),
                emailCode: state.code,
              })
              .then(Authn.as.none(authn).beginSession)
              .then(NOOP.promise),

          default: NOOP.observable,
        })(stateUnion);
      }),
    ),
  ),
);

// ============================
//  selector
// ============================
export const selector = makeSelector({
  dispatch: Dispatch,
})<State, PasswordResetConfirmProps>(({ state, dispatch }) =>
  State.match({
    initializing: PasswordResetConfirmProps.initializing,
    error: PasswordResetConfirmProps.error,
    expired: () =>
      PasswordResetConfirmProps.expired({
        supportPath: hashedPathTo('support', {}),
        passwordResetPath: hashedPathTo('password_reset_init', {}),
      }),
    prompt: state =>
      PasswordResetConfirmProps.prompt({
        ...cleanObject(state, 'code', 'email'),
        supportPath: hashedPathTo('support', {}),
        complete: (fields: FormFields) => {
          if (fields.password !== fields.password2) {
            dispatch(
              Action.passwordResetConfirmScreen_receiveError({
                ...fields,
                error: toGenericMsg.call({
                  isError: true,
                  key__text: toDict.of(['password2', 'Passwords must match!']),
                }),
              }),
            );
          } else {
            dispatch(Action.passwordResetConfirmScreen_submit(fields));
          }
        },
      }),
    pending: PasswordResetConfirmProps.pending,
  })(state),
);
