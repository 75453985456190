import { UserOtpSummary } from '@tuple-health/eng/dist/th/ds/common/product/user/model/UserOtpSummary';
import { ofType } from 'unionize';
import { makeProps } from '../../core/props';
import { WithMaybeError } from '@tuple-health/common';

// -- credentials -- //
interface WithRemember {
  remember: boolean;
}

export interface WithCredentials extends WithRemember {
  handle: string;
  password: string;
}

export type LoginPromptProps = Partial<WithCredentials>;

interface LoginPromptCallbacks {
  onSubmit(credentials: WithCredentials): void;
  supportNeedAccountPath: string;
  forgotPasswordPath: string;
}

// -- otp -- //
export interface WithOtp extends WithRemember {
  otp: string;
}

export interface WithOtpSummary {
  otpSummary: UserOtpSummary;
}

export type OtpPromptProps = WithRemember & Partial<WithOtp> & WithOtpSummary;

interface OtpPromptCallbacks {
  onSubmit(otp: WithOtp): void;
  onResend(otp: WithOtp): void;
  onCancel(): void;
  resent: boolean;
  supportWrongNumberPath: string;
  supportNoCodePath: string;
}

// -- props union -- //
export const LoginProps = makeProps(
  {
    loginPrompt: ofType<LoginPromptProps & WithMaybeError & LoginPromptCallbacks>(),
    loginPending: ofType<LoginPromptProps>(),
    otpPrompt: ofType<OtpPromptProps & WithMaybeError & OtpPromptCallbacks>(),
    otpPending: ofType<OtpPromptProps & { resent: boolean }>(),
    loggedIn: {},
  },
  'status',
);

export type LoginProps = typeof LoginProps._Union;
