import { App } from '../platform/app.model';
import bpciaApp from './bpcia/bpciaApp';
import hipApp from './hip/hipApp';
import ocmApp from './ocm/ocmApp';
import vcmApp from './vcm/vcmApp';

const allProductApps = [ocmApp, bpciaApp, hipApp, vcmApp];

export const key__productApp: Record<string, App<any>> = {};
allProductApps.forEach(app => {
  key__productApp[app.router.appConcept.uri.id] = app;
});
