import React, { lazy } from 'react';
import {
  customArticleScreen,
  genericArticleScreen,
} from '../../../components/article/articleScreens';
import { ocmAnalyticsProduct } from '../../../components/product/analytics/analyticsProductOcm';
import { lookupConceptsFromV1Query, routerKinds } from '../../platform/v1PlatformConcepts';
import { App } from '../../platform/app.model';
import { adaptScreen } from '../../platform/screen.model';
import { OcmLoc, ocmRouter } from './ocm.router';

const ocm_clinician_list = lazy(() =>
  import(
    /* webpackChunkName: "ocm_clinician_list" */ '../../../components/clinicianList/ocmClinicianList/OcmClinicianList'
  ),
);

const article_list = lazy(
  /* webpackChunkName: "article_list" */ () =>
    import('../../../screens/articleList/articleList.screen'),
);

const patients = lazy(
  /* webpackChunkName: "ocm_patients" */ () => import('../../../screens/patients/ocm/OcmPatients'),
);

const OcmEpisodes = lazy(
  /* webpackChunkName: "ocm_episodes" */ () => import('../../../screens/patients/ocm/OcmEpisodes'),
);

const patient_timeline = lazy(() =>
  import(
    /* webpackChunkName: "patient_timeline" */ '../../../components/timeline/view/TimelineScreen'
  ),
);

const ocmApp: App<OcmLoc> = {
  router: ocmRouter,
  lookupSearchingConcepts: async (_session, runRequest, handle, customerRecord) => {
    if (!customerRecord) throw Error('expecting customer');
    try {
      if (customerRecord.key !== 'atlantis') {
        const clinicians = await lookupConceptsFromV1Query(
          runRequest,
          ocmAnalyticsProduct,
          'attributedClinician.npi',
          routerKinds.clinician,
        );
        handle(clinicians);
      }
    } catch (e) {} // TODO causing problesm in tests

    // patients
    // const patients = await lookupConceptsFromV1Query(
    //   platform,
    //   ocmAnalyticsProduct,
    //   'ocmPatient.cmsBeneIdHash',
    // );
    // handle(patients);
    // console.log('ocm patients: ', patients.length);
  },
  screens: {
    globals: genericArticleScreen('globals'),
    // =========================================================================
    // folders
    // =========================================================================
    folders: article_list,
    // =========================================================================
    // articles
    // =========================================================================
    article_list,
    article_detail: customArticleScreen,
    // =========================================================================
    // patients
    // =========================================================================
    patients,
    episodes: () => <OcmEpisodes />,
    patient_timeline,
    // =========================================================================
    // clinicians
    // =========================================================================
    ocm_clinician_list: adaptScreen(ocm_clinician_list),
    ocm_clinician_overview: genericArticleScreen('ocm_clinician_overview'),
    // =========================================================================
    // episode
    // =========================================================================
    ocm_episode_summary: genericArticleScreen('ocm_episode_summary'),
    pp4_update: genericArticleScreen('pp4_update'),
    // ===========================================================================
    // methodology
    // ===========================================================================
    methodology_overview: genericArticleScreen('methodology_overview'),
    methodology_costs: genericArticleScreen('methodology_costs'),
    methodology_pbp: genericArticleScreen('methodology_pbp'),
    methodology_prediction_model: genericArticleScreen('methodology_prediction_model'),
    methodology_metastatic: genericArticleScreen('methodology_metastatic'),
    methodology_updates: genericArticleScreen('methodology_updates'),
    methodology_two_sided: genericArticleScreen('methodology_two_sided'),
    // =========================================================================
    // reconciliation
    // =========================================================================
    recon_financials: genericArticleScreen('recon_financials'),
    recon_cancers: genericArticleScreen('recon_cancers'),
    // recon_markets: createArticleScreen('recon_markets'),
    recon_clinicians: genericArticleScreen('recon_clinicians'),
    // recon_trueups: createArticleScreen('recon_trueups'),
    patient_explorer: genericArticleScreen('patient_explorer'),
    // =========================================================================
    // snf
    // =========================================================================
    snf_overview: genericArticleScreen('snf_overview'),
    snf_utilization: genericArticleScreen('snf_utilization'),
    snf_costs: genericArticleScreen('snf_costs'),
    snf_outliers: genericArticleScreen('snf_outliers'),
    snf_insights: genericArticleScreen('snf_insights'),
    snf_performance: genericArticleScreen('snf_performance'),
    // hh
    // =========================================================================
    hh_overview: genericArticleScreen('hh_overview'),
    hh_utilization: genericArticleScreen('hh_utilization'),
    hh_costs: genericArticleScreen('hh_costs'),
    hh_insights: genericArticleScreen('hh_insights'),
    hh_performance: genericArticleScreen('hh_performance'),
    // =========================================================================
    // =========================================================================
    // eol
    // =========================================================================
    eol_overview: genericArticleScreen('eol_overview'),
    eol_utilization: genericArticleScreen('eol_utilization'),
    eol_costs: genericArticleScreen('eol_costs'),
    eol_outliers: genericArticleScreen('eol_outliers'),
    eol_insights: genericArticleScreen('eol_insights'),
    eol_performance: genericArticleScreen('eol_performance'),
    // =========================================================================
    // home
    // =========================================================================
    customer_home: lazy(() => import('../../../screens/homeOcm/v2/OcmHomeV2')),
    // =========================================================================
    // hosp
    // =========================================================================
    v2_hosp_impact: genericArticleScreen('v2_hosp_impact'),
    v2_hosp_utilization: genericArticleScreen('v2_hosp_utilization'),
    v2_hosp_outliers: genericArticleScreen('v2_hosp_outliers'),
    v2_hosp_cancers: genericArticleScreen('v2_hosp_cancers'),
    v2_hosp_readmissions: genericArticleScreen('v2_hosp_readmissions'),
    v2_hosp_stays: genericArticleScreen('v2_hosp_stays'),

    // =========================================================================
    // ed
    // =========================================================================
    v2_ed_impact: genericArticleScreen('v2_ed_impact'),
    v2_ed_utilization: genericArticleScreen('v2_ed_utilization'),
    v2_ed_observation: genericArticleScreen('v2_ed_observation'),
    v2_ed_metric: genericArticleScreen('v2_ed_metric'),
    v2_ed_outliers: genericArticleScreen('v2_ed_outliers'),
    v2_ed_cancers: genericArticleScreen('v2_ed_cancers'),
    v2_ed_visits: genericArticleScreen('v2_ed_visits'),
    // =========================================================================
    // pac
    // =========================================================================
    v2_pac_impact: genericArticleScreen('v2_pac_impact'),
    v2_pac_utilization: genericArticleScreen('v2_pac_utilization'),
    v2_pac_cancers: genericArticleScreen('v2_pac_cancers'),
    v2_pac_outliers: genericArticleScreen('v2_pac_outliers'),
    v2_pac_stays: genericArticleScreen('v2_pac_stays'),
    // =========================================================================
    // eol
    // =========================================================================
    v2_eol_metric: genericArticleScreen('v2_eol_metric'),
    v2_eol_performance: genericArticleScreen('v2_eol_performance'),
    v2_eol_hospice: genericArticleScreen('v2_eol_hospice'),
    v2_eol_eol: genericArticleScreen('v2_eol_eol'),
    v2_eol_outliers: genericArticleScreen('v2_eol_outliers'),
    v2_eol_quality: genericArticleScreen('v2_eol_quality'),
    // =========================================================================
    // drug
    // =========================================================================
    v2_drug_impact: genericArticleScreen('v2_drug_impact'),
    v2_drug_classes: genericArticleScreen('v2_drug_classes'),
    v2_drug_agents: genericArticleScreen('v2_drug_agents'),
    v2_drug_substitutions: genericArticleScreen('v2_drug_substitutions'),
    v2_drug_orals: genericArticleScreen('v2_drug_orals'),
    v2_drug_outliers: genericArticleScreen('v2_drug_outliers'),
    v2_drug_clinicians: genericArticleScreen('v2_drug_clinicians'),
    // v2_drug_cohorts: genericArticleScreen('v2_drug_cohorts'),
    // =========================================================================
    // radiation
    // =========================================================================
    v2_radiation_impact: genericArticleScreen('v2_radiation_impact'),
    v2_radiation_utilization: genericArticleScreen('v2_radiation_utilization'),
    v2_radiation_protocol: genericArticleScreen('v2_radiation_protocol'),
    v2_radiation_costs: genericArticleScreen('v2_radiation_costs'),
    v2_radiation_outliers: genericArticleScreen('v2_radiation_outliers'),
    // =========================================================================
    // home health
    // =========================================================================
    v2_hh_impact: genericArticleScreen('v2_hh_impact'),
    v2_hh_utilization: genericArticleScreen('v2_hh_utilization'),
    v2_hh_diagnosis: genericArticleScreen('v2_hh_diagnosis'),
    v2_hh_provider: genericArticleScreen('v2_hh_provider'),
  },
};

export default ocmApp;
