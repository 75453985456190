import * as toDict from '@tuple-health/eng/dist/dryscript/lib/common/prelude/dict/toDict';
import * as toGenericMsg from '@tuple-health/eng/dist/dryscript/lib/common/ui/content/msg/toGenericMsg';
import * as gatewayErrors from '@tuple-health/eng/dist/th/ds/common/agent/gatewayApi/service/gatewayErrors';
import { Record, String } from 'runtypes';
import { merge as observableMerge } from 'rxjs';
import { distinctUntilKeyChanged, first, mergeMap } from 'rxjs/operators';
import { ofType } from 'unionize';
import { pathTo, hashedPathTo } from '../../app/locs';
import { Authn } from '../../contracts/Authn';
import { Dispatch } from '../../contracts/Dispatch';
import { Gateway } from '../../contracts/Gateway';
import { makeActions } from '../../core/action';
import { makeAgent, NOOP } from '../../core/agent';
import { Router } from '../../core/appbase/router.service';
import { cleanObject, constant, pipe, WithError, WithMaybeError } from '@tuple-health/common';

import { makeReducer } from '../../core/reducer';
import { makeSelector } from '../../core/selector';
import { makeStates } from '../../core/state';

import { FIELD_HANDLE, FIELD_INVITE_ID, FIELD_PHONE_RECORD } from '../support/support.screen';
import {
  OtpPromptProps,
  PasswordPromptProps,
  RegistrationProps,
  SubmitPasswordParams,
  WithPhoneOtp,
} from './registration.props';
import { makeApi } from '../../services/gateway/api';
import { Reply } from '@tuple-health/eng/dist/dryscript/ds';

const QueryParameters = Record({
  email: String,
  invite: String,
});

// ============================
//  state
// ============================
type BaseProps = OtpPromptProps & {
  email: string;
  emailCode: string;
};

interface WithResent {
  resent: boolean;
}

const State = makeStates({
  initializing: {},
  error: ofType<WithError>(),
  otpSendPrompt: ofType<BaseProps & WithMaybeError>(),
  otpSendPending: ofType<BaseProps>(),
  otpSubmitPrompt: ofType<BaseProps & WithResent & Partial<WithPhoneOtp> & WithMaybeError>(),
  otpResendPending: ofType<BaseProps & WithResent & Partial<WithPhoneOtp>>(),
  otpSubmitPending: ofType<BaseProps & WithResent & WithPhoneOtp>(),
  passwordPrompt: ofType<BaseProps & WithPhoneOtp & PasswordPromptProps & WithMaybeError>(),
  passwordPending: ofType<BaseProps & WithPhoneOtp & PasswordPromptProps & SubmitPasswordParams>(),
  accountCreated: {},
});
type State = typeof State._Union;

// ============================
//  action
// ============================
const Action = makeActions({
  registrationScreen_initializeError: ofType<WithError>(),
  registrationScreen_initializeOtpPrompt: ofType<BaseProps>(),
  registrationScreen_sendOtp: {},
  registrationScreen_receiveSendOtpSuccess: {},
  registrationScreen_receiveSendOtpFailure: ofType<WithError>(),
  registrationScreen_submitOtp: ofType<WithPhoneOtp>(),
  registrationScreen_resendOtp: ofType<WithPhoneOtp>(),
  registrationScreen_receiveSubmitOtpSuccess: {},
  registrationScreen_receiveSubmitOtpFailure: ofType<WithError>(),
  registrationScreen_submitPassword: ofType<SubmitPasswordParams>(),
  registrationScreen_receivePasswordFailure: ofType<Partial<SubmitPasswordParams> & WithError>(),
});
type Action = typeof Action._Union;

// ============================
//  reducer
// ============================
export const reducer = makeReducer<State, Action>(State.initializing(), s => a =>
  State.match(s, {
    initializing: () =>
      Action.match(a, {
        registrationScreen_initializeError: State.error,
        registrationScreen_initializeOtpPrompt: State.otpSendPrompt,
        default: constant(s),
      }),
    otpSendPrompt: state =>
      Action.match(a, {
        registrationScreen_sendOtp: () => State.otpSendPending(cleanObject(state, 'error')),
        default: constant(s),
      }),
    otpSendPending: state =>
      Action.match(a, {
        registrationScreen_receiveSendOtpSuccess: () =>
          State.otpSubmitPrompt({ ...state, error: undefined, resent: false }),
        registrationScreen_receiveSendOtpFailure: action =>
          State.otpSendPrompt({ ...state, ...action }),
        default: constant(s),
      }),
    otpSubmitPrompt: state =>
      Action.match(a, {
        registrationScreen_submitOtp: action =>
          State.otpSubmitPending({ ...cleanObject(state, 'error'), ...action }),
        registrationScreen_resendOtp: action =>
          State.otpResendPending({ ...cleanObject(state, 'error'), ...action, resent: true }),
        default: constant(s),
      }),
    otpResendPending: state =>
      Action.match(a, {
        registrationScreen_receiveSendOtpSuccess: () =>
          State.otpSubmitPrompt({ ...state, error: undefined }),
        registrationScreen_receiveSendOtpFailure: action =>
          State.otpSubmitPrompt({ ...state, ...action }),
        default: constant(s),
      }),
    otpSubmitPending: state =>
      Action.match(a, {
        registrationScreen_receiveSubmitOtpSuccess: () =>
          State.passwordPrompt({ ...cleanObject(state, 'resent'), error: undefined }),
        registrationScreen_receiveSubmitOtpFailure: action =>
          State.otpSubmitPrompt({ ...state, ...action }),
        default: constant(s),
      }),
    passwordPrompt: state =>
      Action.match(a, {
        registrationScreen_submitPassword: action =>
          State.passwordPending({ ...cleanObject(state, 'error'), ...action }),
        registrationScreen_receivePasswordFailure: action =>
          State.passwordPrompt({ ...state, ...action }),
        default: constant(s),
      }),
    passwordPending: state =>
      Action.match(a, {
        registrationScreen_receivePasswordFailure: action =>
          State.passwordPrompt({ ...state, ...action }),
        default: constant(s),
      }),
    accountCreated: State.accountCreated,
    default: constant(s),
  }),
);

// ============================
//  agent
// ============================
export const agent = makeAgent({
  authn: Authn,
  gateway: Gateway,
  router: Router,
})<State>($ =>
  observableMerge(
    $.pipe(
      first(),
      mergeMap(async ({ gateway, router }) => {
        const api = makeApi(gateway);

        const { query } = router.location!;
        if (!QueryParameters.guard(query))
          return Action.registrationScreen_initializeError({
            error: toGenericMsg.call({
              isError: true,
              text: 'Invalid query parameters.',
            }),
          });

        const { email, invite: emailCode } = query;

        try {
          const summary = await api.checkOnboard({
            email,
            emailCode,
          });

          return Action.registrationScreen_initializeOtpPrompt({
            emailCode,
            email,
            summary,
          });
        } catch (e) {
          // eslint-disable-next-line prefer-destructuring
          const reply: Reply<void> = e.reply;

          if (reply.errors) {
            if (reply.errors.allMatch(e => e.code === gatewayErrors.onboardExpired.code)) {
              router.redirectTo(
                pathTo(
                  'support_welcome_expired',
                  {},
                  {
                    [FIELD_INVITE_ID]: emailCode,
                    [FIELD_HANDLE]: email,
                  },
                ),
              );
            } else if (reply.errors.allMatch(e => e.code === gatewayErrors.onboardCompleted.code)) {
              router.redirectTo(pathTo('login', {}));
            }
          }

          return Action.registrationScreen_initializeError({
            error: toGenericMsg.fromReply(reply),
          });
        }
      }),
    ),

    $.pipe(
      distinctUntilKeyChanged('state'),
      mergeMap(({ state: stateUnion, gateway, authn }) => {
        const api = makeApi(gateway);

        return State.match({
          otpSendPending: state =>
            api
              .sendOnboardOtp({
                ...cleanObject(state, 'summary', 'phoneOtp'),
                userPhone: undefined,
              })
              .then(Action.registrationScreen_receiveSendOtpSuccess)
              .catch(e =>
                Action.registrationScreen_receiveSendOtpFailure({
                  error: toGenericMsg.fromReply(e.reply),
                }),
              ),

          otpResendPending: state =>
            api
              .sendOnboardOtp({
                ...cleanObject(state, 'summary', 'phoneOtp', 'resent'),
                userPhone: undefined,
              })
              .then(Action.registrationScreen_receiveSendOtpSuccess)
              .catch(e =>
                Action.registrationScreen_receiveSendOtpFailure({
                  error: toGenericMsg.fromReply(e.reply),
                }),
              ),

          otpSubmitPending: state =>
            api
              .checkOnboardOtp(cleanObject(state, 'summary'))
              .then(Action.registrationScreen_receiveSubmitOtpSuccess)
              .catch(e =>
                Action.registrationScreen_receiveSubmitOtpFailure({
                  error: toGenericMsg.fromReply(e.reply),
                }),
              ),

          passwordPending: state =>
            api
              .completeOnboard(cleanObject(state, 'summary', 'password2'))
              .then(Authn.as.none(authn).beginSession)
              .then(NOOP.promise)
              .catch(e =>
                Action.registrationScreen_receivePasswordFailure({
                  error: toGenericMsg.fromReply(e.reply),
                }),
              ),

          default: NOOP.observable,
        })(stateUnion);
      }),
    ),
  ),
);

// ============================
//  selector
// ============================
export const selector = makeSelector({
  authn: Authn,
  dispatch: Dispatch,
})<State, RegistrationProps>(({ state, authn, dispatch }) =>
  Authn.match({
    none: () =>
      State.match({
        initializing: RegistrationProps.initializing,
        error: RegistrationProps.error,
        otpSendPrompt: otpSendPrompt =>
          RegistrationProps.otpSendPrompt({
            ...cleanObject(otpSendPrompt, 'email', 'emailCode'),
            sendOtp: () => dispatch(Action.registrationScreen_sendOtp()),
            supportWrongNumberPath: hashedPathTo(
              'support_welcome_wrong_number',
              {},
              {
                [FIELD_INVITE_ID]: otpSendPrompt.emailCode,
                [FIELD_HANDLE]: otpSendPrompt.email,
                [FIELD_PHONE_RECORD]: otpSendPrompt.summary.invitePhone,
              },
            ),
          }),
        otpSendPending: RegistrationProps.otpSendPending,
        otpSubmitPrompt: otpSubmitPrompt =>
          RegistrationProps.otpSubmitPrompt({
            ...cleanObject(otpSubmitPrompt, 'email', 'emailCode'),
            onResend: pipe(Action.registrationScreen_resendOtp, dispatch),
            onSubmit: pipe(Action.registrationScreen_submitOtp, dispatch),
            supportNoCodePath: hashedPathTo(
              'support_welcome_no_code',
              {},
              {
                [FIELD_INVITE_ID]: otpSubmitPrompt.emailCode,
                [FIELD_HANDLE]: otpSubmitPrompt.email,
                [FIELD_PHONE_RECORD]: otpSubmitPrompt.summary.invitePhone,
              },
            ),
            supportWrongNumberPath: hashedPathTo(
              'support_welcome_wrong_number',
              {},
              {
                [FIELD_INVITE_ID]: otpSubmitPrompt.emailCode,
                [FIELD_HANDLE]: otpSubmitPrompt.email,
                [FIELD_PHONE_RECORD]: otpSubmitPrompt.summary.invitePhone,
              },
            ),
          }),
        otpResendPending: RegistrationProps.otpSubmitPending,
        otpSubmitPending: RegistrationProps.otpSubmitPending,
        passwordPrompt: state =>
          RegistrationProps.passwordPrompt({
            ...cleanObject(state, 'emailCode', 'email'),
            onSubmit: (params: SubmitPasswordParams) => {
              if (params.password !== params.password2) {
                dispatch(
                  Action.registrationScreen_receivePasswordFailure({
                    ...params,
                    error: toGenericMsg.call({
                      isError: true,
                      key__text: toDict.of(['password2', 'Passwords must match!']),
                    }),
                  }),
                );
              } else {
                dispatch(Action.registrationScreen_submitPassword(params));
              }
            },
          }),
        passwordPending: RegistrationProps.passwordPending,
        accountCreated: () => {
          throw new Error('impossible');
        },
      })(state),
    session: RegistrationProps.accountCreated,
    initializing: () => {
      throw new Error('impossible');
    },
  })(authn),
);
