import { Analytics } from './index';

const matomoAnalytics: Analytics = {
  initialize() {
    ma('enableHeartBeatTimer');
    ma('enableJSErrorTracking');
  },
  setUserId(userId) {
    ma('setUserId', userId);
  },
  pageView(title, path, lastPath) {
    ma('setCustomUrl', path);
    if (lastPath) ma('setReferrerUrl', lastPath);
    ma('setDocumentTitle', title);
    ma('setGenerationTimeMs', 0);
    ma('trackPageView');
  },
  event(...args) {
    ma('trackEvent', ...args);
  },
  error() {
    // noop; will get automatically logged via enableJSErrorTracking
  },
};

export default matomoAnalytics;

const ma = <K extends keyof MatomoApi>(eventName: K, ...args: Parameters<MatomoApi[K]>) => {
  const { _paq } = window as any;
  // Will be undefined when testing in node and Chromatic
  if (_paq) _paq.push([eventName, ...args]);
};

// See: https://developer.matomo.org/guides/tracking-javascript
interface MatomoApi {
  enableHeartBeatTimer(delayInSeconds?: number): void;
  enableJSErrorTracking(): void;
  setCustomUrl(url: string): void;
  setDocumentTitle(title: string): void;
  setGenerationTimeMs(generationTime: number): void;
  setReferrerUrl(url: string): void;
  setUserId(userId: string): void;
  trackEvent(category: string, action: string, name?: string, value?: number): void;
  trackPageView(): void;
}
