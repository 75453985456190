import { useMemo } from 'react';
import { UserConf, UserConfKey } from './userConf.model';
import useMethods from 'use-methods';

export interface UserConfMachineArgs {
  initialConf: Partial<Record<UserConfKey, string>>;
}

export type UserConfMachine = ReturnType<typeof useUserConfMachine>;

export function useUserConfMachine(args: UserConfMachineArgs) {
  const initialState: UserConf = useMemo(() => init(args), [args]);

  const [state, callbacks] = useMethods(methods, initialState);

  //TODO, these defaults are hard coded in the gateway also (toUserConf.py)
  const convoShowBrowserNotes = useMemo(
    () => toBool({ val: state.key__val.convoShowBrowserNotes, defaultVal: false }),
    [state.key__val],
  );

  const convoSendReminderEmail = useMemo(
    () => toBool({ val: state.key__val.convoSendReminderEmail, defaultVal: true }),
    [state.key__val],
  );

  // TODO, defaults
  const convoSendDigestEmail = useMemo(
    () => toBool({ val: state.key__val.convoSendDigestEmail, defaultVal: false }),
    [state.key__val],
  );

  return useMemo(
    () => ({
      ...state,
      ...callbacks,
      convoShowBrowserNotes,
      convoSendReminderEmail,
      convoSendDigestEmail,
    }),
    [state, callbacks, convoShowBrowserNotes, convoSendReminderEmail, convoSendDigestEmail],
  );
}

function init({ initialConf }: UserConfMachineArgs): UserConf {
  const key__val = initialConf as Record<UserConfKey, string>;

  return {
    key__val,
  };
}

function methods(userConf: UserConf) {
  function setKey(key: UserConfKey, val: string) {
    userConf.key__val[key] = val;
  }

  // function resetKeys(key__val?: Record<UserConfKey, string>) {
  //   const emptyRecord: Record<UserConfKey, string> = {};
  //   userConf.key__val = key__val || emptyRecord;
  // }

  return {
    setKey,
    // resetKeys,
    disableKeys(keys: UserConfKey[]) {
      for (const key of keys) {
        setKey(key, toBoolStr(false));
      }
    },
    toggleConvoShowBrowserNotes() {
      setKey('convoShowBrowserNotes', toBoolStr(!isTrue(userConf.key__val.convoShowBrowserNotes)));
    },
    toggleConvoSendReminderEmail() {
      setKey(
        'convoSendReminderEmail',
        toBoolStr(!isTrue(userConf.key__val.convoSendReminderEmail)),
      );
    },
    toggleConvoSendDigestEmail() {
      setKey('convoSendDigestEmail', toBoolStr(!isTrue(userConf.key__val.convoSendDigestEmail)));
    },
  };
}

function toBool({ val, defaultVal }: { val?: string; defaultVal: boolean }) {
  if (val === undefined) return defaultVal;
  return isTrue(val);
}

function isTrue(val?: string) {
  return val === '1';
}

function toBoolStr(flag: boolean) {
  return flag ? '1' : '0';
}
