import { createContext } from 'react';
import {
  MessageHandler,
  registerServiceWorkerListener,
  deregisterServiceWorkerListener,
} from './actor/registerServiceWorker';

export interface PushNoteStore {
  /** @returns success or failure of registration */
  registerPushListener: (handler: MessageHandler) => boolean;
  /** @returns success or failure of deregistration */
  deregisterPushListener: (handler: MessageHandler) => boolean;
}

export const PushNoteContext = createContext<PushNoteStore>({
  registerPushListener: registerServiceWorkerListener,
  deregisterPushListener: deregisterServiceWorkerListener,
});
