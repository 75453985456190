import { App } from '../../platform/app.model';
import { dummyScreen } from '../../platform/screen.model';
import { HipLoc, hipRouter } from './hip.router';

/*
const home = defineScreen(() => (
  <Page>
    <h3 style={{ marginTop: 0 }}>Home</h3>
    Navigation Review
    <ul>
      <li>
        Navigation levels
        <ul>
          <li>Top-Bar (can include a dropdown like Reports)</li>
          <li>
            Side-Bar
            <ul>
              <li>General side-bar under "Home"</li>
              <li>Concept specific side-bar for individual providers and patients</li>
            </ul>
          </li>
          <li>Tab-bar under "Reports {'>'} ED Visits"</li>
        </ul>
      </li>
      <li>
        Search
        <ul>
          <li>Can search for any global page</li>
          <li>Can also search for any page within a concept (e.g. patients or providers)</li>
        </ul>
      </li>
      <li>
        Breadcrumbs
        <ul>
          <li>Shown on pages otehr than the home page</li>
          <li>
            Click on concepts to switch to another concept with same type. This preserves your
            relative location within the concept (e.g. staying at patient home vs patient timeline
            vs patient goals when switching patient).
          </li>
        </ul>
      </li>
      <li>Logo becomes a back button</li>
      <li>
        Other top-bar functionality
        <ul>
          <li>
            Organization and app/product name (clickable if have multiple orgs and/or multiple apps)
          </li>
          <li>User menu for logout, password, and other user settings</li>
          <li>User notifitions for messaging</li>
        </ul>
      </li>
      <li>
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://docs.google.com/document/d/1m4oXAkLRdrNV3Wq7jJbauTxpStoce2i_SPqynte0L1k/edit?usp=sharing"
        >
          Design rationale document (somewhat out of date now)
        </a>
      </li>
    </ul>
  </Page>
));

const providers = defineScreen(() => {
  const linker = useLinker();
  return (
    <Page>
      <ul>
        {hipDb.providers.map((provider, i) => (
          <li key={i}>
            <a {...linker.routeLink(hipRouter.route('provider', { concept: provider }))}>
              {provider.decodedLabel}
            </a>
          </li>
        ))}
      </ul>
    </Page>
  );
});

const patients = defineScreen(() => {
  const linker = useLinker();
  return (
    <Page>
      <ul>
        {hipDb.patients.map((patient, i) => (
          <li key={i}>
            <a {...linker.routeLink(hipRouter.route('patient', { concept: patient }))}>
              {patient.decodedLabel}
            </a>
          </li>
        ))}
      </ul>
    </Page>
  );
});
*/
const hipApp: App<HipLoc> = {
  router: hipRouter,
  lookupSearchingConcepts: async (_session, _runRequest, _handle) => {
    // await delay(5000); // for async tesing // TODO delete or replace with sleep
    // handle(hipDb.allConcepts);
  },
  screens: {
    me: dummyScreen('home'),
    me_conversations: dummyScreen('me_conversations'),
    providers: dummyScreen('providers'),
    provider: dummyScreen('provider'),
    provider_report: dummyScreen('provider_report'),
    patients: dummyScreen('patients'),
    patient: dummyScreen('patient'),
    patient_timeline: dummyScreen('patient_timeline'),
    patient_goals: dummyScreen('patient_goals'),
    ed: dummyScreen('ed'),
    ed_utilization: dummyScreen('ed_utilization'),
    ed_costs: dummyScreen('ed_costs'),
    ed_outliers: dummyScreen('ed_outliers'),
    ed_insights: dummyScreen('ed_insights'),
    ed_performance: dummyScreen('ed_performance'),
    hospitalizations: dummyScreen('hospitalizations'),
  },
};

export default hipApp;
