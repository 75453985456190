import { createRouter, vocabulary } from '@tuple-health/common/dist/router';
import { routerKinds } from '../../platform/v1PlatformConcepts';

// =============================================================================
// locs
// =============================================================================

const locs = vocabulary({
  home: undefined,
  signout: 'logout',
  changePassword: undefined,
  conversations: undefined,
  conversation: undefined,
  invitations: undefined,
  orgs: undefined,
  org: undefined,
});

export type UserLoc = typeof locs.Term;

// =============================================================================
// builder
// =============================================================================

const _ = createRouter<UserLoc>('user', 'User', locs);

// =============================================================================
// home
// =============================================================================

export const logoutSlug = 'sign-out';

_.add(
  _.page({
    loc: 'home',
    label: 'Me',
    childItems: [
      _.page({
        loc: 'signout',
        slug: logoutSlug,
        label: 'Sign Out',
      }),
      _.page({
        loc: 'changePassword',
        slug: 'change-password',
        label: 'Change Password',
      }),
      _.page({
        loc: 'conversations',
        slug: 'conversations',
        label: 'Conversations',
        visibility: 'tupleStaff',
        childConcept: _.concept(
          routerKinds.conversation,
          _.page({
            loc: 'conversation',
            label: 'Conversation',
            visibility: 'tupleStaff',
            // TODO showBreadcrumbs: false,
          }),
        ),
      }),
      _.page({
        loc: 'invitations',
        slug: 'invitations',
        label: 'Manage Invitations',
        visibility: 'tupleStaff',
      }),
      _.page({
        loc: 'orgs',
        slug: 'orgs',
        label: 'Organizations',
        childConcept: _.concept(
          routerKinds.customerSlug,
          _.page({
            loc: 'org',
            label: 'Organization',
            overflow: true,
          }),
        ),
      }),
    ],
  }),
);

// =============================================================================
// router
// =============================================================================

export const userRouter = _.build();
