import { Button, TextField } from '@material-ui/core';
import { observer } from 'mobx-react';
import React from 'react';
import { Helmet } from 'react-helmet';
import { style } from 'typestyle';
import CenteredFrame from '../../components/CenteredFrame';
import { Declassify } from '../../core/store/storeView';
import { ChangePasswordStore, ChangePasswordSubmission } from './changePassword.store';

interface Props {
  store: Declassify<ChangePasswordStore>;
}

type State = ChangePasswordSubmission;

@observer
export default class ChangePassword extends React.Component<Props, State> {
  state: State = {
    oldPassword: '',
    newPassword: '',
    reenteredPassword: '',
  };

  render() {
    const {
      props: { store },
      state,
    } = this;

    const onSubmit = store.status === 'pending' ? undefined : () => store.request(state);

    const onKeyPress = (ev: React.KeyboardEvent<{}>) => {
      if (onSubmit && ev.key === 'Enter') {
        ev.preventDefault();
        void onSubmit();
      }
    };

    const isErrored = store.status === 'error';

    let msgGeneric: string | undefined;
    let msgOldPassword: string | undefined;
    let msgNewPassword: string | undefined;
    let msgRePassword: string | undefined;
    switch (store.status) {
      case 'success':
        msgGeneric = 'Successfully changed password.';
        // TODO ideally clear form
        break;
      case 'error':
        msgGeneric = store.errorGeneric;
        msgOldPassword = store.errorOldPassword;
        msgNewPassword = store.errorNewPassword;
        msgRePassword = store.errorRePassword;
        break;
    }

    return (
      <CenteredFrame>
        <Helmet title="Change Password" />
        <TextField
          id="changePassword--oldPassword"
          type="password"
          label="Old password"
          disabled={!onSubmit}
          onChange={e => this.setState({ oldPassword: e.target.value })}
          onKeyPress={onKeyPress}
          className={classes.textField}
          error={msgOldPassword !== undefined}
          helperText={msgOldPassword || ' '}
        />
        <br />
        <TextField
          id="changePassword--newPassword"
          type="password"
          label="New password"
          disabled={!onSubmit}
          onChange={e => this.setState({ newPassword: e.target.value })}
          onKeyPress={onKeyPress}
          className={classes.textField}
          error={msgNewPassword !== undefined}
          helperText={msgNewPassword || ' '}
        />
        <br />
        <TextField
          id="changePassword--reenteredPassword"
          type="password"
          label="Re-enter password"
          disabled={!onSubmit}
          onChange={e => this.setState({ reenteredPassword: e.target.value })}
          onKeyPress={onKeyPress}
          className={classes.textField}
          error={msgRePassword !== undefined}
          helperText={msgRePassword || ' '}
        />
        <br />
        <br />
        <Button variant="contained" color="primary" disabled={!onSubmit} onClick={onSubmit}>
          Submit
        </Button>
        <br />
        <div className={isErrored ? classes.labelError : classes.labelOk}>
          {msgGeneric && (isErrored ? '✖ ' : '✔ ') + msgGeneric}
        </div>
      </CenteredFrame>
    );
  }
}

const classes = {
  textField: style({ marginBottom: 10, width: '100%' }),
  labelOk: style({ height: 15, marginTop: 10, color: 'green' }),
  labelError: style({ height: 15, marginTop: 10, color: 'red' }),
};
