import React, {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useMemo,
} from 'react';
import useApi from '../../core/store/useApi';
import { PushHandlerMachine, usePushHandlerMachine } from '../machine/pushHandler.machine';
import {
  canSubscibeToPushNotifications,
  isSubscribedToPushNotifications,
  registerPushSubscription,
} from './pushNotificationsBrowserApi';

interface PushHandlerAgent extends PushHandlerMachine {
  register: () => void;
}

function usePushHandlerAgent(): PushHandlerAgent {
  const api = useApi();

  const machine = usePushHandlerMachine({
    hasPushSupport: undefined,
    isPushEnabled: undefined,
  });

  const { hasPushSupport, updatePushSupport, updatePushStatus } = machine;

  useEffect(() => {
    let alive = true;
    void canSubscibeToPushNotifications().then(
      canSubscribe => alive && updatePushSupport(canSubscribe),
    );
    return () => {
      alive = false;
    };
  }, [updatePushSupport]);

  useEffect(() => {
    if (!hasPushSupport) return;

    let alive = true;
    void isSubscribedToPushNotifications().then(
      isSubscribed => alive && updatePushStatus(isSubscribed),
    );
    return () => {
      alive = false;
    };
  }, [hasPushSupport, updatePushStatus]);

  const register = useCallback(() => {
    let alive = true;
    void registerPushSubscription(api).then(
      didSubscribe => alive && updatePushStatus(didSubscribe),
    );
    return () => {
      alive = false;
    };
  }, [api, updatePushStatus]);

  return useMemo(
    () => ({
      ...machine,
      register,
    }),
    [machine, register],
  );
}

export function usePushHandler() {
  const actor = useContext(PushHandlerContext);
  if (!actor) throw Error('No PushHandler context found');
  return actor;
}

export const PushHandlerContext = createContext<PushHandlerAgent | undefined>(undefined);
export const PushHandlerProvider = PushHandlerContext.Provider;

export function PushHandlerCreator(props: { children: ReactNode }) {
  const actor = usePushHandlerAgent();

  return <PushHandlerContext.Provider value={actor}>{props.children}</PushHandlerContext.Provider>;
}
