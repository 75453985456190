import { ofType } from 'unionize';

export interface Contract<I> {
  _injectiontag: string;
  _injectiontype: I;
}

export const makeContract = <I>(tag: string): Contract<I> => ({
  _injectiontag: tag,
  _injectiontype: ofType<I>(),
});
