import { Button, Menu, MenuItem } from '@material-ui/core';
import DropdownIcon from '@material-ui/icons/ExpandMore';
import React, { useCallback, useState } from 'react';
import useLinker from '../../core/store/useLinker';
import useNavMenu from '../../core/store/useNavMenu';
import useVisibility from '../../core/store/useVisibility';
import {
  RouterLoc,
  RouterMenuFolder,
  RouterMenuItem,
  RouterMenuPage,
} from '@tuple-health/common/dist/router';
import css from './TopMenu.css';

export default function TopMenu() {
  const visibility = useVisibility();
  const { topMenu } = useNavMenu();
  return (
    <>
      {topMenu.items.filter(visibility.isVisible).map((item, i) => (
        <TopMenuItem key={i} item={item} />
      ))}
    </>
  );
}

// =============================================================================

const TopMenuItem = <Loc extends RouterLoc>({ item }: { item: RouterMenuItem<Loc> }) => {
  switch (item.tag) {
    case 'folder':
      return <TopMenuFolder folder={item} />;
    case 'page':
      return <TopMenuPage page={item} />;
  }
};

// =============================================================================

const TopMenuPage = <Loc extends RouterLoc>({ page }: { page: RouterMenuPage<Loc> }) => {
  const linker = useLinker();
  return (
    <Button
      id={page.id}
      className={css.button}
      style={{
        backgroundColor: page.active ? '#D1D9DD' : undefined,
      }}
      component="a"
      {...linker.pathLink(page.path)}
    >
      {page.label}
    </Button>
  );
};

// =============================================================================

function TopMenuFolder<Loc extends RouterLoc>({ folder }: { folder: RouterMenuFolder<Loc> }) {
  const visibility = useVisibility();
  const linker = useLinker();

  const [anchor, setAnchor] = useState<HTMLElement | undefined>(undefined);
  const open = Boolean(anchor);

  // const handleChange = useCallback((event: any) => {
  //   setAnchor(event.target.checked);
  // }, []);

  const handleMenu = useCallback((event: any) => {
    setAnchor(event.currentTarget);
  }, []);

  const handleClose = useCallback(() => {
    setAnchor(undefined);
  }, []);

  return (
    <>
      <Button
        onClick={handleMenu}
        style={{
          paddingLeft: 10,
          paddingRight: 0,
          minWidth: 0,
          fontSize: 13,
          color: 'white',
          backgroundColor: folder.active ? '#777' : 'transparent',
        }}
      >
        {folder.label}
        <DropdownIcon style={{ marginRight: 5 }} />
      </Button>
      <Menu id="menu-appbar" anchorEl={anchor} open={open} onClose={handleClose}>
        {folder.pages.filter(visibility.isVisible).map((page, i) => (
          <MenuItem key={i} {...linker.pathButton(page.path, handleClose)}>
            {page.label}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
}
