import { cleanObject, constant, pipe, WithError, WithMaybeError } from '@tuple-health/common';
import * as toGenericMsg from '@tuple-health/eng/dist/dryscript/lib/common/ui/content/msg/toGenericMsg';
import { distinctUntilKeyChanged, mergeMap } from 'rxjs/operators';
import { ofType } from 'unionize';
import { hashedPathTo } from '../../../app/locs';
import { Authn } from '../../../contracts/Authn';
import { Dispatch } from '../../../contracts/Dispatch';
import { Gateway } from '../../../contracts/Gateway';
import { makeActions } from '../../../core/action';
import { makeAgent, NOOP } from '../../../core/agent';
import { Router } from '../../../core/appbase/router.service';
import { makeReducer } from '../../../core/reducer';
import { makeSelector } from '../../../core/selector';
import { makeStates } from '../../../core/state';
import { makeApi } from '../../../services/gateway/api';
import { PasswordResetInitiateProps as Props, PromptFields } from './props';

// ============================
//  state
// ============================
const State = makeStates({
  prompt: ofType<PromptFields & WithMaybeError>(),
  pending: ofType<PromptFields>(),
  complete: {},
});
type State = typeof State._Union;

// ============================
//  action
// ============================
const Action = makeActions({
  passwordResetInitiateScreen_initiate: ofType<PromptFields>(),
  passwordResetInitiateScreen_receiveFailure: ofType<WithError>(),
  passwordResetInitiateScreen_receiveSuccess: {},
});
type Action = typeof Action._Union;

// ============================
//  reducer
// ============================
export const reducer = makeReducer<State, Action>(State.prompt({ email: '', phone: '' }), s => a =>
  State.match(s, {
    prompt: state =>
      Action.match(a, {
        passwordResetInitiateScreen_initiate: action =>
          State.pending({ ...cleanObject(state, 'error'), ...action }),
        default: constant(s),
      }),
    pending: state =>
      Action.match(a, {
        passwordResetInitiateScreen_receiveSuccess: () =>
          State.complete({ ...state, error: undefined, resent: false }),
        passwordResetInitiateScreen_receiveFailure: action => State.prompt({ ...state, ...action }),
        default: constant(s),
      }),
    complete: State.complete,
  }),
);

// ============================
//  agent
// ============================
export const agent = makeAgent({
  authn: Authn,
  gateway: Gateway,
  router: Router,
})<State>($ =>
  $.pipe(
    distinctUntilKeyChanged('state'),
    mergeMap(({ state: stateUnion, gateway }) => {
      const api = makeApi(gateway);

      return State.match({
        pending: state =>
          api
            .sendPasswordReset(state)
            .then(() => Action.passwordResetInitiateScreen_receiveSuccess())
            .catch(e =>
              Action.passwordResetInitiateScreen_receiveFailure({
                error: toGenericMsg.fromReply(e.reply),
              }),
            ),
        default: NOOP.promise,
      })(stateUnion);
    }),
  ),
);

// ============================
//  selector
// ============================
export const selector = makeSelector({
  dispatch: Dispatch,
})<State, Props>(({ state, dispatch }) =>
  State.match({
    prompt: prompt =>
      Props.prompt({
        ...prompt,
        initiate: pipe(Action.passwordResetInitiateScreen_initiate, dispatch),
        supportPath: hashedPathTo('support', {}),
      }),
    pending: Props.pending,
    complete: () =>
      Props.complete({
        supportPath: hashedPathTo('support', {}),
      }),
  })(state),
);
