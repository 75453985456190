import { RoutesMap } from 'redux-first-router';
import { FrameState } from '../../services/frame/frame.service';
import { Contract } from '../contract';
import { combineServices, ComposedState, Service, ServiceMap } from '../service';
import { makeRouter } from './router.service';
import { ScreenParameterMap, ScreenRoutes, ScreenState } from './screen';
import { GenericScreenProps, makeScreenWidget } from './screen.widget';

export interface App<S extends ScreenParameterMap, M> {
  routes: RoutesMap<{}, any>;
  service: Service<{
    services: M;
    screen: ComposedState<ScreenState<S>, FrameState>;
  }>;
  contract: Contract<GenericScreenProps>;
  render: (props: GenericScreenProps) => React.ReactElement<any>;
}

export function makeApp<S extends ScreenParameterMap, M>(
  screens: ScreenRoutes<S>,
  services: ServiceMap<M>,
): App<S, M> {
  const { routes, router } = makeRouter(screens);
  const screen = makeScreenWidget(screens, router);

  const service = combineServices({
    services: combineServices(services),
    screen: screen.service,
  });

  return {
    routes,
    service,
    contract: screen.contract,
    render: screen.render,
  };
}
