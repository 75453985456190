import React, { createContext, ReactNode, useContext, useMemo, useState } from 'react';
import { ConvoPointer } from '../../components/messaging/chatAgent';

interface Agent {
  initialConvo?: ConvoPointer;
  setInitialConvo(convo: ConvoPointer): void;
}

const Context = createContext<Agent | undefined>(undefined);

const { Provider } = Context;

interface Props {
  children?: ReactNode;
}

export function InitialConvoCreator({ children }: Props) {
  const [initialConvo, setInitialConvo] = useState<ConvoPointer | undefined>(undefined);

  const agent: Agent = useMemo(
    () => ({
      initialConvo,
      setInitialConvo,
    }),
    [initialConvo],
  );

  return <Provider value={agent}>{children}</Provider>;
}

export default function useInitialConvo() {
  const context = useContext(Context);
  if (!context) throw Error('initial convo context not provided');
  return context;
}
