import {
  Collapse,
  ListItem,
  ListItemIcon,
  ListItemText,
  Theme,
  WithStyles,
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import { observer } from 'mobx-react';
import React, { useState } from 'react';
import { style } from 'typestyle';
import useLinker from '../../core/store/useLinker';
import useVisibility from '../../core/store/useVisibility';
import { classNames } from '../../core/styles';
import {
  RouterMenu,
  RouterMenuFolder,
  RouterMenuItem,
  RouterMenuPage,
} from '@tuple-health/common/dist/router';

interface Props {
  menu: RouterMenu<any>;
}

interface State {
  expandedIndex?: number;
}

function SideMenu({ menu, classes }: Props & WithStyles<typeof styles>) {
  const linker = useLinker();
  const visibility = useVisibility();
  const [state, setState] = useState<State>({});

  return <div>{menu.items.filter(visibility.isVisible).map(renderItem)}</div>;

  function renderItem(item: RouterMenuItem<any>, index: number): JSX.Element {
    switch (item.tag) {
      case 'folder':
        return renderFolder(item, index);
      case 'page':
        return renderPage(item, index);
    }
  }

  function renderPage(page: RouterMenuPage<any>, index: number, nested?: boolean) {
    return (
      <ListItem
        key={index}
        classes={{
          root: classNames(page.active && classes.activeLink, nested && classes.nestedItem),
        }}
        button
        component="a"
        {...linker.pathLink(page.path)}
      >
        {renderCommon({ tag: 'page', ...page })}
      </ListItem>
    );
  }

  function renderFolder(folder: RouterMenuFolder<any>, index: number) {
    const containsSelected = folder.active;
    const isExpanded = state.expandedIndex === index || containsSelected;
    const onClick = containsSelected
      ? undefined
      : () => {
          setState({ expandedIndex: isExpanded ? undefined : index });
        };
    return (
      <div key={index}>
        <ListItem
          className={classNames(isExpanded && classes.expandedFolder)}
          button
          onClick={onClick}
        >
          {renderCommon({ tag: 'folder', ...folder })}
          {isExpanded ? (
            <ExpandLess
              className={classNames(classes.primary, containsSelected && classes.disabled)}
            />
          ) : (
            <ExpandMore className={classes.primary} />
          )}
        </ListItem>
        <Collapse
          className={classNames(isExpanded && classes.expandedContents)}
          in={isExpanded}
          timeout="auto"
          unmountOnExit
        >
          {folder.pages
            .filter(visibility.isVisible)
            .map((page, subIndex) => renderPage(page, subIndex, true))}
        </Collapse>
      </div>
    );
  }

  function renderCommon(item: RouterMenuItem<any>) {
    return (
      <>
        {item.icon && (
          <ListItemIcon classes={{ root: classNames(classes.primary, itemIcon) }}>
            {item.icon}
          </ListItemIcon>
        )}
        <ListItemText
          classes={{ primary: classNames(classes.primary, itemText) }}
          primary={item.label}
        />
      </>
    );
  }
}

const itemIcon = style({
  minWidth: 0,
});

const itemText = style({
  // color: 'white', // dark styling
  padding: '0 16px',
  fontWeight: 400,
  letterSpacing: '0.02em',
  fontSize: '0.9em',
  lineHeight: '24px',
});

const styles = (theme: Theme) => {
  // const activeColor = theme.palette.secondary.main; // dark styling
  const activeColor = 'rgba(0, 0, 0, 0.08)';
  return {
    activeLink: {
      backgroundColor: activeColor,
      '&:hover': {
        backgroundColor: activeColor,
      },
      '&:focus': {
        backgroundColor: activeColor,
      },
    },
    expandedFolder: {
      // backgroundColor: '#5D6B7B', // dark styling
      backgroundColor: '#F5F5F5', // TODO choose a color for light styling
    },
    expandedContents: {
      // backgroundColor: '#334558', // dark styling
      backgroundColor: '#F5F5F5', // TODO choose a color for light styling
    },
    nestedItem: {
      paddingLeft: theme.spacing(6),
    },
    primary: {
      // color: 'white', // dark styling
      marginRight: 0,
      color: theme.palette.grey[700],
    },
    disabled: {
      opacity: 0.5,
    },
  };
};

export default withStyles(styles)(observer(SideMenu));
