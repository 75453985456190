import { createContext } from 'react';
import { Dictionary, keys } from '@tuple-health/common';
import { Contract } from './contract';
import { Providers } from './provider';

export type Resolver = <I extends Contract<any>>(contract: I) => I['_injectiontype'];

export function makeResolver<S>(providers: Providers<S>, state: S): Resolver {
  const resolve: Resolver = contract => {
    const provider = providers[contract._injectiontag];
    return provider && provider({ state }, resolve);
  };
  return resolve;
}

export type ContractDictionary = Dictionary<Contract<any>>;

export type InjectedContext<I extends ContractDictionary = ContractDictionary> = {
  [K in keyof I]: I[K]['_injectiontype'];
};

export function bindInjector<I extends ContractDictionary = ContractDictionary>(map: I) {
  const mapKeys = keys(map);

  return <T extends {}>(original: T, resolve: Resolver): T & InjectedContext<I> => {
    const result = { ...(original as any) };

    for (const key of mapKeys) {
      const contract = map[key];
      const instance = resolve(contract);
      if (!instance) throw new Error(`unable to resolve contract "${contract._injectiontag}"`);
      result[key] = instance;
    }

    return result;
  };
}

export const ResolverContext = createContext<Resolver | undefined>(undefined);
