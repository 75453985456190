import 'core-js/stable';
import 'dom4';
import 'regenerator-runtime/runtime';
import 'resize-observer-polyfill';
import 'whatwg-fetch';
import './commonIncludes';
import './fonts';
import './index.global.css';

// Headers.forEach (Firefox < 47)
if (!Headers.prototype.forEach && (Headers.prototype as any).entries) {
  Headers.prototype.forEach = function (this: any, f: any) {
    Array.from(this.entries()).forEach(f);
  };
}
