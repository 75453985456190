import { Concept, conceptSearchQuery, UriType } from '@tuple-health/common/dist/data';
import { isBlank } from '@tuple-health/eng/dist/dryscript/lib/common/prelude/str/strs';
import React, { createContext, ReactNode, useContext, useMemo } from 'react';
import { gateway } from '../../gateway.fetch';

interface ConceptSearchInputs {
  text: string;
  uriType: UriType;
  // slice
}

type ConceptSearch = (inputs: ConceptSearchInputs) => Promise<Concept[]>;

// wrapper enables us to add other methods/helpers later
interface ConceptSearchAgent {
  search: ConceptSearch;
}

// =============================================================================
// hook
// =============================================================================

const Context = createContext<ConceptSearchAgent | undefined>(undefined);

export function useConceptSearch(): ConceptSearchAgent {
  const agent = useContext(Context);
  if (!agent) throw Error('search agent not provided');
  return agent;
}

// =============================================================================
// provider
// =============================================================================

interface Props {
  agent: ConceptSearchAgent;
  children?: ReactNode;
}

function ConceptSearchProvider({ agent, children }: Props) {
  return <Context.Provider value={agent}>{children}</Context.Provider>;
}

export function DummyConceptSearchProvider({
  concepts,
  children,
}: {
  concepts: Concept[];
  children: ReactNode;
}) {
  const search = useDummyConceptSearch(concepts);
  const agent: ConceptSearchAgent = useMemo(() => ({ search }), [search]);
  return <ConceptSearchProvider agent={agent}>{children}</ConceptSearchProvider>;
}

// =============================================================================

export function GatewayConceptSearchProvider({ children }: { children: ReactNode }) {
  // const { customerId } = useCustomer(); // TODO used for caching

  const agent: ConceptSearchAgent = useMemo(() => ({ search: conceptSearch }), []);

  return <ConceptSearchProvider agent={agent}>{children}</ConceptSearchProvider>;
}

const conceptSearch: ConceptSearch = async ({ text, uriType }) => {
  // can't do a search for a blank string
  if (isBlank(text)) return Promise.resolve([]);

  const query = conceptSearchQuery({ uriType, text, isPrefix: true });

  return gateway.queryStructs(query);
};

// =============================================================================
// =============================================================================

function useDummyConceptSearch(concepts: Concept[]): ConceptSearch {
  return useMemo(() => genDummyConceptSearch(concepts), [concepts]);
}

function genDummyConceptSearch(concepts: Concept[]): ConceptSearch {
  const namespaces__text__cachedConcepts: Record<string, Record<string, Concept[]>> = {};

  return async function ({ uriType, text }) {
    // console.log(`search for "${text}" with type: ${JSON.stringify(uriType)}`);

    // TODO this is a hack for storybook interactions, not an actually safe search cache
    const namespaces = (uriType.namespaces || []).join(',');
    if (!namespaces__text__cachedConcepts[namespaces])
      namespaces__text__cachedConcepts[namespaces] = {};
    const text__cachedConcepts = namespaces__text__cachedConcepts[namespaces];

    return new Promise(resolve => {
      if (text__cachedConcepts[text]) {
        resolve(text__cachedConcepts[text]);
        return;
      }

      setTimeout(() => {
        const matches = concepts
          .filter(c => !uriType.namespaces || uriType.namespaces.includes(c.uri.ns))
          .filter(c => c.label && c.label.toLowerCase().includes(text.toLowerCase()))
          .map(c => c)
          .slice(0, 10);

        text__cachedConcepts[text] = matches;
        resolve(text__cachedConcepts[text]);
      }, Math.floor(Math.random() * (1500 - 200 + 1)) + 200);
    });
  };
}
