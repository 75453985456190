import React from 'react';

import { makeScreen } from '../core/appbase/screen';

export default makeScreen({
  view: () => {
    // try to preserve old location
    let { hash } = window.location;
    if (hash.includes('/org/')) {
      hash = hash.replace('/org/', '/menu/orgs/');
      for (const product of ['ocm', 'bpcia']) {
        if (hash.includes(`/${product}/`)) {
          window.location.hash = hash.replace(`/${product}/`, `/apps/${product}/`);
          return <div />;
        } else if (hash.endsWith(`/${product}`)) {
          window.location.hash =
            hash.substring(0, hash.length - `/${product}`.length) + `/apps/${product}`;
          return <div />;
        }
      }
    }
    // this is a 404, so redirect to home
    window.location.hash = '#';
    return <div />;
  },
});
