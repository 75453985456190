import { Typography } from '@material-ui/core';
import ErrorIcon from '@material-ui/icons/ErrorOutline';
import React from 'react';
import { style } from 'typestyle';
import { classNames } from '../core/styles';
import theme from '../theme';
import { StylingProps } from './util/StylingProps';
import analytics from '../analytics';

interface UncaughtErrorProps extends StylingProps {
  error?: Error | string;
  message?: string;
}

export default function UncaughtError({
  error = 'UNKNOWN',
  message = 'Oops... there was a problem here',
  ...props
}: UncaughtErrorProps) {
  if (process.env.NODE_ENV !== 'test') {
    // eslint-disable-next-line no-console
    console.error('An uncaught error occurred', error);
  }

  analytics.error(error instanceof Error ? error.message : error);

  return (
    <div className={classNames(className, props.className)} style={props.style}>
      <ErrorIcon />
      <Typography color="inherit">{message}</Typography>
    </div>
  );
}

const className = style({
  color: theme.palette.error.main,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  padding: 8,
  $nest: {
    '& > *': { marginRight: theme.spacing(1) },
  },
});
