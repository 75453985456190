import { Button } from '@material-ui/core';
import React, { useEffect } from 'react';
import useCustomer from '../../../core/store/useCustomer';
import useLinker from '../../../core/store/useLinker';
import { useProductOpt } from '../../../core/store/useProduct';
import { App } from '../../platform/app.model';
import NotFound from '../../platform/NotFound';
import { defineScreen, dummyScreen } from '../../platform/screen.model';
import { adaptPlatformProduct } from '../../platform/session.adapt';
import { CustomerLoc, customerRouter } from './customer.router';

const home = defineScreen(() => {
  const linker = useLinker();
  const context = useProductOpt();

  useEffect(() => {
    if (context) {
      linker.navRoute(customerRouter.route('app', { uri: context.productConcept }));
    }
  }, [context, linker]);

  return context ? null : (
    <div>
      <ul>
        <li>
          <Button {...linker.routeButton(customerRouter.route('apps'))}>My Applications</Button>
        </li>
      </ul>
    </div>
  );
});

const apps = defineScreen(() => {
  const linker = useLinker();
  const { customer } = useCustomer();
  return (
    <div>
      <ul>
        {[...customer.productKeys].map((productKey, i) => {
          const app = adaptPlatformProduct(productKey);
          return (
            <li key={i}>
              <Button {...linker.routeButton(customerRouter.route('app', { uri: app }))}>
                {app.labelOrId}
              </Button>
            </li>
          );
        })}
      </ul>
    </div>
  );
});

const app = defineScreen(() => <NotFound />);

const customerApp: App<CustomerLoc> = {
  router: customerRouter,
  lookupSearchingConcepts: (_session, _runRequest, handle, customerRecord) => {
    if (!customerRecord) throw Error('customer app requires a customer concept');
    const products = [...customerRecord.data.productKeys].map(adaptPlatformProduct);
    handle(products);
  },
  screens: {
    home,
    admin: dummyScreen('admin'),
    apps,
    app,
  },
};

export default customerApp;
