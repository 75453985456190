import 'typeface-roboto';

// Preload fonts by putting (invisible) text in the page which uses them

const fontsToPreload = [
  { weight: 300 },
  { weight: 400 },
  { weight: 400, italic: true },
  { weight: 500 },
  { weight: 700 },
];

const div = document.createElement('div');
div.setAttribute('style', 'width: 0; height: 0; overflow: hidden; font-family: Roboto');
document.body.appendChild(div);
fontsToPreload.forEach(({ weight, italic = false }) => {
  div.appendChild(createFontedSpan(weight, false));
  if (italic) {
    div.appendChild(createFontedSpan(weight, true));
  }
});

function createFontedSpan(weight: number, italic: boolean) {
  const span = document.createElement('span');
  span.setAttribute('style', 'font-weight: ' + weight + (italic ? '; font-style: italic' : ''));
  span.textContent = `${weight}${italic ? 'i' : ''}`;
  return span;
}
