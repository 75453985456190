import { createContext, useContext } from 'react';
import { RouteContext } from '@tuple-health/common/dist/router';

const Context = createContext<RouteContext<any> | undefined>(undefined);

export const NavMenuProvider = Context.Provider;

export default function useNavMenu() {
  const context = useContext(Context);
  if (!context) throw Error('navMenu context not provided');
  return context;
}
