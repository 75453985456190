import * as _ from '@tuple-health/eng/dist/dryscript/ds';
import { Customer } from '@tuple-health/eng/dist/th/ds/common/domain/customer/customer/Customer';
import { Person } from '@tuple-health/eng/dist/th/ds/common/domain/person/person/Person';
import { ThreadSummary } from '@tuple-health/eng/dist/th/ds/common/product/user/thread/ThreadSummary';
import { ConvoPointer } from '../chatAgent';
import { MessageEntry, parseMessageRecord } from '../message/message.model';
import { lookupAndAdaptPerson, PersonModel } from '../person/person.model';

export interface ConvoSummary {
  pointer: ConvoPointer;
  unviewedCount: number;
  participants: PersonModel[];
  subject?: string;
  latestMsg?: MessageEntry;
  customerLabel: string;
}

export function parseThreadSummaryRecord(
  record: _.Record<ThreadSummary>,
  myId: string,
  customerId__record: _.Dict<string, _.Record<Customer>>,
  id__person: _.Dict<string, Person>,
  author?: Person,
): ConvoSummary {
  const { customerId, userIds, userId__reader, subject, msgRecord } = record.data;
  const customerRecord = customerId__record.call(customerId);
  const participants = [...userIds].sort().map(id => lookupAndAdaptPerson(id__person, id, author));

  const myReader = userId__reader.callOpt(myId);

  return {
    pointer: { id: record.id, customerId },
    unviewedCount: myReader ? myReader.unviewedCount : 0,
    participants,
    subject,
    latestMsg: msgRecord ? parseMessageRecord(msgRecord, id__person, author) : undefined,
    customerLabel: customerRecord.data.name,
  };
}
