import { MuiThemeProvider, StylesProvider } from '@material-ui/core';
import cssVars from 'css-vars-ponyfill';
import { create } from 'jss';
import preset from 'jss-preset-default';
import React, { useEffect } from 'react';
import theme from './theme';

const opts = preset();

// Set where JSS inserts its styles so we can guarantee that TypeStyle's
// inserted styles come later (thus taking precedence). See:
// - public/index.html
// - config/storybook/preview-head.html
opts.insertionPoint = document.getElementById('jss-insertion-point');

const jss = create(opts);

interface ThemeProviderProps {
  children?: React.ReactNode;
}

export default function ThemeProvider({ children }: ThemeProviderProps) {
  useEffect(() => {
    setCssVars();
    window.addEventListener('resize', setCssVars);
    return () => window.removeEventListener('resize', setCssVars);
  }, []);

  return (
    <StylesProvider jss={jss}>
      <MuiThemeProvider theme={theme}>{children}</MuiThemeProvider>
    </StylesProvider>
  );
}

function setCssVars() {
  cssVars({
    watch: true,
    variables: {
      // =======================================================================
      // Spacing
      // =======================================================================

      '--theme-spacing-unit': `${theme.spacing(1)}px`,

      // =======================================================================
      // Shape
      // =======================================================================

      '--theme-shape-borderRadius': `${theme.shape.borderRadius}px`,

      // =======================================================================
      // Palette
      // =======================================================================

      '--theme-palette-primary-light': theme.palette.primary.light,
      '--theme-palette-primary-main': theme.palette.primary.main,
      '--theme-palette-primary-dark': theme.palette.primary.dark,
      '--theme-palette-primary-contrastText': theme.palette.primary.contrastText,

      '--theme-palette-secondary-light': theme.palette.secondary.light,
      '--theme-palette-secondary-main': theme.palette.secondary.main,
      '--theme-palette-secondary-dark': theme.palette.secondary.dark,
      '--theme-palette-secondary-contrastText': theme.palette.secondary.contrastText,

      '--theme-palette-error-light': theme.palette.error.light,
      '--theme-palette-error-main': theme.palette.error.main,
      '--theme-palette-error-dark': theme.palette.error.dark,
      '--theme-palette-error-contrastText': theme.palette.error.contrastText,

      '--theme-palette-grey-50': theme.palette.grey[50],
      '--theme-palette-grey-100': theme.palette.grey[100],
      '--theme-palette-grey-200': theme.palette.grey[200],
      '--theme-palette-grey-300': theme.palette.grey[300],
      '--theme-palette-grey-400': theme.palette.grey[400],
      '--theme-palette-grey-500': theme.palette.grey[500],
      '--theme-palette-grey-600': theme.palette.grey[600],
      '--theme-palette-grey-700': theme.palette.grey[700],
      '--theme-palette-grey-800': theme.palette.grey[800],
      '--theme-palette-grey-900': theme.palette.grey[900],
      '--theme-palette-grey-A100': theme.palette.grey.A100,
      '--theme-palette-grey-A200': theme.palette.grey.A200,
      '--theme-palette-grey-A400': theme.palette.grey.A400,
      '--theme-palette-grey-A700': theme.palette.grey.A700,

      // =======================================================================
      // Misc
      // =======================================================================

      // See https://css-tricks.com/the-trick-to-viewport-units-on-mobile/
      '--viewport-height': `${window.innerHeight}px`,
    },
  });
}
