import { toAdapter } from '../../../components/util/adaptLib';
import { parseEmbedOpt, writeEmbed, writeEmbedLine } from '../embed/embed.format';
import { slideFormat } from '../slide/slide.format';
import { Resource, parseConceptResource } from './resource.model';
import { Router } from '@tuple-health/common/dist/router';
import { cohortFormat } from '../../../components/cohort/model/cohort.format';

const writeResourceEmbed = Resource.match<string>({
  externalUrl: url => url,
  route: route => writeEmbed({ tag: 'page', body: route.resourceText }),
  conceptUri: uri => writeEmbedLine({ tag: 'concept', body: `${uri.ns}:${uri.key}` }),
  richText: text => writeEmbed({ tag: 'text', body: text }),
  slide: slide => writeEmbed({ tag: 'slide', body: slideFormat.write(slide) }),
  cohort: cohort => writeEmbed({ tag: 'cohort', body: cohortFormat.write(cohort) }),
});

const parseResourceEmbed = (router: Router<any>, markup: string): Resource => {
  const embed = parseEmbedOpt(markup);

  if (!embed) return Resource.externalUrl(markup);

  // console.log('parseResourceEmbed');
  // console.log(embed.tag);
  // console.log(embed.args);
  // console.log(embed.body);

  switch (embed.tag) {
    case 'page':
      const route = router.parseResourceText(embed.body);
      return Resource.route(route);
    case 'concept':
      return parseConceptResource(embed.body);
    case 'text':
      return Resource.richText(embed.body);
    case 'slide':
      return Resource.slide(slideFormat.parse(embed.body));
    case 'cohort':
      return Resource.cohort(cohortFormat.parse(embed.body));
    case 'textOrEmbed':
      return embed.body.trim().startsWith('```{')
        ? parseResourceEmbed(router, embed.body)
        : Resource.richText(embed.body);
  }

  throw Error(`could not parse resource:\n${markup}`);
};

export function toResourceEmbedFormat(router: Router<any>) {
  return toAdapter<string, Resource>(
    (markup: string) => parseResourceEmbed(router, markup),
    writeResourceEmbed,
  );
}
