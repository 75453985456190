import * as _ from '@tuple-health/eng/dist/dryscript/ds';
import { toAdapter } from '../../../components/util/adaptLib';
import {
  isSlideConfStart,
  nextEmbed,
  normalizeText,
  parseOptionalText,
  skipBlankLines,
  slideTitlePrefix,
} from '../article/format/articleMarkupHelpers';
import { unboundDatavizEmbedFormat } from '../dataviz/unboundDatavizEmbed.format';
import { unboundImageEmbedFormat } from '../image/unboundImageEmbed.format';
import { UnboundRichLink } from '../richLink/richLink.model';
import { parseUnboundRichText } from '../richText/richText.parse';
import { writeUnboundRichTextMarkup } from '../richText/richText.write';
import { parseTitleLines, writeTitleLines } from '../title/title.format';
import {
  Slide,
  SlideHead,
  SlideMixin,
  slide__unboundSlide,
  UnboundSlide,
  UnboundSlideContent,
  unboundSlideWithoutVariables__slide,
} from './slide.model';

// =============================================================================
// bound
// =============================================================================

const writeSlide = (slide: Slide): string => {
  const unboundSlide = slide__unboundSlide(slide);
  return unboundSlideFormat.write(unboundSlide);
};

const parseSlide = (text: string): Slide => {
  const unboundSlide = unboundSlideFormat.parse(text);
  return unboundSlideWithoutVariables__slide(unboundSlide);
};

export const slideFormat = toAdapter<string, Slide>(parseSlide, writeSlide);

// =============================================================================
// unbound
// =============================================================================

const writeUnboundSlide = (slide: UnboundSlide): string => {
  const head = writeSlideHead(slide);

  const body = writeUnboundSlideBody(slide);

  return `${head}\n\n${body}`;
};

const writeUnboundSlideBody = (slide: UnboundSlide): string => {
  let body = '';

  if (slide.intro) {
    body += `${normalizeText(slide.intro)}`;
  }

  if (_.strs.notBlank(body)) body += '\n\n';

  body += writeUnboundSlideContent(slide.content);

  if (slide.footnote) {
    body += `\n\n${footnotePrefix} ${normalizeText(slide.footnote)}`;
  }

  if (slide.caption) {
    body += `\n\n${normalizeText(slide.caption)}`;
  }

  return body;
};

// =====================================

const parseUnboundSlide = (textWithLinks: string): UnboundSlide => {
  // console.log('textWithLinks');
  // console.log(textWithLinks);

  // replace links with uuids to make it easier to parse
  const { textWithUuids, links } = parseUnboundRichText(textWithLinks);
  // console.log('textWithUuids');
  // console.log(textWithUuids);

  // TODO review, can't normalize lines because tabs are significant in YAML
  const remainingLines = textWithUuids.split('\n');

  const { title, subtitle, shortTitle, ...head } = parseSlideHead(remainingLines, links);

  const bodyText = remainingLines.join('\n');

  // TODO won't work with rich links with nested content
  const isTextSlide = !bodyText.includes('`image ') && !bodyText.includes('```{dataviz}');

  let intro: string | undefined;
  let footnote: string | undefined;
  let caption: string | undefined;

  if (!isTextSlide) {
    intro = parseOptionalText(remainingLines);
  }

  const content = parseUnboundSlideContent(remainingLines);
  const c = content as any;
  if (c.tag === 'richText') c.field = writeUnboundRichTextMarkup(c.field, links);

  if (!isTextSlide) {
    footnote = parseFootnote(remainingLines);
    caption = parseOptionalText(remainingLines);
  }

  const slide: SlideMixin = {
    ...head,
    title,
  };
  if (subtitle) slide.subtitle = subtitle;
  if (shortTitle) slide.shortTitle = shortTitle;
  if (intro) slide.intro = writeUnboundRichTextMarkup(intro, links);
  if (caption) slide.caption = writeUnboundRichTextMarkup(caption, links);
  if (footnote) slide.footnote = writeUnboundRichTextMarkup(footnote, links);

  return { ...slide, content };
};

export const unboundSlideFormat = toAdapter<string, UnboundSlide>(
  parseUnboundSlide,
  writeUnboundSlide,
);

// =============================================================================
// slide content
// =============================================================================

const writeUnboundSlideContent = UnboundSlideContent.match<string>({
  imageSource: unboundImageEmbedFormat.write,
  datavizMarkup: unboundDatavizEmbedFormat.write,
  richText: normalizeText,
});

const parseUnboundSlideContent = (remainingLines: string[]): UnboundSlideContent => {
  skipBlankLines(remainingLines);
  if (!remainingLines.length) return UnboundSlideContent.richText('');

  if (isImageEmbedStart(remainingLines[0])) {
    return UnboundSlideContent.imageSource(
      unboundImageEmbedFormat.parse(nextEmbed(remainingLines)),
    );
  }

  if (isDatavizEmbedStart(remainingLines[0])) {
    return UnboundSlideContent.datavizMarkup(
      unboundDatavizEmbedFormat.parse(nextEmbed(remainingLines)),
    );
  }

  return UnboundSlideContent.richText(parseOptionalText(remainingLines) || '');
};

const isImageEmbedStart = (line: string) => line.startsWith('`image ');
const isDatavizEmbedStart = (line: string) => line.startsWith('```{dataviz}');

// =============================================================================
// head
// =============================================================================

const writeSlideHead = (slide: SlideHead): string => {
  let markup = writeTitleLines(slideTitlePrefix, slide);

  if (slide.name || slide.view) {
    markup += '\n`slide';
    if (slide.name) markup += ` name=${slide.name}`;
    if (slide.view) markup += ` view=${slide.view}`;
    markup += '`';
  }

  return markup;
};

const parseSlideHead = (remainingLines: string[], links: UnboundRichLink[]): SlideHead => {
  const conf: SlideHead = parseTitleLines('###', remainingLines, links);

  let name: undefined | string;
  let view: undefined | 'hidden';
  skipBlankLines(remainingLines);
  if (remainingLines.length && isSlideConfStart(remainingLines[0])) {
    const confLine = remainingLines.shift()!;
    const parts = _.strs.replace(confLine, '`', '').split(' ').slice(1);
    for (const part of parts) {
      const [key, val] = part.split('=');
      if (key === 'name') {
        name = val;
      } else if (key === 'view' && val === 'hidden') {
        view = 'hidden';
      } else
        throw Error(
          `could not parse slide configuration: ${confLine}, with key = ${key} and val = ${val}`,
        );
    }
  }
  if (name) conf.name = name;
  if (view) conf.view = view;

  return conf;
};

// =============================================================================
// slide footnote
// =============================================================================

const parseFootnote = (remainingLines: string[]): string | undefined => {
  skipBlankLines(remainingLines);
  if (!remainingLines.length) return undefined;

  const line = remainingLines[0];
  if (!line.startsWith(footnotePrefix)) return undefined;

  remainingLines.shift();
  return normalizeText(line.substring(footnotePrefix.length));
};

const footnotePrefix = '#### footnote:';
