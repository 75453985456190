import * as _ from '@tuple-health/eng/dist/dryscript/ds';
import { skipBlankLines } from '../article/format/articleMarkupHelpers';
import { UnboundRichLink } from '../richLink/richLink.model';
import { writeUnboundRichTextMarkup } from '../richText/richText.write';
import { HasTitle } from './title.model';

// =============================================================================
// title line
// =============================================================================

export const writeTitleLines = (prefix: string, hasTitle: HasTitle): string => {
  if (prefix.endsWith(' ')) throw Error('title prefix should not end in space');

  const lines = [`${prefix} ${hasTitle.title}`];

  if (hasTitle.subtitle) {
    lines.push(`${prefix}(sub) ${hasTitle.subtitle}`);
  }

  if (hasTitle.shortTitle) {
    lines.push(`${prefix}(short) ${hasTitle.shortTitle}`);
  }

  return lines.join('\n');
};

export const parseTitleLines = (
  prefix: string,
  remainingLines: string[],
  links: UnboundRichLink[],
): HasTitle => {
  if (prefix.endsWith(' ')) throw Error('title prefix should not end in space');
  const titlePrefix = `${prefix} `;
  const subtitlePrefix = `${prefix}(sub) `;
  const shortTitlePrefix = `${prefix}(short) `;
  const extraTitlePrefix = `${prefix}(`;

  skipBlankLines(remainingLines);
  if (!remainingLines.length) {
    throw Error('slide markup cannot be empty');
  }
  const titleLine = remainingLines.shift()!;
  const titleText = titleLine.substring(titlePrefix.length).trim();

  const [title, subtitle, shortTitle] = parseTitleText(titleText);
  const hasTitle: HasTitle = { title: writeUnboundRichTextMarkup(title, links) };
  if (subtitle) hasTitle.subtitle = writeUnboundRichTextMarkup(subtitle, links);
  if (shortTitle) hasTitle.shortTitle = writeUnboundRichTextMarkup(shortTitle, links);

  skipBlankLines(remainingLines);
  while (remainingLines.length && remainingLines[0].startsWith(extraTitlePrefix)) {
    const line = remainingLines.shift()!;
    skipBlankLines(remainingLines);

    if (line.startsWith(subtitlePrefix)) {
      hasTitle.subtitle = writeUnboundRichTextMarkup(
        line.substring(subtitlePrefix.length).trim(),
        links,
      );
    } else if (line.startsWith(shortTitlePrefix)) {
      hasTitle.shortTitle = writeUnboundRichTextMarkup(
        line.substring(shortTitlePrefix.length).trim(),
        links,
      );
    } else {
      throw Error(`unexpected title configuration line: ${line}`);
    }
  }

  return hasTitle;
};

// =============================================================================
// title text
// =============================================================================

const parseTitleText = (allTitles: string): [string, string | undefined, string | undefined] => {
  const [withoutShortTitle, shortTitle] = parseTitlePart(allTitles, 'shortTitle');

  const [withoutSubtitle, newSubtitle] = parseTitlePart(withoutShortTitle, 'subtitle');
  let subtitle = newSubtitle;
  let title = withoutSubtitle;

  // backwards compatibility
  const oldSubtitleStartIndex = title.indexOf('[');
  if (oldSubtitleStartIndex > 0 && !newSubtitle && !title.includes('](') && !title.includes(']{')) {
    // TODO replace ugly trim and strip combo with substring()?
    let oldSubtitle = title.substring(oldSubtitleStartIndex);
    oldSubtitle = oldSubtitle.trim();
    oldSubtitle = _.strs.strip(oldSubtitle, '[');
    oldSubtitle = _.strs.strip(oldSubtitle, ']');
    oldSubtitle = oldSubtitle.trim();
    subtitle = oldSubtitle;

    title = title.substring(0, oldSubtitleStartIndex);
  }

  return [
    title.trim(),
    subtitle ? _.strs.noneIfBlank(subtitle) : undefined,
    shortTitle ? _.strs.noneIfBlank(shortTitle) : undefined,
  ];
};

// =============================================================================
// title part
// =============================================================================

const parseTitlePart = (line: string, partLabel: string): [string, string | undefined] => {
  const openDelim = `[${partLabel}|`;
  const closeDelim = '|]';

  const startIndex = line.indexOf(openDelim);
  if (startIndex < 0) return [line, undefined];

  const stopIndex = line.indexOf(closeDelim, startIndex);
  if (!stopIndex) throw Error(`unterminated ${partLabel}`);

  const titlePart = line.substring(startIndex + openDelim.length, stopIndex);

  const rest = line.substring(0, startIndex) + line.substring(stopIndex + closeDelim.length);

  return [rest.trim(), _.strs.noneIfBlank(titlePart.trim())];
};
