import * as _ from '@tuple-health/eng/dist/dryscript/ds';
import { Person } from '@tuple-health/eng/dist/th/ds/common/domain/person/person/Person';
import { ConvoEvent } from '@tuple-health/eng/dist/th/ds/common/product/user/thread/event/ConvoEvent';
import { fromDsEnum } from '../../../core/ds';
import { ProductRichLink } from '../../../packages/content/richLink/richLink.model';
import { PersonModel } from '../person/person.model';
import { AddRemoveUserEntry, parseAddRemoveUserRecord } from './addUserEvent.model';
import { MessageEntry, parseMessageRecord } from './message.model';

export type ConvoEventEntry = AddRemoveUserEntry | MessageEntry;

export const parseConvoEventRecord = (
  record: _.Record<ConvoEvent>,
  id__person: _.Dict<string, Person>,
  author?: Person,
): ConvoEventEntry => {
  const { data } = record;

  const e = fromDsEnum(data);

  switch (e.tag) {
    case 'msg':
      return parseMessageRecord(record.withData(data.msg), id__person, author);
    case 'addUser':
    case 'removeUser':
      return parseAddRemoveUserRecord(record.withData(e.field), id__person, e.tag, author);
  }
};

export function buildAddUserEntry({
  id,
  creator,
  created,
  addedUser,
}: {
  id: string;
  creator: PersonModel;
  created: Date;
  addedUser: PersonModel;
}): AddRemoveUserEntry {
  return {
    id,
    uri: `convoEvent:${id}`,
    creator,
    created,
    user: addedUser,
    type: 'addUser',
  };
}

export function buildMessage({
  text,
  attachments,
  creator,
  created,
  modified,
  id,
}: {
  text: string;
  attachments?: ProductRichLink[];
  creator: PersonModel;
  created: Date;
  modified?: Date;
  id?: string;
}): MessageEntry {
  return {
    text,
    attachments: attachments || [],
    id,
    uri: id ? `msg:${id}` : undefined,
    creator,
    created,
    modified,
    type: 'message',
  };
}
