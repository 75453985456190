import * as _ from '@tuple-health/eng/dist/dryscript/ds';
import { GenericMsg } from '@tuple-health/eng/dist/dryscript/lib/common/ui/content/msg/GenericMsg';
import * as toGenericMsg from '@tuple-health/eng/dist/dryscript/lib/common/ui/content/msg/toGenericMsg';
import { action, computed, observable } from 'mobx';
import { Api } from '../../services/gateway/api';

export interface ChangePasswordSubmission {
  oldPassword: string;
  newPassword: string;
  reenteredPassword: string;
}

export class ChangePasswordStore {
  constructor(private api: Api) {}

  @action.bound
  async request(submission: ChangePasswordSubmission) {
    this.status = 'pending';
    this.errorMessage = undefined;

    if (submission.newPassword !== submission.reenteredPassword) {
      this.status = 'error';
      this.errorMessage = toGenericMsg.call({
        isError: true,
        key__text: _.toDict.of(['reenteredPassword', 'must match new password']),
      });
      return;
    }

    try {
      await this.api.updatePassword(submission);
      this.setSuccess();
    } catch (e) {
      this.setError(e.reply);
    }
  }

  @action
  private setSuccess() {
    this.status = 'success';
    this.errorMessage = undefined;
  }

  @action
  private setError(reply: _.Reply<void>) {
    this.status = 'error';
    this.errorMessage = toGenericMsg.fromReply(reply);
  }

  @observable
  status: 'initial' | 'pending' | 'success' | 'error' = 'initial';

  @observable
  errorMessage?: GenericMsg;

  @computed
  get errorGeneric() {
    return this.errorMessage ? this.errorMessage.text : undefined;
  }

  @computed
  get errorOldPassword() {
    return this.errorMessage ? this.errorMessage.key__text.callOpt('oldPassword') : undefined;
  }

  @computed
  get errorNewPassword() {
    return this.errorMessage ? this.errorMessage.key__text.callOpt('newPassword') : undefined;
  }

  @computed
  get errorRePassword() {
    return this.errorMessage ? this.errorMessage.key__text.callOpt('reenteredPassword') : undefined;
  }
}
