import { CubeFactPredicate } from '@tuple-health/eng/dist/dryscript/lib/common/cube/boolExp/CubeFactPredicate';
import * as cubeQueryFormat from '@tuple-health/eng/dist/dryscript/lib/common/cube/format/cubeQueryFormat';
import { schema } from '@tuple-health/eng/dist/th/ds/common/product/customer/house/cube/houseCube';
import * as RT from 'runtypes';
import { parseDataset } from '../../product/dataset/dataset.model';
import { bpciaAnalyticsProduct } from '../../product/analytics/analyticsProductBpci';
import { ocmAnalyticsProduct } from '../../product/analytics/analyticsProductOcm';
import { toAdapter } from '../../util/adaptLib';
import * as yamlLib from '../../util/yamlLib';
import { Cohort } from './cohort.model';

// =============================================================================
// serial model
// =============================================================================

const SerialCohort = RT.Record({
  fact: RT.String,
  dataset: RT.String,
}).And(
  RT.Partial({
    // timePeriod: RT.String,
    deepFilters: RT.String,
    shallowFilters: RT.String,
  }),
);
export interface SerialCohort extends RT.Static<typeof SerialCohort> {}

// =============================================================================
// adapter
// =============================================================================

export const cohortAdapter = toAdapter<SerialCohort, Cohort>(
  serial => {
    const catalog = schema.name__catalog.call(serial.fact);
    if (!catalog) throw Error(`unknown fact name: ${serial.fact}`);

    const model: Cohort = {
      catalog,
      dataset: parseDataset(serial.dataset),
      // timePeriod: serial.timePeriod ? parseTimePeriod(serial.timePeriod) : undefined,
      deepFilters: parseCohortFilterText(serial.fact, serial.deepFilters),
      shallowFilters: parseCohortFilterText(serial.fact, serial.shallowFilters),
    };
    return model;
  },
  model => {
    const serial: SerialCohort = {
      fact: model.catalog.name,
      dataset: model.dataset.key,
      // timePeriod: model.timePeriod ? model.timePeriod.key : undefined,
      deepFilters: writeCohortFilterText(model.deepFilters),
      shallowFilters: writeCohortFilterText(model.shallowFilters),
    };
    return serial;
  },
);

// TODO we really really shouldn't need a calc to parse these filters
export const hackParseCohortFilterText = (text: string | undefined): CubeFactPredicate[] => {
  if (!text) return [];
  for (const analytics of [ocmAnalyticsProduct, bpciaAnalyticsProduct]) {
    try {
      const sentence = `${analytics.defaultCatalogCount}\n${text}`;
      return parseCohortFilterQueryText(sentence);
    } catch (e) {}
  }
  throw Error(`hackParseCohortFilterText failed for:\n${text}`);
};

const parseCohortFilterText = (
  catalogName: string,
  text: string | undefined,
): CubeFactPredicate[] => {
  if (!text) return [];
  const sentence = `CALC ${catalogName}.count\n${text}`;
  return parseCohortFilterQueryText(sentence);
};

export const parseCohortFilterQueryText = (sentence: string): CubeFactPredicate[] => {
  const query = cubeQueryFormat.parse(schema, sentence);
  return [...query.clauses]
    .filter(c => !c.isCalc)
    .map(clause => {
      if (!clause.isWhere)
        throw Error(`cohort should only contain where clauses, found: ${clause.tag}`);
      return clause.where;
    });
};

export const writeCohortFilterText = (
  filters: CubeFactPredicate[] | undefined,
): string | undefined => {
  if (!filters || !filters.length) return undefined;
  return filters.map(cubeQueryFormat.writeWhere).join('\n');
};

// =============================================================================
// format
// =============================================================================

const serialCohortFormat = toAdapter<string, SerialCohort>(
  yaml => {
    const serial = yamlLib.parse(yaml);
    return SerialCohort.check(serial);
  },
  serial => {
    try {
      return yamlLib.write(serial);
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(serial);
      throw e;
    }
  },
);

export const cohortFormat = serialCohortFormat.then(cohortAdapter);
