import { Dict } from '@tuple-health/eng/dist/dryscript/ds';
import { Person } from '@tuple-health/eng/dist/th/ds/common/domain/person/person/Person';
import { hasTupleEmail } from '../chatUtils';

export interface PersonModel {
  id: string;
  initials: string;
  shortName: string;
  firstName?: string;
  hasTupleEmail: boolean;
}

export const adaptPerson = (person: Person): PersonModel => ({
  id: person.id,
  initials: person.profile.initials || person.profile.shortLabel[0].toUpperCase(),
  shortName: person.profile.shortLabel,
  firstName: person.profile.firstName,
  hasTupleEmail: hasTupleEmail(person.profile),
});

export const lookupAndAdaptPerson = (
  id__person: Dict<string, Person>,
  userId: string,
  author?: Person,
): PersonModel => {
  const person = id__person.callOpt(userId) || author;
  if (!person) throw Error(`could not find person: ${userId}`);
  return adaptPerson(person);
};

export const lookupAndAdaptPeople = (
  id__person: Dict<string, Person>,
  userIds: string[],
): PersonModel[] => userIds.map(userId => lookupAndAdaptPerson(id__person, userId));
