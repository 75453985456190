import { $concept, Concept } from '@tuple-health/common/dist/data';
import * as _ from '@tuple-health/eng/dist/dryscript/ds';
import { GatewaySession } from '@tuple-health/eng/dist/th/ds/common/agent/gatewayApi/service/session/GatewaySession';
import { Customer } from '@tuple-health/eng/dist/th/ds/common/domain/customer/customer/Customer';
import { key__productApp } from '../apps/allApps';
import { PlatformCustomer } from '../apps/customer/customer.model';
import { routerKinds } from '../platform/v1PlatformConcepts';
import { PlatformSession, PlatformUser } from './session.model';

export const adaptPlatformSession = (gatewaySession: GatewaySession): PlatformSession => ({
  user: adaptPlatformUser(gatewaySession),
  customers: [...gatewaySession.customerRecords].map(adaptPlatformCustomer),
});

const adaptPlatformUser = (gatewaySession: GatewaySession): PlatformUser => {
  const { user } = gatewaySession;
  const userConcept = $concept.create({
    uri: routerKinds.userId.uri(user.id),
    label: user.bio.fullLabel,
  });
  return {
    userConcept,
    initials: user.bio.initials || user.bio.shortLabel[0].toUpperCase(),
    isTupleStaff: !!user.userIsEmployee,
  };
};

export const adaptPlatformCustomer = (customerRecord: _.Record<Customer>): PlatformCustomer => {
  const customer = customerRecord.data;

  const customerConcept = $concept.create({
    uri: routerKinds.customerSlug.uri(customerRecord.slug!),
    label: customer.name,
  });

  return {
    oldRecord: customerRecord,
    customerConcept,
    productConcepts: [...customer.productKeys].map(adaptPlatformProduct),
  };
};

export const adaptPlatformProduct = (key: string): Concept => {
  const app = key__productApp[key];
  if (!app) throw Error(`adaptPlatformProduct: unexpected product key: ${key}`);
  return app.router.appConcept;
};
