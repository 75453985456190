import { $concept } from '@tuple-health/common/dist/data';
import { lazy } from 'react';
import { App } from '../../platform/app.model';
import { dummyScreen } from '../../platform/screen.model';
import { gateway } from './gateway.fetch';
import { VcmLoc, vcmRouter } from './vcm.router';

const vcmApp: App<VcmLoc> = {
  router: vcmRouter,
  // lookupSearchingConcepts: (_session, _runRequest, handle) => Promise.resolve(handle([])),
  lookupSearchingConcepts: (_session, _runRequest, handle) => {
    void gateway.listSchemas({}).then(schemas => {
      const schemaConcepts = schemas.map(s =>
        $concept.create({ uri: s.uri, label: s.singularLabel }),
      );
      void gateway.listKinds({}).then(kinds => {
        const kindConcepts = kinds.map(k =>
          $concept.create({ uri: k.uri, label: k.singularLabel }),
        );
        handle([...schemaConcepts, ...kindConcepts]);
      });
    });
  },
  screens: {
    // =================================
    // data
    // =================================
    // dataHome: dummyScreen('data home'),
    schemaList: lazy(() => import('./db/screens/schemaListScreen')),
    schemaDetail: lazy(() => import('./db/screens/schemaDetail/schemaDetailScreen')),
    kindList: lazy(() => import('./db/screens/kindListScreen')),
    conceptList: lazy(() => import('./db/screens/kindDetailScreen')),
    conceptDetail: lazy(() => import('./db/screens/conceptDetailScreen')),
    // =================================
    // vcm
    // =================================
    home: lazy(() => import('../../../components/vcm/edi/EdiHomeScreen')),
    edi: dummyScreen('edi'),
    edi837List: lazy(() => import('../../../components/vcm/edi/837/Edi837ListScreen')),
    edi837Detail: lazy(() => import('../../../components/vcm/edi/837/Edi837DetailScreen')),
    edi835List: lazy(() => import('../../../components/vcm/edi/835/Edi835ListScreen')),
    edi835Detail: lazy(() => import('../../../components/vcm/edi/835/Edi835DetailScreen')),
    ediClaimList: lazy(() => import('../../../components/vcm/edi/claim/EdiClaimListScreen')),
    ediClaimDetail: lazy(() => import('../../../components/vcm/edi/claim/EdiClaimDetailScreen')),
    ediClaimLineList: lazy(() =>
      import('../../../components/vcm/edi/claimLine/EdiClaimLineListScreen'),
    ),
    ediClaimLineDetail: lazy(() =>
      import('../../../components/vcm/edi/claimLine/EdiClaimLineDetailScreen'),
    ),
    paymentElementList: lazy(() => import('../../../components/vcm/pm/PaymentElementListScreen')),
    paymentElementDetail: dummyScreen('paymentElementDetail'),
    patientList: lazy(() => import('../../../components/vcm/patient/PatientListScreen')),
    patientDetail: lazy(() => import('../../../components/vcm/patient/PatientDetailScreen')),
    patientTimeline: lazy(() => import('../../../components/vcm/patient/PatientTimelineScreen')),
    patientCoverage: dummyScreen('patientCoverage'),
    superbillList: dummyScreen('superbillList'),
    superbillDetail: lazy(() => import('../../../components/vcm/patient/SuperbillDetailScreen')),
  },
};

export default vcmApp;
