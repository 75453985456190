import React from 'react';
import { Helmet } from 'react-helmet';
import { MiniButton, RaisedButton } from '../../../components/workflow/buttons';
import {
  classes as wfClasses,
  ErrorSection,
  StatusSection,
} from '../../../components/workflow/common';
import WorkflowFrame from '../../../components/workflow/WorkflowFrame';
import WorkflowTextField from '../../../components/workflow/WorkflowTextField';
import { Dictionary, WithMaybeError } from '@tuple-health/common';
import theme from '../../../theme';
import { ExpiredProps, FormFields, FormProps, PasswordResetConfirmProps as Props } from './props';

type State = Dictionary<string>;

export default class PasswordResetConfirm extends React.PureComponent<Props, State> {
  state = { ...this.props } as State;

  render() {
    return (
      <WorkflowFrame>
        <Helmet title="Confirm Account Recovery" />
        {Props.match(this.props, {
          initializing: () => null,
          expired: this.renderExpired,
          error: error => <ErrorSection {...error} />,
          prompt: this.renderPasswordPrompt,
          pending: this.renderPasswordPrompt,
        })}
      </WorkflowFrame>
    );
  }

  renderPasswordPrompt = ({
    complete,
    supportPath,
    error,
  }: FormFields & WithMaybeError & Partial<FormProps>) => {
    const submit =
      complete &&
      (() =>
        complete({
          phoneOtp: this.state.phoneOtp || '',
          password: this.state.password || '',
          password2: this.state.password2 || '',
        }));

    const disabled = !submit;

    const tfprops = {
      state: this.state,
      setState: this.setState.bind(this),
      error,
      submit,
    };

    return (
      <>
        {PasswordExplanation}
        <WorkflowTextField {...tfprops} field="phoneOtp" label="Confirmation Code" />
        <WorkflowTextField {...tfprops} field="password" label="Enter Password" type="password" />
        <WorkflowTextField
          {...tfprops}
          field="password2"
          label="Confirm Password"
          type="password"
        />
        <RaisedButton style={{ marginTop: 16 }} disabled={disabled} onClick={submit}>
          Set Password
        </RaisedButton>
        <StatusSection
          status={disabled ? 'pending' : { error, skipKeys: ['phoneOtp', 'password', 'password2'] }}
        />
        <MiniButton style={{ marginTop: theme.spacing(3) }} path={supportPath}>
          Need additional help?
        </MiniButton>
      </>
    );
  };

  renderExpired = ({ passwordResetPath, supportPath }: ExpiredProps) => (
    <>
      <h3 className={wfClasses.smallSpacer}>Recovery Window Expired</h3>
      <p className={wfClasses.spacer}>
        The link and code you received have expired. You can start the account recovery process
        again, or if you're having trouble, reach out to us for support.
      </p>
      <RaisedButton path={passwordResetPath}>Start Account Recovery</RaisedButton>
      <RaisedButton path={supportPath}>Contact Support</RaisedButton>
    </>
  );
}

const PasswordExplanation = (
  <>
    <h3 className={wfClasses.smallSpacer}>Verify your phone number</h3>
    <p className={wfClasses.spacer}>
      Please set your password. It must be at least 8 characters long and must include an uppercase
      letter, a lowercase letter, a number, and a symbol.
    </p>
  </>
);
