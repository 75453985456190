import { OcmLoc, ocmRouter } from '../../../platform/apps/ocm/ocm.router';
import { key__dataset } from '../dataset/dataset.model';
import { key__timePeriod } from '../dataset/timePeriod.model';
import { toAnalyticsProduct } from '../model/analyticsProductModel';

const defaultOcmMarkup = `\
\`\`\`{article}
dataset: ocmDefault
timePeriod: ocmPP1
\`\`\`

# Untitled Data Story

## Untitled Chapter

### Untitled Text Slide

Hello!

### Untitled Data Slide

\`\`\`{dataviz}
query: |
  CALC ocmEpisode.count
\`\`\`
`;

// =============================================================================

const ocmDefaultLevelKeys = [
  '-- OCM Episode Filters --',
  'ocmEpisodeSummary.ocmPeriod.2',
  'ocmEpisodeSummary.ocmCancer.2',
  'ocmEpisodeSummary.winsorCategory.1',
  'ocmEpisodeSummary.isFirstEpisode.1',
  'ocmEpisodeSummary.didPassAway.1',
  'ocmEpisodeSummary.isDualEligible.1',
  'ocmEpisodeSummary.hasMultipleOcmCancers.1',

  '-- Patient Filters --',
  'ocmPatient.sex.1',
  'ocmPatient.ageBucket.1',
  'ocmPatient.hasPartD.1',
  'ocmPatientAddress.customerMarket.1',
  // 'ocmPatientAddress.stateHrrHsaZip.2',

  '-- Attributed Clinician Filters --',
  // 'attributedClinician.npi.1',
  'attributedClinician.npi.1',
  'attributedClinician.customerLocation.1',
  'attributedClinicianLocationAddress.customerMarket.1',
  // 'ocmAttributedClinicianLocationAddress.stateHrrHsaZip.2',

  '-- OCM Episode Utilization Filters --',
  'ocmEpisodeSummary.hadHomeHealth.1',
  'ocmEpisodeSummary.hadHospiceStay.1',
  'ocmEpisodeSummary.hadDme.1',
  'ocmEpisodeSummary.hadDrug.1',
  // 'ocmEpisodeSummary.hadDrugsAndBlood.1',
  // 'ocmEpisodeSummary.hadEmergencyCare.1',
  // need ambulance
  // 'ocmEpisodeSummary.hadHospiceCare.1',
  'ocmEpisodeSummary.hadImaging.1',
  // 'ocmEpisodeSummary.hadInpatientCare.1',
  'ocmEpisodeSummary.hadLab.1',
  'ocmEpisodeSummary.hadOfficeVisit.1',
  // 'ocmEpisodeSummary.hadPostAcuteCare.1',
  'ocmEpisodeSummary.hadModelPayment.1',
  'ocmEpisodeSummary.hadHospitalization.1',
  'ocmEpisodeSummary.hadIcuAdmission.1',
  'ocmEpisodeSummary.hadPostAcuteStay.1',
  'ocmEpisodeSummary.hadIrfAdmission.1',
  'ocmEpisodeSummary.hadSnfStay.1',
  'ocmEpisodeSummary.hadLtcAdmission.1',
  'ocmEpisodeSummary.hadHormonalOnly.1',
  'ocmEpisodeSummary.hadSurgery.1',
  'ocmEpisodeSummary.hadRadiation.1',
  'ocmEpisodeSummary.hadEdVisit.1',
  'ocmEpisodeSummary.hadObsVisit.1',

  '-- OCM Episode Medication Filters --',
  'ocmEpisodeSummary.didUseNovelTherapy.1',
  'episodeDrugSummary.hadOndansetron.1',
  'episodeDrugSummary.hadGranisetron.1',
  'episodeDrugSummary.hadDenosumab.1',
  'episodeDrugSummary.hadZoledronicAcid.1',
  'episodeDrugSummary.hadAbraxane.1',
  'episodeDrugSummary.hadApixaban.1',
  'episodeDrugSummary.hadDalteparin.1',
  'episodeDrugSummary.hadFilgrastim.1',
  'episodeDrugSummary.hadPegfilgrastim.1',
  'episodeDrugSummary.hadPalonosetron.1',
  'episodeDrugSummary.hadBevacizumab.1',
  'episodeDrugSummary.hadIbrutinib.1',
  'episodeDrugSummary.hadPaclitaxel.1',
  'episodeDrugSummary.hadImatinib.1',
  'episodeDrugSummary.hadNivolumab.1',
  'episodeDrugSummary.hadLenalidomide.1',
  'episodeDrugSummary.hadPembrolizumab.1',
  'episodeDrugSummary.hadFulvestrant.1',
  'episodeDrugSummary.hadbortezomib.1',
  'episodeDrugSummary.hadTrastuzumab.1',
  'episodeDrugSummary.hadPalbociclib.1',
  'episodeDrugSummary.hadRituximab.1',
  'episodeDrugSummary.hadSipuleucelT.1',
  'episodeDrugSummary.hadPemetrexed.1',
  'episodeDrugSummary.hadRadium223.1',
  'episodeDrugSummary.hadPertuzumab.1',
];

// =============================================================================
// analytics
// =============================================================================

export const ocmAnalyticsProduct = toAnalyticsProduct<OcmLoc>({
  key: 'ocm',
  // router
  router: ocmRouter,
  globalsLoc: 'globals',
  articleDetailLoc: 'article_detail',
  clinicianHomeLoc: 'ocm_clinician_overview',
  patientHomeLoc: 'patient_timeline',
  patientTimelineLoc: 'patient_timeline',
  queryId__episodeExplorerRoute: queryId =>
    ocmRouter.route('episodes', { params: { cohort: queryId } }),
  //
  defaultCatalogName: 'ocmEpisode',
  defaultDataset: key__dataset.ocmDefault,
  datasets: [key__dataset.ocmDefault, key__dataset.ocmUnfiltered, key__dataset.ocmBene],
  timePeriods: [
    key__timePeriod.ocmAllTime,
    key__timePeriod.ocmBaseline,
    key__timePeriod.ocmPP1,
    key__timePeriod.ocmBaselineToPP1,
    key__timePeriod.ocmPP2,
    key__timePeriod.ocmBaselineToPP2,
    key__timePeriod.ocmPP1ToPP2,
    key__timePeriod.ocmPP3,
    key__timePeriod.ocmBaselineToPP3,
    key__timePeriod.ocmPP1ToPP3,
    key__timePeriod.ocmPP2ToPP3,
    key__timePeriod.ocmPP4,
    key__timePeriod.ocmBaselineToPP4,
    key__timePeriod.ocmPP1ToPP4,
    key__timePeriod.ocmPP2ToPP4,
    key__timePeriod.ocmPP3ToPP4,
    key__timePeriod.ocmPP5,
    key__timePeriod.ocmBaselineToPP5,
    key__timePeriod.ocmPP1ToPP5,
    key__timePeriod.ocmPP2ToPP5,
    key__timePeriod.ocmPP3ToPP5,
    key__timePeriod.ocmPP4ToPP5,
    key__timePeriod.ocmPP6,
    key__timePeriod.ocmBaselineToPP6,
    key__timePeriod.ocmPP1ToPP6,
    key__timePeriod.ocmPP2ToPP6,
    key__timePeriod.ocmPP3ToPP6,
    key__timePeriod.ocmPP4ToPP6,
    key__timePeriod.ocmPP5ToPP6,
    key__timePeriod.ocmPP7,
    key__timePeriod.ocmBaselineToPP7,
    key__timePeriod.ocmPP1ToPP7,
    key__timePeriod.ocmPP2ToPP7,
    key__timePeriod.ocmPP3ToPP7,
    key__timePeriod.ocmPP4ToPP7,
    key__timePeriod.ocmPP5ToPP7,
    key__timePeriod.ocmPP6ToPP7,
    key__timePeriod.ocmPP8,
    key__timePeriod.ocmBaselineToPP8,
    key__timePeriod.ocmPP1ToPP8,
    key__timePeriod.ocmPP2ToPP8,
    key__timePeriod.ocmPP3ToPP8,
    key__timePeriod.ocmPP4ToPP8,
    key__timePeriod.ocmPP5ToPP8,
    key__timePeriod.ocmPP6ToPP8,
    key__timePeriod.ocmPP7ToPP8,
    key__timePeriod.ocmPP9,
    key__timePeriod.ocmBaselineToPP9,
    key__timePeriod.ocmPP1ToPP9,
    key__timePeriod.ocmPP2ToPP9,
    key__timePeriod.ocmPP3ToPP9,
    key__timePeriod.ocmPP4ToPP9,
    key__timePeriod.ocmPP5ToPP9,
    key__timePeriod.ocmPP6ToPP9,
    key__timePeriod.ocmPP7ToPP9,
    key__timePeriod.ocmPP8ToPP9,
    key__timePeriod.ocmPP10,
    key__timePeriod.ocmBaselineToPP10,
    key__timePeriod.ocmPP1ToPP10,
    key__timePeriod.ocmPP2ToPP10,
    key__timePeriod.ocmPP3ToPP10,
    key__timePeriod.ocmPP4ToPP10,
    key__timePeriod.ocmPP5ToPP10,
    key__timePeriod.ocmPP6ToPP10,
    key__timePeriod.ocmPP7ToPP10,
    key__timePeriod.ocmPP8ToPP10,
    key__timePeriod.ocmPP9ToPP10,
    key__timePeriod.ocmPP11,
    key__timePeriod.ocmBaselineToPP11,
    key__timePeriod.ocmPP1ToPP11,
    key__timePeriod.ocmPP2ToPP11,
    key__timePeriod.ocmPP3ToPP11,
    key__timePeriod.ocmPP4ToPP11,
    key__timePeriod.ocmPP5ToPP11,
    key__timePeriod.ocmPP6ToPP11,
    key__timePeriod.ocmPP7ToPP11,
    key__timePeriod.ocmPP8ToPP11,
    key__timePeriod.ocmPP9ToPP11,
    key__timePeriod.ocmPP10ToPP11,
  ],
  timeTree: 'ocmEpisodeSummary.performancePeriod',
  initialArticleMarkup: defaultOcmMarkup,
  defaultFilterLevelKeys: ocmDefaultLevelKeys,
  // dataviz config:
  catalogKey: 'catalogOcmEpisode',
  patientRelnKey: 'ocmEpisodeSummaryPatientSnapshot',
  episodeRelnKey: 'ocmEpisodeSummary',
  showOcmPeriods: true,
});
