import { makeScreen } from '../../core/appbase/screen';
import * as logic from './registration.logic';
import Registration from './Registration';

export default makeScreen({
  agent: logic.agent,
  reducer: logic.reducer,
  selector: logic.selector,
  view: Registration,
});
