import React from 'react';
import { classNames } from '../core/styles';
import css from './centeredContainer.css';

interface CenteredContainerProps {
  className?: string;
}

const CenteredContainer: React.FunctionComponent<CenteredContainerProps> = ({
  children,
  className,
}) => <div className={classNames(css.root, className)}>{children}</div>;

export default CenteredContainer;
