import { useEffect } from 'react';

export default function useAutoWelcome(openDialog: () => void) {
  useEffect(() => {
    if (!hasSeenWelcome()) openDialog();
  }, [openDialog]);
}

const storageKey = 'hide-welcome';

export function hasSeenWelcome() {
  return localStorage.getItem(storageKey) === 'true';
}

export function setHasSeenWelcome(hasSeen: boolean) {
  localStorage.setItem(storageKey, String(hasSeen));
}
