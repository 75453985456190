import * as _ from '@tuple-health/eng/dist/dryscript/ds';
import { Customer } from '@tuple-health/eng/dist/th/ds/common/domain/customer/customer/Customer';
import React, {
  createContext,
  MutableRefObject,
  ReactNode,
  useCallback,
  useContext,
  useMemo,
  useRef,
  useState,
} from 'react';
import useMethods from 'use-methods';
import useUserDrawer from '../../core/store/useUserDrawer';
import { ProductRichLink } from '../../packages/content/richLink/richLink.model';
import usePushNotes from '../../pushHandler/usePushNotes';
import useSidebarRoute from './userDrawer/useSidebarRoute';

type ChatAgent = ChatState & {
  config: ConvoConfig;
  setConfig: (config: Partial<ConvoConfig>) => void;
  goToList: () => void;
  goToDetail: (convo: ConvoPointer) => void;
  goToCreation: (customerRecords: _.Record<Customer>[]) => void;
  setCustomer: (customer?: _.Record<Customer> | undefined) => void;
  addAttachRef: AddAttchmentRef;
};

interface ConvoConfig {
  isPublic: boolean;
  includeUninvolved: boolean;
}

type ChatState =
  | {
      mode: 'list';
    }
  | {
      mode: 'detail';
      convo: ConvoPointer;
    }
  | {
      mode: 'creation';
      customer?: _.Record<Customer>;
    };

export interface ConvoPointer {
  id: string;
  customerId: string;
}

export type AddAttchmentRef = MutableRefObject<undefined | ((attachment: ProductRichLink) => void)>;

// =============================================================================
// state manipulation methods
// =============================================================================

export const chatState__ownerId = (state: ChatState): string | undefined => {
  switch (state.mode) {
    case 'creation':
      return state.customer ? state.customer.id : undefined;
    case 'detail':
      return state.convo.customerId;
    default:
      return undefined;
  }
};

function useChatAgent(): ChatAgent {
  const [mode, callbacks] = useMethods(methods, { mode: 'list' });
  const [config, setConfigRaw] = useState<ConvoConfig>({
    isPublic: false,
    includeUninvolved: false,
  });

  const setConfig = useCallback((config: Partial<ConvoConfig>) => {
    setConfigRaw(oldConfig => ({ ...oldConfig, ...config }));
  }, []);

  const addAttachRef = useRef<(link: ProductRichLink) => void>();

  const agent = useMemo(
    () => ({
      ...mode,
      ...callbacks,
      config,
      setConfig,
      addAttachRef,
    }),
    [callbacks, config, mode, setConfig],
  );

  const { goToChat } = useSidebarRoute();
  const drawer = useUserDrawer();

  usePushNotes(({ event, isClick }) => {
    if (event.tag === 'receivedMsg' && isClick) {
      drawer.open();
      agent.goToDetail({ id: event.field.conversationId, customerId: event.field.customerId });
      goToChat();
    }
  });

  return agent;
}

export function useChat() {
  const actor = useChatOpt();
  if (!actor) throw Error('No Chat context found');
  return actor;
}

export function useChatOpt() {
  const actor = useContext(Context);
  return actor;
}

const Context = createContext<ChatAgent | undefined>(undefined);

export function ChatCreator(props: { children: ReactNode }) {
  const agent = useChatAgent();
  return (
    <Context.Provider value={agent}>
      <ConvoConfigContext.Provider value={agent.config}>
        {props.children}
      </ConvoConfigContext.Provider>
    </Context.Provider>
  );
}

const methods = (s: ChatState) => ({
  goToList: (): ChatState => ({
    mode: 'list',
  }),

  goToDetail: (convo: ConvoPointer): ChatState => ({
    mode: 'detail',
    convo,
  }),

  goToCreation: (customerRecords: _.Record<Customer>[]): ChatState => ({
    mode: 'creation',
    customer: customerRecords.length === 1 ? customerRecords[0] : undefined,
  }),

  setCustomer(customer?: _.Record<Customer>) {
    if (s.mode !== 'creation') throw Error('cannot set creator when not in creation mode');
    s.customer = customer;
  },
});

export const ConvoConfigContext = createContext<ConvoConfig | undefined>(undefined);
