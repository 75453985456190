import {
  FIELD_EMAIL_ADDRESS,
  FIELD_HANDLE,
  FIELD_INVITE_ID,
  FIELD_NAME,
  FIELD_PHONE_RECORD,
  FIELD_SUPPORT_TYPE,
  makeSupportScreen,
} from './support.screen';

const FIELD = {
  comments: {
    name: 'Comments',
    multiline: true,
    optional: true,
  },
  name: {
    name: FIELD_NAME,
  },
  email: {
    name: FIELD_EMAIL_ADDRESS,
  },
};

const COMMON = [
  {
    name: 'Organization',
  },
  FIELD.name,
  FIELD.email,
  {
    name: 'Phone Number',
  },
  FIELD.comments,
];

export const supportScreens = {
  general: makeSupportScreen({
    fields: [
      {
        name: FIELD_SUPPORT_TYPE,
        value: 'General Request',
        hidden: true,
      },
      ...COMMON,
    ],
  }),

  welcomeWrongNumber: makeSupportScreen({
    intro: `Whoops! Please provide us with your correct phone number and we'll be in touch.`,
    fields: [
      {
        name: FIELD_SUPPORT_TYPE,
        value: 'Wrong Number (Welcome)',
        hidden: true,
      },
      {
        name: FIELD_HANDLE,
        hidden: true,
      },
      {
        name: FIELD_INVITE_ID,
        hidden: true,
      },
      {
        name: FIELD_PHONE_RECORD,
        hidden: true,
      },
      ...COMMON,
    ],
  }),

  loginWrongNumber: makeSupportScreen({
    intro: `Whoops! Please provide us with your correct phone number and we'll be in touch.`,
    fields: [
      {
        name: FIELD_SUPPORT_TYPE,
        value: 'Wrong Number (Login)',
        hidden: true,
      },
      {
        name: FIELD_HANDLE,
        hidden: true,
      },
      {
        name: FIELD_INVITE_ID,
        hidden: true,
      },
      {
        name: FIELD_PHONE_RECORD,
        hidden: true,
      },
      ...COMMON,
    ],
  }),

  welcomeNoCode: makeSupportScreen({
    intro: `Whoops! To help us investigate, please provide the information below. We'll be in touch ASAP.`,
    fields: [
      {
        name: FIELD_SUPPORT_TYPE,
        value: 'No Code (Welcome)',
        hidden: true,
      },
      {
        name: FIELD_HANDLE,
        hidden: true,
      },
      {
        name: FIELD_INVITE_ID,
        hidden: true,
      },
      {
        name: FIELD_PHONE_RECORD,
        hidden: true,
      },
      ...COMMON,
    ],
  }),

  loginNoCode: makeSupportScreen({
    intro: `Whoops! To help us investigate, please provide the information below. We'll be in touch ASAP.`,
    fields: [
      {
        name: FIELD_SUPPORT_TYPE,
        value: 'No Code (Login)',
        hidden: true,
      },
      {
        name: FIELD_HANDLE,
        hidden: true,
      },
      {
        name: FIELD_PHONE_RECORD,
        hidden: true,
      },
      ...COMMON,
    ],
  }),

  createAccount: makeSupportScreen({
    intro: `Hi there! Please provide the information below. We'll be in touch to get you set up.`,
    fields: [
      {
        name: FIELD_SUPPORT_TYPE,
        value: 'Create Account',
        hidden: true,
      },
      ...COMMON,
    ],
  }),

  welcomeInviteExpired: makeSupportScreen({
    intro: `This invitation has expired! Please provide your contact information below and we'll send you a new invitation.`,
    fields: [
      {
        name: FIELD_SUPPORT_TYPE,
        value: 'Invite Expired',
        hidden: true,
      },
      {
        name: FIELD_HANDLE,
        hidden: true,
      },
      {
        name: FIELD_INVITE_ID,
        hidden: true,
      },
      ...COMMON,
    ],
  }),
};
