import { Array, Boolean, Number, Partial, Static, String, Union } from 'runtypes';
import { pick } from '@tuple-health/common';
import { parseOldDatasetsJson, writeOldDatasetsJson } from '../../product/dataset/oldDataset.model';
import { parseTimePeriodOpt, parseTimePeriod } from '../../product/dataset/timePeriod.model';
import { JsonRefineMenu, jsonRefineMenuAdapter } from '../../refinePanel/refineMenu.format';
import { toAdapter } from '../../util/adaptLib';
import * as yamlLib from '../../util/yamlLib';
import { VizConfig } from '../../viz/model/VizConfig';
import { VizSelection } from '../../viz/model/VizSelection';
import { Dataviz } from '../model/dataviz.model';
import { JsonTable, JsonTableDelta } from '../model/tableJson';
import { TableSort, TableSortType } from '../model/tableSort';
import { parseDataset } from '../../product/dataset/dataset.model';
import { writeCohortFilterText, hackParseCohortFilterText } from '../../cohort/model/cohort.format';

// =============================================================================
// json model
// =============================================================================

const JsonTableSort = Union(TableSortType, TableSort);
type JsonTableSort = Static<typeof JsonTableSort>;

const JsonDataviz = Partial({
  comparison: String,
  representative: Boolean,
  timePeriod: String,
  newDataset: String,
  dataset: String,
  datasets: Array(String),
  query: String,
  table: JsonTable,
  tableChanges: JsonTableDelta,
  tableSort: JsonTableSort,
  height: Number,
  sidebar: Boolean,
  viz: VizConfig,
  selection: VizSelection,
  refine: JsonRefineMenu,
  refines: Array(JsonRefineMenu),
  filters: Boolean, // deepFilterModels
  deepFilters: String,
});
type JsonDataviz = Static<typeof JsonDataviz>;

// =============================================================================
// sort
// =============================================================================

const tableSortAdapter = toAdapter<JsonTableSort, TableSort>(
  json => {
    if (typeof json === 'string') {
      return { type: json };
    } else {
      return json;
    }
  },
  model => (model.direction ? model : model.type),
);

// =============================================================================
// dataviz
// =============================================================================

const jsonDatavizFormat = toAdapter<string, JsonDataviz>(
  yaml => {
    const json = yamlLib.parse(yaml);
    return JsonDataviz.check(json);
  },
  json => {
    try {
      return yamlLib.write(json);
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(json);
      throw e;
    }
  },
);

// TODO merge into single pick (may need to reoder morkup in tests)
const jsonDatavizAdapter = toAdapter<JsonDataviz, Dataviz>(
  json => {
    if (!json.query && !json.table) {
      throw Error('dataviz must specify a query or a table');
    }

    const newDataset = json.newDataset ? parseDataset(json.newDataset) : undefined;

    let timePeriod = json.timePeriod ? parseTimePeriod(json.timePeriod) : undefined;
    let oldDatasets = parseOldDatasetsJson(json.dataset, json.datasets);
    if (timePeriod && oldDatasets) {
      throw Error('dataviz cannot specify timePeriod and datasets');
    }

    // parse single named dataset as a timePeriod instead of as datasets
    if (json.dataset) {
      const datasetTimePeriod = parseTimePeriodOpt(json.dataset);
      if (datasetTimePeriod) {
        timePeriod = parseTimePeriod(json.dataset);
        oldDatasets = undefined;
      }
    }

    if (json.refine && json.refines) throw Error('cannot specify refine and refines');
    const jsonRefineMenus = json.refine ? [json.refine] : json.refines;
    const refineMenus = jsonRefineMenus
      ? jsonRefineMenus.map(jsonRefineMenuAdapter.parse)
      : undefined;

    const dataviz: Dataviz = {
      ...pick(json, 'table', 'height', 'selection', 'tableChanges', 'comparison'),
    };

    // ensure data structure is clean for testing equality
    if ('sidebar' in json) dataviz.enableSidebar = json.sidebar;
    if (json.viz) dataviz.vizConfig = json.viz;
    if (json.query) dataviz.query = json.query.trim();
    if (newDataset) dataviz.newDataset = newDataset;
    if (timePeriod) dataviz.timePeriod = timePeriod;
    if (oldDatasets) dataviz.oldDatasets = oldDatasets;
    if (refineMenus) dataviz.shallowRefineMenus = refineMenus;
    if (json.filters) dataviz.deepFilterModels = [];
    if (json.tableSort) dataviz.tableSort = tableSortAdapter.parse(json.tableSort);
    if (json.representative) dataviz.representative = true;
    if (json.deepFilters) dataviz.deepFilterPreds = hackParseCohortFilterText(json.deepFilters);

    return dataviz;
  },
  model => {
    const jsonRefineMenus = model.shallowRefineMenus
      ? model.shallowRefineMenus.map(jsonRefineMenuAdapter.write)
      : undefined;

    return {
      representative: model.representative,
      newDataset: model.newDataset ? model.newDataset.key : undefined,
      timePeriod: model.timePeriod ? model.timePeriod.key : undefined,
      ...writeOldDatasetsJson(model.oldDatasets),
      ...pick(model, 'query', 'table', 'comparison'),
      sidebar: model.enableSidebar,
      ...pick(model, 'height'),
      viz: model.vizConfig,
      ...pick(model, 'selection', 'tableChanges'),
      tableSort: model.tableSort && tableSortAdapter.write(model.tableSort),
      refines: jsonRefineMenus,
      filters: model.deepFilterModels ? true : undefined,
      deepFilters:
        model.deepFilterPreds && model.deepFilterPreds.length
          ? writeCohortFilterText(model.deepFilterPreds)
          : undefined,
    };
  },
);

export const datavizFormat = jsonDatavizFormat.then(jsonDatavizAdapter);
