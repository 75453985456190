import { $example, $kind, $schema } from '@tuple-health/common/dist/data';
import {
  $edi835Document,
  $edi837Claim,
  $edi837ClaimLine,
  $edi837Document,
} from '@tuple-health/common/dist/edi';
import { $superbill } from '@tuple-health/common/dist/emr/superbill/superbill.model';
import { $patient } from '@tuple-health/common/dist/pm/patient';
import { createRouter, vocabulary } from '@tuple-health/common/dist/router';
import { createElement } from 'react';
import { HomeIcon } from '../../../components/icons/icons';
import { $paymentElement } from '@tuple-health/common/dist/pm';

// =============================================================================
// locs
// =============================================================================

const locs = vocabulary({
  // ===================================
  // data
  // ===================================
  schemaList: undefined,
  schemaDetail: undefined,
  kindList: undefined,
  conceptList: undefined,
  conceptDetail: undefined,
  // ===================================
  // vcm
  // ===================================
  home: undefined,
  edi: undefined,
  edi837List: undefined,
  edi837Detail: undefined,
  edi835List: undefined,
  edi835Detail: undefined,
  ediClaimList: undefined,
  ediClaimDetail: undefined,
  ediClaimLineList: undefined,
  ediClaimLineDetail: undefined,
  patientList: undefined,
  patientDetail: undefined,
  patientTimeline: undefined,
  patientCoverage: undefined,
  paymentElementList: undefined,
  paymentElementDetail: undefined,
  superbillList: undefined,
  superbillDetail: undefined,
});

export type VcmLoc = typeof locs.Term;

// =============================================================================
// builder
// =============================================================================

const _ = createRouter<VcmLoc>('vcm', 'Value Cycle Management', locs);

// =============================================================================
// home
// =============================================================================

_.add(
  _.sideMenu({
    items: [
      _.page({
        loc: 'home',
        icon: createElement(HomeIcon),
        label: 'Home',
        childItems: [
          _.page({
            loc: 'edi',
            slug: 'edi',
            label: 'EDI',
            childItems: [
              _.page({
                loc: 'edi837List',
                slug: '837s',
                label: '837 List',
                childConcept: _.concept(
                  $edi837Document,
                  _.page({
                    loc: 'edi837Detail',
                    label: '837',
                  }),
                ),
              }),
              _.page({
                loc: 'edi835List',
                slug: 'eras',
                label: 'ERA 835 List',
                childConcept: _.concept(
                  $edi835Document,
                  _.page({
                    loc: 'edi835Detail',
                    label: 'ERA 835',
                  }),
                ),
              }),
              _.page({
                loc: 'ediClaimList',
                slug: 'claims',
                label: 'Claim List',
                childConcept: _.concept(
                  $edi837Claim,
                  _.page({
                    loc: 'ediClaimDetail',
                    label: 'Claim',
                  }),
                ),
              }),
              _.page({
                loc: 'ediClaimLineList',
                slug: 'claim-lines',
                label: 'Claim Line List',
                childConcept: _.concept(
                  $edi837ClaimLine,
                  _.page({
                    loc: 'ediClaimLineDetail',
                    label: 'Claim Line',
                  }),
                ),
              }),
            ],
          }),
        ],
      }),
    ],
  }),
);

// =============================================================================
// patients
// =============================================================================

const superbill = _.concept(
  $superbill,
  _.page({
    loc: 'superbillDetail',
    label: 'Superbill Detail',
  }),
);

const patient = _.concept(
  $patient,
  _.tabMenu({
    items: [
      _.page({
        loc: 'patientDetail',
        label: 'Demographic & Contact',
      }),
      _.page({
        loc: 'patientCoverage',
        slug: 'coverage',
        label: 'Insurance Coverage',
      }),
      _.page({
        loc: 'patientTimeline',
        slug: 'timeline',
        label: 'Clinical Service Timeline',
        childItems: [
          _.page({
            loc: 'superbillList',
            slug: 'superbills',
            label: 'Superbills',
            childConcept: superbill,
          }),
        ],
      }),
    ],
  }),
);

_.add(
  _.page({
    loc: 'patientList',
    slug: 'patients',
    label: 'Patient List',
    childConcept: patient,
  }),
);

const paymentElement = _.concept(
  $paymentElement,
  _.page({
    loc: 'paymentElementDetail',
    label: 'Payment Element Detail',
  }),
);

_.add(
  _.page({
    loc: 'paymentElementList',
    slug: 'payment-elements',
    label: 'Payment Elements',
    childConcept: paymentElement,
  }),
);

// const patient2 = _.tabMenu({
//   slug: '',
//   label: 'Patient Home',
//   items: [
//     _.page({
//       loc: 'patientDetails',
//       label: 'Demographic & Contact',
//     }),
//     _.page({
//       loc: 'patientTimeline',
//       slug: 'timeline',
//       label: 'Clinical Service Timeline',
//     }),
//   ],
// });

_.add(
  _.folder({
    label: 'Data',
    // icon?
    items: [
      _.page({
        loc: 'schemaList',
        slug: 'tables',
        label: 'Tables',
        childConcept: _.concept(
          $schema,
          _.page({
            loc: 'schemaDetail',
            label: 'Table',
          }),
        ),
      }),
      _.page({
        loc: 'kindList',
        slug: 'concepts',
        label: 'Concepts',
        childConcept: _.concept(
          $kind,
          _.page({
            loc: 'conceptList',
            label: 'Concepts',
            childConcept: _.concept(
              $example, // TODO this is a hack, in truth we are getting the uri.ns from the previous slug
              _.page({
                loc: 'conceptDetail',
                label: 'Concept',
              }),
            ),
          }),
        ),
      }),
    ],
  }),
);

// =============================================================================
// router
// =============================================================================

export const vcmRouter = _.build();
