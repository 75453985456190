import { parse } from 'query-string';
import { pathToAction, redirect as wrappedRedirect, RoutesMap } from 'redux-first-router';
import { Dispatch } from '../../contracts/Dispatch';
import { makeContract } from '../contract';
import { memoize } from '@tuple-health/common';
import { makeProvider } from '../provider';
import { makeSelector } from '../selector';
import { Screen, ScreenParameterMap, ScreenRoutes, ScreenState } from './screen';
import { setFragment } from '../store/useUrlFragment';

// ============================
// contract
// ============================
export interface Router {
  location?: {
    path: string;
    screen: Screen;
    parameters: {};
    query: {};
  };
  redirectTo(path: string): void;
}

export const Router = makeContract<Router>('router');

// ============================
// service
// ============================
export const makeRouter = <S extends ScreenParameterMap>(screens: ScreenRoutes<S>) => {
  // -- data structures -- //
  const routes: RoutesMap = {};
  for (const path of Object.keys(screens)) routes[path] = { path };

  // -- Router API -- //

  const router = makeProvider(
    Router,
    makeSelector({
      dispatch: Dispatch,
    })<ScreenState<S>, Router>(
      memoize(
        ({ state, dispatch }: { state: ScreenState<S>; dispatch: Dispatch }) => {
          if (!state) {
            return {
              location: undefined,
              redirectTo,
            };
          }

          const screen = screens[state.pathId];
          if (!screen) throw new Error(`unroutable path: ${state.pathId}`);

          return {
            location: {
              path: state.path,
              screen,
              parameters: state.parameters,
              query: state.query,
            },
            redirectTo,
          };

          function redirectTo(path: string) {
            // TODO this is hacky, but for testing we need to sync browser hash with the router state
            setFragment(path);

            // console.log({ path });
            const queryStart = path.indexOf('?');
            let query = {};
            if (queryStart >= 0) {
              query = parse(path.substring(queryStart + 1));
              path = path.substring(0, queryStart);
            }
            let action = pathToAction(path, routes) as any;
            action = wrappedRedirect({ ...action, query });
            dispatch(action);
          }
        },
        ([{ state }]: { state: ScreenState<S> }[]) => [
          state && state.pathId,
          state && state.parameters,
          state && state.query,
        ],
      ),
    ),
  );

  // -- return -- //
  return {
    router,
    routes,
  };
};
