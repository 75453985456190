// TODO this is deprecated, prefer useRunQuery()

import { DataResult } from '@tuple-health/eng/dist/th/ds/common/tech/analytics/data/DataResult';
import React, { createContext, ReactNode, useCallback, useContext } from 'react';
import useCustomer from '../../core/store/useCustomer';
import useUser from '../../core/store/useUser';
import { submitRequest } from './query.cache';
import { CubeRequest } from './query.types';
import useGateway from '../../core/store/useGateway';

// =============================================================================
// send request
// =============================================================================

export type SendRequest = (args: {
  customerId: string;
  request: CubeRequest;
}) => Promise<DataResult>;

const SendRequestContext = createContext<SendRequest | undefined>(undefined);

export const SendRequestProvider = SendRequestContext.Provider;

export function SendRequestCreator({ children }: { children?: ReactNode }) {
  const { session } = useUser();
  const gateway = useGateway();

  const sendRequest: SendRequest = useCallback(
    ({ customerId, request }) => submitRequest({ gateway, session, customerId, request }),
    [gateway, session],
  );

  return <SendRequestProvider value={sendRequest}>{children}</SendRequestProvider>;
}

// =============================================================================
// run request
// =============================================================================

export type RunRequest = (request: CubeRequest) => Promise<DataResult>;

export default function useRunRequest() {
  const sendRequest = useContext(SendRequestContext);
  if (!sendRequest) throw new Error('send request not provided');

  const { customerId } = useCustomer();

  const runRequest: RunRequest = useCallback(
    (request: CubeRequest) => sendRequest({ customerId, request }),
    [customerId, sendRequest],
  );

  return runRequest;
}
