import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';
import React from 'react';
import { ReactComponent as AmbulanceSvg } from './ambulance.svg';
import { ReactComponent as AnchorSvg } from './anchor.svg';
import { ReactComponent as TherapySvg } from './bandAid.svg';
import { ReactComponent as DrugSvg } from './bowl.svg';
import { ReactComponent as FacilitySvg } from './building.svg';
import { ReactComponent as CrossBundleAnalysisSvg } from './crossBundleAnalysis.svg';
import { ReactComponent as DiagnosticsSvg } from './diagnostics.svg';
import { ReactComponent as DmeSvg } from './dme.svg';
import { ReactComponent as DoctorSvg } from './doctor.svg';
import { ReactComponent as EpisodeSvg } from './episode.svg';
import { ReactComponent as HospiceSvg } from './holdingHands.svg';
import { ReactComponent as HomeHealthSvg } from './housePlus.svg';
import { ReactComponent as ImagingSvg } from './imaging.svg';
import { ReactComponent as IrfSvg } from './irf.svg';
import { ReactComponent as LabsSvg } from './labs.svg';
import { ReactComponent as LibrarySvg } from './library.svg';
import { ReactComponent as SnfSvg } from './nurse.svg';
import { ReactComponent as OfficeVisitsSvg } from './officeVisits.svg';
import { ReactComponent as OutpatientRehabSvg } from './outpatientRehab.svg';
import { ReactComponent as ProceduresSvg } from './procedures.svg';
import { ReactComponent as ReadmitSvg } from './readmission.svg';
import { ReactComponent as RadiationSvg } from './revolvingPlanets.svg';
import { ReactComponent as ShareSvg } from './share.svg';
import { ReactComponent as MethodologySvg } from './squareLoop.svg';
import { ReactComponent as HospitalSvg } from './toolbox.svg';
import { ReactComponent as OutpatientSvg } from './walkingPerson.svg';
import { ReactComponent as FollowNotificationsOnSvg } from './follow-notifications-on.svg';
import { ReactComponent as FollowNotificationsOffSvg } from './follow-notifications-off.svg';

// demo
import { ReactComponent as DemoChatSvg } from './demo-chat.svg';
import { ReactComponent as DemoGridSvg } from './demo-grid.svg';
import { ReactComponent as DemoLibrarySvg } from './demo-library.svg';
import { ReactComponent as DemoMailSvg } from './demo-mail.svg';
import { ReactComponent as DemoPersonSvg } from './demo-person.svg';
import { ReactComponent as DemoTupleSvg } from './demo-tuple.svg';
import { VizNs } from '@tuple-health/common/dist/data';
import GridVizIcon from '@material-ui/icons/TableChart';
import ChartVizIcon from '@material-ui/icons/BarChart';
import TreeVizIcon from '@material-ui/icons/AccountTree';

export { default as FilterIcon } from '@material-ui/icons/FilterList';
export { default as SortIcon } from '@material-ui/icons/ImportExport';

export { default as CohortIcon } from '@material-ui/icons/BarChart'; // TODO distinguish from dataviz
// export { default as DatavizSlideIcon } from '@material-ui/icons/BarChart';
export { default as DownloadIcon } from '@material-ui/icons/CloudDownload';
// export { default as UploadIcon } from '@material-ui/icons/CloudUpload';
export { default as DatavizSlideIcon } from '@material-ui/icons/Assessment';
export { default as PromptSlideIcon } from '@material-ui/icons/Comment';
export { default as ChapterIcon } from '@material-ui/icons/FolderOpen';
export { default as TextSlideIcon } from '@material-ui/icons/FontDownload';
export { default as ImageIcon } from '@material-ui/icons/Image';
export { default as TextIcon } from '@material-ui/icons/Subject';
export { default as PatientIcon } from '@material-ui/icons/Face';
export { default as RowIcon } from '@material-ui/icons/ViewColumn';
export { default as RowStopIcon } from '@material-ui/icons/Stop';
export { default as CodeIcon } from '@material-ui/icons/Code';
export { default as DefineTextIcon } from '@material-ui/icons/Receipt';
export { default as QueryIcon } from '@material-ui/icons/Search';
export { default as SearchIcon } from '@material-ui/icons/Search'; // TODO should be different from Query?
export { default as EditIcon } from '@material-ui/icons/Edit';
export { default as SettingsIcon } from '@material-ui/icons/Settings';
// export { default as AttachIcon } from '@material-ui/icons/Mail';
// export { default as SendIcon } from '@material-ui/icons/ArrowUpward';
export { default as ArrowDropIcon } from '@material-ui/icons/ArrowDropDown';
export { default as ExternalLinkIcon } from '@material-ui/icons/Language';
export { default as InternalLinkIcon } from '@material-ui/icons/Link';
export { default as CloseIcon } from '@material-ui/icons/Close';
export { default as AddIcon } from '@material-ui/icons/Add';
export { default as DeleteIcon } from '@material-ui/icons/Delete';
export { default as ColumnIcon } from '@material-ui/icons/ViewColumn';
export { default as CheckIcon } from '@material-ui/icons/Check';
export { default as CopyIcon } from '@material-ui/icons/FileCopy';
export { default as ExpandMoreIcon } from '@material-ui/icons/ExpandMore';
export { default as ExpandLessIcon } from '@material-ui/icons/ExpandLess';
export { default as RenameIcon } from '@material-ui/icons/TextFormat';

export { default as ReconciliationIcon } from '@material-ui/icons/Assignment';
export { default as DataStoryIcon } from '@material-ui/icons/Folder';
export { default as HomeIcon } from '@material-ui/icons/Home';
export { default as LtachIcon } from '@material-ui/icons/Hotel';
export { default as ContactsIcon } from '@material-ui/icons/People'; // TODO should be different from patients
export { default as PatientsIcon } from '@material-ui/icons/People';
export { default as PersonAdd } from '@material-ui/icons/PersonAddOutlined';
export { default as PersonOutlineIcon } from '@material-ui/icons/PersonOutline';
export { default as MailIcon } from '@material-ui/icons/Email';
export { default as MailOutlineIcon } from '@material-ui/icons/MailOutline';
export { default as PrivateConvoIcon } from '@material-ui/icons/Email';
export { default as PublicConvoIcon } from '@material-ui/icons/Forum';
export { default as ArticleIcon } from '@material-ui/icons/InsertDriveFile';
export { default as AttachIcon } from '@material-ui/icons/AttachFile';
export { default as SaveIcon } from '@material-ui/icons/Bookmark'; // TODO rename to Bookmark
export { default as SaveIcon2 } from '@material-ui/icons/Save';
export { default as ProfileIcon } from '@material-ui/icons/AccountCircle';
export { default as BackIcon } from '@material-ui/icons/ArrowBack';
export { default as ForwardIcon } from '@material-ui/icons/ArrowForward';
export { default as HelpIcon } from '@material-ui/icons/HelpOutline';
export { default as StarCheckedIcon } from '@material-ui/icons/Star';
export { default as StarUncheckedIcon } from '@material-ui/icons/StarBorder';
export { default as SlideRightIcon } from '@material-ui/icons/KeyboardArrowRight';
export { default as SlideLeftIcon } from '@material-ui/icons/KeyboardArrowLeft';
export { default as ProblemIcon } from '@material-ui/icons/ReportProblemOutlined';
export { default as RedoIcon } from '@material-ui/icons/Redo';
export { default as UndoIcon } from '@material-ui/icons/Undo';
export { default as MoreIcon } from '@material-ui/icons/MoreHoriz';
export { default as DomainIcon } from '@material-ui/icons/Domain';
export { default as DescriptionIcon } from '@material-ui/icons/DescriptionOutlined';
export { default as NetworkIcon } from '@material-ui/icons/ShareOutlined';
export { default as LocationIcon } from '@material-ui/icons/PlaceOutlined';
export { default as BulletIcon } from '@material-ui/icons/FormatListBulletedOutlined';
export { default as PhoneIcon } from '@material-ui/icons/PhoneOutlined';
export { default as NumberedListIcon } from '@material-ui/icons/FormatListNumberedOutlined';

export const AnchorIcon = customIcon(AnchorSvg);
export const ClinicianIcon = customIcon(DoctorSvg);
export const CrossBundleAnalysisIcon = customIcon(CrossBundleAnalysisSvg);
export const DiagnosticsIcon = customIcon(DiagnosticsSvg);
export const DmeIcon = customIcon(DmeSvg);
export const DrugIcon = customIcon(DrugSvg);
export const EdIcon = customIcon(AmbulanceSvg);
export const EpisodeIcon = customIcon(EpisodeSvg);
export const FacilityIcon = customIcon(FacilitySvg);
export const HomeHealthIcon = customIcon(HomeHealthSvg);
export const HospiceIcon = customIcon(HospiceSvg);
export const HospitalIcon = customIcon(HospitalSvg);
export const ImagingIcon = customIcon(ImagingSvg);
export const IrfIcon = customIcon(IrfSvg);
export const PacIcon = customIcon(SnfSvg);
export const LabsIcon = customIcon(LabsSvg);
export const LibraryIcon = customIcon(LibrarySvg);
export const MethodologyIcon = customIcon(MethodologySvg);
export const OfficeVisitsIcon = customIcon(OfficeVisitsSvg);
export const OutpatientIcon = customIcon(OutpatientSvg);
export const FollowNotificationsOnIcon = customIcon(FollowNotificationsOnSvg);
export const FollowNotificationsOffIcon = customIcon(FollowNotificationsOffSvg);
export const OutpatientRehabIcon = customIcon(OutpatientRehabSvg);
export const ProceduresIcon = customIcon(ProceduresSvg);
export const RadiationIcon = customIcon(RadiationSvg);
export const ShareIcon = customIcon(ShareSvg);
export const ReadmitIcon = customIcon(ReadmitSvg);
export const SnfIcon = customIcon(SnfSvg);
export const TherapyIcon = customIcon(TherapySvg);

// demo icons
export const DemoChatIcon = customIcon(DemoChatSvg);
export const DemoGridIcon = customIcon(DemoGridSvg);
export const DemoLibraryIcon = customIcon(DemoLibrarySvg);
export const DemoMailIcon = customIcon(DemoMailSvg);
export const DemoPersonIcon = customIcon(DemoPersonSvg);
export const DemoTupleIcon = customIcon(DemoTupleSvg);

function customIcon(Svg: React.ComponentType<React.SVGProps<SVGSVGElement>>) {
  const Component = ({ viewBox, ...props }: React.SVGProps<SVGSVGElement>) => <Svg {...props} />;
  return (props: SvgIconProps) => <SvgIcon component={Component} {...props} />;
}

export function VizIcon({ ns }: { ns: VizNs }): JSX.Element {
  switch (ns) {
    case 'gridViz':
      return <GridVizIcon />;
    case 'treeViz':
      return <TreeVizIcon />;
    case 'chartViz':
      return <ChartVizIcon />;
  }
}
