import { LinearProgress } from '@material-ui/core';
import React, { Suspense } from 'react';
import UncaughtError from '../../components/UncaughtError';
import Frame from '../../services/frame/frame.service';
import { ResolverContext } from '../resolver';
import { placeholder } from './screen';
import { GenericScreenProps } from './screen.widget';

interface State {
  error?: Error;
}

export default class ScreenView extends React.PureComponent<GenericScreenProps, State> {
  state: State = {};

  // set a temporary error state
  static getDerivedStateFromError = (error: Error) => ({ error });

  // notify parent of error
  componentDidCatch(error: Error) {
    this.props.onDidCatch(error);
  }
  // when prop error comes back, reset state error
  static getDerivedStateFromProps = (props: GenericScreenProps, state: State) => ({
    error: props.error ? undefined : state.error,
  });

  render() {
    const { props } = this;

    if (props.pathId === '@@init') return '';

    const frame = Frame.render({
      ...props.frame,
      children: this.renderChildren(),
    });

    return <ResolverContext.Provider value={props.resolve}>{frame}</ResolverContext.Provider>;
  }

  renderChildren() {
    const { props, state } = this;
    const error = props.error || state.error;

    if (error) return <UncaughtError error={error} />;

    const screen = props.screens[props.pathId];
    const View = screen.view || placeholder;

    return (
      <Suspense fallback={<LinearProgress />}>
        <View {...props.screen} />
      </Suspense>
    );
  }
}
