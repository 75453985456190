import { createContext, useContext } from 'react';
import { Persistence } from '../../contracts/Persistence';

const Context = createContext<Persistence | undefined>(undefined);

export const CookieProvider = Context.Provider;

export default function useCookie() {
  const context = useContext(Context);
  if (!context) throw Error('cookie context not provided');
  return context;
}
