/* eslint-disable @typescript-eslint/require-await */
import { constant, pipe } from '@tuple-health/common';
import { gatewayCookies } from '@tuple-health/common/dist/gateway';
import { Reply } from '@tuple-health/eng/dist/dryscript/ds';
import * as toGenericMsg from '@tuple-health/eng/dist/dryscript/lib/common/ui/content/msg/toGenericMsg';
import * as identityErrors from '@tuple-health/eng/dist/dryscript/lib/server/auth/identity/identityErrors';
import * as gatewayErrors from '@tuple-health/eng/dist/th/ds/common/agent/gatewayApi/service/gatewayErrors';
import { GatewaySession } from '@tuple-health/eng/dist/th/ds/common/agent/gatewayApi/service/session/GatewaySession';
import { IObservableValue, observable } from 'mobx';
import { merge as observableMerge, timer as observableTimer } from 'rxjs';
import { filter, first, map, mergeMap, tap, withLatestFrom } from 'rxjs/operators';
import { ofType } from 'unionize';
import { Authn } from '../../contracts/Authn';
import { Dispatch } from '../../contracts/Dispatch';
import { Gateway } from '../../contracts/Gateway';
import { Persistence } from '../../contracts/Persistence';
import { makeActions } from '../../core/action';
import { makeAgent, NOOP } from '../../core/agent';
import { makeProvider } from '../../core/provider';
import { makeReducer } from '../../core/reducer';
import { makeSelector } from '../../core/selector';
import { makeService } from '../../core/service';
import { makeStates } from '../../core/state';
import { Api, makeApi } from '../../services/gateway/api';
import { makeQueryCache } from '../query/query.cache';

const KEEPALIVE = 60 * 1000; // 1 minute
const SESSION_TIMEOUT = 20 * 60 * 1000; // 20 minutes

// ============================
//  state / action / reducer
// ============================
const State = makeStates({
  initializing: {},
  none: {},
  // We use a boxed observable value for the expiry as a temporary hack (pending the removal
  // of Redux) to prevent having to recreate all envs (and thereby doing a full page refresh)
  // every time the expiry updates.
  loggedIn: ofType<{ session: GatewaySession; expiry: IObservableValue<number> }>(),
});
type State = typeof State._Union;

const Action = makeActions({
  checkToken: {},
  initializeNone: {},
  initializeSession: ofType<GatewaySession>(),
  beginSession: ofType<GatewaySession>(),
  updateExpiry: ofType<number>(),
  continueSession: {},
  initiateLogout: {},
  clearSession: {},
});
type Action = typeof Action._Union;

const loggedIn = (session: GatewaySession) => {
  (session as any).queryCache = makeQueryCache();
  return observable(
    State.loggedIn({ session, expiry: observable.box(Date.now() + SESSION_TIMEOUT) }),
  );
};

const STATE_NONE = State.none();
const reducer = makeReducer<State, Action>(State.initializing(), s => a =>
  State.match(s, {
    initializing: () =>
      Action.match(a, {
        initializeNone: constant(STATE_NONE),
        initializeSession: loggedIn,
        default: constant(s),
      }),
    none: () =>
      Action.match(a, {
        initializeSession: loggedIn,
        beginSession: loggedIn,
        default: constant(s),
      }),
    loggedIn: state =>
      Action.match(a, {
        // initiateLogout: undefined, // HACK: need to better support no-op return value?
        clearSession: constant(STATE_NONE),
        // hack: mutate expiry in place so we don't regenerate stores on every keepalive
        updateExpiry: expiry => (state.expiry.set(expiry), s),
        default: constant(s),
      }),
  }),
);

// ============================
//  contract
// ============================
const AUTHN_INITIALIZING = Authn.initializing();
const provider = makeProvider(
  Authn,
  makeSelector({ dispatch: Dispatch })<State, Authn>(({ state, dispatch }) =>
    State.match({
      none: () =>
        Authn.none({
          beginSession: pipe(Action.beginSession, dispatch),
        }),

      loggedIn: loggedIn =>
        Authn.session({
          ...loggedIn,
          initiateLogout: () => dispatch(Action.initiateLogout()),
          continueSession: () => dispatch(Action.continueSession()),
        }),

      initializing: constant(AUTHN_INITIALIZING),
    })(state),
  ),
);

// ============================
//  agent
// ============================
const SESSION_ERRORS = [gatewayErrors.invalidSession.code, identityErrors.staleIdentity.code];
const agent = makeAgent({ gateway: Gateway, persistence: Persistence, dispatch: Dispatch })<State>(
  $ => {
    let clientHasBeenActive: () => boolean;

    return observableMerge(
      $.pipe(
        first(),
        tap(() => {
          // bind global activity listener
          clientHasBeenActive = subscribeActivityListener();
        }),
        // always check token when app starts
        map(() => Action.checkToken()),
      ),

      // TODO: expose application lifecycle actions
      $.pipe(
        filter(({ action }) => Action.is.checkToken(action as Action)),
        mergeMap(async ({ state, gateway, persistence }) => {
          (window as any).gatewayApi = gateway;

          // attempt to load session token from persistence
          const token = persistence.load(gatewayCookies.clientSideSessionToken);

          if (State.is.loggedIn(state)) {
            // logged out in another tab
            if (!token) return Action.initiateLogout();

            // reload page, somehow session was replaced with a new one
            if (token !== state.value.session.token) window.location.reload();

            // whoops, this shouldn't happen
            return NOOP.promise();
          } else {
            if (!token) return Action.initializeNone();

            // validate token to restore session
            try {
              const session = await makeApi(gateway, { token }).welcome();
              return Action.initializeSession(session);
            } catch (e) {
              return Action.initializeNone();
            }
          }
        }),
      ),

      $.pipe(
        mergeMap(({ action: untypedAction, state, gateway, persistence }) => {
          const action: Action = untypedAction as Action;
          return State.match(state, {
            initializing: constant(NOOP.observable()),
            none: () =>
              Action.match(action, {
                // agent receives state AFTER action applied in reducer
                initializeNone: () => {
                  persistence.clear(gatewayCookies.clientSideSessionToken);
                  persistence.clear(gatewayCookies.clientSideSessionTimeout);
                  return NOOP.observable();
                },
                clearSession: () => {
                  persistence.clear(gatewayCookies.clientSideSessionToken);
                  persistence.clear(gatewayCookies.clientSideSessionTimeout);
                  return NOOP.observable();
                },
                default: constant(NOOP.observable()),
              }),
            loggedIn: ({ session, expiry }) => {
              const api = makeApi(gateway, { session });

              return Action.match(action, {
                // agent receives state AFTER action applied in reducer
                initializeSession: NOOP.observable,
                beginSession: () => {
                  persistence.save(gatewayCookies.clientSideSessionToken, session.token);
                  persistence.save(gatewayCookies.clientSideSessionTimeout, `${expiry}`);
                  return NOOP.observable();
                },
                initiateLogout: async () => {
                  try {
                    // don't care about response, just want to let server know the token should expire
                    await api.logout();
                  } catch (e) {
                    // eslint-disable-next-line prefer-destructuring
                    const reply: Reply<void> = e.reply;
                    if (
                      reply.errors &&
                      reply.errors.anyMatch(e => !SESSION_ERRORS.includes(e.code))
                    )
                      throw new Error(toGenericMsg.fromReply(reply).text);
                  }
                  return Action.clearSession();
                },
                continueSession: () => pingServer(api, persistence),
                default: constant(NOOP.observable()),
              });
            },
            default: constant(NOOP.observable()),
          });
        }),
      ),

      // ping the server to keep session alive when user has been active
      observableTimer(KEEPALIVE, KEEPALIVE).pipe(
        withLatestFrom($),
        map(([_count, frame]) => frame),
        filter(({ state }) => State.is.loggedIn(state)),
        mergeMap(async ({ state, gateway, persistence }) => {
          const { session, expiry } = State.as.loggedIn(state);
          const api = makeApi(gateway, { session });

          if (clientHasBeenActive()) {
            return pingServer(api, persistence);
          } else if (Date.now() > expiry.get()) {
            return pingServer(api, persistence, false);
          }

          return NOOP.promise();
        }),
      ),
    );
  },
);

const pingServer = (api: Api, persistence: Persistence, activate = true) =>
  api
    .ping({ activate })
    .then(() => {
      if (activate) {
        const newExpiry = Date.now() + SESSION_TIMEOUT; // update timeout
        persistence.save(gatewayCookies.clientSideSessionTimeout, `${newExpiry}`);
        return Action.updateExpiry(newExpiry);
      }
      return NOOP.promise();
    })
    .catch(NOOP.promise); // Ignore failed pings

// ============================
//  service
// ============================
export const service = makeService({
  reducer,
  agent,
  providers: [provider],
});

// ============================
//  service
// ============================
function subscribeActivityListener() {
  let active = false;

  const setActive = () => (active = true);
  const options = { capture: true, passive: true };
  window.addEventListener('mousedown', setActive, options);
  window.addEventListener('wheel', setActive, options);
  window.addEventListener('keydown', setActive, options);

  return () => {
    const result = active;
    active = false;
    return result;
  };
}
