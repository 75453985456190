import * as _ from '@tuple-health/eng/dist/dryscript/ds';
import React, { useContext, useEffect, createContext, ReactNode } from 'react';
import {
  UserConfMachineArgs,
  useUserConfMachine,
  UserConfMachine,
} from '../machine/userConf.machine';
import { UserConfKey } from '../machine/userConf.model';
import useApi from '../../../core/store/useApi';
import useUser from '../../../core/store/useUser';
import { jsObj__dsDict, dsDict__jsObj } from '@tuple-health/common';

type Agent = UserConfMachine;

type AgentArgs = UserConfMachineArgs;

function useUserConfAgent(args: AgentArgs) {
  const machine = useUserConfMachine(args);
  const api = useApi();

  const { key__val } = machine;

  // optimistic effect
  useEffect(
    () =>
      void api.userUpdateConf({
        key__val: _.toDict.call(
          _.toDict
            .call(jsObj__dsDict(key__val).valWhere(val => val !== undefined))
            .valPipe(val => val!),
        ),
      }),
    [api, key__val],
  );

  return machine;
}

export function useUserConf() {
  const actor = useContext(UserConfContext);
  if (!actor) throw Error('No UserConf context found');
  return actor;
}

export const UserConfContext = createContext<Agent | undefined>(undefined);

export function UserConfCreator(props: { children: ReactNode }) {
  const { session } = useUser();
  // this is due to tests needing to be mocked. will clean up post mega merge
  const key__val = session.user
    ? (dsDict__jsObj<string>(session.user.userConf) as Record<UserConfKey, string>)
    : {};

  const actor = useUserConfAgent({ initialConf: key__val });

  return <UserConfContext.Provider value={actor}>{props.children}</UserConfContext.Provider>;
}
