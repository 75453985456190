import { DataRequest } from '@tuple-health/eng/dist/th/ds/common/tech/analytics/query/DataRequest';
import * as toDataQuery from '@tuple-health/eng/dist/th/ds/common/tech/analytics/query/toDataQuery';
import hash from 'object-hash';

export interface CubeRequest {
  deepQuery: string; // deepFilters, including from Dataset
  shallowQuery: string; // shallowFilters & columns
  comparison?: string;
}

export interface CustomerCubeRequest extends CubeRequest {
  customerId: string;
  fingerprint: string;
}

export function makeCustomerCubeRequest(
  request: Omit<CustomerCubeRequest, 'fingerprint'>,
): CustomerCubeRequest {
  return {
    ...request,
    fingerprint: hash(request),
  };
}

export function cubeRequest__dataRequest({ deepQuery, shallowQuery }: CubeRequest): DataRequest {
  return toDataQuery.cube(shallowQuery).request(deepQuery);
}
