import { createRouter, vocabulary } from '@tuple-health/common/dist/router';
import { createElement } from 'react';
import {
  AnchorIcon,
  ClinicianIcon,
  CrossBundleAnalysisIcon,
  DataStoryIcon,
  DiagnosticsIcon,
  DmeIcon,
  EdIcon,
  EpisodeIcon,
  FacilityIcon,
  HomeHealthIcon,
  HomeIcon,
  HospiceIcon,
  HospitalIcon,
  ImagingIcon,
  IrfIcon,
  LabsIcon,
  LtachIcon,
  MethodologyIcon,
  OfficeVisitsIcon,
  OutpatientIcon,
  OutpatientRehabIcon,
  PatientsIcon,
  ProceduresIcon,
  ReadmitIcon,
  SnfIcon,
} from '../../../components/icons/icons';
import { routerKinds } from '../../platform/v1PlatformConcepts';

// =============================================================================
// locs
// =============================================================================

// note: page names are stored in databases and links, so must preserve backwards compat
const locs = vocabulary({
  bpcia_customer_home: undefined,
  globals: undefined,
  // ===========================================================================
  // folders
  // ===========================================================================
  folders: undefined,
  // ===========================================================================
  // article
  // ===========================================================================
  bpcia_article_list: undefined,
  bpcia_article_detail: undefined,
  // ===========================================================================
  // patient
  // ===========================================================================
  bpcia_episodes: undefined,
  bpcia_patient_timeline: undefined,
  // ===========================================================================
  // clinician
  // ===========================================================================
  bpcia_clinician_list: undefined,
  bpcia_clinician_overview: undefined,
  // ===========================================================================
  // program overview
  // ===========================================================================
  bpcia_crossbundle_baseline_overview: undefined,
  bpcia_crossbundle_baseline_utilization: undefined,
  bpcia_crossbundle_pp_overview: undefined,
  bpcia_methodology: undefined,
  // ===========================================================================
  // bundle
  // ===========================================================================
  bundles: undefined,
  bundle: undefined,
  bundle_patients: undefined,
  bundle_facilities: undefined,
  // ===========================================================================
  // bundle and facility
  // ===========================================================================
  bundleAndFacility: undefined,
  bundleAndFacility_patients: undefined,
  // ===================================
  // bundleAndFacility.episodes
  // ===================================
  bpcia_episodes_metrics: undefined,
  bpcia_episodes_drgs: undefined,
  bpcia_episodes_clinicians: undefined,
  bpcia_episodes_performance: undefined,
  bpcia_patient_explorer: undefined,
  // ===================================
  // bundleAndFacility.anchor
  // ===================================
  bpcia_anchors_summary: undefined,
  bpcia_anchors_drgs: undefined,
  bpcia_anchors_outliers: undefined,
  // ===================================
  // bundleAndFacility.readmits
  // ===================================
  bpcia_readmits_overview: undefined,
  bpcia_readmits_utilization: undefined,
  bpcia_readmits_costs: undefined,
  bpcia_readmits_outliers: undefined,
  // bpcia_readmits_insights: undefined,
  // ===================================
  // bundleAndFacility.ed
  // ===================================
  bpcia_ed_overview: undefined,
  bpcia_ed_utilization: undefined,
  bpcia_ed_costs: undefined,
  bpcia_ed_outliers: undefined,
  // bpcia_ed_insights: undefined,
  // ===================================
  // bundleAndFacility.snf
  // ===================================
  bpcia_snf_overview: undefined,
  bpcia_snf_utilization: undefined,
  bpcia_snf_costs: undefined,
  bpcia_snf_outliers: undefined,
  bpcia_snf_insights: undefined,
  // ===================================
  // bundleAndFacility.irf
  // ===================================
  bpcia_irf_overview: undefined,
  bpcia_irf_utilization: undefined,
  bpcia_irf_costs: undefined,
  bpcia_irf_outliers: undefined,
  bpcia_irf_insights: undefined,
  // ===================================
  // bundleAndFacility.ltach
  // ===================================
  bpcia_ltach_overview: undefined,
  bpcia_ltach_utilization: undefined,
  bpcia_ltach_costs: undefined,
  bpcia_ltach_outliers: undefined,
  bpcia_ltach_insights: undefined,
  // ===================================
  // bundleAndFacility.imaging
  // ===================================
  bpcia_imaging_overview: undefined,
  bpcia_imaging_utilization: undefined,
  bpcia_imaging_costs: undefined,
  bpcia_imaging_outliers: undefined,
  bpcia_imaging_insights: undefined,
  // ===================================
  // bundleAndFacility.labs
  // ===================================
  bpcia_labs_overview: undefined,
  bpcia_labs_utilization: undefined,
  bpcia_labs_costs: undefined,
  bpcia_labs_outliers: undefined,
  bpcia_labs_insights: undefined,
  // ===================================
  // bundleAndFacility.hh
  // ===================================
  bpcia_hh_overview: undefined,
  bpcia_hh_utilization: undefined,
  bpcia_hh_costs: undefined,
  bpcia_hh_outliers: undefined,
  bpcia_hh_insights: undefined,
  // ===================================
  // bundleAndFacility.officeVisits
  // ===================================
  bpcia_officeVisits_overview: undefined,
  bpcia_officeVisits_utilization: undefined,
  bpcia_officeVisits_costs: undefined,
  bpcia_officeVisits_outliers: undefined,
  bpcia_officeVisits_insights: undefined,
  // ===================================
  // bundleAndFacility.outpatientRehab
  // ===================================
  bpcia_outpatientRehab_overview: undefined,
  bpcia_outpatientRehab_utilization: undefined,
  bpcia_outpatientRehab_costs: undefined,
  bpcia_outpatientRehab_outliers: undefined,
  bpcia_outpatientRehab_insights: undefined,
  // ===================================
  // bundleAndFacility.dme
  // ===================================
  bpcia_dme_overview: undefined,
  bpcia_dme_utilization: undefined,
  bpcia_dme_costs: undefined,
  bpcia_dme_outliers: undefined,
  bpcia_dme_insights: undefined,
  // ===================================
  // bundleAndFacility.hospice
  // ===================================
  bpcia_hospice_overview: undefined,
  bpcia_hospice_utilization: undefined,
  bpcia_hospice_costs: undefined,
  bpcia_hospice_outliers: undefined,
  bpcia_hospice_insights: undefined,
  // ===================================
  // bundleAndFacility.otherProcedures
  // ===================================
  bpcia_otherProcedures_overview: undefined,
  // bpcia_otherProcedures_utilization: undefined,
  // bpcia_otherProcedures_costs: undefined,
  // bpcia_otherProcedures_outliers: undefined,
  // bpcia_otherProcedures_insights: undefined,
  // ===========================================================================
  // facility
  // ===========================================================================
  facilities: undefined,
  facility: undefined,
  facility_patients: undefined,
  // ===========================================================================
  // sandbox
  // ===========================================================================
  sandbox: undefined,
});

export type BpciaLoc = typeof locs.Term;

// =============================================================================
// builder
// =============================================================================

const _ = createRouter<BpciaLoc>('bpcia', 'BPCI-A Insights', locs);

// =============================================================================
// folders
// =============================================================================

const folders = _.page({
  loc: 'folders',
  icon: createElement(DataStoryIcon),
  slug: 'folders',
  label: 'Data Stories',
  visibility: 'tupleStaff',
});

// =============================================================================
// articles
// =============================================================================

const bpciaArticles = _.page({
  loc: 'bpcia_article_list',
  icon: createElement(DataStoryIcon),
  slug: 'data-stories',
  label: 'Data Stories',
  childConcept: _.concept(
    routerKinds.article,
    _.page({
      loc: 'bpcia_article_detail',
      label: 'Data Story',
    }),
  ),
});

// =============================================================================
// patients
// =============================================================================

// TODO separate patients from episodes
const bpciaPatients = _.page({
  loc: 'bpcia_episodes',
  icon: createElement(PatientsIcon),
  slug: 'episodes',
  label: 'BPCI-A Episodes',
  childConcept: _.concept(
    routerKinds.patient,
    _.page({
      loc: 'bpcia_patient_timeline',
      label: 'Patient',
    }),
  ),
});

// =============================================================================
// clinicians
// =============================================================================

const bpciaClinicians = _.page({
  loc: 'bpcia_clinician_list',
  icon: createElement(ClinicianIcon),
  slug: 'clinicians',
  label: 'Clinicians',
  // label: 'Attending Clinicians',
  childConcept: _.concept(
    routerKinds.clinician,
    _.page({
      loc: 'bpcia_clinician_overview',
      label: 'Clinician',
    }),
  ),
});

// =============================================================================
// crossBundle
// =============================================================================

const crossBundle = _.tabMenu({
  slug: 'cross-bundle-overview',
  icon: createElement(CrossBundleAnalysisIcon),
  label: 'Cross-Bundle Overview',
  items: [
    _.page({
      loc: 'bpcia_crossbundle_baseline_overview',
      label: 'Overview',
    }),
    _.page({
      loc: 'bpcia_crossbundle_baseline_utilization',
      slug: 'baseline-utilization',
      label: 'Utilization',
    }),
    _.page({
      loc: 'bpcia_crossbundle_pp_overview',
      slug: 'performance-overview',
      label: 'Active Period Overview',
    }),
  ],
});

// =============================================================================
// facilities
// =============================================================================

const facility = _.concept(
  routerKinds.hospital,
  _.sideMenu({
    items: [
      _.page({
        loc: 'facility',
        icon: createElement(HomeIcon),
        label: 'Overview',
      }),
      _.page({
        loc: 'facility_patients',
        icon: createElement(PatientsIcon),
        slug: 'patients',
        label: 'Episodes',
      }),
    ],
  }),
);

// =============================================================================
// bundleAndFacility
// =============================================================================

// =====================================
// bundleAndFacility.episodes
// =====================================

const bundleAndFacilityEpisodeOverview = _.tabMenu({
  slug: 'episode-overview',
  icon: createElement(EpisodeIcon),
  label: 'Episode Overview',
  items: [
    _.page({
      loc: 'bpcia_episodes_metrics',
      label: 'Financial Results',
    }),
    _.page({
      loc: 'bpcia_episodes_drgs',
      slug: 'drgs',
      label: 'DRGs',
    }),
    _.page({
      loc: 'bpcia_episodes_clinicians',
      slug: 'clinicians',
      label: 'Clinicians',
    }),
    _.page({
      loc: 'bpcia_episodes_performance',
      slug: 'performance-period',
      label: 'Performance Period',
    }),
    _.page({
      loc: 'bpcia_patient_explorer',
      slug: 'patient-explorer',
      label: 'Patient Explorer',
    }),
  ],
});

// =====================================
// bundleAndFacillity.anchor
// =====================================

const bundleAndFacilityAnchorOverview = _.tabMenu({
  slug: 'anchor-overview',
  icon: createElement(AnchorIcon),
  label: 'Anchor Overview',
  items: [
    _.page({
      loc: 'bpcia_anchors_summary',
      label: 'Summary',
    }),
    _.page({
      loc: 'bpcia_anchors_drgs',
      slug: 'drgs',
      label: 'DRGs',
    }),
    _.page({
      loc: 'bpcia_anchors_outliers',
      slug: 'outliers',
      label: 'Outliers',
    }),
  ],
});

// =====================================
// bundleAndFacility.readmits
// =====================================

const bundleAndFacilityReadmits = _.tabMenu({
  slug: 'readmissions',
  icon: createElement(ReadmitIcon),
  label: 'Readmissions',
  items: [
    _.page({
      loc: 'bpcia_readmits_overview',
      label: 'Overview',
    }),
    _.page({
      loc: 'bpcia_readmits_utilization',
      slug: 'utilization',
      label: 'Utilization',
    }),
    _.page({
      loc: 'bpcia_readmits_costs',
      slug: 'costs',
      label: 'Costs',
    }),
    _.page({
      loc: 'bpcia_readmits_outliers',
      slug: 'outliers',
      label: 'Outliers',
    }),
  ],
});

// =====================================
// bundleAndFacility.ed
// =====================================

const bundleAndFacilityEd = _.tabMenu({
  slug: 'ed-visits',
  icon: createElement(EdIcon),
  label: 'ED Visits',
  items: [
    _.page({
      loc: 'bpcia_ed_overview',
      label: 'Overview',
    }),
    _.page({
      loc: 'bpcia_ed_utilization',
      slug: 'utilization',
      label: 'Utilization',
    }),
    _.page({
      loc: 'bpcia_ed_costs',
      slug: 'costs',
      label: 'Costs',
    }),
    _.page({
      loc: 'bpcia_ed_outliers',
      slug: 'outliers',
      label: 'Outliers',
    }),
  ],
});

// =====================================
// bundleAndFacilitySnf
// =====================================

const bundleAndFacilitySnf = _.tabMenu({
  slug: 'snf-stays',
  icon: createElement(SnfIcon),
  label: 'SNF Stays',
  items: [
    _.page({
      loc: 'bpcia_snf_overview',
      label: 'Overview',
    }),
    _.page({
      loc: 'bpcia_snf_utilization',
      slug: 'utilization',
      label: 'Utilization',
    }),
    _.page({
      loc: 'bpcia_snf_costs',
      slug: 'costs',
      label: 'Costs',
    }),
    _.page({
      loc: 'bpcia_snf_outliers',
      slug: 'outliers',
      label: 'Outliers',
    }),
    _.page({
      loc: 'bpcia_snf_insights',
      slug: 'insights',
      label: 'Key Insights',
      visibility: 'tupleStaff',
    }),
  ],
});

// =====================================
// bundleAndFacility.irf
// =====================================

const bundleAndFacilityIrf = _.tabMenu({
  slug: 'irf-stays',
  icon: createElement(IrfIcon),
  label: 'IRF Stays',
  items: [
    _.page({
      loc: 'bpcia_irf_overview',
      label: 'Overview',
    }),
    _.page({
      loc: 'bpcia_irf_utilization',
      slug: 'utilization',
      label: 'Utilization',
    }),
    _.page({
      loc: 'bpcia_irf_costs',
      slug: 'costs',
      label: 'Costs',
    }),
    _.page({
      loc: 'bpcia_irf_outliers',
      slug: 'outliers',
      label: 'Outliers',
    }),
    _.page({
      loc: 'bpcia_irf_insights',
      slug: 'insights',
      label: 'Key Insights',
      visibility: 'tupleStaff',
    }),
  ],
});

// =====================================
// bundleAndFacility.ltach
// =====================================

const bundleAndFacilityLtach = _.tabMenu({
  slug: 'ltach-stays',
  icon: createElement(LtachIcon),
  label: 'Ltach Stays',
  items: [
    _.page({
      loc: 'bpcia_ltach_overview',
      label: 'Overview',
    }),
    _.page({
      loc: 'bpcia_ltach_utilization',
      slug: 'utilization',
      label: 'Utilization',
    }),
    _.page({
      loc: 'bpcia_ltach_costs',
      slug: 'costs',
      label: 'Costs',
    }),
    _.page({
      loc: 'bpcia_ltach_outliers',
      slug: 'outliers',
      label: 'Outliers',
    }),
    _.page({
      loc: 'bpcia_ltach_insights',
      slug: 'insights',
      label: 'Key Insights',
      visibility: 'tupleStaff',
    }),
  ],
});

// =====================================
// bundleAndFacility.imaging
// =====================================

const bundleAndFacilityImaging = _.tabMenu({
  slug: 'imaging',
  icon: createElement(ImagingIcon),
  label: 'Imaging',
  items: [
    _.page({
      loc: 'bpcia_imaging_overview',
      label: 'Overview',
    }),
    _.page({
      loc: 'bpcia_imaging_utilization',
      slug: 'utilization',
      label: 'Utilization',
    }),
    _.page({
      loc: 'bpcia_imaging_costs',
      slug: 'costs',
      label: 'Costs',
    }),
    _.page({
      loc: 'bpcia_imaging_outliers',
      slug: 'outliers',
      label: 'Outliers',
    }),
    _.page({
      loc: 'bpcia_imaging_insights',
      slug: 'insights',
      label: 'Key Insights',
      visibility: 'tupleStaff',
    }),
  ],
});

// =====================================
// bundleAndFacility.labs
// =====================================

const bundleAndFacilityLabs = _.tabMenu({
  slug: 'labs',
  icon: createElement(LabsIcon),
  label: 'Labs',
  items: [
    _.page({
      loc: 'bpcia_labs_overview',
      label: 'Overview',
    }),
    _.page({
      loc: 'bpcia_labs_utilization',
      slug: 'utilization',
      label: 'Utilization',
    }),
    _.page({
      loc: 'bpcia_labs_costs',
      slug: 'costs',
      label: 'Costs',
    }),
    _.page({
      loc: 'bpcia_labs_outliers',
      slug: 'outliers',
      label: 'Outliers',
    }),
    _.page({
      loc: 'bpcia_labs_insights',
      slug: 'insights',
      label: 'Key Insights',
      visibility: 'tupleStaff',
    }),
  ],
});

// =====================================
// bundleAndFacility.hh
// =====================================

const bundleAndFacilityHH = _.tabMenu({
  slug: 'home-health',
  icon: createElement(HomeHealthIcon),
  label: 'Home Health',
  items: [
    _.page({
      loc: 'bpcia_hh_overview',
      label: 'Overview',
    }),
    _.page({
      loc: 'bpcia_hh_utilization',
      slug: 'utilization',
      label: 'Utilization',
    }),
    _.page({
      loc: 'bpcia_hh_costs',
      slug: 'costs',
      label: 'Costs',
    }),
    _.page({
      loc: 'bpcia_hh_outliers',
      slug: 'outliers',
      label: 'Outliers',
      visibility: 'tupleStaff',
    }),
    _.page({
      loc: 'bpcia_hh_insights',
      slug: 'insights',
      label: 'Key Insights',
      visibility: 'tupleStaff',
    }),
  ],
});

// =====================================
// bundleAndFacility.officeVisits
// =====================================

const bundleAndFacilityOfficeVisits = _.tabMenu({
  slug: 'office-visits',
  icon: createElement(OfficeVisitsIcon),
  label: 'Office Visits',
  items: [
    _.page({
      loc: 'bpcia_officeVisits_overview',
      label: 'Overview',
    }),
    _.page({
      loc: 'bpcia_officeVisits_utilization',
      slug: 'utilization',
      label: 'Utilization',
    }),
    _.page({
      loc: 'bpcia_officeVisits_costs',
      slug: 'costs',
      label: 'Costs',
    }),
    _.page({
      loc: 'bpcia_officeVisits_outliers',
      slug: 'outliers',
      label: 'Outliers',
    }),
    _.page({
      loc: 'bpcia_officeVisits_insights',
      slug: 'insights',
      label: 'Key Insights',
      visibility: 'tupleStaff',
    }),
  ],
});

// =====================================
// bundleAndFacility.outpatientRehab
// =====================================

const bundleAndFacilityOutpatientRehab = _.tabMenu({
  slug: 'outpatient-rehab',
  icon: createElement(OutpatientRehabIcon),
  label: 'Outpatient Rehab',
  items: [
    _.page({
      loc: 'bpcia_outpatientRehab_overview',
      label: 'Overview',
      visibility: 'tupleStaff',
    }),
    _.page({
      loc: 'bpcia_outpatientRehab_utilization',
      slug: 'utilization',
      label: 'Utilization',
      visibility: 'tupleStaff',
    }),
    _.page({
      loc: 'bpcia_outpatientRehab_costs',
      slug: 'costs',
      label: 'Costs',
      visibility: 'tupleStaff',
    }),
    _.page({
      loc: 'bpcia_outpatientRehab_outliers',
      slug: 'outliers',
      label: 'Outliers',
      visibility: 'tupleStaff',
    }),
    _.page({
      loc: 'bpcia_outpatientRehab_insights',
      slug: 'insights',
      label: 'Key Insights',
      visibility: 'tupleStaff',
    }),
  ],
});

// =====================================
// bundleAndFacility.dme
// =====================================

const bundleAndFacilityDme = _.tabMenu({
  slug: 'dme',
  icon: createElement(DmeIcon),
  label: 'DME',
  items: [
    _.page({
      loc: 'bpcia_dme_overview',
      label: 'Overview',
    }),
    _.page({
      loc: 'bpcia_dme_utilization',
      slug: 'utilization',
      label: 'Utilization',
    }),
    _.page({
      loc: 'bpcia_dme_costs',
      slug: 'costs',
      label: 'Costs',
    }),
    _.page({
      loc: 'bpcia_dme_outliers',
      slug: 'outliers',
      label: 'Outliers',
      visibility: 'tupleStaff',
    }),
    _.page({
      loc: 'bpcia_dme_insights',
      slug: 'insights',
      label: 'Key Insights',
      visibility: 'tupleStaff',
    }),
  ],
});

// =====================================
// bundleAndFacility.hospice
// =====================================

const bundleAndFacilityHospice = _.tabMenu({
  slug: 'hospice',
  icon: createElement(HospiceIcon),
  label: 'Hospice',
  items: [
    _.page({
      loc: 'bpcia_hospice_overview',
      label: 'Overview',
    }),
    _.page({
      loc: 'bpcia_hospice_utilization',
      slug: 'utilization',
      label: 'Utilization',
    }),
    _.page({
      loc: 'bpcia_hospice_costs',
      slug: 'costs',
      label: 'Costs',
    }),
    _.page({
      loc: 'bpcia_hospice_outliers',
      slug: 'outliers',
      label: 'Outliers',
    }),
    _.page({
      loc: 'bpcia_hospice_insights',
      slug: 'insights',
      label: 'Key Insights',
    }),
  ],
});

// =====================================
// bundleAndFacility
// =====================================

const bpciaBundleAndFacility = _.concept(
  routerKinds.hospital,
  _.sideMenu({
    items: [
      _.page({
        loc: 'bundleAndFacility',
        icon: createElement(HomeIcon),
        label: 'Overview',
      }),
      _.page({
        loc: 'bundleAndFacility_patients',
        icon: createElement(PatientsIcon),
        slug: 'patients',
        label: 'Episodes',
      }),
      bundleAndFacilityEpisodeOverview,
      bundleAndFacilityAnchorOverview,
      _.folder({
        label: 'Post-Anchor Hospital Use',
        icon: createElement(HospitalIcon),
        items: [bundleAndFacilityReadmits, bundleAndFacilityEd],
      }),
      _.folder({
        label: 'PAC Facilities',
        icon: createElement(FacilityIcon),
        items: [bundleAndFacilitySnf, bundleAndFacilityIrf, bundleAndFacilityLtach],
      }),
      _.folder({
        label: 'Diagnostics',
        icon: createElement(DiagnosticsIcon),
        items: [bundleAndFacilityImaging, bundleAndFacilityLabs],
      }),
      _.folder({
        label: 'Outpatient Support',
        icon: createElement(OutpatientIcon),
        items: [
          bundleAndFacilityHH,
          bundleAndFacilityOfficeVisits,
          bundleAndFacilityOutpatientRehab,
          bundleAndFacilityDme,
        ],
      }),
      bundleAndFacilityHospice,
      _.page({
        loc: 'bpcia_otherProcedures_overview',
        icon: createElement(ProceduresIcon),
        slug: 'post-anchor-procedures',
        label: 'Post-Anchor Procedures',
      }),
      _.page({
        loc: 'sandbox',
        icon: createElement(ProceduresIcon),
        slug: 'sandbox',
        label: 'Sandbox',
        visibility: 'tupleStaff',
      }),
    ],
  }),
);

// =============================================================================
// bundle
// =============================================================================

const bpciaBundle = _.concept(
  routerKinds.bpciaBundle,
  _.sideMenu({
    items: [
      _.page({
        loc: 'bundle',
        icon: createElement(HomeIcon),
        label: 'Overview',
        childItems: [
          _.page({
            loc: 'bundle_facilities',
            icon: createElement(MethodologyIcon),
            slug: 'facilities',
            label: 'Facilities',
            childConcept: bpciaBundleAndFacility,
          }),
        ],
      }),
      _.page({
        loc: 'bundle_patients',
        icon: createElement(PatientsIcon),
        slug: 'patients',
        label: 'Episodes',
      }),
    ],
  }),
);

// =============================================================================
// home
// =============================================================================

const home = _.sideMenu({
  items: [
    _.page({
      loc: 'bpcia_customer_home',
      icon: createElement(HomeIcon),
      label: 'Home',
      childItems: [
        bpciaArticles,
        _.page({
          loc: 'globals',
          slug: 'globals',
          label: 'Global Variables',
        }),
        _.page({
          loc: 'facilities',
          icon: createElement(MethodologyIcon),
          slug: 'facilities',
          label: 'Facilities',
          childConcept: facility,
        }),
        _.page({
          loc: 'bundles',
          icon: createElement(MethodologyIcon),
          slug: 'bundles',
          label: 'Bundles',
          childConcept: bpciaBundle,
        }),
      ],
    }),
    crossBundle,
    _.page({
      loc: 'bpcia_methodology',
      icon: createElement(MethodologyIcon),
      slug: 'methodology',
      label: 'BPCI-A Methodology',
    }),
  ],
});

// =============================================================================
// router
// =============================================================================

_.add(home);
_.add(bpciaPatients);
_.add(bpciaClinicians);
_.add(folders);

export const bpciaRouter = _.build();
